<script>
import analytics from '@/utilities/analytics'
import ManageSubscriptionWidget from '@/components/payments/ManageSubscriptionWidget.vue'
import { removeActionsCache } from '@/utilities/actionsCache'
import ResetPasswordForm from '@/components/form/ResetPasswordForm.vue'
import { useAuthStore } from '@/stores/AuthStore'
import UserSettingsForm from '@/components/form/UserSettingsForm.vue'

export default {
  components: {
    ManageSubscriptionWidget,
    ResetPasswordForm,
    UserSettingsForm,
  },
  setup() {
    return {
      authStore: useAuthStore(),
    }
  },
  methods: {
    onLogout() {
      analytics.trackEvent('Logging Out')
      this.authStore.logout()
        .then(() => {
          removeActionsCache()
          analytics.resetUser()
          this.$router.replace({ name: 'signin' })
          this.$router.go(0) // Reload to clear state (TODO: pinia way?)
        })
    },
  },
}
</script>

<template>
  <div class="settings">
    <h1 class="settings__header">
      Settings
    </h1>
    <UserSettingsForm class="settings__form" />
    <ResetPasswordForm class="settings__form" />
    <ManageSubscriptionWidget class="settings__widget" />
    <div class="user-form">
      <p class="settings__support">
        If you'd like to delete your account, or if you are having any other problems using Italic Type, please let us know at <a href="mailto:hello@italictype.com">hello@italictype.com</a>. (We'll streamline account deletion in the future, but it's a manual process for now &mdash; thanks for your understanding!).
      </p>
      <div class="user-form__input-wrap settings__logout-button">
        <BaseButton
          button-size="large"
          button-type="secondary"
          @click="onLogout"
        >
          Logout
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.settings {
  &__header {
    position: relative;
    padding: 0 6rem 0 8rem;

    @include respond-to('small') {
      padding: 1.25rem 2.5rem 0;
    }
  }

  &__form {
    margin-bottom: 3rem;

    @include respond-to('medium') {
      border-bottom: 1px solid $color-section-head-border;
      margin-bottom: 0;
    }
  }

  &__support {
    border-bottom: 1px solid $color-section-head-border;
    border-top: 1px solid $color-section-head-border;
    padding: 3rem 0;
    margin-bottom: 0;

    @include respond-to('medium') {
      padding: 2rem 0;
    }
  }

  &__widget {
    margin: 0 auto;
    margin-bottom: 3rem;
    width: 50rem;

    @include respond-to('small') {
      padding: 2rem;
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__logout-button {
    padding: 3rem 0;

    button {
      width: 100%;
      text-align: center;
    }
  }
}

h1 {
  text-align: left;
}
</style>
