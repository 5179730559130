<script>
</script>

<template>
  <div class="badge" />
</template>

<style lang='scss' scoped>
.badge {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: $color-notification-badge-bg;
  box-shadow: 0 0 1px #333;
}
</style>
