<script>
import GirlReadingFrown from '@/components/svgs/GirlReadingFrown.vue'

export default {
  components: {
    GirlReadingFrown,
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div id="not-found">
      <div class="not-found-blurb">
        <div class="not-found_head">
          <GirlReadingFrown class="image-error mar-b-xl" />
          <h1>That's Odd</h1>
          <p>This page appears to be missing.</p>
        </div>
        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'home' }"
        >
          <button @click="navigate">
            Go Home
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#not-found {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
}

.not-found-blurb {
  width: 45rem;
  margin: 0 auto;
  text-align: center;

  h1, p {
    margin: 0;
  }

  h1 {
    margin-bottom: 0.8rem;
  }

  .not-found_head {
    margin-bottom: 3.4rem;

    .image-error {
      width: 18.2rem;
      position: relative;
      margin: 0 auto;
    }
  }
}
</style>
