<script>
export default {
  name: 'CloseX',
}
</script>

<template>
  <div class="icon close-x">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
      <g transform="translate(-722 -928)">
        <g class="a-close-x" transform="translate(722 928)">
          <circle class="c-close-x" cx="46" cy="46" r="46" />
          <circle class="d-close-x" cx="46" cy="46" r="44.25" />
        </g>
        <g transform="translate(-633.262 994.374) rotate(-45)">
          <line class="b-close-x" y2="38.497" transform="translate(1005.248 957.5)" />
          <line class="b-close-x" y2="38.497" transform="translate(1024.497 976.748) rotate(90)" />
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang='scss' scoped>
.close-x {
  .a-close-x {
    fill: $color-white;
  }

  .a-close-x,.b-close-x {
    stroke: color.adjust($color-gainsboro, $lightness: -3%);
    stroke-width: 3.5px;
  }

  .b-close-x,.d-close-x {
    fill: none;
  }

  .b-close-x {
    stroke-linecap: round;
  }

  .c-close-x {
    stroke: none;
  }
}
</style>
