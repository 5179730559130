import axios from 'axios'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import * as Sentry from '@sentry/vue'
import MagicUrl from 'quill-magic-url'
import 'quill-mention'
import Quill from 'quill'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { useAuthStore } from './stores/AuthStore'
import { useQueueStore } from './stores/QueueStore'
import { ENDPOINT_AUTH_JWT_REFRESH } from './services/endpoints'

import './scss/base.scss'

Quill.register('modules/magicUrl', MagicUrl)

function initializeSentry(app) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      app,
      dsn: 'https://70090513cd494e2d94ab4507f2179be6@sentry.io/1251961',
      attachProps: true,
      logErrors: true,
    })
  }
}

function registerBaseComponents(app) {
  const requireComponent = require.context(
    './components/form',
    false,
    /Base[A-Z]\w+\.(vue|js)$/,
  )

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)

    const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')),
    )

    app.component(componentName, componentConfig.default || componentConfig)
  })
}

const app = createApp(App)
const pinia = createPinia()
initializeSentry(app)
app.use(router)
app.use(pinia)
registerBaseComponents(app)

const authStore = useAuthStore()
const queueStore = useQueueStore()

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.addTrailingSlash = true

// This solution is informed by the discussion found here:
// https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c?permalink_comment_id=3341286#gistcomment-3341286
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { config: originalRequest, response } = error
    if (originalRequest.url !== ENDPOINT_AUTH_JWT_REFRESH && !originalRequest.isRetryAttempt
        && response && response.status === 403) {
      try {
        await authStore.tryAutoLogin()
        originalRequest.isRetryAttempt = true
        originalRequest.headers.Authorization = axios.defaults.headers.common.Authorization
        return await axios.request(originalRequest)
      }
      catch (e) {
        if (e === 'No valid refresh tokens' || (e.response && e.response.status === 403)) {
          // Persistent forbidden errors, so log user out
          authStore.logout()
        }
        throw e
      }
    }
    else {
      throw error
    }
  },
)

// Load app settings before mounting the Vue instance but don't wait for return. We have
// sensible fallbacks in place.
queueStore.getSettings()
  .catch(() => {
    // Fail silently if we cannot retrieve the settings.
  })

authStore.tryAutoLogin()
  .then(() => {
    app.mount('#app')
  })
  .catch(() => {
    // We still want to mount the app on an error, but dispatch logout to clear all state
    authStore.logout()
      .then(() => {
        app.mount('#app')
      })
  })
