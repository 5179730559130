<script>
import BookListCard from './BookListCard.vue'
import BookListEmptyState from './BookListEmptyState.vue'
import BookListLoadMoreCard from './BookListLoadMoreCard.vue'
import BookListHeader from './BookListHeader.vue'
import LoadingBookCarousel from './LoadingBookCarousel.vue'
import { useQueueStore } from '@/stores/QueueStore'
import ArchivedBookshelfIllustration from '@/components/svgs/BookToEmptyBookshelfIllustration.vue'
import BookshelfIllustration from '@/components/svgs/BookshelfIllustration.vue'
import HoldingBooksIllustration from '@/components/svgs/HoldingBooksIllustration.vue'
import PausedBookshelfIllustration from '@/components/svgs/GirlPlantBookshelfIllustration.vue'

export default {
  components: {
    ArchivedBookshelfIllustration,
    BookListCard,
    BookListEmptyState,
    BookListHeader,
    BookListLoadMoreCard,
    BookshelfIllustration,
    HoldingBooksIllustration,
    LoadingBookCarousel,
    PausedBookshelfIllustration,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    listMeta: {
      type: Object,
      default: () => {},
    },
    listTotalCount: {
      type: Number,
      default: 0,
    },
    listType: {
      type: String,
      required: true,
      validator(value) {
        // The list type must match one of the four types.
        return ['queue', 'completed', 'paused', 'archived'].includes(value)
      },
    },
    loadMoreActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['loadMoreBooks'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    listLength() {
      let length
      switch (this.listType) {
        case 'queue':
          length = this.queueStore.queueBooksLength
          break
        case 'completed':
          length = this.queueStore.completedBooksLength
          break
        case 'paused':
          length = this.queueStore.pausedBooksLength
          break
        case 'archived':
          length = this.queueStore.archivedBooksLength
          break
      }
      return length
    },
    emptyStateImage() {
      let component
      switch (this.listType) {
        case 'queue':
          component = 'HoldingBooksIllustration'
          break
        case 'completed':
          component = 'BookshelfIllustration'
          break
        case 'paused':
          component = 'PausedBookshelfIllustration'
          break
        case 'archived':
          component = 'ArchivedBookshelfIllustration'
          break
      }
      return component
    },
    emptyStateTitle() {
      let title
      switch (this.listType) {
        case 'queue':
          title = 'Search for books in the top bar or in Explore. Select "add" to add a book '
                  + 'to your queue.'
          break
        case 'completed':
          title = 'Once you complete your books, it will show up here for you to continue to '
                  + 'add notes and review.'
          break
        case 'paused':
          title = 'Need to take a break from reading a book? Pause it to restart it later.'
          break
        case 'archived':
          title = 'Books that you are never going to finish can be moved to the archives.'
          break
      }
      return title
    },
    viewAllURL() {
      // If the list is empty, null out the view all url so that it doesn't show up.
      let url = ''
      if (this.listMeta && this.list && this.list.length > 0)
        url = this.listMeta.url

      return url
    },
  },
  methods: {
    pluralCondition(list) {
      return list.length > 1 ? 's' : ''
    },
  },
}
</script>

<template>
  <div id="book-list">
    <BookListHeader
      v-if="listMeta"
      :border-top="listMeta.borderTop"
      :name="listMeta.name"
      :description="listMeta.description"
      :list-url="viewAllURL"
      :list-length="listLength"
      :list-total-count="listTotalCount"
    />
    <LoadingBookCarousel v-if="queueStore.loading.bookLists" />
    <div v-else>
      <div v-if="list && list.length && listType" class="book-list__book-carousel">
        <BookListCard
          v-for="item in list"
          :key="item.id"
          :item="item"
          :list-type="listType"
        />
        <BookListLoadMoreCard
          v-if="loadMoreActive"
          :list-type="listType"
          @load-more-books="$emit('loadMoreBooks')"
        />
      </div>
      <BookListEmptyState v-else>
        <template #empty-state-image>
          <component :is="emptyStateImage" />
        </template>
        <template #empty-state-title>
          {{ emptyStateTitle }}
        </template>
        <template #empty-state-cta>
          <router-link v-if="listType === 'completed'" :to="{ name: 'file-import-export' }">
            Upload your Goodreads reading history
          </router-link>
        </template>
      </BookListEmptyState>
    </div>
    <router-link
      v-if="viewAllURL"
      class="book-list__mobile-view-all"
      :to="viewAllURL"
    >
      View All {{ listType }} Books
    </router-link>
  </div>
</template>

<style lang='scss' scoped>
.book-list {
  &__book-carousel {
    position: relative;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 3rem 8rem 6rem;

    &::after {
      content: "";
      flex: 0 0 1rem;
    }

    @include respond-to('small') {
      padding: 1.6rem 8rem 2rem 2rem;
    }
  }

  &__mobile-view-all {
    display: none;
    font: $body2;
    text-align: left;
    padding-left: 2.5rem;
    text-decoration: none;
    text-transform: capitalize;

    .material-icons {
      @include font-size(1.8);

      vertical-align: middle;
    }

    @include respond-to('small') {
      display: block;
    }
  }
}
</style>
