<script>
import debounce from 'lodash/debounce'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import SearchResultsList from '@/components/search/SearchResultsList.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    SearchResultsList,
    MiniLoader,
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      bookSearch: null,
      joinedTerms: null,
      nothingFound: {
        state: false,
        message: 'Nothing found for your search.',
      },
      mobileVisibilityToggle: false,
    }
  },
  computed: {
    showResults() {
      return (this.bookSearch && this.queueStore.search.results
        && this.queueStore.search.results.length)
    },
  },
  watch: {
    bookSearch(value) {
      if (!value) {
        /*
          If debounce fires once more after search value is cleared out
          a result will still be shown in search list, so we will watch
          the search term and when it empties, wait a longer period than
          the debounce, and clear it out.
        */
        setTimeout(() => {
          this.queueStore.setSearchResults([])
        }, 1000)
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.onDismissiveClick)
  },
  methods: {
    handleSearchFocus() {
      if (this.bookSearch && !this.queueStore.search.results) {
        this.joinedTerms = this.bookSearch.toLowerCase().split(' ').join('+')
        this.queueStore.searchBooks({ query: this.joinedTerms })
      }
    },
    handleBookSearch: debounce(function () {
      if (this.bookSearch && this.bookSearch.split('').length > 2) {
        this.joinedTerms = this.bookSearch.toLowerCase().split(' ').join('+')
        this.queueStore.searchBooks({ query: this.joinedTerms })
      }
      else {
        this.queueStore.setSearchResults([])
        this.joinedTerms = null
      }
    }, 300),
    goToFullSearch() {
      this.$router.push({ name: 'search', query: { q: this.joinedTerms } })
    },
    onDismissiveClick(evt) {
      /**
       * In mini nav-based search only remove result when clicking
       * on anything except for results and search input.
       */
      if (!evt.target.closest('#mini-book-results') && !evt.target.closest('#it-search-input')
          && this.$route.path !== '/search')
        this.queueStore.search.results = null
    },
  },
}
</script>

<template>
  <div class="mini-search">
    <input
      id="it-search-input"
      v-model.trim="bookSearch"
      type="text"
      class="mini-search__input"
      placeholder="Find a book..."
      @input="handleBookSearch"
      @keyup.enter="goToFullSearch"
      @focus="handleSearchFocus"
    >
    <MiniLoader v-if="queueStore.loading.search" class="mini-search__loader" />
    <span
      v-if="queueStore.search.results && !queueStore.loading.search"
      class="mini-search__ui-hint"
    >
      ENTER to see all
    </span>
    <i
      v-if="bookSearch"
      class="material-icons mini-search__clear-icon cur-p"
      @click="bookSearch = null"
    >
      close
    </i>
    <i
      v-else
      class="material-icons mini-search__search-icon"
    >
      search
    </i>
    <div
      id="mini-book-results"
      class="mini-search__results"
      :class="{ 'mini-search__results--show': showResults }"
    >
      <SearchResultsList
        :search-value="bookSearch"
        mode="list"
        @see-all="goToFullSearch"
        @close-results="queueStore.search.results = null"
      />
      <p
        v-if="!queueStore.search.results || !queueStore.search.results.length"
        class="mini-search__results__empty-message"
      >
        {{ nothingFound.message }}
      </p>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.mini-search__input {
  width: 100%;
  border-bottom: 1px solid $color-gray;
  border-radius: 0;
  padding-left: 0;
  padding-bottom: 1rem;
  background-color: transparent;

  @include respond-to('small') {
    padding: 0.65rem 1.5rem;
    background-color: $color-mini-search-input-bg;
    border-radius: 0.4rem;
    border: none;
    transition: all 0.18s ease-in-out;

    &:focus {
      background-color: $color-snow-drift;
    }
  }
}

.mini-search__loader {
  position: absolute;
  top: 50%;
  right: 2rem;
}

.mini-search__ui-hint {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3.15rem;
  font-size: 1.1rem;
  color: $color-mini-search-icon;
}

.mini-search__clear-icon, .mini-search__search-icon {
  display: block;
  position: absolute;
  top: 1rem;
  right: 0;
  font-size: 2.25rem;
  color: $color-mini-search-icon;

  @include respond-to('small') {
    top: 0.5rem;
    right: 0.5rem;
  }
}

.mini-search__results {
  position: absolute;
  top: 7rem;
  width: 100%;
  height: 43.5rem;
  padding: 1rem 2rem;
  overflow-y: scroll;
  background-color: $color-white;
  box-shadow: $mini-search-shadow;
  border-radius: 0.8rem;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -20px, 0);
  transition: all 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  min-width: 36rem;

  &--show {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  &__empty-message {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @include respond-to('small') {
    top: 10rem;
    position: fixed;
    left: 0;
    background-color: $color-mini-search-results-bg;
  }
}
</style>
