/* refreshPage.js
 *
 * The refresh page composable, checks for front end distribution updates, and will trigger a reload.
 */
import { ref } from 'vue'
import { useQueueStore } from '@/stores/QueueStore'

export function useRefreshPage() {
  const queueStore = useQueueStore()
  let currentHash = '{{POST_BUILD_REPLACES_HASH_HERE}}'
  let newHash = ''
  let versionCheckIntervalId = null
  const versionCheckInterval = 1000 * 60 * 2

  const hashChanged = ref(false)

  function checkVersion() {
    queueStore.getSettings()
      .then((settings) => {
        newHash = settings.APP_VERSION_HASH
        hashChanged.value = hasHashChanged(currentHash, newHash)
      })
      .catch(() => {
        // Fail silently if we cannot retrieve the settings.
      })
  }

  function hasHashChanged(currentHashValue, newHashValue) {
    return currentHashValue !== newHashValue
  }

  function initVersionCheck() {
    versionCheckIntervalId = setInterval(() => {
      checkVersion()
    }, versionCheckInterval)
  }

  function teardownVersionCheck() {
    if (versionCheckIntervalId)
      clearInterval(versionCheckIntervalId)
  }

  function reloadApp() {
    currentHash = newHash
    window.location.reload()
  }

  return {
    hashChanged,
    initVersionCheck,
    reloadApp,
    teardownVersionCheck,
  }
}
