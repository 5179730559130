<script>
import NotificationItem from '@/components/notifications/NotificationItem.vue'

export default {
  components: {
    NotificationItem,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  mounted() {
    document.addEventListener('click', (evt) => {
      if (!evt.target.closest('#notification-list-id'))
        this.open = false
    })
  },
}
</script>

<template>
  <div id="notification-list-id" class="notification-list">
    <div class="notification-list__slot" @click="open = !open">
      <slot class="notification-list__trigger" />
    </div>
    <transition name="drop-fade">
      <div v-if="open" class="notification-list__item-wrap align-right">
        <template v-if="notifications.length">
          <NotificationItem
            v-for="(notification, i) in notifications"
            :key="i"
            :notification="notification"
          />
        </template>
        <template v-else>
          <p class="notification-list__item-wrap__empty-message">
            No new chat message notifications.
          </p>
        </template>
      </div>
    </transition>
  </div>
</template>

<style lang='scss' scoped>
.notification-list {
  &__trigger {
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__item-wrap {
    position: absolute;
    top: 3.5rem;
    width: 30rem;
    padding: 1rem 0.8rem;
    background-color: $color-white;
    box-shadow: $pop-menu-shadow;
    z-index: 2;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -7px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $color-white;
    }

    &.align-right {
      right: -2.5rem;

      &::before {
        left: 80%;
      }
    }

    &__empty-message {
      margin-bottom: 0;
      padding: 1rem;
      text-align: left;
    }
  }
}

.drop-fade-enter-active, .drop-fade-leave-active {
  transition: all 0.15s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.drop-fade-enter-from, .drop-fade-leave-to {
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}
</style>
