/* queueEventHandler.js
 *
 * When building out views throughout the application, we started to realize that the event
 * handlers were being copy and pasted everywhere, and we were violating the DRY principle of
 * coding in this project. This is an attempt to fix this through an event handler composable.
 *
 */
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import {
  CODEX_SOURCE_NAME,
  CODEX_SOURCE_NAME_ID,
  GOOGLE_BOOKS_SOURCE_NAME,
  GOOGLE_BOOKS_SOURCE_NAME_ID,
  MANUAL_WEB_SCRAPE_SOURCE_NAME,
  MANUAL_WEB_SCRAPE_SOURCE_NAME_ID,
} from '@/services/endpoints'
import {
  ARCHIVE_BOOK_EVENT,
  CLEAR_FINISHED_DATE_EVENT,
  COMPLETE_BOOK_EVENT,
  MOVE_BOOK_TO_QUEUE_EVENT,
  PAUSE_BOOK_EVENT,
  RECOMMEND_BOOK_EVENT,
  REMOVE_BOOK_EVENT,
  RESTART_BOOK_EVENT,
  REVIEW_BOOK_EVENT,
  START_BOOK_EVENT,
  VIEW_BOOK_DETAIL_EVENT,
} from '@/customEvents'
import { useQueueStore } from '@/stores/QueueStore'

export function useQueueEventHandler() {
  const queueStore = useQueueStore()
  const router = useRouter()

  const bookQueueItemCompleted = ref(null)
  const bookQueueItemRecommended = ref(null)
  const showRecommendationModal = ref(false)
  const showReviewCreateModal = ref(false)
  const showReviewViewEditModal = ref(false)

  function clearBookFinishedDateHandler(bookQueueItem) {
    const confirmation = confirm('Are you sure that you want to clear the finished date?') // eslint-disable-line no-alert
    if (confirmation) {
      queueStore.clearDateFinished(bookQueueItem)
        .then(() => {
          queueStore.setAlert({
            type: 'success',
            message: `The finished date for ${bookQueueItem.book.title} has been cleared.`,
            active: true,
          })
        })
    }
  }

  function completeBookHandler(bookQueueItem) {
    bookQueueItemCompleted.value = bookQueueItem
    if (bookQueueItem.user_review)
      showReviewViewEditModal.value = true
    else
      showReviewCreateModal.value = true
  }

  function moveBookToQueueHandler(bookQueueItem) {
    queueStore.loading.userBookAction.item = bookQueueItem
    queueStore.moveBookBackToQueue(bookQueueItem)
      .then((response) => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        queueStore.setAlert({
          type: 'success',
          message: `${response.data.book.title} was successfully moved back to your queue.`,
          active: true,
        })
      })
      .catch(() => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        const message = 'An error occurred moving your book back to the queue. Please try again.'
        queueStore.setAlert({
          type: 'error',
          message: `${message}`,
          active: true,
        })
      })
  }

  function pauseBookHandler(bookQueueItem) {
    queueStore.loading.userBookAction.item = bookQueueItem
    queueStore.pauseReadingBook(bookQueueItem)
      .then((response) => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        queueStore.setAlert({
          type: 'success',
          message: `${response.data.book.title} was successfully paused for now.`,
          active: true,
        })
      })
      .catch(() => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        const message = 'An error occurred pausing the reading of your book. Please try again.'
        queueStore.setAlert({
          type: 'error',
          message: `${message}`,
          active: true,
        })
      })
  }

  function archiveBookHandler(bookQueueItem) {
    queueStore.loading.userBookAction.item = bookQueueItem
    queueStore.archiveBook(bookQueueItem)
      .then((response) => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        queueStore.setAlert({
          type: 'success',
          message: `${response.data.book.title} was successfully archived.`,
          active: true,
        })
      })
      .catch(() => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        const message = 'An error occurred archiving your book. Please try again.'
        queueStore.setAlert({
          type: 'error',
          message: `${message}`,
          active: true,
        })
      })
  }

  function recommendBookHandler(bookQueueItem) {
    bookQueueItemRecommended.value = bookQueueItem
    showRecommendationModal.value = true
  }

  function removeBookHandler(bookQueueItem) {
    const confirmation = confirm('Are you sure that you want to remove this book forever?') // eslint-disable-line no-alert
    if (confirmation) {
      queueStore.loading.userBookAction.item = bookQueueItem
      queueStore.removeBook(bookQueueItem)
        .then(() => {
          queueStore.loading.userBookAction.item = null
          queueStore.loading.saveBook.item = null
          queueStore.setAlert({
            type: 'success',
            message: `${bookQueueItem.book.title} was removed from your book lists.`,
            active: true,
          })
          queueStore.removeIsbnFromList(bookQueueItem.book)
        })
        .catch(() => {
          queueStore.loading.userBookAction.item = null
          queueStore.loading.saveBook.item = null
          const message = 'There was an error removing this book from your queue. Please try again.'
          queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    }
  }

  function restartBookHandler(bookQueueItem) {
    queueStore.loading.userBookAction.item = bookQueueItem
    queueStore.startReadingBook(bookQueueItem)
      .then((response) => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        queueStore.setAlert({
          type: 'success',
          message: `${response.data.book.title} was successfully added to your currently reading list to read again.`,
          active: true,
        })
      })
      .catch(() => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        const message = 'An error occurred while trying to restart reading this book. Please try again.'
        queueStore.setAlert({
          type: 'error',
          message: `${message}`,
          active: true,
        })
      })
  }

  function reviewBookHandler(bookQueueItem) {
    bookQueueItemCompleted.value = bookQueueItem
    if (bookQueueItem.user_review)
      showReviewViewEditModal.value = true
    else
      showReviewCreateModal.value = true
  }

  function startBookHandler(bookQueueItem) {
    queueStore.loading.userBookAction.item = bookQueueItem
    queueStore.startReadingBook(bookQueueItem)
      .then((response) => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        queueStore.setAlert({
          type: 'success',
          message: `${response.data.book.title} was successfully added to your currently reading list.`,
          active: true,
        })
      })
      .catch(() => {
        queueStore.loading.saveBook.item = null
        queueStore.loading.userBookAction.item = null
        const message = 'An error occurred while trying to start reading this book. Please try again.'
        queueStore.setAlert({
          type: 'error',
          message: `${message}`,
          active: true,
        })
      })
  }

  function viewBookDetailHandler(bookQueueItem) {
    let sourceNameId = GOOGLE_BOOKS_SOURCE_NAME_ID
    switch (bookQueueItem.book.source_name) {
      case CODEX_SOURCE_NAME:
        sourceNameId = CODEX_SOURCE_NAME_ID
        break
      case GOOGLE_BOOKS_SOURCE_NAME:
        sourceNameId = GOOGLE_BOOKS_SOURCE_NAME_ID
        break
      case MANUAL_WEB_SCRAPE_SOURCE_NAME:
        sourceNameId = MANUAL_WEB_SCRAPE_SOURCE_NAME_ID
        break
    }
    router.push({
      name: 'book-detail',
      params: {
        sourceNameId,
        sourceUid: bookQueueItem.book.source_uid,
        bookSlug: bookQueueItem.book.slug,
      },
    })
  }

  function proxyEvent(eventName, eventData) {
    /*
     * This function is the main entry point to all events coming from all other modules.
     */
    const eventDispatchTable = {}
    eventDispatchTable[ARCHIVE_BOOK_EVENT] = archiveBookHandler
    eventDispatchTable[CLEAR_FINISHED_DATE_EVENT] = clearBookFinishedDateHandler
    eventDispatchTable[COMPLETE_BOOK_EVENT] = completeBookHandler
    eventDispatchTable[MOVE_BOOK_TO_QUEUE_EVENT] = moveBookToQueueHandler
    eventDispatchTable[PAUSE_BOOK_EVENT] = pauseBookHandler
    eventDispatchTable[RECOMMEND_BOOK_EVENT] = recommendBookHandler
    eventDispatchTable[REMOVE_BOOK_EVENT] = removeBookHandler
    eventDispatchTable[RESTART_BOOK_EVENT] = restartBookHandler
    eventDispatchTable[REVIEW_BOOK_EVENT] = reviewBookHandler
    eventDispatchTable[START_BOOK_EVENT] = startBookHandler
    eventDispatchTable[VIEW_BOOK_DETAIL_EVENT] = viewBookDetailHandler
    eventDispatchTable[eventName](eventData)
  }

  return {
    bookQueueItemCompleted,
    bookQueueItemRecommended,
    showRecommendationModal,
    showReviewCreateModal,
    showReviewViewEditModal,
    proxyEvent,
    clearBookFinishedDateHandler,
    completeBookHandler,
    moveBookToQueueHandler,
    pauseBookHandler,
    recommendBookHandler,
    removeBookHandler,
    restartBookHandler,
    reviewBookHandler,
    startBookHandler,
    viewBookDetailHandler,
  }
}
