<script>
import {
  DISPLAY_STATE_ARCHIVED,
  DISPLAY_STATE_COMPLETED,
  DISPLAY_STATE_CURRENTLY_READING,
  DISPLAY_STATE_PAUSED,
  DISPLAY_STATE_QUEUE,
} from '@/services/endpoints'
import {
  COMPLETE_BOOK_EVENT,
  RECOMMEND_BOOK_EVENT,
  RESTART_BOOK_EVENT,
  REVIEW_BOOK_EVENT,
  START_BOOK_EVENT,
} from '@/customEvents'
import GroupMemberAvatars from '@/components/users/GroupMemberAvatars.vue'
import NotificationBadge from '@/components/notifications/NotificationBadge.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useQueueStore } from '@/stores/QueueStore'
import UserAvatar from '@/components/users/UserAvatar.vue'

export default {
  components: {
    GroupMemberAvatars,
    NotificationBadge,
    UserAvatar,
  },
  props: {
    bookBoard: {
      type: Object,
      required: true,
    },
    chatGroups: {
      type: Array,
      required: true,
    },
    showMobileNav: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['closeMobileNav', 'inviteReaders'],
  setup() {
    return {
      authStore: useAuthStore(),
      queueStore: useQueueStore(),
    }
  },
  computed: {
    bookDetailUrl() {
      const detailUrl = new URL(this.queueStore.currentBookQueueItem.book.book_detail_url)
      return `${detailUrl.pathname}?back=${this.$route.path}`
    },
    buttonLabel() {
      return this.getLabelAndEvent()[0]
    },
    inviteReadersDisabled() {
      return this.queueStore.currentBookQueueItem.display_state === DISPLAY_STATE_COMPLETED
    },
  },
  watch: {
    $route() {
      this.$emit('closeMobileNav')
    },
  },
  methods: {
    authorList() {
      return this.bookBoard.book.authors.join(', ')
    },
    groupName(group) {
      let name
      if (group.is_one_to_one) {
        const member = this.authStore.user.username === group.members[0].username ? group.members[1] : group.members[0]
        name = member.name.length > 0 ? member.name : member.username
      }
      else {
        const members = [...group.members]
        const readerLength = members.length
        const inviterLength = group.users_invited

        // If logged in user is in the member list (which they should be), remove from list
        const index = members.findIndex(member => member.username === this.authStore.user.username)
        if (~index)
          members.splice(index, 1)

        if (members.length > 0) {
          const pluralReader = members.length > 1 ? 'readers' : 'reader'
          name = group.name || `Group (${readerLength} ${pluralReader})`
        }
        else {
          name = group.name || `Group (${inviterLength} invited)`
        }
      }
      return name
    },
    getLabelAndEvent() {
      if (!this.queueStore.currentBookQueueItem)
        return []

      let labelAndEvent
      switch (this.queueStore.currentBookQueueItem.display_state) {
        case DISPLAY_STATE_QUEUE:
          labelAndEvent = ['Start Reading Book', START_BOOK_EVENT]
          break
        case DISPLAY_STATE_CURRENTLY_READING:
          labelAndEvent = ['Finish Book', COMPLETE_BOOK_EVENT]
          break
        case DISPLAY_STATE_COMPLETED:
          labelAndEvent = ['Recommend Book', RECOMMEND_BOOK_EVENT]
          break
        case DISPLAY_STATE_PAUSED:
        case DISPLAY_STATE_ARCHIVED:
          labelAndEvent = ['Re-start Book', RESTART_BOOK_EVENT]
          break
      }
      return labelAndEvent
    },
    handleSecondaryButtonState() {
      this.queueStore.setQueueEvent({
        name: this.getLabelAndEvent()[1],
        item: this.queueStore.currentBookQueueItem,
      })
    },
    handleReview() {
      this.queueStore.setQueueEvent({
        name: REVIEW_BOOK_EVENT,
        item: this.queueStore.currentBookQueueItem,
      })
    },
  },
}
</script>

<template>
  <div class="bookboard-nav" :class="`bookboard-nav${showMobileNav ? '--open' : ''}`">
    <div class="bookboard-nav__details">
      <router-link
        v-slot="{ navigate }"
        custom
        :to="{ name: 'app-root' }"
      >
        <i class="material-icons bookboard-nav__details__back" @click="navigate">
          keyboard_arrow_left
        </i>
      </router-link>
      <i class="material-icons bookboard-nav__details__mobile-close" @click="$emit('closeMobileNav')">
        close
      </i>
      <router-link :to="bookDetailUrl">
        <img class="bookboard-nav__details__cover mar-b-l" :src="bookBoard.book.cover_image_url">
      </router-link>
      <router-link :to="bookDetailUrl">
        <h2 class="bookboard-nav__details__title">
          {{ bookBoard.book.title }}
        </h2>
      </router-link>
      <p class="bookboard-nav__details__author">
        by {{ authorList() }}
      </p>
    </div>
    <div class="bookboard-nav__menu-items">
      <router-link
        v-slot="{ isExactActive, navigate }"
        custom
        :to="{ name: 'bookboard', params: { bookSlug: bookBoard.book.slug } }"
      >
        <div
          class="bookboard-nav__menu-items__item"
          :class="{ 'router-link-exact-active': isExactActive }"
          @click="navigate"
        >
          <UserAvatar
            class="bookboard-nav__menu-items__item__avatar"
            :user="authStore.user"
            width="3rem"
            height="3rem"
          />
          <span>My Notes</span>
        </div>
      </router-link>
      <router-link
        v-for="(group, i) in chatGroups"
        :key="group.id"
        v-slot="{ isExactActive, navigate }"
        custom
        :to="{ name: 'chatgroup', params: { groupId: group.id } }"
      >
        <div
          class="bookboard-nav__menu-items__item"
          :class="{
            'first': i === 0,
            'last': i === (chatGroups.length - 1),
            'router-link-exact-active': isExactActive,
          }"
          @click="navigate"
        >
          <GroupMemberAvatars :group="group" />
          <span class="bookboard-nav__menu-items__item__text">{{ groupName(group) }}</span>
          <NotificationBadge v-if="group.unread_messages" class="bookboard-nav__menu-items__item__badge" />
        </div>
      </router-link>
    </div>
    <div v-if="queueStore.currentBookQueueItem" class="bookboard-nav__controls">
      <BaseButton
        v-if="inviteReadersDisabled"
        class="bookboard-nav__controls__button mar-b-m"
        button-size="large"
        button-type="primary"
        @click="handleReview"
      >
        Add / View Review
      </BaseButton>
      <BaseButton
        v-else
        class="bookboard-nav__controls__button mar-b-m"
        button-size="large"
        button-type="primary"
        @click="$emit('inviteReaders')"
      >
        Start a Group Chat
      </BaseButton>
      <BaseButton
        class="bookboard-nav__controls__button"
        button-size="large"
        button-type="secondary"
        @click="handleSecondaryButtonState"
      >
        {{ buttonLabel }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.bookboard-nav {
  width: 28rem;
  height: 100%;
  background: $color-seashell;
  position: fixed;
  text-align: left;
  z-index: 1;

  &__details {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    padding: 3.5rem 3rem 1rem;
    text-align: center;

    &__back {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      cursor: pointer;

      @include respond-to('medium') {
        display: none;
      }
    }

    &__mobile-close {
      position: absolute;
      top: 2rem;
      left: 1rem;
      display: none;
      cursor: pointer;

      @include respond-to('medium') {
        display: block;
      }
    }

    &__cover {
      position: relative;
      display: block;
      width: 10rem;
      border: 5px solid $color-white;
      box-shadow: 0 2px 8px 2px rgb(0 0 0 / 8%);
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 1rem;

      @include font-size(2);
      @include line-height(2.4);
    }

    &__author {
      font-weight: 500;
    }
  }

  &__menu-items {
    max-height: calc(100% - 27rem - 18rem);
    overflow-y: scroll;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 1.5rem 2.8rem;
      background-color: rgb(120 64 62 / 0%);
      transition: all 0.17s ease-in-out;
      cursor: pointer;
      border-top: 1px solid $color-spine-nav-border;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: rgb(120 64 62 / 100%);
        transform-origin: left center;
        transform: scale(0);
        transition: all 0.17s ease-in-out;
      }

      &.router-link-exact-active {
        background-color: rgb(120 64 62 / 6%);

        &::before {
          transform: scale(1);
        }
      }

      &.first {
        border-top: 1px solid $color-spine-nav-border;
      }

      &.last {
        border-bottom: 1px solid $color-spine-nav-border;
      }

      &__avatar {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
      }

      &__text {
        margin-left: 2.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__badge {
        position: absolute;
        right: 2rem;
      }
    }

    @include respond-to('medium') {
      max-height: calc(100% - 27rem - 13.4rem);
    }
  }

  &__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem;

    &__button {
      width: 100%;
    }

    @include respond-to('medium') {
      padding: 1.5rem;
    }
  }

  @include respond-to('medium') {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background: $color-seashell;
    position: fixed;
    text-align: left;
    z-index: 7;
    overflow-y: scroll;
    transform-origin: top right;
    transform: translate3d(10px, -10px, 0) scale(0.95);
    transition: all 0.2s ease-in-out;

    &--open {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0) scale(1);
    }
  }
}
</style>
