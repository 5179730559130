<script>
import analytics from '@/utilities/analytics'
import LongArrow from '@/components/svgs/LongArrow.vue'
import { useNotificationsStore } from '@/stores/NotificationsStore'

export default {
  components: {
    LongArrow,
  },
  setup() {
    return {
      notificationsStore: useNotificationsStore(),
    }
  },
  methods: {
    dismissAnnouncement() {
      analytics.trackEvent('Announcement Dismissed', analytics.TYPE_NOT_DEFINED, {
        announcement_id: this.notificationsStore.activeAnnouncement.id,
        message: this.notificationsStore.activeAnnouncement.message,
      })
      this.notificationsStore.removeAnnouncement(this.notificationsStore.activeAnnouncement)
    },
    followedAction() {
      analytics.trackEvent('Announcement Acted On', analytics.TYPE_NOT_DEFINED, {
        announcement_id: this.notificationsStore.activeAnnouncement.id,
        message: this.notificationsStore.activeAnnouncement.message,
      })
      this.notificationsStore.removeAnnouncement(this.notificationsStore.activeAnnouncement)
    },
  },
}
</script>

<template>
  <div v-if="notificationsStore.activeAnnouncement" class="announcement-bar">
    <router-link
      v-if="notificationsStore.activeAnnouncement.action_route"
      :to="notificationsStore.activeAnnouncement.action_route"
      @click="followedAction()"
    >
      <span>
        {{ notificationsStore.activeAnnouncement.message }}
        <LongArrow class="announcement-bar__icon" />
      </span>
    </router-link>
    <a
      v-else-if="notificationsStore.activeAnnouncement.action_url"
      :href="notificationsStore.activeAnnouncement.action_url"
    >
      <span>
        {{ notificationsStore.activeAnnouncement.message }}
        <LongArrow class="announcement-bar__icon" />
      </span>
    </a>
    <i class="material-icons" @click="dismissAnnouncement()">close</i>
  </div>
</template>

<style lang="scss" scoped>
.announcement-bar {
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-burnt-sienna;
  color: $color-white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);

  a {
    text-align: left;

    @include font-size(1.7);

    flex-grow: 1;
    color: white;
    text-decoration: none;
  }

  &__icon {
    color: $color-white;
    margin-left: 0.2rem;
    width: 4rem;
    display: inline-block;
  }

  i {
    margin-left: 2rem;
    cursor: pointer;
  }

  @include respond-to('small') {
    padding: 1.5rem;
    width: 100%;

    i {
      margin-left: 1rem;
    }
  }
}
</style>
