<script>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'
import VueMultiselect from 'vue-multiselect'

import CloseX from '@/components/svgs/CloseX.vue'
import { emailArray } from '@/utilities/validators'
import { useSpineStore } from '@/stores/SpineStore'
import { useUsersStore } from '@/stores/UsersStore'

export default {
  components: {
    CloseX,
    VueMultiselect,
  },
  props: {
    bookQueueItem: {
      type: Object,
      default: () => {},
    },
    /**
     * If this modal is used in another view other than home layout
     * provide an optional prop to route back home after submitting
     * a recommendation:
     */
    goHomeAfter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup() {
    return {
      spineStore: useSpineStore(),
      usersStore: useUsersStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      chatGroupInvite: {
        chat_group: {
          book: null,
          name: '',
        },
        recipients: [],
        message: '',
      },
      chatGroupInviteError: false,
      errorDetail: '',
      submitted: false,
      options: [],
      multiselectCustomCSS: true,
    }
  },
  validations() {
    return {
      chatGroupInvite: {
        chat_group: {
          name: {
            maxLength: helpers.withMessage(
              ({ $params }) => `Your group name must be less than ${$params.max} characters.`,
              maxLength(50),
            ),
          },
        },
        recipients: {
          required: helpers.withMessage('At least one friend is required.', required),
          emailArray: helpers.withMessage('Please enter a valid email.', emailArray),
        },
        message: {
          required: helpers.withMessage('Message is required.', required),
          maxLength: helpers.withMessage(
            ({ $params }) => `Your message must be less than ${$params.max} characters.`,
            maxLength(500),
          ),
        },
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length) {
          this.chatGroupInviteError = false
          this.errorDetail = ''
        }
      },
      deep: true,
    },
  },
  created() {
    /*
     * Initialize options with the list of contacts from state.
     */
    this.options = [...this.usersStore.contacts]
  },
  methods: {
    addNewContact(contactEmail) {
      const contact = {
        name: '',
        email: contactEmail.trim(),
        user: {
          avatar_thumbnail_square: '',
        },
      }
      this.options.push(contact)
      this.chatGroupInvite.recipients.push(contact)
    },
    handleSkip() {
      if (this.goHomeAfter)
        this.$router.push({ name: 'app-root' })

      this.$emit('close')
    },
    handleInvite() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        this.chatGroupInvite.chat_group.book = this.bookQueueItem.book.id
        this.spineStore.createChatGroupWithInvites(this.chatGroupInvite)
          .then((response) => {
            if (this.goHomeAfter)
              this.$router.push({ name: 'app-root' })

            this.spineStore.setAlert({
              type: 'success',
              message: `Your chat group invites for ${this.bookQueueItem.book.title} have been sent.`,
              active: true,
            })
            this.$emit('close')
            this.$router.push({ name: 'chatgroup', params: { groupId: response.id } })
          })
          .catch((error) => {
            this.submitted = false
            this.chatGroupInviteError = true
            this.errorDetail = (error.data && error.data.non_field_errors)
              ? error.data.non_field_errors[0]
              : 'There was an error creating your chat group invites. Please try again..'
          })
      }
    },
  },
}
</script>

<template>
  <div class="modal">
    <div v-if="bookQueueItem" class="modal__container">
      <div @click="$emit('close')">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2>Invite Friends to Read {{ bookQueueItem.book.title }}</h2>
        <p>Invite friends to read with you. Once you invite them, you will have a group created where you can share insights and thoughts on the book.</p>
      </div>
      <div class="modal__container__head__alerts">
        <p v-if="chatGroupInviteError" class="user-form__alerts__error-msg">
          {{ errorDetail }}
        </p>
        <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
          Please correct the following errors:
        </p>
      </div>
      <form @submit.prevent="handleInvite">
        <div class="modal__container__body">
          <div class="user-form__input-wrap">
            <BaseInput
              v-model="chatGroupInvite.chat_group.name"
              label="Group Name (optional)"
              type="text"
              placeholder="Enter a name for your group."
              @blur="v$.chatGroupInvite.chat_group.name.$touch()"
            />
            <template v-if="v$.chatGroupInvite.chat_group.name.$error">
              <p
                v-for="error in v$.chatGroupInvite.chat_group.name.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
          <div class="modal__container__body__input">
            <label for="multiselect-friends">Friends</label>
            <VueMultiselect
              id="multiselect-friends"
              v-model="chatGroupInvite.recipients"
              placeholder="Add email address or search contacts (pick at least one)."
              :class="{ custom: multiselectCustomCSS }"
              :options="options"
              :searchable="true"
              :allow-empty="true"
              :multiple="true"
              :taggable="true"
              :hide-selected="true"
              tag-placeholder="Add email address to invite friend."
              label="email"
              track-by="email"
              @tag="addNewContact"
              @close="v$.chatGroupInvite.recipients.$touch()"
            />
            <template v-if="v$.chatGroupInvite.recipients.$error">
              <p
                v-for="error in v$.chatGroupInvite.recipients.$errors"
                :key="error.$uid"
                class="form-error-msg"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
          <div class="modal__container__body__input">
            <label for="message-text-area">Message</label>
            <textarea
              id="message-text-area"
              v-model="chatGroupInvite.message"
              placeholder="Send your friends a message about joining your group."
              :class="{ error: v$.chatGroupInvite.message.$error }"
              @blur="v$.chatGroupInvite.message.$touch()"
            />
            <template v-if="v$.chatGroupInvite.message.$error">
              <p
                v-for="error in v$.chatGroupInvite.message.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
              <p
                v-for="error in v$.chatGroupInvite.message.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
        </div>
        <div class="modal__container__foot">
          <div class="modal__container__foot__options">
            <p class="modal__container__foot__options__skip" @click="handleSkip">
              Cancel
            </p>
            <BaseButton
              class="modal__container__foot__options__submit-btn"
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="v$.$errors.length || submitted"
            >
              Invite
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang='scss'>
.multiselect.custom .multiselect__tag,
.multiselect.custom .multiselect__tag span,
.multiselect.custom .multiselect__tag input {
  color: $color-white;
  background: $color-oxblood;
}

.multiselect.custom .multiselect__option .multiselect__option--highlight {
  background: $color-oxblood;
}

.multiselect.custom .multiselect__tag-icon::after {
  color: $color-gainsboro;
}

.multiselect.custom .multiselect__tag-icon:focus,
.multiselect.custom .multiselect__tag-icon:hover {
  background-color: $color-oxblood-dark;
}

.multiselect.custom .multiselect__tag-icon:focus::after,
.multiselect.custom .multiselect__tag-icon:hover::after {
  color: $color-white;
}

.multiselect.custom .multiselect__option--highlight {
  background: $color-oxblood;
  color: $color-white;
}

.multiselect.custom .multiselect__option--highlight::after {
  background: $color-oxblood;
  color: $color-white;
}

.modal {
  &__container {
    &__foot {
      &__options {
        text-align: right;
        float: none;
      }
    }

    &__body {
      margin-top: 2.5rem;

      &__input {
        position: relative;
        margin-top: 2.5rem;
        width: 100%;

        label {
          @include font-size(1.2);

          font: $body2;
          margin-bottom: 0.5rem;
          display: block;
        }

        textarea, input {
          display: block;
          position: relative;
          width: 100%;
        }

        textarea {
          background-color: $color-seashell;
          padding: 2rem;
          height: 13rem;
          resize: none;

          @include font-size(1.3);
        }
      }
    }

    &__close {
      position: absolute;
      top: 2.5rem;
      right: 3rem;
      width: 3.5rem;
      cursor: pointer;
    }
  }
}
</style>
