<template>
  <div class="book-carousel">
    <div v-for="i in 10" :key="`${i}-filler`" class="book-carousel__book">
      <div class="book-carousel__book__wrap">
        <span class="book-carousel__book__wrap__cover" />
        <span class="book-carousel__book__wrap__title" />
        <span class="book-carousel__book__wrap__author" />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-carousel {
  display: flex;
  overflow-x: scroll;
  padding: 1.6rem 8rem 2rem;

  &__book {
    position: relative;
    text-align: left;
    flex-wrap: nowrap;
    width: 17rem;
    margin-right: 7rem;

    &__wrap {
      display: inline-block;
      position: relative;
      list-style-type: none;
      width: 19rem;
      height: 29rem;
      margin: 0;
      margin-right: 2rem;

      &__cover {
        position: relative;
        width: 18rem;
        height: 26rem;
        margin-bottom: 1rem;
        display: block;
        background-color: $color-snow-drift;

        @include respond-to('small') {
          width: 15rem;
          height: 22rem;
        }
      }

      &__title {
        position: relative;
        width: 90%;
        height: 1rem;
        margin-bottom: 1rem;
        display: block;
        background-color: $color-snow-drift;
      }

      &__author {
        position: relative;
        width: 60%;
        height: 1rem;
        display: block;
        background-color: $color-snow-drift;
      }

      @include respond-to('small') {
        width: 15rem;
        height: 26rem;
      }
    }

    @include respond-to('small') {
      width: 15rem;
      margin-right: 2rem;
    }
  }

  @include respond-to('small') {
    padding: 1.25rem 2.5rem 2.5rem;
  }
}
</style>
