import axios from 'axios'

import {
  ACTION_CLEAR_FINISHED_DATE,
  API_BASE_URL,
  BY_ARCHIVED,
  BY_BOOK_SLUG,
  BY_COMPLETED,
  BY_CURATED_LIST_ID,
  BY_CURRENTLY_READING,
  BY_ISBN,
  BY_IS_UNROLLED,
  BY_PAUSED,
  BY_QUEUE,
  BY_SOURCE_NAME_ID,
  BY_SOURCE_UID,
  ENDPOINT_BOOKS,
  ENDPOINT_BOOK_QUEUE_ITEMS,
  ENDPOINT_BOOK_QUEUE_ITEMS_ISBN_LIST,
  ENDPOINT_EXPLORE_BOOKS,
  ENDPOINT_EXPLORE_LISTS,
  ENDPOINT_FILE_EXPORT,
  ENDPOINT_FILE_IMPORT,
  ENDPOINT_READING_GOALS,
  ENDPOINT_READING_GOALS_CURRENT,
  ENDPOINT_RECOMMENDATIONS,
  ENDPOINT_REVIEWS,
  ENDPOINT_REVIEW_TAGS,
  ENDPOINT_SETTINGS,
  ENDPOINT_WELCOME_QUOTES,
} from './endpoints'

const queryTypeMap = {
  currentlyReading: BY_CURRENTLY_READING,
  queue: BY_QUEUE,
  completed: BY_COMPLETED,
  paused: BY_PAUSED,
  archived: BY_ARCHIVED,
}

// Deletes
function deleteBookQueueItem(bookQueueItemId) {
  return axios.delete(`${API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + bookQueueItemId}/`)
}

// Gets
function getBook(bookId) {
  return axios.get(`${API_BASE_URL + ENDPOINT_BOOKS + bookId}/`)
}

function getBookQueueItem(bookQueueItemId) {
  return axios.get(`${API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + bookQueueItemId}/`)
}

function getBookQueueItemsByIsbn(isbn) {
  return axios.get(API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + BY_ISBN + isbn)
}

function getBookQueueItemsByListType(listType) {
  const queryType = queryTypeMap[listType]
  return axios.get(API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + queryType)
}

function getBookQueueItemsBySlug(slug) {
  return axios.get(API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + BY_BOOK_SLUG + slug)
}

function getBookQueueItemsBySource(sourceNameId, sourceUid) {
  const queryString = `?${BY_SOURCE_NAME_ID}${sourceNameId}&${BY_SOURCE_UID}${sourceUid}`
  return axios.get(`${API_BASE_URL}${ENDPOINT_BOOK_QUEUE_ITEMS}${queryString}`)
}

function getBookQueueItemsIsbnList() {
  return axios.get(API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS_ISBN_LIST)
}

function getCurrentReadingGoal() {
  return axios.get(API_BASE_URL + ENDPOINT_READING_GOALS_CURRENT)
}

function getExploreBooks(curatedListId) {
  return axios.get(API_BASE_URL + ENDPOINT_EXPLORE_BOOKS + BY_CURATED_LIST_ID + curatedListId)
}

function getExploreLists(data) {
  const isUnrolled = data.isUnrolled
  return axios.get(`${API_BASE_URL + ENDPOINT_EXPLORE_LISTS + BY_IS_UNROLLED}${isUnrolled}`)
}

function getFileExports() {
  return axios.get(API_BASE_URL + ENDPOINT_FILE_EXPORT)
}

function getNextPage(nextURL) {
  return axios.get(nextURL)
}

function getRandomWelcomeQuote() {
  return axios.get(API_BASE_URL + ENDPOINT_WELCOME_QUOTES)
}

function getReviews() {
  return axios.get(API_BASE_URL + ENDPOINT_REVIEWS)
}

function getReviewTags() {
  return axios.get(API_BASE_URL + ENDPOINT_REVIEW_TAGS)
}

function getSettings() {
  return axios.get(`${API_BASE_URL + ENDPOINT_SETTINGS}?t=${new Date().getTime()}`)
}

// Patches
function patchBookQueueItem(bookQueueItemId, data) {
  return axios.patch(`${API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + bookQueueItemId}/`, data)
}

function patchBookQueueItemClearDateFinished(bookQueueItemId) {
  return axios.patch(API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS + bookQueueItemId
    + ACTION_CLEAR_FINISHED_DATE, {})
}

function patchReadingGoal(readingGoalId, data) {
  return axios.patch(`${API_BASE_URL + ENDPOINT_READING_GOALS + readingGoalId}/`, data)
}

// Posts
function postBookQueueItem(data) {
  return axios.post(API_BASE_URL + ENDPOINT_BOOK_QUEUE_ITEMS, data)
}

function postFileExport() {
  return axios.post(API_BASE_URL + ENDPOINT_FILE_EXPORT, {})
}

function postFileImport(data) {
  return axios.post(API_BASE_URL + ENDPOINT_FILE_IMPORT, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

function postReadingGoal(data) {
  return axios.post(API_BASE_URL + ENDPOINT_READING_GOALS, data)
}

function postRecommendation(data) {
  return axios.post(API_BASE_URL + ENDPOINT_RECOMMENDATIONS, data)
}

function postReview(data) {
  return axios.post(API_BASE_URL + ENDPOINT_REVIEWS, data)
}

// Puts
function putReview(reviewId, data) {
  return axios.put(`${API_BASE_URL + ENDPOINT_REVIEWS + reviewId}/`, data)
}

const booksServiceModule = {
  deleteBookQueueItem,
  getBook,
  getBookQueueItem,
  getBookQueueItemsByIsbn,
  getBookQueueItemsByListType,
  getBookQueueItemsBySlug,
  getBookQueueItemsBySource,
  getBookQueueItemsIsbnList,
  getCurrentReadingGoal,
  getExploreBooks,
  getExploreLists,
  getFileExports,
  getNextPage,
  getRandomWelcomeQuote,
  getReviews,
  getReviewTags,
  getSettings,
  patchBookQueueItem,
  patchBookQueueItemClearDateFinished,
  patchReadingGoal,
  postBookQueueItem,
  postFileExport,
  postFileImport,
  postReadingGoal,
  postRecommendation,
  postReview,
  putReview,
}

export default booksServiceModule
