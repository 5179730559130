<script>
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import BookCardOverlay from '@/components/booklists/BookCardOverlay'
import BookLabel from '@/components/booklists/BookLabel.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import PopMenu from '@/components/menus/PopMenu.vue'
import { REVIEW_BOOK_EVENT } from '@/customEvents'
import { useQueueStore } from '@/stores/QueueStore'
import ViewableFullStar from '@/components/svgs/ViewableFullStar.vue'

export default {
  components: {
    BookCardOverlay,
    BookLabel,
    MiniLoader,
    PopMenu,
    ViewableFullStar,
  },
  inject: [
    'queueContextMenu',
    'queueCustomEvents',
    'completedContextMenu',
    'completedCustomEvents',
    'pausedContextMenu',
    'pausedCustomEvents',
    'archivedContextMenu',
    'archivedCustomEvents',
  ],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    listType: {
      type: String,
      required: true,
      validator(value) {
        // The list type must match one of the four types.
        return ['queue', 'completed', 'paused', 'archived'].includes(value)
      },
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    let contextMenu
    let customEvents
    switch (this.listType) {
      case 'queue':
        contextMenu = this.queueContextMenu
        customEvents = this.queueCustomEvents
        break
      case 'completed':
        contextMenu = this.completedContextMenu
        customEvents = this.completedCustomEvents
        break
      case 'paused':
        contextMenu = this.pausedContextMenu
        customEvents = this.pausedCustomEvents
        break
      case 'archived':
        contextMenu = this.archivedContextMenu
        customEvents = this.archivedCustomEvents
        break
    }
    return {
      contextMenu,
      customEvents,
    }
  },
  computed: {
    isItemLoading() {
      return (this.queueStore.loading.userBookAction.item
        && this.queueStore.loading.userBookAction.item.id === this.item.book.id)
    },
    popMenuAlignment() {
      if (this.listType === 'completed')
        return 'align-bottom'
      else
        return 'align-top'
    },
    finishedDate() {
      let finishedDateString = ''
      const finishedDateGetter = this.queueStore.bookQueueItemFinishedDate
      const dateString = finishedDateGetter(this.item)
      if (dateString)
        finishedDateString = `Finished ${format(parseISO(dateString), 'MMM do, yyyy')}`

      return finishedDateString
    },
  },
  methods: {
    proxyEvent(eventName) {
      /* The proxyEvent method makes sure that the eventName is included in customEvents before
       * we dispatch via global state instead of via event emitters.
       */
      if (this.customEvents.includes(eventName))
        this.queueStore.setQueueEvent({ name: eventName, item: this.item })
    },
    handleActionLink() {
      this.queueStore.setQueueEvent({ name: REVIEW_BOOK_EVENT, item: this.item })
    },
    returnStarState(listedStarIndex, bookQueueItem) {
      let thisStarRatingValue = 0
      let starData = {
        side: null,
        state: false,
      }
      const review = bookQueueItem.user_review
      if (review) {
        thisStarRatingValue = Number(review.rating)
        if (listedStarIndex < thisStarRatingValue) {
          /**
           * This means the rating value is greater than both sides of this given star index
           * so set the last filled-in side of this index as 'right':
           */
          starData = {
            side: 'right',
            state: true,
          }
        }
        else {
          if (thisStarRatingValue === (listedStarIndex - 0.5)) {
            /**
             * If the rating value is only 0.5 greater than
             * the previous condition:
             */
            starData = {
              side: 'left',
              state: true,
            }
          }
          else if (thisStarRatingValue === listedStarIndex) {
            /**
             * If the rating value is equal to the star index:
             */
            starData = {
              side: 'right',
              state: true,
            }
          }
        }
      }
      return starData
    },
  },
}
</script>

<template>
  <div class="book-carousel__book">
    <router-link
      v-slot="{ navigate }"
      :to="{ name: 'bookboard', params: { bookSlug: item.book.slug } }"
      custom
    >
      <div
        class="book-carousel__book__cover-link"
        :style="{ backgroundImage: `url(${item.book.cover_image_url})` }"
        @click="navigate"
      >
        <transition name="fade">
          <div
            v-if="isItemLoading"
            class="book-carousel__book__cover-link__loading-wrap"
          >
            <MiniLoader color-class="light" class="book-carousel__book__cover-link__loading-wrap__loader" />
          </div>
        </transition>
        <div class="book-carousel__book__cover-link__rollover">
          <BookCardOverlay />
        </div>
      </div>
    </router-link>
    <div class="book-carousel__book__meta">
      <PopMenu
        class="book-carousel__book__meta__context-menu"
        :menu-items="contextMenu"
        align="align-right"
        vert-align="align-bottom"
        @menu-item-clicked="proxyEvent"
      >
        <i class="material-icons book-carousel__book__meta__context-menu__icon">more_horiz</i>
      </PopMenu>
      <h4 class="medium book-carousel__book__meta__title">
        {{ item.book.title }}
      </h4>
      <p v-if="listType !== 'completed'" class="book-carousel__book__meta__author">
        by <span v-for="(author, i) in item.book.authors" :key="i">{{ author }}{{ (item.book.authors.length - 1) > i ? ', ' : '' }}</span>
      </p>
      <p v-if="listType === 'completed'" class="book-carousel__book__meta__author">
        {{ finishedDate }}
      </p>
      <BookLabel
        v-if="listType === 'queue'"
        :book-queue-item="item"
        class="mar-t-s book-carousel__book__meta__label"
      />
      <template v-if="listType === 'completed'">
        <template v-if="item.user_review">
          <div
            v-if="item.user_review.rating"
            class="book-carousel__book__meta__star-rating"
            @click="handleActionLink"
          >
            <ViewableFullStar
              v-for="i in 5"
              :key="i"
              class="book-carousel__book__meta__star-rating__star"
              :viewable-state="returnStarState(i, item)"
              :in-review-card="true"
            />
          </div>
          <div v-else class="book-carousel__book__meta__action-link">
            <button class="large link_btn" @click="handleActionLink">
              View Review
            </button>
          </div>
        </template>
        <div v-else class="book-carousel__book__meta__action-link">
          <button class="large link_btn" @click="handleActionLink">
            Add Review
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-carousel {
  &__book {
    position: relative;
    text-align: left;
    flex-wrap: nowrap;
    width: 18rem;
    margin-right: 7rem;

    &__meta {
      position: relative;
      margin-top: 1.7rem;

      &__title {
        position: relative;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__author {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &__context-menu {
        position: absolute;
        top: -0.3rem;
        right: 0;
        z-index: 2;

        &__icon {
          position: absolute;
          top: 0;
          right: 0;

          @include font-size(2);
        }
      }

      &__label {
        position: relative;
        z-index: 1;
      }

      &__star-rating {
        padding-right: 0.7rem;
        margin-top: 0.3rem;
        margin-left: -0.4rem;
        cursor: pointer;

        &__star {
          display: inline-block;
          position: relative;
        }
      }

      &__action-link {
        .link_btn {
          padding: 0;
        }
      }
    }

    &__cover-link {
      position: relative;
      width: 18rem;
      height: 26rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border: 8px solid $color-white;
      box-shadow: 0 3px 9px 1px rgb(43 36 13 / 8%);
      cursor: pointer;

      &__loading-wrap {
        @include full-wh;

        background-color: rgb(0 0 0 / 70%);

        &__loader {
          position: relative;
          display: block;
          margin: 0 auto;
          top: 50%;
          transform: translateY(-50%) scale(1.2);
          left: 10%;
        }
      }

      &__rollover {
        z-index: 1;
        opacity: 0;
        transition: all 0.15s ease-in-out;

        &:hover {
          opacity: 0.9;
        }
      }

      @include respond-to('small') {
        width: 15rem;
        height: 22rem;
        border: 6px solid $color-white;
      }
    }

    @include respond-to('small') {
      width: 15rem;
      margin-right: 2rem;
    }
  }

  @include respond-to('small') {
    padding: 1.25rem 2.5rem 2.5rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
