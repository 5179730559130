import axios from 'axios'

import {
  API_BASE_URL,
  ENDPOINT_AUTH_JWT_CREATE,
  ENDPOINT_AUTH_JWT_REFRESH,
  ENDPOINT_AUTH_USERS,
  ENDPOINT_AUTH_USERS_ACTIVATION,
  ENDPOINT_AUTH_USERS_ME,
  ENDPOINT_AUTH_USERS_RESEND_ACTIVATION,
  ENDPOINT_AUTH_USERS_RESET_PASSWORD,
  ENDPOINT_AUTH_USERS_RESET_PASSWORD_CONFIRM,
  ENDPOINT_AUTH_USERS_SET_PASSWORD,
} from '@/services/endpoints'

function clearAuthorizationHeaders() {
  axios.defaults.headers.common.Authorization = ''
}

function setAuthorizationHeaders(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

// Gets
function getUser() {
  return axios.get(API_BASE_URL + ENDPOINT_AUTH_USERS_ME)
}

// Patches
function patchUser(data, config = {}) {
  return axios.patch(API_BASE_URL + ENDPOINT_AUTH_USERS_ME, data, config)
}

// Posts
function postJWTCreate(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_JWT_CREATE, data)
}

function postJWTRefresh(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_JWT_REFRESH, data)
}

function postUser(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_USERS, data)
}

function postUserActivation(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_USERS_ACTIVATION, data)
}

function postUserResendActivation(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_USERS_RESEND_ACTIVATION, data)
}

function postUserResetPassword(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_USERS_RESET_PASSWORD, data)
}

function postUserResetPasswordConfirm(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_USERS_RESET_PASSWORD_CONFIRM, data)
}

function postUserSetPassword(data) {
  return axios.post(API_BASE_URL + ENDPOINT_AUTH_USERS_SET_PASSWORD, data)
}

const authServiceModule = {
  clearAuthorizationHeaders,
  getUser,
  patchUser,
  postJWTCreate,
  postJWTRefresh,
  postUser,
  postUserActivation,
  postUserResendActivation,
  postUserResetPassword,
  postUserResetPasswordConfirm,
  postUserSetPassword,
  setAuthorizationHeaders,
}

export default authServiceModule
