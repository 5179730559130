<script>
import ChatGroupMembers from '@/components/spine/chatgroups/ChatGroupMembers.vue'
import ChatGroupMessageThread from '@/components/spine/chatgroups/ChatGroupMessageThread.vue'
import GroupMemberAvatars from '@/components/users/GroupMemberAvatars.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useSpineStore } from '@/stores/SpineStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ChatGroupMembers,
    ChatGroupMessageThread,
    GroupMemberAvatars,
    ViewLoader,
  },
  emits: ['addReaders', 'openMobileNav'],
  setup() {
    return {
      authStore: useAuthStore(),
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      chatSelected: true,
    }
  },
  watch: {
    '$route.params': {
      handler(value) {
        if (!value.groupId) {
          // Likely we are loading notes and not the ChatGroupView
          return
        }
        this.chatSelected = true
        const groupId = value.groupId
        const chatGroup = this.spineStore.getChatGroupById(groupId)
        if (chatGroup === undefined) {
          // If undefined, we haven't loaded this group yet
          this.spineStore.getChatGroup(groupId)
        }
        else if (typeof chatGroup.owner === 'string' || typeof chatGroup.book === 'string') {
          // If the groups exist, but owner or book are strings, we have the brief version of
          // the group after creating a new book, so go out and get it.
          this.spineStore.getChatGroup(groupId)
        }
        else {
          this.spineStore.currentChatGroup = chatGroup
        }
      },
      immediate: true,
    },
  },
  methods: {
    propagateAddReadersEvent(group) {
      this.$emit('addReaders', group)
    },
  },
}
</script>

<template>
  <div v-if="spineStore.currentChatGroup" id="chat-thread" class="chat-group">
    <div class="chat-group__bar">
      <div class="chat-group__head">
        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'app-root' }"
        >
          <i class="material-icons chat-group__head__back" @click="navigate">keyboard_arrow_left</i>
        </router-link>
        <h2 class="chat-group__head__title">
          {{ spineStore.currentChatGroupName(authStore.user.username) }}
        </h2>
        <div class="chat-group__head__avatars">
          <GroupMemberAvatars :group="spineStore.currentChatGroup" />
        </div>
        <i class="material-icons material-icons chat-group__head__context-menu" @click="$emit('openMobileNav')">more_horiz</i>
      </div>
      <div class="chat-group__subnav">
        <div class="chat-group__subnav__item" :class="{ active: chatSelected }" @click="chatSelected = true">
          chat
        </div>
        <div class="chat-group__subnav__item" :class="{ active: !chatSelected }" @click="chatSelected = false">
          members
        </div>
      </div>
    </div>
    <ViewLoader v-if="spineStore.loading.chatGroup || spineStore.loading.chatGroupMembers" />
    <ChatGroupMessageThread v-if="chatSelected" :group="spineStore.currentChatGroup" />
    <ChatGroupMembers
      v-else
      :group="spineStore.currentChatGroup"
      @add-readers="propagateAddReadersEvent"
    />
  </div>
</template>

<style lang='scss' scoped>
.chat-group {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0 7%;

  @include respond-to('medium') {
    padding: 0 5%;
  }

  &__bar {
    position: fixed;
    left: 28rem;
    padding: 4rem 5% 2rem;
    background-color: $color-white;
    z-index: 2;
    width: calc(100% - 28rem);

    @include respond-to('medium') {
      left: 0;
      padding: 2rem 0;
      text-align: center;
      width: 100%;
      border-bottom: 1px solid $color-section-head-border;
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__back {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      cursor: pointer;

      @include respond-to('medium') {
        display: block;
      }
    }

    &__context-menu {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      cursor: pointer;

      @include respond-to('medium') {
        display: block;
      }
    }

    &__title {
      font-weight: 600;
      display: inline-flex;
      vertical-align: middle;
      margin: 0;

      @include font-size(2.5);
    }

    @include respond-to('medium') {
      display: block;

      &__title {
        font-weight: 600;

        @include font-size(1.4);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: none;
        }
      }

      &__avatars {
        display: none;
      }
    }
  }

  &__subnav {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $color-section-head-border;

    &__item {
      color: $color-gray-medium;
      cursor: pointer;
      padding: 1rem 0;
      border-bottom: 0;
      text-transform: uppercase;
      transition: all 0.17s ease-in-out;

      &:first-of-type {
        margin-right: 3rem;
      }

      &.active {
        border-bottom: 1px solid $color-burnt-sienna;
        color: $color-burnt-sienna;
        font-weight: 700;
      }
    }

    @include respond-to('medium') {
      position: fixed;
      width: 92%;
      background-color: $color-white;
      z-index: 1;
      top: 6.4rem;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 1rem;

      &__item {
        margin-left: 2rem;
      }
    }
  }
}
</style>
