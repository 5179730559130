<script>
import { useAuthStore } from '@/stores/AuthStore'

export default {
  setup() {
    return {
      authStore: useAuthStore(),
    }
  },
  data() {
    return {
      boardURL: 'https://italictype.nolt.io/',
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { nolt } = window
      nolt('init', { selector: '.it-feedback-button', url: this.boardURL })
      nolt('identify', { jwt: this.authStore.user.nolt_token })
    })
  },
}
</script>

<template>
  <button class="it-feedback-button large primary">
    Feedback
  </button>
</template>

<style lang="scss" scoped>
.it-feedback-button {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  border-radius: 20px;

  &:hover {
    background-color: $color-burnt-sienna-dark;
  }

  @include respond-to('small') {
    display: none;
  }
}
</style>
