<script>
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import analytics from '@/utilities/analytics'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { usePaymentsStore } from '@/stores/PaymentsStore'

export default {
  components: {
    MiniLoader,
  },
  setup() {
    return {
      paymentsStore: usePaymentsStore(),
    }
  },
  data() {
    return {
      loading: true,
      freePlan: false,
      submitted: false,
    }
  },
  computed: {
    formatPriceToString() {
      const price = this.paymentsStore.activeSubscription.price
      const currencyOptions = {
        style: 'currency',
        currency: price.currency,
        minimumFractionDigits: 2,
      }
      const currencyString = new Intl.NumberFormat('en-US', currencyOptions)
        .format(price.unit_amount / 100)
      return `${currencyString} per ${price.recurring_interval}`
    },
    subscriptionRenewalDate() {
      return format(
        parseISO(this.paymentsStore.activeSubscription.current_period_end),
        'MMMM do, yyyy',
      )
    },
  },
  created() {
    // We may already have the activeSubscription retrieved in the parent, but if active
    // subscription is null, try retrieving again, since this component is dependent on it
    if (!this.paymentsStore.activeSubscription) {
      this.paymentsStore.getSubscriptions()
        .then((data) => {
          if (data.count === 0)
            this.freePlan = true

          this.loading = false
        })
        .catch(() => {
          this.paymentsStore.setAlert({
            type: 'error',
            message: 'We could not retrieve your subscription. Please try again.',
            active: true,
          })
        })
    }
    else {
      this.loading = false
    }
  },
  methods: {
    redirectToManagementPortal() {
      this.submitted = true
      this.paymentsStore.launchCustomerPortal({ return_path: this.$route.path })
        .then((response) => {
          analytics.trackEvent('Customer Portal Session Started', analytics.TYPE_NOT_DEFINED, {
            from_path: this.$route.path,
          })

          // Redirect to the management portal
          window.location.href = response.session_url
        })
        .catch(() => {
          this.submitted = false
          this.paymentsStore.setAlert({
            type: 'error',
            message: 'We could not launch the management portal. Please try again.',
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div v-if="!loading" class="widget">
    <template v-if="!freePlan">
      <div class="flex-child-1">
        <h3>Current Plan</h3>
        <p>{{ paymentsStore.activeSubscription.price.product }}</p>
        <p>{{ formatPriceToString }}</p>
        <p v-if="paymentsStore.activeSubscription.cancel_at_period_end" class="italic">
          Your plan will be canceled on {{ subscriptionRenewalDate }}
        </p>
        <p v-else class="italic">
          Your plan renews on {{ subscriptionRenewalDate }}
        </p>
      </div>
      <div class="flex-child-2">
        <BaseButton
          class="widget__submit-btn"
          button-type="primary"
          button-size="medium"
          @click="redirectToManagementPortal"
        >
          <MiniLoader v-if="submitted" />
          <span v-else>Manage Plan</span>
        </BaseButton>
      </div>
    </template>
    <template v-else>
      <div class="flex-child-1">
        <h3>Current Plan</h3>
        <p>You are on the free plan.</p>
        <p class="italic">
          Click the button to consider becoming a member of Italic Type.
        </p>
      </div>
      <div class="flex-child-2">
        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'membership-payments' }"
        >
          <BaseButton
            class="widget__submit-btn"
            button-type="primary"
            button-size="medium"
            @click="navigate"
          >
            Become A Member
          </BaseButton>
        </router-link>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.widget {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .flex-child-1 {
    width: 60%;
  }

  @include respond-to('medium') {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .flex-child-1 {
      width: 100%;
    }
  }

  &__submit-btn {
    width: 14rem;

    @include respond-to('medium') {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
</style>
