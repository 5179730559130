import { defineStore } from 'pinia'
import usersService from '@/services/usersService'

export const useUsersStore = defineStore('users', {
  state: () => ({
    contacts: null,
    contactsNextURL: null,
  }),
  actions: {
    getContacts() {
      return new Promise((resolve, reject) => {
        usersService.getContacts()
          .then((response) => {
            this.contacts = response.data.results
            this.contactsNextURL = response.data.next
            if (response.data.next)
              this.getNextPageOfContacts()

            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getNextPageOfContacts() {
      if (this.contactsNextURL === null)
        return

      return new Promise((resolve, reject) => {
        usersService.getNextPage(this.contactsNextURL)
          .then((response) => {
            this.contacts = this.contacts.concat(response.data.results)
            this.contactsNextURL = response.data.next
            if (response.data.next)
              this.getNextPageOfContacts()

            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
  },
})
