<script>
import ViewableFullStar from '@/components/svgs/ViewableFullStar.vue'

export default {
  components: {
    ViewableFullStar,
  },
  props: {
    rating: {
      type: [String, Number],
      required: true,
      validator(value) {
        // The string value should be a number
        return !isNaN(value)
      },
    },
    inReviewCard: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    returnStarState(listedStarIndex) {
      let starData = {
        side: null,
        state: false,
      }
      const thisStarRatingValue = Number(this.rating)
      if (listedStarIndex < thisStarRatingValue) {
        /**
         * This means the rating value is greater than both sides of this given star index
         * so set the last filled-in side of this index as 'right':
         */
        starData = {
          side: 'right',
          state: true,
        }
      }
      else {
        if (thisStarRatingValue === (listedStarIndex - 0.5)) {
          /**
           * If the rating value is only 0.5 greater than
           * the previous condition:
           */
          starData = {
            side: 'left',
            state: true,
          }
        }
        else if (thisStarRatingValue === listedStarIndex) {
          /**
           * If the rating value is equal to the star index:
           */
          starData = {
            side: 'right',
            state: true,
          }
        }
      }
      return starData
    },
  },
}
</script>

<template>
  <div v-if="rating !== null" class="star-rating">
    <ViewableFullStar
      v-for="i in 5"
      :key="i"
      class="star-rating__star"
      :viewable-state="returnStarState(i)"
      :in-review-card="inReviewCard"
    />
  </div>
</template>

<style lang="scss" scoped>
.star-rating {
  &__star {
    display: inline-block;
    position: relative;
  }
}
</style>
