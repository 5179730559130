<script>
import { useAuthStore } from '@/stores/AuthStore'
import UserAvatar from '@/components/users/UserAvatar.vue'

export default {
  components: {
    UserAvatar,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    avatarWidth: {
      type: String,
      default: '3rem',
    },
    avatarHeight: {
      type: String,
      default: '3rem',
    },
  },
  setup() {
    return {
      authStore: useAuthStore(),
    }
  },
  data() {
    return {
      maxMemberIcons: 4,
    }
  },
  computed: {
    groupMembers() {
      let members = []
      if (this.group.is_one_to_one) {
        // Find whether logged in user is member1 or member2 of the group.
        const member = this.authStore.user.username === this.group.members[0].username
          ? this.group.members[1]
          : this.group.members[0]
        members.push(member)
      }
      else {
        members = [...this.group.members]

        // If logged in user is in the member list (which they should be), remove from list
        const index = members.findIndex(member => member.username === this.authStore.user.username)
        if (~index)
          members.splice(index, 1)
      }
      return members
    },
  },
  methods: {
    groupMembersRemainder() {
      return `+${this.groupMembers.length - this.maxMemberIcons}`
    },
    inviteRemainder() {
      return `+${this.group.users_invited - this.maxMemberIcons}`
    },
  },
}
</script>

<template>
  <div class="group">
    <template v-if="groupMembers.length">
      <div
        v-for="(member, i) in groupMembers.slice(0, maxMemberIcons)"
        :key="i"
        class="group__member"
        :class="{ single: group.is_one_to_one }"
      >
        <UserAvatar
          :class="{ group__avatar: group.is_one_to_one }"
          :user="member"
          width="3rem"
          height="3rem"
        />
      </div>
      <div v-if="groupMembers.length > maxMemberIcons" class="group__member">
        <UserAvatar :no-image-character="groupMembersRemainder()" width="3rem" height="3rem" />
      </div>
    </template>
    <template v-else-if="group.users_invited > 0">
      <div
        v-for="i in Math.min(group.users_invited, maxMemberIcons)"
        :key="i"
        class="group__invite"
      />
      <div v-if="group.users_invited > maxMemberIcons" class="group__invite remainder">
        <span>{{ inviteRemainder() }}</span>
      </div>
    </template>
  </div>
</template>

<style lang='scss' scoped>
.group {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &__member {
    box-sizing: content-box;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-right: -1.5rem;
    border-radius: 50%;
    border: 2px solid $color-white;

    &.single {
      border: none;
    }
  }

  &__invite {
    box-sizing: content-box;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-right: -1.5rem;
    border-radius: 50%;
    border: 1px dashed $color-gray-medium;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $color-gainsboro;

    &.remainder {
      background-color: $color-nobel;
      text-align: center;

      span {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: $color-white;
        text-transform: uppercase;
      }
    }
  }

  &__avatar {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
  }
}
</style>
