<script>
export default {
  name: 'LongArrow',
}
</script>

<template>
  <div class="icon long-arrow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.61 14.57">
      <line y1="7.32" x2="88" y2="7.32" style="fill: none; stroke: currentcolor;" />
      <path d="M82.16.32l5.8,7-5.8,7" transform="translate(0 0)" style="fill: none; stroke: currentcolor;" />
    </svg>
  </div>
</template>
