<script>
import LongArrow from '@/components/svgs/LongArrow.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    LongArrow,
    MiniLoader,
  },
  props: {
    listType: {
      type: String,
      required: true,
      validator(value) {
        // The list type must match one of the four types.
        return ['queue', 'completed', 'paused', 'archived'].includes(value)
      },
    },
  },
  emits: ['loadMoreBooks'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    isLoading() {
      let loading = false
      if (this.queueStore.loading.loadMore[this.listType])
        loading = true

      return loading
    },
  },
}
</script>

<template>
  <div class="load-more-card__card">
    <div class="load-more-card__card__cover-link" @click="$emit('loadMoreBooks')">
      <transition name="fade">
        <div
          v-if="isLoading"
          class="load-more-card__card__cover-link__loading-wrap"
        >
          <MiniLoader color-class="light" class="load-more-card__card__cover-link__loading-wrap__loader" />
        </div>
      </transition>
      <div class="load-more-card__card__cover-link__overlay">
        <p class="load-more-card__card__cover-link__overlay__text">
          Load More Books
        </p>
        <LongArrow class="load-more-card__card__cover-link__overlay__arrow-icon" />
      </div>
    </div>
  </div>
</template>

<style lang='scss'>
.load-more-card {
  &__card {
    position: relative;
    text-align: left;
    flex-wrap: nowrap;
    width: 18rem;
    margin-right: 7rem;

    &__cover-link {
      position: relative;
      width: 18rem;
      height: 26rem;
      background-color: $color-oxblood;
      box-shadow: 0 3px 9px 1px rgb(43 36 13 / 8%);
      cursor: pointer;

      &__loading-wrap {
        @include full-wh;

        background-color: rgb(0 0 0 / 70%);

        &__loader {
          position: relative;
          display: block;
          margin: 0 auto;
          top: 40%;
          transform: translateY(-40%) scale(1.2);
          left: 10%;
        }
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &__arrow-icon {
          display: block;
          position: relative;
          top: 50%;
          text-align: center;
          margin: 0 auto;
          transform: translateY(-50%);
          width: 6rem;
          color: $color-white;

          svg {
            path {
              fill: none;
              stroke: $color-white;
            }
          }
        }

        &__text {
          color: $color-white;
          position: relative;
          top: 50%;
          text-align: center;
          transform: translateY(-50%);
        }
      }

      @include respond-to('small') {
        width: 15rem;
        height: 22rem;
      }
    }

    @include respond-to('small') {
      width: 15rem;
      margin-right: 2rem;
    }
  }

  @include respond-to('small') {
    padding: 1.25rem 2.5rem 2.5rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
