<script>
import 'quill/dist/quill.core.css'

import CameraIcon from '@/components/svgs/CameraIcon.vue'
import { getExifData } from '@/utilities/exif'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useSpineStore } from '@/stores/SpineStore'
import QuillEditor from '@/components/spine/QuillEditor.vue'

export default {
  components: {
    CameraIcon,
    MiniLoader,
    QuillEditor,
  },
  props: {
    bookBoard: {
      type: Object,
      required: true,
    },
  },
  emits: ['blurred', 'focused', 'newEntryAdded'],
  setup() {
    return {
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      addBtnDisabled: true,
      attachmentFile: null,
      attachmentPreviewImage: null,
      attachmentRotation: 0,
      content: '',
      editing: false,
      editorOptions: {
        theme: 'snow',
        placeholder: 'Add an entry...',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'link'],
          magicUrl: true,
        },
      },
    }
  },
  watch: {
    content(value) {
      if (value || this.attachmentPreviewImage)
        this.addBtnDisabled = false
      else
        this.addBtnDisabled = true
    },
  },
  methods: {
    onEditorBlur() {
      if (!this.content && !this.attachmentFile) {
        this.editing = false
        this.$emit('blurred')
      }
    },
    onEditorFocus() {
      this.editing = true
      this.$emit('focused')
    },
    onEditorReady() {
    },
    handleAttachImage() {
      document.getElementById('attachment-upload-el').click()
      this.$refs.bookBoardNewEntryEditor.quill.focus()
    },
    handleFileUpload($e) {
      if ($e.target.files && $e.target.files[0]) {
        this.setPreviewImage($e.target.files[0])
        this.attachmentFile = $e.target.files[0]
      }
    },
    setPreviewImage(file) {
      getExifData(file).then((exifData) => {
        this.attachmentRotation = exifData.exifOrientation
        const reader = new FileReader()
        reader.onload = (e) => {
          this.attachmentPreviewImage = e.target.result
          this.addBtnDisabled = false
        }
        reader.readAsDataURL(file)
      })
    },
    handleSaveNote() {
      this.spineStore.loading.newEntry = true
      const newBookBoardEntry = {
        data: null,
        contentHeader: { 'Content-Type': 'application/json' },
      }
      if (this.attachmentFile) {
        newBookBoardEntry.data = new FormData()
        newBookBoardEntry.data.append('book_board', this.bookBoard.id)
        newBookBoardEntry.data.append('text_content', this.content)
        newBookBoardEntry.data.append('image_original', this.attachmentFile)
        newBookBoardEntry.contentHeader = { 'Content-Type': 'multipart/form-data' }
      }
      else {
        newBookBoardEntry.data = {
          book_board: this.bookBoard.id,
          text_content: this.content,
        }
      }
      this.spineStore.addNewBookBoardEntry(newBookBoardEntry)
        .then(() => {
          this.addBtnDisabled = true
          this.attachmentFile = null
          this.attachmentPreviewImage = null
          this.attachmentRotation = 0
          this.content = ''
          this.editing = false
          this.$emit('blurred')
          this.$emit('newEntryAdded')
          this.$nextTick(() => {
            this.spineStore.loading.newEntry = false
          })
        })
        .catch(() => {
          const message = 'Unable to post a new entry to your book board. Please try again.'
          this.spineStore.loading.newEntry = false
          this.spineStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div class="new-bookboard-entry">
    <QuillEditor
      v-if="bookBoard"
      ref="bookBoardNewEntryEditor"
      v-model="content"
      :options="editorOptions"
      class="new-entry"
      :class="{ focused: editing }"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
    <div
      v-if="attachmentPreviewImage"
      class="new-bookboard-entry__attachment-preview"
      :class="`new-bookboard-entry__attachment-preview--rotate-${attachmentRotation}`"
      :style="{ backgroundImage: `url(${attachmentPreviewImage})` }"
    />
    <span
      class="new-bookboard-entry__attachment"
      @click="handleAttachImage"
    >
      <CameraIcon class="new-bookboard-entry__attachment__icon" />
      <input
        id="attachment-upload-el"
        type="file"
        @change="handleFileUpload"
      >
    </span>
    <button class="new-bookboard-entry__save" :disabled="addBtnDisabled" @click="handleSaveNote">
      <span v-if="!spineStore.loading.newEntry">
        <span class="new-bookboard-entry__save__text">Add</span>
        <span class="new-bookboard-entry__save__text-mobile">
          <i class="material-icons">
            add
          </i>
        </span>
      </span>
      <MiniLoader v-else class="new-bookboard-entry__save__loader" color-class="light" />
    </button>
  </div>
</template>

<style lang='scss' scoped>
@import '../../../scss/vendor/quill-snow-override.css';

.new-entry {
  max-height: 28rem;
  min-height: 5.35rem;
  background-color: $color-white;
  overflow-y: scroll;
  border: 1px solid $color-new-entry-border;
  padding: 1rem 8.5rem 1rem 4.75rem;
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 4%);
  transition: all 0.2s ease-in-out;

  &.focused {
    min-height: 10rem;
  }

  @include respond-to('medium') {
    box-shadow: 0 -2px 8px 0 rgb(0 0 0 / 8%);

    .ql-toolbar {
      bottom: 0;
      left: 0;
    }
  }
}

.new-bookboard-entry {
  position: relative;

  &__attachment {
    position: absolute;
    bottom: 1.7rem;
    right: 9.2rem;
    cursor: pointer;
    z-index: 1;

    &__icon {
      width: 2.4rem;
    }
  }

  &__attachment-preview {
    position: absolute;
    width: 6.8rem;
    height: 3.9rem;
    right: 1.2rem;
    bottom: 5.2rem;
    border: 1px solid $color-attachment-preview-border;
    border-radius: 0.3rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    transform-origin: center;

    &--rotate-0 {
      transform: rotate(0deg);
    }

    &--rotate-180 {
      transform: rotate(180deg);
    }

    &--rotate-90 {
      transform: rotate(90deg);
      width: 3.9rem;
      height: 6.8rem;
      right: 2.8rem;
      bottom: 4rem;
    }

    &--rotate-270 {
      transform: rotate(270deg);
      width: 3.9rem;
      height: 6.8rem;
      right: 2.8rem;
      bottom: 4rem;
    }
  }

  &__save {
    background-color: $color-black;
    position: absolute;
    bottom: 1rem;
    right: 1.1rem;
    width: 7rem;
    height: 3.45rem;
    padding: 0.75rem 1.75rem;
    z-index: 1;

    &__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &__text-mobile {
      display: none;

      i {
        @include font-size(1.8);
      }
    }
  }

  @include respond-to('medium') {
    &__attachment {
      bottom: 1.5rem;
      right: 6rem;

      @include font-size(1.5);
    }

    &__attachment-preview {
      width: 3.8rem;
      right: 0.75rem;
      bottom: 5.2rem;

      &--rotate-90 {
        width: 3.8rem;
        height: 3.8rem;
        right: 0.75rem;
        bottom: 5.2rem;
      }

      &--rotate-270 {
        width: 3.8rem;
        height: 3.8rem;
        right: 0.75rem;
        bottom: 5.2rem;
      }
    }

    &__save {
      bottom: 0.65rem;
      right: 0.75rem;
      padding: 1rem;
      height: 3.85rem;
      width: 3.85rem;

      &__text {
        display: none;
      }

      &__text-mobile {
        display: block;
      }

      &__loader {
        transform: translateX(-50%) translateY(-50%) scale(0.8);
      }
    }
  }
}

#attachment-upload-el {
  display: none;
}
</style>
