<script>
import { Capacitor } from '@capacitor/core'

import AppRefreshModal from '@/components/modals/AppRefreshModal.vue'
import SystemAlerts from '@/components/SystemAlerts.vue'
import { useRefreshPage } from '@/composables/refreshPage'
import { usePaymentsStore } from '@/stores/PaymentsStore'
import { useSpineStore } from '@/stores/SpineStore'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  name: 'App',
  components: {
    AppRefreshModal,
    SystemAlerts,
  },
  setup() {
    const { hashChanged, initVersionCheck, reloadApp, teardownVersionCheck } = useRefreshPage()
    return {
      hashChanged,
      paymentsStore: usePaymentsStore(),
      spineStore: useSpineStore(),
      queueStore: useQueueStore(),
      initVersionCheck,
      reloadApp,
      teardownVersionCheck,
    }
  },
  data() {
    return {
      developmentMode: process.env.NODE_ENV === 'development',
      isRunningNativeMobile: Capacitor.isNativePlatform(),
    }
  },
  computed: {
    systemAlert() {
      /**
       * stop-gap fix here.
       * in the future this will need to probably be an alert list/array
       * that can receive alerts from different state modules
       * and display them sequentially.
      */
      let alert = null
      if (this.spineStore.spineAlert.active)
        alert = this.spineStore.spineAlert

      if (this.queueStore.queueAlert.active)
        alert = this.queueStore.queueAlert

      if (this.paymentsStore.paymentsAlert.active)
        alert = this.paymentsStore.paymentsAlert

      return alert
    },
  },
  mounted() {
    this.initVersionCheck()
  },
  beforeUnmount() {
    this.teardownVersionCheck()
  },
}
</script>

<template>
  <div :class="`route-${$route.name}`">
    <router-view />
    <transition name="fade">
      <SystemAlerts
        v-if="systemAlert"
        :alert="systemAlert"
        class="it-system-alerts"
      />
    </transition>
    <transition name="scale-fade">
      <AppRefreshModal
        v-if="hashChanged && !developmentMode && !isRunningNativeMobile"
        @reload-app-event="reloadApp()"
        @close="hashChanged = false"
      />
    </transition>
  </div>
</template>

<style lang="scss">
#app {
  height: 100%;

  .it-system-alerts {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
  }

  &.spine {
    .it-system-alerts {
      top: 2rem;
      bottom: unset;
      left: 50%;
      transform: translateX(calc(-50% + 14rem));
      z-index: 99999;
      box-shadow: 0 1px 16px 0 rgb(0 0 0 / 13%);
    }
  }
}

.scale-fade-enter-active, .scale-fade-leave-active {
  transition: all 0.22s ease-in-out;
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
}

.scale-fade-enter-from, .scale-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, 30px, 0) scale(1.2);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.15s ease-in-out;
  opacity: 1;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
