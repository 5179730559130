import { defineStore } from 'pinia'
import { getSeenAnnouncementIds, storeSeenAnnouncementId } from '@/utilities/announcementState'

import notificationsService from '@/services/notificationsService'

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    announcements: [],
    unreadNotifications: [],
    unreadNotificationsCount: 0,
    unreadNotificationsNextURL: null,
  }),
  getters: {
    activeAnnouncement() {
      // This getter will return an active announcement if there is one available that has not
      // yet been acted upon. It uses local storage to know if an announcment has already been
      // acted upon (either dismissed or clicked)
      let announcement = null
      if (this.announcements.length) {
        const seenIds = new Set(getSeenAnnouncementIds())
        // Find first announcement that is not in seen announcement ids.
        const difference = [...new Set(this.announcements.filter(a => !seenIds.has(a.id)))]
        if (difference.length)
          announcement = difference[0]
      }
      return announcement
    },
  },
  actions: {
    getActiveAnnouncements() {
      return new Promise((resolve, reject) => {
        notificationsService.getAnnouncements()
          .then((response) => {
            this.announcements = response.data.results
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getUnreadNotifications() {
      return new Promise((resolve, reject) => {
        notificationsService.getNotifications()
          .then((response) => {
            this.unreadNotifications = response.data.results
            this.unreadNotificationsCount = response.data.count
            this.unreadNotificationsNextURL = response.data.next
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    removeAnnouncement(announcement) {
      const index = this.announcements.findIndex(a => a.id === announcement.id)
      if (~index)
        this.announcements.splice(index, 1)

      storeSeenAnnouncementId(announcement.id)
    },
    removeNotification(notification) {
      const index = this.unreadNotifications.findIndex(n => n.id === notification.id)
      if (~index)
        this.unreadNotifications.splice(index, 1)
    },
  },
})
