<script>
import { mapState } from 'pinia'

import ChatGroupAddReadersModal from '@/components/modals/ChatGroupAddReadersModal.vue'
import ChatGroupInviteModal from '@/components/modals/ChatGroupInviteModal.vue'
import ChatMessageThreadModal from '@/components/spine/chatgroups/ChatMessageThreadModal.vue'
import RecommendationModal from '@/components/modals/RecommendationModal.vue'
import ReviewCreateModal from '@/components/modals/ReviewCreateModal.vue'
import ReviewViewEditModal from '@/components/modals/ReviewViewEditModal.vue'
import SpineNav from '@/components/spine/SpineNav.vue'
import { useQueueEventHandler } from '@/composables/queueEventHandler'
import { useQueueStore } from '@/stores/QueueStore'
import { useSpineStore } from '@/stores/SpineStore'
import { useUsersStore } from '@/stores/UsersStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ChatGroupAddReadersModal,
    ChatGroupInviteModal,
    ChatMessageThreadModal,
    RecommendationModal,
    ReviewCreateModal,
    ReviewViewEditModal,
    SpineNav,
    ViewLoader,
  },
  setup() {
    const {
      bookQueueItemCompleted,
      bookQueueItemRecommended,
      showRecommendationModal,
      showReviewCreateModal,
      showReviewViewEditModal,
      proxyEvent,
      recommendBookHandler,
    } = useQueueEventHandler()
    return {
      queueStore: useQueueStore(),
      spineStore: useSpineStore(),
      usersStore: useUsersStore(),
      bookQueueItemCompleted,
      bookQueueItemRecommended,
      showRecommendationModal,
      showReviewCreateModal,
      showReviewViewEditModal,
      proxyEvent,
      recommendBookHandler,
    }
  },
  data() {
    return {
      groupToAddReaders: {},
      longPollingInterval: 1000 * 60, // Set this to check every minute
      longPollingIntervalId: null,
      longPollingErrorCount: 0,
      showChatGroupInviteModal: false,
      showChatGroupAddReadersModal: false,
      showMobileNav: false,
    }
  },
  computed: {
    ...mapState(useQueueStore, ['queueEventHandledStatus']),
  },
  watch: {
    queueEventHandledStatus: {
      handler(newValue) {
        if (!newValue) {
          const eventName = this.queueStore.queueEvent.name
          const bookQueueItem = this.queueStore.queueEvent.item
          this.queueStore.clearQueueEvent()
          this.proxyEvent(eventName, bookQueueItem)
        }
      },
      immediate: true,
    },
  },
  created() {
    this.spineStore.loading.bookBoard = true
    if (this.usersStore.contacts === null)
      this.usersStore.getContacts()

    const bookSlug = this.$route.params.bookSlug
    if (this.spineStore.bookBoard && this.queueStore.currentBookQueueItem
      && this.spineStore.bookBoard.book.slug === bookSlug
      && this.queueStore.currentBookQueueItem.book.slug === bookSlug) {
      // Cached information should still be accurate
      // Set long polling timer to refresh unread messages state
      this.longPollingIntervalId = setInterval(
        this.pollUsersUnreadMessages,
        this.longPollingInterval,
      )
      this.spineStore.loading.bookBoard = false
      return
    }

    const getBookBoardEntries = bookBoardId => (this.spineStore.getBookBoardEntries(bookBoardId))
    const getCurrentBookQueueItem = this.queueStore.getCurrentBookQueueItemByBookSlug(bookSlug)

    this.spineStore.getBookBoardByBookSlug(bookSlug)
      .then((bookBoard) => {
        Promise.all([getBookBoardEntries(bookBoard.id), getCurrentBookQueueItem])
          .then(() => {
            // Set long polling timer to refresh unread messages state
            this.longPollingIntervalId = setInterval(
              this.pollUsersUnreadMessages,
              this.longPollingInterval,
            )
            this.spineStore.loading.bookBoard = false
          })
          .catch(() => {
            this.$router.push({ name: 'app-root' })
            this.spineStore.setAlert({
              type: 'error',
              message: 'There was an error retrieving your book board entries. Please try again.',
              active: true,
            })
          })
      })
      .catch(() => {
        this.$router.push({ name: 'route-not-found' })
        this.spineStore.setAlert({
          type: 'error',
          message: 'We could not retrieve your book board. Please try again.',
          active: true,
        })
      })
  },
  beforeUnmount() {
    this.destroyPollingInterval()
  },
  methods: {
    closeChatMessageThread() {
      this.spineStore.threadParentMessage = null
      this.spineStore.showMessageThread = !this.spineStore.showMessageThread
    },
    destroyPollingInterval() {
      if (this.longPollingIntervalId)
        clearInterval(this.longPollingIntervalId)
    },
    handleAddReadersEvent(group) {
      this.groupToAddReaders = group
      this.showChatGroupAddReadersModal = true
    },
    pollUsersUnreadMessages() {
      // This function is called on an interval to look for unread chat messages. If there are more
      // then 3 errors in a row, we destroy the polling interval since the user likely has an invalid token.
      const CONSECUTIVE_ERRORS_BEFORE_POLLING_RESET = 3
      this.spineStore.getUsersChatGroupsUnreadMessagesByBookId()
        .then(() => {
          this.longPollingErrorCount = 0
        })
        .catch(() => {
          this.longPollingErrorCount += 1
          if (this.longPollingErrorCount > CONSECUTIVE_ERRORS_BEFORE_POLLING_RESET) {
            this.destroyPollingInterval()
            this.longPollingErrorCount = 0
          }
        })
    },
  },
}
</script>

<template>
  <div class="bookboard">
    <ViewLoader v-if="spineStore.loading.bookBoard" />
    <div v-else class="bookboard__container">
      <SpineNav
        v-if="spineStore.bookBoard && spineStore.chatGroups"
        :book-board="spineStore.bookBoard"
        :chat-groups="spineStore.chatGroups"
        :show-mobile-nav="showMobileNav"
        @close-mobile-nav="showMobileNav = false"
        @invite-readers="showChatGroupInviteModal = true"
      />
      <div class="bookboard__container__content">
        <router-view
          @open-mobile-nav="showMobileNav = true"
          @add-readers="handleAddReadersEvent"
        />
      </div>
    </div>
    <transition name="scale-fade">
      <ChatGroupInviteModal
        v-if="showChatGroupInviteModal"
        :book-queue-item="queueStore.currentBookQueueItem"
        :go-home-after="false"
        @close="showChatGroupInviteModal = false"
      />
    </transition>
    <transition name="scale-fade">
      <ChatGroupAddReadersModal
        v-if="showChatGroupAddReadersModal"
        :chat-group="groupToAddReaders"
        :go-home-after="false"
        @close="showChatGroupAddReadersModal = false"
      />
    </transition>
    <transition name="scale-fade">
      <ChatMessageThreadModal
        v-if="spineStore.showMessageThread"
        @close="closeChatMessageThread"
      />
    </transition>
    <transition name="scale-fade">
      <ReviewCreateModal
        v-if="showReviewCreateModal"
        :book-queue-item="bookQueueItemCompleted"
        :go-home-after="false"
        @close="showReviewCreateModal = false"
        @trigger-recommend-modal="recommendBookHandler"
      />
    </transition>
    <transition name="scale-fade">
      <ReviewViewEditModal
        v-if="showReviewViewEditModal"
        :book-queue-item="bookQueueItemCompleted"
        :go-home-after="false"
        @close="showReviewViewEditModal = false"
      />
    </transition>
    <transition name="scale-fade">
      <RecommendationModal
        v-if="showRecommendationModal"
        :book-queue-item="bookQueueItemRecommended"
        :go-home-after="false"
        @close="showRecommendationModal = false"
      />
    </transition>
  </div>
</template>

<style lang='scss' scoped>
.bookboard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      min-width: 71rem;
      padding-left: 28rem;
      text-align: left;
      height: 100%;

      @include respond-to('medium') {
        display: block;
        flex-direction: unset;
        min-width: unset;
        padding-left: 0;
      }
    }
  }
}

.scale-fade-enter-active, .scale-fade-leave-active {
  transition: all 0.22s ease-in-out;
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
}

.scale-fade-enter-from, .scale-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, 30px, 0) scale(1.2);
  opacity: 0;
}
</style>
