<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
}
</script>

<template>
  <div class="radio-button-group" :class="{ horizontal: !vertical }">
    <component
      :is="vertical ? 'div' : 'span'"
      v-for="option in options"
      :key="option.value"
      :class="{ horizontal: !vertical }"
    >
      <BaseRadioButton
        :label="option.label"
        :value="option.value"
        :name="name"
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
      />
    </component>
  </div>
</template>

<style lang="scss" scoped>
.radio-button-group {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;
    justify-content: flex-start;

    @include respond-to('medium') {
      justify-content: space-around;
    }
  }
}

span.horizontal {
  display: inline-block;
  margin-right: 4rem;

  @include respond-to('medium') {
    margin-right: 2rem;
  }
}
</style>
