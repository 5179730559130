<script>
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    MiniLoader,
  },
  props: {
    invitation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['acceptedInvite', 'dismissedInvite'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    isItemLoading() {
      return (this.queueStore.loading.invitationAction.item
        && this.queueStore.loading.invitationAction.item.id === this.invitation.id)
    },
    invitationCardHeader() {
      let header
      switch (this.invitation.invite_type) {
        case 'bookrecommendation':
          header = 'You have a new book recommendation!'
          break
        case 'chatgroupinvite':
          header = 'You have a new chat group invite!'
          break
        default:
          header = 'You have a new invite!'
      }
      return header
    },
    invitationLabel() {
      // If this invitation was not sent to the authenticated user, don't include the label
      const receivedInvites = this.invitation.invited_object.recipients.filter((recipient) => {
        const recipientUsername = recipient.user ? recipient.user.username : null
        return this.invitation.owner.username === recipientUsername
      })
      if (receivedInvites.length === 0)
        return null

      let label = null
      const friendlyName = this.invitation.invited_object.owner.friendly_name
      switch (this.invitation.invite_type) {
        case 'bookrecommendation':
          label = `Recommended by ${friendlyName}`
          break
        case 'chatgroupinvite':
          label = `Invited by ${friendlyName}`
          break
        default:
          label = null
      }
      return label
    },
    bookObject() {
      let book
      switch (this.invitation.invite_type) {
        case 'bookrecommendation':
          book = this.invitation.invited_object.book
          break
        case 'chatgroupinvite':
          book = this.invitation.invited_object.chat_group.book
          break
        default:
          book = {}
      }
      return book
    },
    acceptInviteLabel() {
      let label
      switch (this.invitation.invite_type) {
        case 'bookrecommendation':
          label = 'Add to Queue'
          break
        case 'chatgroupinvite':
          label = 'Join Group'
          break
        default:
          label = 'Accept Invite'
      }
      return label
    },
  },
  methods: {
    dismissInvite(invitation) {
      this.$emit('dismissedInvite', invitation)
    },
    acceptInvite(invitation) {
      this.$emit('acceptedInvite', invitation)
    },
  },
}
</script>

<template>
  <div class="invitation">
    <div class="invitation__header">
      <p class="medium invitation__header__blurb">
        {{ invitationCardHeader }}
      </p>
    </div>
    <div class="invitation__book-details">
      <div class="invitation__book-details__cover" :style="{ backgroundImage: `url(${bookObject.cover_image_url})` }">
        <transition name="fade">
          <div
            v-if="isItemLoading"
            class="invitation__book-details__cover__loader"
          >
            <MiniLoader color-class="light" class="invitation__book-details__cover__loader__dots" />
          </div>
        </transition>
      </div>
      <div class="invitation__book-details__meta">
        <p class="medium invitation__book-details__meta__title">
          {{ bookObject.title }}
        </p>
        <p class="invitation__book-details__meta__author">
          by <span v-for="(author, i) in bookObject.authors" :key="i">{{ author }}{{ (bookObject.authors.length - 1) > i ? ', ' : '' }}</span>
        </p>
        <div v-if="invitationLabel" class="invitation__book-details__meta__tag set">
          <span type="text">{{ invitationLabel }}</span>
        </div>
      </div>
    </div>
    <div class="invitation__action-links">
      <button
        class="large link_btn link_btn--secondary"
        @click="dismissInvite(invitation)"
      >
        Not Interested
      </button>
      <button
        class="large link_btn link_btn--primary"
        @click="acceptInvite(invitation)"
      >
        {{ acceptInviteLabel }}
      </button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.invitation {
  position: relative;
  text-align: left;
  width: 41rem;
  padding: 1rem 2rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  margin-right: 6rem;
  background-color: $color-white;
  box-shadow: 0 0 20px 1px rgb(43 36 13 / 8%);
  margin-bottom: 2rem;

  &__header {
    position: relative;
    padding: 1.5rem;
    padding-left: 0;

    &__blurb {
      @include font-size(2);

      font-weight: 500;
      line-height: 1.7rem;
      margin-bottom: 0;

      @include respond-to('small') {
        @include font-size(1.45);
      }
    }
  }

  &__book-details {
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $color-section-head-border;

    &__cover {
      position: relative;
      padding: 1.5rem;
      width: 6rem;
      height: 8rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      &__loader {
        @include full-wh;

        background-color: rgb(0 0 0 / 70%);

        &__dots {
          position: relative;
          display: block;
          margin: 0 auto;
          top: 50%;
          transform: translateY(-50%) scale(1.2);
          left: 10%;
        }
      }
    }

    &__meta {
      position: relative;
      width: calc(100% - 6rem);
      padding: 0 1.5rem;

      &__title {
        @include font-size(1.4);

        font-weight: 600;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__author {
        @include font-size(1.2);

        line-height: 1.7rem;
        margin-bottom: 2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__tag {
        position: relative;

        @include font-size(1.2);

        span {
          position: relative;

          @include font-size(1.2);

          border: 1px solid $color-san-felix;
          border-radius: 4rem;
          outline: none;
          padding: 0.35rem 1rem;
          background-color: transparent;
          text-align: center;
          z-index: 2;
          transition: all 0.18s ease-in-out;
          color: $color-san-felix;
          &:focus { background-color: transparent; }
        }

        &.set {
          input {
            position: relative;
            color: $color-san-felix;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            border: 1px solid $color-san-felix;
          }
        }
      }
    }
  }

  &__action-links {
    padding-top: 0.75rem;
    text-align: right;

    .link_btn {
      bottom: 1.5rem;

      &--primary {
        padding-right: 0;
      }

      &--secondary {
        color: $color-gray-medium;
      }
    }
  }

  @include respond-to('small') {
    width: 100%;
    box-shadow: 0 2px 5px 0 rgb(43 36 13 / 11%);
    padding: 0.7rem 1.5rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
