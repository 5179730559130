<script>
import OptionItem from './OptionItem.vue'

export default {
  components: {
    OptionItem,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    initialValues: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['selectionUpdate'],
  methods: {
    handleSelectionUpdate(option) {
      this.$emit('selectionUpdate', option)
    },
    isSelected(option) {
      return this.initialValues.includes(option.id)
    },
  },
}
</script>

<template>
  <div v-if="options" class="forms_multi-option-set">
    <OptionItem
      v-for="(option, i) in options"
      :key="i"
      :option="option"
      :initial-selected="isSelected(option)"
      @selection-update="handleSelectionUpdate"
    />
  </div>
</template>

<style lang='scss' scoped>
.forms_multi-option-set {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}
</style>
