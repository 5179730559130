<script>
import debounce from 'lodash/debounce'

import SearchResultsList from '@/components/search/SearchResultsList.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    SearchResultsList,
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      bookSearch: null,
      bookSearchPlaceholder: 'Find a book...',
    }
  },
  mounted() {
    if (this.$route.query.q) {
      this.bookSearch = this.$route.query.q.split('+').join(' ')
      this.queueStore.searchBooks({ query: this.$route.query.q })
    }
  },
  methods: {
    handleBookSearch: debounce(function () {
      if (this.bookSearch) {
        const joinedTerms = this.bookSearch.toLowerCase().split(' ').join('+')
        this.queueStore.searchBooks({ query: joinedTerms })
      }
      else {
        this.queueStore.setSearchResults()
      }
    }, 250),
  },
}
</script>

<template>
  <div id="italic-search">
    <input
      id="search-input"
      v-model.trim="bookSearch"
      type="text"
      :placeholder="bookSearchPlaceholder"
      @input="handleBookSearch"
    >
    <div v-if="queueStore.search.results" id="search-meta">
      <p>Showing {{ queueStore.search.results.length }} titles related to <span class="search_terms">{{ bookSearch }}</span></p>
    </div>
    <SearchResultsList :search-value="bookSearch" />
  </div>
</template>

<style lang='scss' scoped>
#italic-search {
  @include full-wh;

  position: fixed;
  background-color: $color-queue-background;
  z-index: 2;
  padding: 8rem;
  overflow-y: scroll;

  #search-input {
    position: relative;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding: 0;
    width: 100%;
    height: 7rem;
    font: $title1;
    border-radius: 0;
    border-bottom: 1px solid $color-section-head-border;

    @include respond-to('small') {
      font: $title1-small;
    }
  }

  #search-meta {
    margin-bottom: 3rem;

    p {
      color: $color-gray-medium;

      .search_terms {
        color: $color-black;
      }
    }
  }
}
</style>
