/* customEvents
 *
 * This file contains references to custom events naming so that we don't introduce bugs when
 * trying to use correct event names.
 */
export const ARCHIVE_BOOK_EVENT = 'archiveBook'
export const CLEAR_FINISHED_DATE_EVENT = 'clearFinishedDate'
export const COMPLETE_BOOK_EVENT = 'completeBook'
export const MOVE_BOOK_TO_QUEUE_EVENT = 'moveBookToQueue'
export const PAUSE_BOOK_EVENT = 'pauseBook'
export const RECOMMEND_BOOK_EVENT = 'recommendBook'
export const REMOVE_BOOK_EVENT = 'removeBook'
export const RESTART_BOOK_EVENT = 'restartBook'
export const REVIEW_BOOK_EVENT = 'reviewBook'
export const START_BOOK_EVENT = 'startBook'
export const VIEW_BOOK_DETAIL_EVENT = 'viewBookDetail'

export const CUSTOM_EVENTS_ARRAY = [
  ARCHIVE_BOOK_EVENT,
  CLEAR_FINISHED_DATE_EVENT,
  COMPLETE_BOOK_EVENT,
  MOVE_BOOK_TO_QUEUE_EVENT,
  PAUSE_BOOK_EVENT,
  RECOMMEND_BOOK_EVENT,
  REMOVE_BOOK_EVENT,
  RESTART_BOOK_EVENT,
  REVIEW_BOOK_EVENT,
  START_BOOK_EVENT,
  VIEW_BOOK_DETAIL_EVENT,
]
