/*
 * This module adds new actions from URL parameters to the action cache stored in local storage
 * and retrieves the action cache from local storage.
 */

const acceptableActions = ['book', 'bookrecommendation', 'chatgroupinvite']

export function addNewActionToCache(action) {
  const actionType = action.actionType.toLowerCase()
  if (!acceptableActions.includes(actionType)) {
    // Not an acceptable action, return
    return
  }

  const actionsCache = getActionsCache()
  const actionExists = actionsCache.some((aObj) => {
    return aObj.actionType === actionType && aObj.identifier === action.identifier
  })
  if (!actionExists) {
    actionsCache.push(action)
    localStorage.setItem('actionsCache', JSON.stringify(actionsCache))
  }
}

export function getActionsCache() {
  let actionsCache = []
  if (localStorage.getItem('actionsCache')) {
    actionsCache = JSON.parse(localStorage.getItem('actionsCache'))

    // We need to test if the actionsCache is actually an object to protect against a previous
    // version of the actionsCache that was an object of stored arrays. We need the actionsCache
    // to be an array of actionObjects in the newest version.
    if (!Array.isArray(actionsCache))
      actionsCache = []
  }
  return actionsCache
}

export function removeActionFromCache(action) {
  const actionType = action.actionType.toLowerCase()
  if (!acceptableActions.includes(actionType)) {
    // Not an acceptable action, return
    return
  }

  const actionsCache = getActionsCache()
  if (actionsCache.length) {
    const index = actionsCache.findIndex((aObj) => {
      return aObj.actionType === actionType && aObj.identifier === action.identifier
    })
    if (~index) {
      actionsCache.splice(index, 1)
      localStorage.setItem('actionsCache', JSON.stringify(actionsCache))
    }
  }
}

export function removeActionsCache() {
  // This function removes the actions cache from local storage, typically on a logout.
  localStorage.removeItem('actionsCache')
}
