<script>
import ConfettiExplosion from 'vue-confetti-explosion'
import analytics from '@/utilities/analytics'
import ShippingAddressForm from '@/components/form/ShippingAddressForm.vue'
import { usePaymentsStore } from '@/stores/PaymentsStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ConfettiExplosion,
    ShippingAddressForm,
    ViewLoader,
  },
  setup() {
    return {
      paymentsStore: usePaymentsStore(),
    }
  },
  data() {
    const confettiColors = [
      '#1d5330', '#000000', '#143a22', '#603332', '#f7f5f3', '#ddB49f', '#965532', '#78403e',
    ]
    return {
      isLoading: true,
      confettiOptions: {
        stageHeight: 1000,
        colors: confettiColors,
      },
    }
  },
  created() {
    this.paymentsStore.getCheckoutSession(this.$route.query.session_id)
      .then(() => {
        this.isLoading = false
        analytics.trackEvent('Checkout Success', analytics.TYPE_NOT_DEFINED, {})
      })
      .catch(() => {
        // Fail silently because the only draw back is to autofill the address in the form.
      })

    // We have to retrieve the customer again because the status has likely changed once we land
    // here.
    this.paymentsStore.getCustomer()
      .catch(() => {
        this.paymentsStore.setAlert({
          type: 'error',
          message: 'We could not retrieve your customer data. Please reload to try again.',
          active: true,
        })
      })
  },
  methods: {
    optOut() {
      this.paymentsStore.updateCustomerMembershipPerks({ wantsMembershipPerks: false })
        .then(() => {
          analytics.trackEvent('Selected Membership Perks', analytics.TYPE_NOT_DEFINED, {
            opt_in: false,
          })
          this.paymentsStore.setAlert({
            type: 'success',
            message: 'We have opted you out from membership perks.',
            active: true,
          })
          this.$router.replace({ name: 'membership' })
        })
        .catch(() => {
          this.paymentsStore.setAlert({
            type: 'error',
            message: 'We could not opt you out from membership perks. Please try again.',
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div class="subscription-success-view">
    <div v-if="!isLoading" class="subscription-success">
      <h1>Thank you for your support!</h1>
      <h2>You're a Champion of Letters. A bon vivant and arbiter of taste. Simply put: you're the best!</h2>
      <p class="subscription-success__body">
        As a founding member of Italic Type you're in a special club. One that comes with surprise mail from time to time. Please confirm your mailing address below. If you need to update your address in the future, you can do so from your membership settings.
      </p>
      <ShippingAddressForm
        class="subscription-success__form"
        @address-saved="$router.replace({ name: 'membership' })"
      >
        <template #title>
          <h3 class="user-form__form-header">
            Confirm Shipping Address
          </h3>
        </template>
      </ShippingAddressForm>
      <button
        class="link_btn link_btn--inverse large opt-out-link"
        @click="optOut"
      >
        No Thanks
      </button>
      <ConfettiExplosion
        class="confetti"
        :colors="confettiOptions.colors"
        :stage-height="confettiOptions.stageHeight"
      />
    </div>
    <ViewLoader v-else />
  </div>
</template>

<style lang="scss" scoped>
.subscription-success-view {
  width: 100%;
  height: 100%;
  position: relative;
}

.subscription-success {
  width: 100%;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-white;
  background-color: $color-burnt-sienna;

  &__form {
    margin-top: 3rem;
  }

  h1, h2, p {
    text-align: center;
    color: $color-white;
    width: 50%;
    min-width: 50rem;
  }

  h2, p {
    margin-top: 2rem;
  }

  &__body {
    @include font-size(1.5);
  }

  @include respond-to('small') {
    padding: 4rem 2rem;

    &__form {
      margin-top: 1rem;
    }

    h1, h2, p {
      width: 100%;
      min-width: unset;
    }

    &__body {
      @include font-size(1.4);
    }
  }
}

.opt-out-link {
  text-decoration: underline;
  margin-top: 2rem;

  @include respond-to('small') {
    margin-top: 0;
  }
}

.confetti {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 33%;
  top: 10%;
}
</style>
