<script>
import UserAvatar from '@/components/users/UserAvatar.vue'

export default {
  components: {
    UserAvatar,
  },
  props: {
    bookQueueItem: {
      type: Object,
      required: true,
    },
    memberStat: {
      type: Object,
      required: true,
    },
  },
  computed: {
    progress() {
      if (this.memberStat.is_book_completed)
        return 100

      return (this.memberStat.current_page_number / this.bookQueueItem.page_count) * 100
    },
  },
}
</script>

<template>
  <div class="member-progress">
    <div class="member-progress__avatar">
      <UserAvatar :user="memberStat.owner" width="4rem" height="4rem" />
    </div>
    <div class="member-progress__wrap">
      <div class="member-progress__wrap__meta">
        <p class="member-progress__wrap__meta__name">
          {{ memberStat.owner.name || memberStat.owner.username }}
        </p>
        <p v-if="memberStat.is_book_completed" class="member-progress__wrap__meta__progress" :class="{ completed: memberStat.is_book_completed }">
          Completed!
        </p>
        <p v-else class="member-progress__wrap__meta__progress">
          pg {{ memberStat.current_page_number }}
        </p>
      </div>
      <div class="progress-bar-wrap">
        <div class="progress-bar" :style="{ width: `${progress}%` }" />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.member-progress {
  padding: 2rem 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__avatar {
    margin-right: 1rem;
    width: 6rem;
  }

  &__wrap {
    display: inline-block;
    width: calc(100% - 6rem);

    &__meta {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.progress-bar-wrap {
  position: relative;
  width: calc(100% - 0.5rem);
  height: 0.5rem;
  background-color: $color-gainsboro;
  margin: 0.5rem 0 1.5rem;
  border-radius: 4.1rem;
  overflow: hidden;

  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: $color-oxblood;
    transition: all 0.2s ease-in-out;
  }

  @include respond-to('small') {
    margin: 0.5rem 0;
  }
}

.completed {
  color: $color-racing-green;
}
</style>
