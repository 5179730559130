<script>
import BookBoardEdit from '@/components/svgs/BookBoardEdit.vue'

export default {
  components: {
    BookBoardEdit,
  },
}
</script>

<template>
  <div class="overlay">
    <div class="overlay__wrap">
      <BookBoardEdit class="overlay__wrap__book-icon" />
      <p class="overlay__wrap__text mar-t-s">
        <slot>Book Board</slot>
      </p>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-oxblood-light;

  &__wrap {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    &__book-icon {
      display: block;
      position: relative;
      text-align: center;
      margin: 0 auto;
      width: 2.2rem;
      height: 2.2rem;
    }

    &__text {
      color: $color-oxblood;
      position: relative;
      text-align: center;
      font: $button-type;
    }
  }
}
</style>
