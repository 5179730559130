import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'
import { useQueueStore } from '@/stores/QueueStore'

import { addNewActionToCache } from '@/utilities/actionsCache'
import { trackPage } from '@/utilities/analytics'

// Auth related pages
import AccountActivatorView from '@/views/AccountActivatorView.vue'
import ForgotPasswordView from '@/views/ForgotPasswordView.vue'
import LoginView from '@/views/LoginView.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import PasswordResetView from '@/views/PasswordResetView.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import ResendVerificationEmailView from '@/views/ResendVerificationEmailView.vue'
import VerifyAccountView from '@/views/VerifyAccountView.vue'

// Book related pages
import BookDetailView from '@/views/BookDetailView.vue'
import BookListView from '@/views/BookListView.vue'
import BookListReorderView from '@/views/BookListReorderView.vue'
import ExploreView from '@/views/ExploreView.vue'
import FileImportExportView from '@/views/FileImportExportView.vue'
import HomeLayout from '@/layouts/HomeLayout.vue'
import HomeView from '@/views/HomeView.vue'
import SearchView from '@/views/SearchView.vue'

// Payments related pages
import MembershipView from '@/views/MembershipView.vue'
import PaymentsView from '@/views/PaymentsView.vue'
import SubscriptionSuccessView from '@/views/SubscriptionSuccessView.vue'

// Spine related pages
import SpineLayout from '@/layouts/SpineLayout.vue'
import ChatGroupView from '@/views/ChatGroupView.vue'
import BookBoardView from '@/views/BookBoardView.vue'

// User related pages
import SettingsView from '@/views/SettingsView.vue'

// System pages
import RouteNotFoundView from '@/views/RouteNotFoundView.vue'

function isLoggedIn() {
  const authStore = useAuthStore()
  return authStore.isAuthenticated
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'app-root',
      redirect: { name: 'home' },
      component: HomeLayout,
      children: [
        {
          path: 'books/:sourceNameId/:sourceUid/:bookSlug',
          name: 'book-detail',
          component: BookDetailView,
        },
        { path: 'home', name: 'home', component: HomeView },
        { path: 'explore', name: 'explore', component: ExploreView },
        { path: 'import', name: 'file-import-export', component: FileImportExportView },
        { path: 'search', name: 'search', component: SearchView },
        { path: 'membership', name: 'membership', component: MembershipView },
        { path: 'membership/payments', name: 'membership-payments', component: PaymentsView },
        {
          path: 'membership/payments/success',
          name: 'membership-payments-success',
          component: SubscriptionSuccessView,
        },
        { path: 'settings', name: 'settings', component: SettingsView },
        { path: 'view-all/queue', name: 'view-all-queue', component: BookListReorderView },
        {
          path: 'view-all/completed',
          name: 'completed',
          component: BookListView,
          props: { listType: 'completed' },
        },
        {
          path: 'view-all/paused',
          name: 'paused',
          component: BookListView,
          props: { listType: 'paused' },
        },
        {
          path: 'view-all/archived',
          name: 'archived',
          component: BookListView,
          props: { listType: 'archived' },
        },
      ],
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next()
        else
          next({ name: 'signin', query: { next: to.fullPath } })
      },
    },
    {
      path: '/actions/:actionType/:identifier',
      name: 'actions',
      beforeEnter(to, from, next) {
        // Add params (actionType, identifer) and query parameters (cid) to the action cache.
        addNewActionToCache({ ...to.params, ...to.query })
        if (isLoggedIn()) {
          const queueStore = useQueueStore()
          queueStore.actOnCachedActions()
          next({ name: 'home' })
        }
        else {
          next({ name: 'signin' })
        }
      },
    },
    {
      path: '/board/:bookSlug',
      component: SpineLayout,
      children: [
        { path: '', name: 'bookboard', component: BookBoardView },
        { path: ':groupId', name: 'chatgroup', component: ChatGroupView },
      ],
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next()
        else
          next({ name: 'signin', query: { next: to.fullPath } })
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: RegistrationView,
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next({ name: 'home' })
        else
          next()
      },
    },
    {
      path: '/signin',
      name: 'signin',
      component: LoginView,
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next({ name: 'home' })
        else
          next()
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPasswordView,
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next({ name: 'home' })
        else
          next()
      },
    },
    {
      path: '/forgot-password/confirm',
      name: 'forgot-password-confirm',
      component: ForgotPasswordView,
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next({ name: 'home' })
        else
          next()
      },
    },
    {
      path: '/password/reset/confirm/:uid/:token',
      name: 'password-reset',
      component: PasswordResetView,
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next({ name: 'home' })
        else
          next()
      },
    },
    {
      path: '/activate/:uid/:token',
      name: 'activate-account',
      component: AccountActivatorView,
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: OnboardingView,
      beforeEnter(to, from, next) {
        if (isLoggedIn())
          next()
        else
          next({ name: 'signin' })
      },
    },
    {
      path: '/verify',
      name: 'verify-account',
      component: VerifyAccountView,
    },
    {
      path: '/verify/resend',
      name: 'verify-resend',
      component: ResendVerificationEmailView,
    },
    {
      path: '/not-found',
      name: 'route-not-found',
      component: RouteNotFoundView,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route-not-found' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    else {
      return { top: 0, left: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.hash.startsWith('#/'))
    next(to.hash.slice(1))
  else
    next()
})

router.afterEach((to) => {
  // Capture analytics data of every page view
  trackPage(to.name, { path: to.path })
})

export default router
