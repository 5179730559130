<script>
import draggable from 'vuedraggable'

import BookListCard from '@/components/booklists/BookListCard.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useBooksPagination } from '@/composables/booksPagination'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    BookListCard,
    Draggable: draggable,
    MiniLoader,
  },
  setup() {
    const { getNextPageOfBooks } = useBooksPagination()
    return {
      getNextPageOfBooks,
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      orderCache: null,
      orderMode: false,
    }
  },
  computed: {
    queueList: {
      get() {
        return this.queueStore.queueBooks
      },
      set(queueArray) {
        this.queueStore.updateQueuePositions(queueArray)
      },
    },
  },
  created() {
    if (this.queueStore.queueBooks === null)
      this.queueStore.getUserBookList('queue')

    if (this.queueStore.isbnListAllBooks === null)
      this.queueStore.getIsbnList()
  },
  methods: {
    cancelReorder() {
      this.queueStore.updateQueuePositions(this.orderCache)
      this.orderMode = false
    },
    saveReorder() {
      this.orderMode = false
      this.queueStore.saveQueueOrder()
    },
    startReorder() {
      this.orderCache = Array.from(this.queueStore.queueBooks)
      this.orderMode = true
    },
  },
}
</script>

<template>
  <div class="book-list">
    <div class="book-list__section-head">
      <h1>Queue</h1>
      <div class="book-list__section-head__controls">
        <button v-if="!orderMode" class="book-list__section-head__controls__edit" @click="startReorder">
          Reorder
        </button>
        <div v-if="orderMode" class="book-list__section-head__controls__cancel-save">
          <button class="book-list__section-head__controls__cancel-save__cancel mar-r-m" @click="cancelReorder">
            Cancel
          </button>
          <button @click="saveReorder">
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="book-list__container">
      <Draggable
        v-model="queueList"
        item-key="id"
        :animation="200"
        :disabled="!orderMode"
        :force-fallback="true"
        ghost-class="ghost"
        :scroll-sensitivity="190"
        class="book-list__container__drag-wrap"
        @start="drag = true"
        @end="drag = false"
      >
        <template #item="{ element }">
          <BookListCard
            :item="element"
            list-type="queue"
            class="book-list__container__drag-wrap__item"
            :class="{ reordering: orderMode }"
          />
        </template>
      </Draggable>
    </div>
    <div v-if="queueStore.queueBooksNextURL !== null" class="load-more">
      <transition name="fade">
        <div
          v-if="queueStore.loading.loadMore.queue"
          class="load-more__button__loading-wrap"
        >
          <MiniLoader color-class="light" class="load-more__button__loading-wrap__loader" />
        </div>
      </transition>
      <button class="load-more__button" @click="getNextPageOfBooks('queue')">
        Load More Books
      </button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-list {
  &__section-head {
    padding: 1rem 6.5rem;

    h1 {
      display: inline-block;
      vertical-align: middle;
    }

    &__controls {
      float: right;
      vertical-align: middle;

      &__edit {
        border: 2px solid $color-burnt-sienna;
        color: $color-burnt-sienna;
        background-color: transparent;
        padding: 1.4rem 3rem;
      }

      &__cancel-save {
        &__cancel {
          border: 2px solid $color-burnt-sienna;
          color: $color-burnt-sienna;
          background-color: transparent;
          padding: 1.4rem 3rem;
        }
      }
    }

    @include respond-to('small') {
      padding: 1rem 2.5rem;
    }
  }

  &__container {
    position: relative;
    width: 100%;
    padding: 4rem;

    &__drag-wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;
      justify-content: flex-start;

      &__item {
        position: relative;
        width: 21.2rem;
        text-align: left;
        margin: 1rem;
        cursor: pointer;
        padding: 1.5rem;
        border: 1px dashed rgb(0 0 0 / 0%);

        &.reordering {
          border: 1px dashed rgb(0 0 0 / 55%);
        }

        &.sortable-chosen {
          border: 1px dashed rgb(0 0 0 / 0%);
        }

        &.ghost.reordering {
          background-color: $color-white-smoke;
          border: 1px dashed rgb(0 0 0 / 0%);
        }

        @include respond-to('small') {
          width: 100%;
          padding: 1rem 0;
          margin: 0;
          border-top: 1px solid $color-section-head-border;
        }
      }
    }

    @include respond-to('small') {
      padding: 2.5rem;
    }
  }
}

.load-more {
  width: 40rem;
  height: 4.6rem;
  position: relative;
  margin: 0 auto;

  @include respond-to('small') {
    width: 80%;
  }

  &__button {
    width: 100%;

    &__loading-wrap {
      @include full-wh;

      background-color: rgb(0 0 0 / 80%);

      &__loader {
        position: relative;
        display: block;
        margin: 0 auto;
        top: 50%;
        left: 10%;
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
