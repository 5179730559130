import Velocity from 'velocity-animate'

export const Animations = {
  beforeEnter(el) {
    el.style.opacity = 0
  },
  enter(el, done) {
    const delay = el.dataset.index * 0
    setTimeout(() => {
      Velocity(el, { opacity: 1 }, { complete: done })
    }, delay)
  },
  leave(el, done) {
    const delay = el.dataset.index * 20
    setTimeout(() => {
      Velocity(el, { opacity: 0 }, { complete: done })
    }, delay)
  },
}
