<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div id="it-system-alerts">
    <p>
      <i v-if="alert.type === 'warning'" class="material-icons">warning</i>
      <i v-if="alert.type === 'success'" class="material-icons">check</i>
      <i v-if="alert.type === 'error'" class="material-icons">error</i>
      {{ alert.message }}
    </p>
  </div>
</template>

<style lang='scss' scoped>
#it-system-alerts {
  background-color: $color-white;
  padding: 2rem;
  border-radius: 0.4rem;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%);

  &.caution {
    color: $color-error;
  }

  &.warning {
    color: $color-warning;
  }

  &.success {
    color: $color-racing-green;
  }
}
</style>
