<script>
import BookCardOverlay from '@/components/booklists/BookCardOverlay'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    BookCardOverlay,
    MiniLoader,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    showCurationDetails: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    curationDetailString() {
      let curationString = ''
      if (this.item.curator) {
        const firstName = this.item.curator.name.split(' ')[0]
        curationString = `Staff Pick: ${firstName}`
      }
      else if (this.item.curation_category) {
        curationString = this.item.curation_category
      }
      return curationString
    },
    bookDetailUrl() {
      const backUrl = this.$router.resolve({
        name: this.$router.name,
        params: this.$router.params,
      })
      const detailUrl = new URL(this.item.book.book_detail_url)
      return `${detailUrl.pathname}?back=${backUrl.path}`
    },
    isItemLoading() {
      return (this.queueStore.loading.saveBook.item
        && this.queueStore.loading.saveBook.item.source_uid === this.item.book.source_uid)
    },
    isAdded() {
      return this.saveText === 'Added'
    },
    saveText() {
      if (this.queueStore.isbnListAllBooks) {
        const foundBooks = this.queueStore.isbnListAllBooks.includes(this.item.book.isbn_13)
        if (foundBooks)
          return 'Added'
        else
          return 'Add'
      }
      else {
        return 'Add'
      }
    },
  },
  methods: {
    handleAddBook(book) {
      this.queueStore.loading.saveBook.item = book
      this.queueStore.addBookToQueue({ book })
        .then((bookQueueItem) => {
          setTimeout(() => {
            this.queueStore.getUserBookList('queue')
              .then(() => {
                this.queueStore.addNewIsbnToList({ isbn_13: book.isbn_13 })
                this.queueStore.loading.saveBook.item = null
                this.queueStore.setAlert({
                  type: 'success',
                  message: `${bookQueueItem.book.title} was successfully added to your reading list.`,
                  active: true,
                })
              })
              .catch(() => {
                const message = 'An error occurred updating your book lists. Please try again.'
                this.queueStore.setAlert({
                  type: 'error',
                  message: `${message}`,
                  active: true,
                })
                this.queueStore.loading.saveBook.item = null
              })
          }, 500)
        })
        .catch(() => {
          const message = 'An error occurred adding this book to your queue. Please try again.'
          this.queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
          this.queueStore.loading.saveBook.item = null
        })
    },
  },
}
</script>

<template>
  <div class="book-card">
    <router-link
      v-slot="{ navigate }"
      custom
      :to="bookDetailUrl"
    >
      <div
        class="book-card__cover-link"
        :style="{ backgroundImage: `url(${item.book.cover_image_url})` }"
        @click="navigate"
      >
        <div class="book-card__cover-link__rollover">
          <BookCardOverlay>Book Details</BookCardOverlay>
        </div>
      </div>
    </router-link>
    <div class="book-card__meta">
      <h4 class="book-card__meta__title medium ">
        {{ item.book.title }}
      </h4>
      <p class="book-card__meta__author">
        by <span v-for="(author, i) in item.book.authors" :key="i">{{ author }}{{ (item.book.authors.length - 1) > i ? ', ' : '' }}</span>
      </p>
      <p v-if="showCurationDetails" class="book-card__meta__curation-details">
        {{ curationDetailString }}
      </p>
    </div>
    <button
      class="book-card__add-to-queue large"
      :class="{ added: isAdded }"
      :disabled="isAdded"
      @click.stop="handleAddBook(item.book)"
    >
      <MiniLoader
        v-if="isItemLoading"
        class="book-card__add-to-queue__loader"
      />
      <span v-else>
        <i
          v-if="isAdded"
          class="material-icons book-card__add-to-queue__added-check"
        >
          check
        </i>
        {{ saveText }}
      </span>
    </button>
  </div>
</template>

<style lang='scss' scoped>
.book-card {
  position: relative;
  text-align: left;
  flex-wrap: nowrap;
  width: 18rem;
  margin-right: 7rem;

  &__meta {
    position: relative;
    margin-top: 1.7rem;

    &__title {
      position: relative;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__author {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0.5rem;
    }
  }

  &__cover-link {
    position: relative;
    width: 18rem;
    height: 26rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 8px solid $color-white;
    box-shadow: 0 3px 9px 1px rgb(43 36 13 / 8%);
    cursor: pointer;

    &__loading-wrap {
      @include full-wh;

      background-color: rgb(0 0 0 / 70%);

      &__loader {
        position: relative;
        display: block;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%) scale(1.2);
        left: 10%;
      }
    }

    &__rollover {
      z-index: 1;
      opacity: 0;
      transition: all 0.15s ease-in-out;

      &:hover {
        opacity: 0.9;
      }
    }

    @include respond-to('small') {
      width: 15rem;
      height: 22rem;
      border: 6px solid $color-white;
    }
  }

  &__add-to-queue {
    position: relative;

    &__loader {
      width: 2.5rem;
      position: relative;
      margin: 0 auto;
      left: -0.2rem;
    }

    &__added-check {
      font-size: 1.4rem;
      line-height: 1rem;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -0.1rem;
      left: -0.18rem;
      color: $color-white;
    }

    &.added {
      background-color: $color-racing-green;
      border-color: $color-racing-green;
      color: $color-white;

      &:disabled {
        opacity: 1;
      }
    }
  }

  @include respond-to('small') {
    width: 15rem;
    margin-right: 2rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
