<script>
import MemberProgressCard from '@/components/spine/chatgroups/MemberProgressCard.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useSpineStore } from '@/stores/SpineStore'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    MemberProgressCard,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  emits: ['addReaders'],
  setup() {
    return {
      authStore: useAuthStore(),
      queueStore: useQueueStore(),
      spineStore: useSpineStore(),
    }
  },
  watch: {
    group: {
      handler(group) {
        this.spineStore.loading.chatGroupMembers = true
        this.spineStore.getChatGroupMemberStats(group.id)
          .then(() => {
            this.spineStore.loading.chatGroupMembers = false
          })
          .catch(() => {
            this.spineStore.loading.chatGroupMembers = false
            this.spineStore.setAlert({
              type: 'error',
              message: 'Unable to retrieve group members. Please try again.',
              active: true,
            })
          })
      },
      immediate: true,
    },
  },
  methods: {
    handleAddReaders() {
      this.$emit('addReaders', this.group)
    },
  },
}
</script>

<template>
  <div v-if="!spineStore.loading.chatGroupMembers" class="members">
    <div class="members__body">
      <MemberProgressCard
        v-for="(memberStat, i) in spineStore.chatGroupMemberStats"
        :key="i"
        :book-queue-item="queueStore.currentBookQueueItem"
        :member-stat="memberStat"
      />
      <button
        v-if="group.owner.username === authStore.user.username"
        class="large link_btn link_btn--secondary mar-b-xl"
        @click="handleAddReaders"
      >
        + Add Readers
      </button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.members {
  position: relative;
  height: auto;

  &__body {
    margin-top: 15rem;

    @include respond-to('medium') {
      margin-top: 13rem;
    }
  }
}
</style>
