<script>
/* This module was inspired from this open source project:
 * https://github.com/surmon-china/vue-quill-editor
 */
import _Quill from 'quill'

const Quill = window.Quill || _Quill
const defaultOptions = {
  theme: 'snow',
  boundary: document.body,
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image', 'video'],
    ],
  },
  placeholder: 'Insert text here ...',
  readOnly: false,
}

// export
export default {
  props: {
    content: String,
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ['blur', 'change', 'focus', 'ready', 'update:modelValue'],
  data() {
    return {
      $_options: {},
      $_content: '',
      defaultOptions,
    }
  },
  watch: {
    // Watch content change
    content(newVal) {
      if (this.quill) {
        if (newVal && newVal !== this.$_content) {
          this.$_content = newVal
          this.quill.pasteHTML(newVal)
        }
        else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    // Watch content change
    modelValue(newVal) {
      if (this.quill) {
        if (newVal && newVal !== this.$_content) {
          this.$_content = newVal
          this.quill.pasteHTML(newVal)
        }
        else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    // Watch disabled change
    disabled(newVal) {
      if (this.quill)
        this.quill.enable(!newVal)
    },
  },
  mounted() {
    this.initialize()
  },
  beforeUnmount() {
    delete this.quill
    this.quill = null
  },
  methods: {
    // Init Quill instance
    initialize() {
      if (this.$el) {
        // Options
        this.$_options = Object.assign({}, this.defaultOptions, this.globalOptions, this.options)

        // Instance
        this.quill = new Quill(this.$refs.editor, this.$_options)
        this.quill.enable(false)

        // Set editor content
        if (this.modelValue || this.content)
          this.quill.pasteHTML(this.modelValue || this.content)

        // Disabled editor
        if (!this.disabled)
          this.quill.enable(true)

        // Mark model as touched if editor lost focus
        this.quill.on('selection-change', (range) => {
          if (!range)
            this.$emit('blur', this.quill)
          else
            this.$emit('focus', this.quill)
        })

        // Update model if text changes
        this.quill.on('text-change', () => {
          let html = this.$refs.editor.children[0].innerHTML
          const quill = this.quill
          const text = this.quill.getText()
          if (html === '<p><br></p>')
            html = ''
          this.$_content = html
          this.$emit('update:modelValue', this.$_content)
          this.$emit('change', { html, text, quill })
        })

        // Emit ready event
        this.$emit('ready', this.quill)
      }
    },
  },
}
</script>

<template>
  <div class="quill-editor">
    <slot name="toolbar" />
    <div ref="editor" />
  </div>
</template>
