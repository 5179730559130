import { defineStore } from 'pinia'
import { isObjectEmpty } from '@/utilities/empty'

import paymentsService from '@/services/paymentsService'

/*
 * This function looks for the best shipping address out of the returned addresses. Here is
 * the order it searches.
 *  (shipping, default)
 *  (shipping, not default)
 *  (billing, default)
 *  (billing, not default)
 *
 * Returns new shipping address
 */
function findBestDefaultShippingAddress(addresses) {
  let bestShippingAddress = {}
  const shippingAddresses = addresses.filter(addr => addr.address_type === 'shipping')
  if (shippingAddresses.length) {
    const defaultShippingAddress = shippingAddresses.find(addr => addr.is_default)
    if (defaultShippingAddress) {
      bestShippingAddress = defaultShippingAddress
    }
    else {
      // There is no default shipping address (that's a bug), so just take the first one
      bestShippingAddress = shippingAddresses[0]
    }
  }
  else {
    const billingAddresses = addresses.filter(addr => addr.address_type === 'billing')
    if (billingAddresses.length) {
      const defaultBillingAddress = billingAddresses.find(addr => addr.is_default)
      if (defaultBillingAddress) {
        bestShippingAddress = defaultBillingAddress
      }
      else {
        // There is no default billing address (that's a bug), so just take the first one
        bestShippingAddress = billingAddresses[0]
      }
    }
  }
  return bestShippingAddress
}

export const usePaymentsStore = defineStore('payments', {
  state: () => ({
    addresses: [],
    checkoutSessionId: null,
    checkoutSession: null,
    customer: null,
    defaultShippingAddress: {},
    product: {},
    prices: [],
    subscriptions: [],

    // Alert system
    paymentsAlert: {
      type: null,
      message: null,
      active: false,
    },
  }),
  getters: {
    activeSubscription() {
      let subscription = null
      if (this.subscriptions.length === 1) {
        subscription = this.subscriptions[0]
      }
      else {
        // Find first active one (should really only be one)
        subscription = this.subscriptions.find(sub => sub.status === 'active')
      }
      return subscription
    },
    customerAddress() {
      if (isObjectEmpty(this.defaultShippingAddress)) {
        return {
          city: '',
          country: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: '',
        }
      }
      else {
        return this.defaultShippingAddress
      }
    },
    customerWantsPerks() {
      let wantsPerks = true
      if (this.customer?.wants_membership_perks === false) {
        // When a customer opts-out, this flag will be set to false. When it's null, they have not
        // made a decision yet, so assume they want perks until they say otherwise.
        wantsPerks = false
      }
      return wantsPerks
    },
    isCustomerActiveSubscriber() {
      let activeSubscriber = false
      if (this.customer?.is_active_subscriber)
        activeSubscriber = true

      return activeSubscriber
    },
  },
  actions: {
    createNewCheckoutSession(priceId) {
      return new Promise((resolve, reject) => {
        paymentsService.postCheckoutSession({ price_id: priceId })
          .then((response) => {
            this.checkoutSessionId = response.data.checkout_session_id
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCheckoutSession(sessionId) {
      return new Promise((resolve, reject) => {
        paymentsService.getCheckoutSession(sessionId)
          .then((response) => {
            this.checkoutSessionId = response.data.id
            this.checkoutSession = response.data
            this.defaultShippingAddress = response.data.customer_details.address
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    createShippingAddress(payload) {
      return new Promise((resolve, reject) => {
        paymentsService.postAddress(payload)
          .then((response) => {
            this.defaultShippingAddress = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateShippingAddress(payload) {
      return new Promise((resolve, reject) => {
        paymentsService.patchAddress(payload.id, payload)
          .then((response) => {
            this.defaultShippingAddress = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCustomer() {
      return new Promise((resolve, reject) => {
        paymentsService.getCustomers()
          .then((response) => {
            this.customer = {}
            if (response.data.count > 0)
              this.customer = response.data.results[0]

            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateCustomerMembershipPerks(payload) {
      return new Promise((resolve, reject) => {
        paymentsService.patchCustomer(this.customer.id, {
          wants_membership_perks: payload.wantsMembershipPerks,
        })
          .then((response) => {
            this.customer.wants_membership_perks = response.data.wants_membership_perks
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getProductAndPrices() {
      return new Promise((resolve, reject) => {
        paymentsService.getProducts()
          .then((response) => {
            if (response.data.count > 0) {
              // Make sure there is at least one product
              this.product = response.data.results[0]
              this.prices = this.product.prices
            }
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getSubscriptions() {
      return new Promise((resolve, reject) => {
        paymentsService.getSubscriptions()
          .then((response) => {
            this.subscriptions = response.data.results
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getAddresses() {
      return new Promise((resolve, reject) => {
        paymentsService.getAddresses()
          .then((response) => {
            this.addresses = response.data.results
            const shippingAddress = findBestDefaultShippingAddress(this.addresses)
            if (!isObjectEmpty(shippingAddress))
              this.defaultShippingAddress = shippingAddress

            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    launchCustomerPortal(payload) {
      return new Promise((resolve, reject) => {
        paymentsService.postBilling(payload)
          .then((response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    setAlert(payload) {
      this.paymentsAlert = payload
      setTimeout(() => {
        this.paymentsAlert.active = false
      }, 4000)
    },
  },
})
