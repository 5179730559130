<script>
import { useAuthStore } from '@/stores/AuthStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ViewLoader,
  },
  setup() {
    return {
      authStore: useAuthStore(),
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    if (this.$route.name === 'activate-account') {
      this.authStore.activateAccount(this.$route.params)
        .then(() => {
          setTimeout(() => {
            this.loading = false
            this.$router.replace({ name: 'signin' })
          }, 1000)
        },
        (error) => {
          setTimeout(() => {
            this.loading = false
            if (error.status === 403) {
              // A 403 status implies that the user has already verified their account
              this.$router.replace({ name: 'signin' })
            }
            else {
              this.$router.replace({ name: 'verify-resend' })
            }
          }, 1000)
        })
    }
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-status-based-page">
      <div v-if="loading" class="user-form-status">
        <ViewLoader />
        <div class="user-form-status__head">
          <h1>Verifying your account!</h1>
          <p>Give us a second to activate your account...</p>
        </div>
      </div>
    </div>
  </div>
</template>
