import axios from 'axios'

import { API_BASE_URL, ENDPOINT_CONTACTS } from '@/services/endpoints'

// Gets
function getContacts() {
  return axios.get(API_BASE_URL + ENDPOINT_CONTACTS)
}

function getNextPage(nextURL) {
  return axios.get(nextURL)
}

const usersServiceModule = {
  getContacts,
  getNextPage,
}

export default usersServiceModule
