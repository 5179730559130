<script>
import SearchResult from './SearchResult.vue'
import { Animations } from '@/utilities/animations'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    SearchResult,
  },
  props: {
    mode: {
      type: String,
      default: 'grid',
    },
  },
  emits: ['closeResults', 'seeAll'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      animations: Animations,
    }
  },
  computed: {
    results() {
      let results = []
      if (this.queueStore.search.results && this.queueStore.search.results.length) {
        if (this.mode === 'list') {
          if (this.queueStore.search.results.length > 5)
            results = this.queueStore.search.results.slice(0, 5)
          else
            results = this.queueStore.search.results
        }
        else {
          results = this.queueStore.search.results
        }
      }
      return results
    },
  },
  methods: {
    handleSeeAll() {
      this.$emit('seeAll')
    },
  },
}
</script>

<template>
  <div class="search-results-list">
    <transition-group
      name="staggered-fade"
      tag="div"
      :css="false"
      @before-enter="animations.beforeEnter"
      @enter="animations.enter"
      @leave="animations.leave"
    >
      <SearchResult
        v-for="(book, i) in results"
        :key="`book-result-${i}`"
        :book="book"
        :data-index="i"
        :mode="mode"
        @close-results="$emit('closeResults')"
      />
    </transition-group>
    <p
      v-if="results.length && results.length < 6 && queueStore.search.results.length > 5"
      class="search-results-list__see-all cur-p"
      @click="$emit('seeAll')"
    >
      See All ({{ queueStore.search.results.length }})
    </p>
  </div>
</template>

<style lang='scss' scoped>
  .search-results-list__see-all {
    text-align: center;
    position: relative;
    margin-top: 2.5rem;
    font: $body2;
  }
</style>
