import { defineStore } from 'pinia'
import jwt_decode from 'jwt-decode'

import analytics from '@/utilities/analytics'
import authService from '@/services/authService'

export function decodeJWT(token) {
  // Decode the JWT to get the expiration time of the access token
  let decodedJSON

  try {
    decodedJSON = jwt_decode(token)
  }
  catch (e) {
    decodedJSON = null
  }
  return decodedJSON
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: null,
    accessExpirationDate: null,
    refreshPromise: null,
    refreshToken: null,
    refreshExpirationDate: null,
    activeLoginTimeoutId: null,
    activeRefreshTimeoutId: null,
    user: null,
    userId: null,
    email: null,
    username: null,
    accountActivated: null,
  }),
  getters: {
    isAuthenticated() {
      /* Check both if the access token is valid and if the refresh token is valid, because
       * the client will refresh on the next 403 or when the access token times out.
       */
      const now = new Date()
      return (this.accessToken !== null && now <= this.accessExpirationDate)
             || (this.refreshToken !== null && now <= this.refreshExpirationDate)
    },
  },
  actions: {
    activateAccount(payload) {
      return new Promise((resolve, reject) => {
        authService.postUserActivation({ uid: payload.uid, token: payload.token })
          .then((response) => {
            this.accountActivated = true
            resolve(response)
          },
          (error) => {
            this.accountActivated = false
            reject(error.response)
          })
      })
    },
    clearOnboardingFlag() {
      return new Promise((resolve, reject) => {
        authService.patchUser({ is_onboarded_web: true })
          .then((response) => {
            this.storeUserData(response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    fetchUser() {
      return new Promise((resolve, reject) => {
        if (!this.isAuthenticated)
          return reject(new Error('No access token'))

        authService.getUser()
          .then((response) => {
            this.storeUserData(response.data)
            analytics.identifyUser(response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    login(authData) {
      return new Promise((resolve, reject) => {
        authService.postJWTCreate({ username: authData.username, password: authData.password })
          .then((response) => {
            return this.storeNewTokens(response.data)
          },
          (error) => {
            reject(error.response)
          })
          .then(() => {
            // New tokens have been stored
            resolve()
          })
      })
    },
    logout() {
      return new Promise((resolve) => {
        this.clearAuthData()
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessExpiration')
        localStorage.removeItem('refreshExpiration')
        localStorage.removeItem('userId')
        authService.clearAuthorizationHeaders()
        resolve()
      })
    },
    refreshTokens() {
      return new Promise((resolve, reject) => {
        const now = new Date()
        if (!this.refreshToken || now >= this.refreshExpirationDate) {
          // If no refresh token or refreshExpirationDate has passed, reject - nothing to do
          return reject(new Error('Cannot refresh tokens'))
        }

        authService.postJWTRefresh({ refresh: this.refreshToken })
          .then((response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          })
      })
    },
    resendActivationEmail(payload) {
      return new Promise((resolve, reject) => {
        authService.postUserResendActivation({ email: payload.email })
          .then((response) => {
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    resetPassword(payload) {
      return new Promise((resolve, reject) => {
        authService.postUserResetPassword({ email: payload.email })
          .then((response) => {
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    setLogoutTimer(expirationDate) {
      this.cancelActiveLoginTimeout()
      const t = expirationDate.getTime() - new Date().getTime()
      const timeoutId = setTimeout(() => {
        this.clearAuthData()
      }, t)
      this.activeLoginTimeoutId = timeoutId
    },
    resetNewPassword(payload) {
      return new Promise((resolve, reject) => {
        authService.postUserResetPasswordConfirm(payload)
          .then((response) => {
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    setNewPassword(payload) {
      return new Promise((resolve, reject) => {
        authService.postUserSetPassword(payload)
          .then((response) => {
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    setRefreshTimer(expirationDate) {
      this.cancelActiveRefreshTimeout()
      const t = expirationDate.getTime() - new Date().getTime()
      const timeoutId = setTimeout(() => {
        this.refreshPromise = this.refreshPromise || this.refreshTokens()
        this.refreshPromise
          .then((data) => {
            this.refreshPromise = null
            return this.storeNewTokens(data)
          })
          .then(() => {
            this.fetchUser()
          })
      }, t)
      this.activeRefreshTimeoutId = timeoutId
    },
    signup(authData) {
      return new Promise((resolve, reject) => {
        authService.postUser({
          email: authData.email,
          name: authData.name,
          username: authData.username,
          password: authData.password,
        })
          .then((response) => {
            analytics.aliasUser(response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    storeNewTokens(resData) {
      return new Promise((resolve, reject) => {
        const accessTokenPayload = decodeJWT(resData.access)
        if (accessTokenPayload === null) {
          // Invalid token, cannot be decoded so reject
          return reject(new Error('Invalid access token'))
        }
        const accessExpirationDate = new Date(accessTokenPayload.exp * 1000)

        const refreshTokenPayload = decodeJWT(resData.refresh)
        if (refreshTokenPayload === null) {
          // Invalid token, cannot be decoded so reject
          return reject(new Error('Invalid refresh token'))
        }
        const refreshExpirationDate = new Date(refreshTokenPayload.exp * 1000)

        const authData = { ...resData, accessExpirationDate, refreshExpirationDate }
        this.accessToken = authData.access
        authService.setAuthorizationHeaders(authData.access)

        this.refreshToken = authData.refresh
        this.accessExpirationDate = authData.accessExpirationDate
        this.refreshExpirationDate = authData.refreshExpirationDate
        this.setRefreshTimer(authData.accessExpirationDate)
        this.setLogoutTimer(authData.refreshExpirationDate)

        localStorage.setItem('accessToken', authData.access)
        localStorage.setItem('refreshToken', authData.refresh)
        localStorage.setItem('accessExpiration', authData.accessExpirationDate)
        localStorage.setItem('refreshExpiration', authData.refreshExpirationDate)
        localStorage.setItem('userId', accessTokenPayload.user_id)

        analytics.identifyUserIdOnly(accessTokenPayload.user_id)
        resolve(resData)
      })
    },
    tryAutoLogin() {
      return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('accessToken')
        const refreshToken = localStorage.getItem('refreshToken')
        const accessExpirationDate = new Date(localStorage.getItem('accessExpiration'))
        const refreshExpirationDate = new Date(localStorage.getItem('refreshExpiration'))
        const now = new Date()

        if (accessToken && refreshToken && now <= accessExpirationDate) {
          const tokenResponse = {
            access: accessToken,
            refresh: refreshToken,
          }
          this.storeNewTokens(tokenResponse)
            .then(() => {
              // Tokens stored, fetch the user object
              return this.fetchUser()
            })
            .then(() => {
              // Successfully fetched the user, resolve
              return resolve()
            })
            .catch((error) => {
              // Return with rejection to end processing function.
              return reject(error)
            })
        }
        else {
          // Remove stale authentication data if exists
          localStorage.removeItem('accessToken')
          localStorage.removeItem('accessExpiration')
          localStorage.removeItem('userId')

          if (refreshToken && now <= refreshExpirationDate) {
            // We have a valid refresh token, try to refresh the token but only if we are not
            // actively trying to refresh the token
            this.refreshToken = refreshToken
            this.refreshExpirationDate = refreshExpirationDate
            this.refreshPromise = this.refreshPromise || this.refreshTokens()
            this.refreshPromise
              .then((data) => {
                // Success, store new tokens
                this.refreshPromise = null
                return this.storeNewTokens(data)
              })
              .then(() => {
                // Tokens stored, fetch the user
                return this.fetchUser()
              })
              .then(() => {
                // Successfully fetched the user
                resolve()
              })
              .catch((error) => {
                // Catch any errors from any of the above states, and reject
                reject(error)
              })
          }
          else {
            // Remove stale refresh data
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('refreshExpiration')
            reject(new Error('No valid refresh tokens'))
          }
        }
      })
    },
    updateUser(userData) {
      return new Promise((resolve, reject) => {
        authService.patchUser(userData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            this.storeUserData(response.data)
            analytics.identifyUser(response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    storeUserData(userData) {
      this.user = userData
      this.email = userData.email
      this.username = userData.username
      this.userId = userData.id
    },
    clearAuthData() {
      this.cancelActiveLoginTimeout()
      this.cancelActiveRefreshTimeout()
      this.$reset()
    },
    cancelActiveLoginTimeout() {
      if (this.activeLoginTimeoutId) {
        clearTimeout(this.activeLoginTimeoutId)
        this.activeLoginTimeoutId = null
      }
    },
    cancelActiveRefreshTimeout() {
      if (this.activeRefreshTimeoutId) {
        clearTimeout(this.activeRefreshTimeoutId)
        this.activeRefreshTimeoutId = null
      }
    },
  },
})
