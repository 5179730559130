<script>
import CloseX from '@/components/svgs/CloseX.vue'
import { DISPLAY_STATE_COMPLETED } from '@/services/endpoints'
import MultiSelectList from '@/components/form/MultiSelectList.vue'
import SelectableStarRating from '@/components/reviews/SelectableStarRating'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    CloseX,
    MultiSelectList,
    SelectableStarRating,
  },
  props: {
    bookQueueItem: {
      type: Object,
      default: () => {},
    },
    /**
     * If this modal is used in another view other than home layout
     * provide an optional prop to route back home after submitting
     * a recommendation:
     */
    goHomeAfter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'triggerRecommendModal'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      review: {
        book: null,
        review_tags: [],
        thoughts: '',
        rating: null,
      },
      reviewError: false,
      errorDetail: '',
      submitted: false,
    }
  },
  mounted() {
    if (this.queueStore.reviewTags === null)
      this.queueStore.getReviewTags()
  },
  methods: {
    closeAndAlert(message) {
      this.$emit('close')
      this.queueStore.setAlert({
        type: 'success',
        message,
        active: true,
      })
    },
    handleSkip() {
      if (this.bookQueueItem.display_state === DISPLAY_STATE_COMPLETED) {
        this.$emit('close')
      }
      else {
        this.queueStore.completeBook(this.bookQueueItem)
          .then(() => {
            this.closeAndAlert(
              `${this.bookQueueItem.book.title} was successfully added to your completed list.`,
            )
            if (this.goHomeAfter)
              this.$router.push({ name: 'app-root' })
          })
          .catch(() => {
            const message = 'An error occurred while moving your book to completed. Please try again.'
            this.queueStore.setAlert({
              type: 'error',
              message: `${message}`,
              active: true,
            })
          })
      }
    },
    handleComplete() {
      this.submitted = true
      this.review.book = this.bookQueueItem.book.id
      this.queueStore.completeReview({ review: this.review })
        .then(() => {
          // If book is already completed, don't complete book, just close and alert
          if (this.bookQueueItem.display_state === DISPLAY_STATE_COMPLETED) {
            this.queueStore.updateBookQueueItem(this.bookQueueItem)
              .then(() => {
                this.closeAndAlert(`You have successfully reviewed ${this.bookQueueItem.book.title}.`)
              })
          }
          else {
            this.queueStore.completeBook(this.bookQueueItem)
              .then(() => {
                this.$emit('triggerRecommendModal', this.bookQueueItem)
                this.closeAndAlert(
                  `${this.bookQueueItem.book.title} was successfully added to your completed list.`,
                )
              })
          }
        })
        .catch((error) => {
          this.reviewError = true
          this.errorDetail = 'Please either click tags, write your thoughts, or click a star '
                             + 'rating (or all three :) )'
          if (error.data) {
            if (error.data.non_field_errors) {
              this.errorDetail = error.data.non_field_errors[0]
            }
            else if (error.data.error) {
              // On this error, this means that we already have a review and should clear state
              this.errorDetail = error.data.error
            }
          }
          this.submitted = false
        })
    },
    handleMultiSelectUpdate(selectedOption) {
      // Check for existence of this review tag already in review tags
      const index = this.review.review_tags.indexOf(selectedOption.id)
      if (selectedOption.selected) {
        if (index < 0)
          this.review.review_tags.push(selectedOption.id)
      }
      else {
        if (~index)
          this.review.review_tags.splice(index, 1)
      }
    },
  },
}
</script>

<template>
  <div class="modal">
    <div v-if="bookQueueItem && queueStore.reviewTags" class="modal__container">
      <div @click="$emit('close')">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2>Congratulations on finishing {{ bookQueueItem.book.title }}! What are your thoughts?</h2>
      </div>
      <div class="modal__container__head__alerts">
        <p v-if="reviewError" class="modal__container__head__alerts__error-msg">
          {{ errorDetail }}
        </p>
      </div>
      <form @submit.prevent="handleComplete">
        <div class="modal__container__body">
          <MultiSelectList
            :options="queueStore.reviewTags"
            @selection-update="handleMultiSelectUpdate"
          />
          <div class="modal__container__body__text-area">
            <label for="thoughts-text-area">Thoughts</label>
            <div class="modal__container__body__text-area__wrap">
              <textarea
                id="thoughts-text-area"
                v-model="review.thoughts"
                maxlength="440"
                placeholder="What you liked or didn't like about the book."
              />
            </div>
            <p class="modal__container__body__text-area__character-count">
              character count {{ review.thoughts.split('').length }}/440
            </p>
          </div>
        </div>
        <div class="modal__container__foot">
          <div class="modal__container__foot__star-rating">
            <p>Overall Review</p>
            <SelectableStarRating
              v-model="review.rating"
              class="modal__container__foot__star-rating__star-group"
            />
          </div>
          <div class="modal__container__foot__options">
            <p class="modal__container__foot__options__skip" @click="handleSkip">
              Skip review
            </p>
            <BaseButton
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="submitted"
            >
              Complete
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.modal {
  &__container {
    padding: 5rem 6rem;

    &__body {
      &__text-area {
        position: relative;
        height: 16rem;
        margin-top: 2.5rem;
        width: 100%;

        label {
          @include font-size(1.2);

          font: $body2;
        }

        &__wrap {
          margin-top: 1rem;
          height: 13rem;
          width: 100%;
          background-color: $color-seashell;
          padding: 2rem;
        }

        textarea {
          display: block;
          width: 100%;
          height: 100%;
          resize: none;

          @include font-size(1.3);

          background-color: transparent;
        }

        &__character-count {
          position: absolute;
          bottom: 0.5rem;
          right: 1rem;

          @include font-size(1.1);
        }
      }
    }

    &__foot {
      &__star-rating {
        display: inline-block;

        &__star-group {
          margin-top: -2rem;
          margin-left: -1rem;
        }
      }
    }
  }
}
</style>
