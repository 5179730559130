<script>
</script>

<template>
  <div class="empty-cta">
    <div class="empty-cta__image mar-b-xl">
      <slot name="empty-state-image" />
    </div>
    <div class="empty-cta__text-block">
      <slot name="empty-state-title" />
      <div class="empty-cta__text-block__link">
        <slot name="empty-state-cta" />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.empty-cta {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;

  &__image {
    width: 17rem;
    position: relative;
    margin: 0 4rem 0 auto;
  }

  &__text-block {
    width: 60%;
    padding: 1.25rem 7.5rem 2.5rem 2.5rem;
    text-align: left;
    font: $title1-small;

    &__link {
      padding: 1rem 0;
      font: $body1;

      a {
        text-decoration: none;
      }
    }
  }

  @include respond-to('medium') {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    &__image {
      width: 17rem;
      position: relative;
      margin: 0 auto;
    }

    &__text-block {
      padding: 1.25rem 2.5rem 2.5rem;
      width: 100%;
      text-align: center;
      font: $title1-small;
    }
  }
}
</style>
