/*
 * This module contains all the hooks to push analytics events up to segment.io.
 */

export const TYPE_NOT_DEFINED = ''
export const TYPE_BOOK = 'book'
export const TYPE_BOOK_RECOMMENDATION = 'bookrecommendation'
export const TYPE_BOOK_QUEUE_ITEM = 'bookqueueitem'
export const TYPE_CHAT_GROUP_INVITE = 'chatgroupinvite'
export const TYPE_INVITATION = 'invitation'
export const TYPE_USER = 'user'

/**
 * The getProperties function takes a property type and nested properties and returns a flat
 * object with the properties we want to pass up to analytics exactly for this type. It defaults
 * to an empty object if there is no recognized type.
 */
function getProperties(propertiesType, nestedProperties) {
  let properties = {}

  switch (propertiesType) {
    case TYPE_BOOK:
      properties = flattenBook(nestedProperties)
      break
    case TYPE_BOOK_RECOMMENDATION:
      properties = flattenBookRecommendation(nestedProperties)
      break
    case TYPE_BOOK_QUEUE_ITEM:
      properties = flattenBookQueueItem(nestedProperties)
      break
    case TYPE_CHAT_GROUP_INVITE:
      properties = flattenChatGroupInvite(nestedProperties)
      break
    case TYPE_INVITATION:
      properties = flattenInvitation(nestedProperties)
      break
    case TYPE_USER:
      properties = flattenUser(nestedProperties)
      break
    default:
      properties = nestedProperties
  }
  return properties
}

/**
 * The flattenBook function takes a book object and pulls out the information we want to pass
 * to analytics for this type.
 */
function flattenBook(book) {
  return {
    book_id: book.id,
    book_slug: book.slug,
    book_source_name: book.source_name,
    book_source_uid: book.source_uid,
    book_source_url: book.source_url,
    book_title: book.title,
    book_isbn_13: book.isbn_13,
    book_page_count: book.page_count,
    book_published_date: book.published_date,
    book_publisher: book.publisher,
  }
}

/**
 * The flattenBookRecommendation function takes a book recommendation and pulls out the
 * information we want to pass to analytics for this type.
 */
function flattenBookRecommendation(recommendation) {
  return {
    book_id: recommendation.book,
    recipients: recommendation.recipients.map(recipient => recipient.email),
    share_identifier: recommendation.share_identifier,
    sent_at: recommendation.sent_at,
  }
}

/**
 * The flattenBookQueueItem function takes a bookQueueItem and pulls out the information we want
 * to pass to analytics for this type.
 */
function flattenBookQueueItem(bookQueueItem) {
  const book = flattenBook(bookQueueItem.book)
  return {
    queue: bookQueueItem.display_state,
    current_page_number: bookQueueItem.current_page_number,
    label: bookQueueItem.label,
    is_removed: bookQueueItem.is_removed,
    date_finished: bookQueueItem.date_finished,
    started_reading_at: bookQueueItem.started_reading_at,
    finished_reading_at: bookQueueItem.finished_reading_at,
    restarted_reading_at: bookQueueItem.restarted_reading_at,
    paused_reading_at: bookQueueItem.paused_reading_at,
    number_of_times_read: bookQueueItem.number_of_times_read,
    removed_at: bookQueueItem.removed_at,
    ...book,
  }
}

/**
 * The flattenChatGroupInvite function takes a chatGroupInvite object and pulls out the
 * information we want to pass to analytics for this type.
 */
function flattenChatGroupInvite(invite) {
  return {
    group_name: invite.chat_group.name,
    book_id: invite.chat_group.book,
    recipients: invite.recipients.map(recipient => recipient.email),
    share_identifier: invite.share_identifier,
    users_invited: invite.chat_group.users_invited,
    created_at: invite.created_at,
  }
}

/**
 * The flattenInvitation function takes an invitation object and pulls out the information we
 * want to pass to analytics for this type.
 */
function flattenInvitation(invitation) {
  const invitedObject = flattenInvitedObject(invitation.invite_type, invitation.invited_object)
  return {
    invite_type: invitation.invite_type,
    accepted_invite: invitation.accepted_invite,
    responded_at: invitation.responded_at,
    ...invitedObject,
  }
}

/**
 * The flattenInvitedObject function takes an invited object and pulls out the information based
 * on the type of object.
 */
function flattenInvitedObject(inviteType, invitedObject) {
  let properties
  const baseProperties = {
    share_identifier: invitedObject.share_idenitifier,
    sender_id: invitedObject.owner.username,
    sent_at: invitedObject.sent_at,
  }
  switch (inviteType) {
    case TYPE_BOOK_RECOMMENDATION:
      properties = { ...baseProperties, ...flattenBook(invitedObject.book) }
      break
    case TYPE_CHAT_GROUP_INVITE:
      properties = { ...baseProperties }
      break
    default:
      properties = {}
  }
  return properties
}

/**
 * The flattenUser function takes a user object and pulls out the information we want to pass
 * to analytics for this type.
 */
function flattenUser(user) {
  return {
    email: user.email,
    username: user.username,
    name: user.name,
    date_joined: user.date_joined,
    is_onboarded_web: user.is_onboarded_web,
    is_onboarded_ios: user.is_onboarded_ios,
    avatar: user.avatar,
  }
}

export function aliasUser(userData) {
  window.analytics.alias(userData.id)
}

export function identifyUser(userData) {
  window.analytics.identify(userData.id, flattenUser(userData))
}

export function identifyUserIdOnly(userId) {
  window.analytics.identify(userId)
}

export function resetUser() {
  window.analytics.reset()
}

/**
 * The trackEvent function takes an event name, properties type and nested properties. It uses
 * this information to create a flattened properties list to be passed up to analytics. This
 * keeps all analytics and what is passed up to segment self contained in this module instead of
 * scattering analytics all over the source code. This module knows how to flatten out
 * parameters for each type.
 */
export function trackEvent(eventName, propertiesType = '', nestedProperties = {}) {
  const flatProperties = getProperties(propertiesType, nestedProperties)
  window.analytics.track(eventName, flatProperties)
}

export function trackPage(pageName, properties = {}) {
  window.analytics.page(pageName, properties)
}

const analyticsModule = {
  aliasUser,
  identifyUser,
  identifyUserIdOnly,
  resetUser,
  trackEvent,
  trackPage,
  TYPE_BOOK,
  TYPE_BOOK_RECOMMENDATION,
  TYPE_BOOK_QUEUE_ITEM,
  TYPE_CHAT_GROUP_INVITE,
  TYPE_INVITATION,
  TYPE_NOT_DEFINED,
  TYPE_USER,
}

export default analyticsModule
