import { defineStore } from 'pinia'
import analytics from '@/utilities/analytics'

import spinesService from '@/services/spinesService'

export const useSpineStore = defineStore('spine', {
  state: () => ({
    // Bookboard state
    bookBoard: null,
    bookBoardEntries: null,
    bookBoardEntriesNextURL: null,

    // Chat group state
    chatGroups: null,
    chatGroupMemberStats: {},
    currentChatGroup: null,
    chatGroupMessages: null,
    chatGroupMessagesNextURL: null,

    // Message threads state
    showMessageThread: false,
    threadMessages: null,
    threadMessagesCount: 0,
    threadMessagesNextURL: null,
    threadParentMessage: null,

    // Loading state
    loading: {
      bookBoard: false,
      chatGroup: false,
      chatGroupMembers: false,
      messageThread: false,
      moreEntries: false,
      moreMessages: false,
      newEntry: false,
      newMessage: false,
    },

    // Alert system
    spineAlert: {
      type: null,
      message: null,
      active: false,
    },
  }),
  getters: {
    currentChatGroupName: state => (username) => {
      let name = ''
      if (state.currentChatGroup) {
        if (state.currentChatGroup.is_one_to_one) {
          const member = username === state.currentChatGroup.members[0].username
            ? state.currentChatGroup.members[1]
            : state.currentChatGroup.members[0]
          name = member.name.length > 0 ? member.name : member.username
        }
        else {
          if (state.currentChatGroup.name)
            name = state.currentChatGroup.name
          else
            name = `Group (${state.currentChatGroup.members.length} readers)`
        }
      }
      return name
    },
    getChatGroupById: state => (id) => {
      let chatGroup
      if (state.chatGroups)
        chatGroup = state.chatGroups.find(chatGroup => chatGroup.id === id)

      return chatGroup
    },
  },
  actions: {
    getBookBoardByBookSlug(bookSlug) {
      this.bookBoard = null
      return new Promise((resolve, reject) => {
        spinesService.getBookBoardsBySlug(bookSlug)
          .then((response) => {
            if (response.data.count > 0) {
              // Since this is a list view, it is possible that this view returns an empty
              // list because it could not find the book by slug. Only move forward if we found
              // a book.

              // Pick off the first result (there should only be one since slug is unique)
              const bookBoard = response.data.results[0]
              this.bookBoard = bookBoard
              this.getUsersChatGroupsByBookId(bookBoard.book.id)
              resolve(bookBoard)
            }
            else {
              // This is the equivalent of not finding the book board.
              reject(new Error('Book Board not found for slug'))
            }
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getBookBoardEntries(bookBoardId) {
      return new Promise((resolve, reject) => {
        spinesService.getBookBoardEntriesByBoardId(bookBoardId)
          .then((response) => {
            this.bookBoardEntries = response.data.results.reverse()
            this.bookBoardEntriesNextURL = response.data.next
            resolve(response.data.results)
          },
          (error) => {
            reject(error)
          })
      })
    },
    getNextPageOfEntries() {
      return new Promise((resolve, reject) => {
        spinesService.getNextPage(this.bookBoardEntriesNextURL)
          .then((response) => {
            const reversedEntrySet = response.data.results.reverse()
            this.bookBoardEntries = reversedEntrySet.concat(this.bookBoardEntries)
            this.bookBoardEntriesNextURL = response.data.next
            resolve(response.data.results)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    addNewBookBoardEntry(newBookBoardEntry) {
      return new Promise((resolve, reject) => {
        spinesService.postBookBoardEntry(newBookBoardEntry.data, {
          headers: newBookBoardEntry.contentHeader,
        })
          .then((response) => {
            this.bookBoardEntries.push(response.data)
            const bookBoardId = newBookBoardEntry.data.book_board
              || parseInt(newBookBoardEntry.data.get('book_board'))
            analytics.trackEvent('Book Board Entry Added', analytics.TYPE_NOT_DEFINED, {
              book_board_id: bookBoardId,
              has_attachment: newBookBoardEntry.data instanceof FormData, // If FormData, attachment included
            })
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateBookBoardEntry(bookBoardEntry) {
      return new Promise((resolve, reject) => {
        spinesService.patchBookBoardEntry(bookBoardEntry.entryId, {
          text_content: bookBoardEntry.textContent,
        })
          .then((response) => {
            const index = this.bookBoardEntries.findIndex(entry => entry.id === response.data.id)
            if (~index)
              this.bookBoardEntries.splice(index, 1, response.data)

            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    removeBookBoardEntry(bookBoardEntryId) {
      return new Promise((resolve, reject) => {
        spinesService.deleteBookBoardEntry(bookBoardEntryId)
          .then((response) => {
            const index = this.bookBoardEntries.findIndex(entry => entry.id === bookBoardEntryId)
            if (~index)
              this.bookBoardEntries.splice(index, 1)

            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getChatGroup(groupId) {
      this.currentChatGroup = null
      return new Promise((resolve, reject) => {
        spinesService.getChatGroupById(groupId)
          .then((response) => {
            this.currentChatGroup = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getChatGroupMemberStats(groupId) {
      this.chatGroupMemberStats = {}
      return new Promise((resolve, reject) => {
        spinesService.getChatGroupMembers(groupId)
          .then((response) => {
            this.chatGroupMemberStats = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getUsersChatGroupsByBookId(bookId) {
      this.chatGroups = null
      return new Promise((resolve, reject) => {
        spinesService.getChatGroupsByBookId(bookId)
          .then((response) => {
            this.chatGroups = response.data.results
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getUsersChatGroupsUnreadMessagesByBookId() {
      return new Promise((resolve, reject) => {
        if (this.bookBoard) {
          spinesService.getChatGroupsUnreadMessagesByBookId(this.bookBoard.book.id)
            .then((response) => {
              this.updateChatGroupsUnreadMessages(response.data)
              resolve(response)
            },
            (error) => {
              reject(error.response)
            })
        }
        else {
          reject(new Error('Unable to update chat groups without a book board.'))
        }
      })
    },
    getChatGroupMessages(groupId) {
      return new Promise((resolve, reject) => {
        spinesService.getChatMessagesByGroup(groupId)
          .then((response) => {
            this.chatGroupMessages = response.data.results.reverse()
            this.chatGroupMessagesNextURL = response.data.next
            analytics.trackEvent('Chat Group Viewed', analytics.TYPE_NOT_DEFINED, {
              chat_group_id: groupId,
            })
            resolve(response.data.results)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getNextPageOfMessages() {
      return new Promise((resolve, reject) => {
        spinesService.getNextPage(this.chatGroupMessagesNextURL)
          .then((response) => {
            const reversedMessageSet = response.data.results.reverse()
            this.chatGroupMessages = reversedMessageSet.concat(this.chatGroupMessages)
            this.chatGroupMessagesNextURL = response.data.next
            resolve(response.data.results)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getThreadMessages() {
      return new Promise((resolve, reject) => {
        spinesService.getChatMessagesThreadByGroup(
          this.currentChatGroup.id,
          this.threadParentMessage.id,
        )
          .then((response) => {
            this.threadMessages = response.data.results.reverse()
            this.threadMessagesCount = response.data.count
            this.threadMessagesNextURL = response.data.next
            analytics.trackEvent('Chat Group Thread Viewed', analytics.TYPE_NOT_DEFINED, {
              chat_group_id: this.currentChatGroup.id,
              parent_chat_message: this.threadParentMessage.id,
            })
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getNextPageOfThreadMessages() {
      return new Promise((resolve, reject) => {
        spinesService.getNextPage(this.threadMessagesNextURL)
          .then((response) => {
            const reversedMessageSet = response.data.results.reverse()
            this.threadMessages = reversedMessageSet.concat(this.threadMessages)
            this.threadMessagesCount = response.data.count
            this.threadMessagesNextURL = response.data.next
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    sendNewChatGroupMessage(newMessage) {
      return new Promise((resolve, reject) => {
        spinesService.postChatMessageToGroup(newMessage.groupId, newMessage.data, {
          headers: newMessage.contentHeader,
        })
          .then((response) => {
            const chatMessage = response.data
            if (chatMessage.parent_chat_message) {
              this.threadMessages.push(chatMessage)
              // Update local state without doing another fetch by incrementing total count
              this.threadMessagesCount += 1
              // Increment the thread_message_count of the parent message if found
              const index = this.chatGroupMessages.findIndex(message => message.id === chatMessage.parent_chat_message)
              if (~index)
                this.chatGroupMessages[index].thread_message_count += 1
            }
            else {
              this.chatGroupMessages.push(chatMessage)
            }
            analytics.trackEvent('Chat Group Message Sent', analytics.TYPE_NOT_DEFINED, {
              parent_chat_message: newMessage.data.parent_chat_message || '',
              chat_group_id: newMessage.groupId,
              has_mention: newMessage.hasMention,
              has_attachment: newMessage.data instanceof FormData, // If FormData, attachment included
            })
            resolve(chatMessage)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateChatGroupMessage(message) {
      return new Promise((resolve, reject) => {
        spinesService.patchChatMessage(message.groupId, message.messageId, {
          text_content: message.textContent,
        })
          .then((response) => {
            const chatMessage = response.data
            let messageArray = this.chatGroupMessages
            if (chatMessage.parent_chat_message)
              messageArray = this.threadMessages

            const index = messageArray.findIndex(message => message.id === chatMessage.id)
            if (~index)
              messageArray.splice(index, 1, chatMessage)

            resolve(chatMessage)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    removeChatGroupMessage(message) {
      return new Promise((resolve, reject) => {
        spinesService.deleteChatMessage(message.chat_group, message.id)
          .then((response) => {
            let messageArray = this.chatGroupMessages
            if (message.parent_chat_message)
              messageArray = this.threadMessages

            const index = messageArray.findIndex(msg => msg.id === message.id)
            if (~index) {
              messageArray.splice(index, 1)

              if (message.parent_chat_message) {
                // Update local state without doing another fetch by decrementing total count
                this.threadMessagesCount -= 1
                if (this.threadMessagesCount < 0)
                  this.threadMessagesCount = 0

                // Decrement the thread_message_count of the parent message if found
                const index = this.chatGroupMessages.findIndex(msg => msg.id === message.parent_chat_message)
                if (~index) {
                  this.chatGroupMessages[index].thread_message_count -= 1
                  if (this.chatGroupMessages[index].thread_message_count < 0)
                    this.chatGroupMessages[index].thread_message_count = 0
                }
              }
            }
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    createChatGroupWithInvites(chatGroupInvite) {
      return new Promise((resolve, reject) => {
        spinesService.postChatGroupInvites(chatGroupInvite)
          .then((response) => {
            if (this.chatGroups === null)
              this.chatGroups = [response.data.chat_group]
            else
              this.chatGroups.push(response.data.chat_group)

            analytics.trackEvent('Chat Group Invite Sent', analytics.TYPE_CHAT_GROUP_INVITE,
              response.data)
            resolve(response.data.chat_group)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    addReadersToChatGroup(payload) {
      return new Promise((resolve, reject) => {
        spinesService.patchChatGroupInviteReaders(payload.groupId, {
          recipients: payload.recipients,
        })
          .then((response) => {
            const chatGroup = response.data
            const index = this.chatGroups.findIndex(group => group.id === chatGroup.id)
            if (~index)
              this.chatGroups.splice(index, 1, chatGroup)

            this.currentChatGroup = chatGroup
            resolve(chatGroup)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    setAlert(payload) {
      this.spineAlert = payload
      setTimeout(() => {
        this.spineAlert.active = false
      }, 4000)
    },
    updateChatGroupsUnreadMessages(updatedGroups) {
      if (this.chatGroups === null) {
        // If this.chatGroups does not hold anything yet, simply return without updates
        return
      }
      for (const groupUpdate of updatedGroups) {
        const index = this.chatGroups.findIndex(group => group.id === groupUpdate.id)
        if (~index)
          this.chatGroups[index].unread_messages = groupUpdate.unread_messages
      }
    },
  },
})
