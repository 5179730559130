<script>
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.core.css'

import formatRelative from 'date-fns/formatRelative'
import parseISO from 'date-fns/parseISO'
import UserAvatar from '@/components/users/UserAvatar.vue'
import QuillEditor from '@/components/spine/QuillEditor.vue'

export default {
  components: {
    QuillEditor,
    UserAvatar,
  },
  props: {
    parentMessage: {
      type: Object,
      required: true,
    },
  },
  emits: ['imageLoaded'],
  data() {
    return {
      content: null,
      editorOptions: {
        theme: 'bubble',
        placeholder: 'Add a caption',
      },
    }
  },
  computed: {
    parentMessageTimestamp() {
      return formatRelative(parseISO(this.parentMessage.created_at), new Date())
    },
    messageImageUrl() {
      let imageUrl = this.parentMessage.image_original
      if (this.parentMessage.image_thumbnail_small)
        imageUrl = this.parentMessage.image_thumbnail_small
      else if (this.parentMessage.image_thumbnail_large)
        imageUrl = this.parentMessage.image_thumbnail_large

      return imageUrl
    },
  },
  mounted() {
    if (this.parentMessage)
      this.content = this.parentMessage.text_content
  },
  methods: {
    handleImageLoad() {
      this.$emit('imageLoaded')
    },
  },
}
</script>

<template>
  <div class="parent_message">
    <div class="parent-message__meta">
      <UserAvatar class="parent-message__meta__avatar" :user="parentMessage.owner" width="3rem" height="3rem" />
      <div class="parent-message__meta__header">
        <span class="parent-message__meta__header__username">{{ parentMessage.owner.friendly_name }}</span>
        <span class="parent-message__meta__header__timestamp">{{ parentMessageTimestamp }}</span>
      </div>
    </div>
    <QuillEditor
      v-model="content"
      :options="editorOptions"
      :disabled="true"
    />
    <div v-if="messageImageUrl" class="parent-message__attachment">
      <img :src="messageImageUrl" @load="handleImageLoad">
    </div>
  </div>
</template>

<style lang="scss">
.parent-message {
  &__meta {
    position: relative;

    &__avatar {
      position: absolute;
      left: -4.5rem;
    }

    &__header {
      &__username {
        font-weight: 600;
        margin-right: 1rem;
      }

      &__timestamp {
        @include font-size(1.1);

        color: $color-dark-gray;
        margin: 0;
        width: 75%;
      }
    }
  }

  &__attachment {
    max-width: 70%;
    border: 1px solid $color-gainsboro;
    border-radius: 0.3rem;
    margin: 1.35rem 0;
    padding: 0.5rem;
  }

  p, blockquote, em, strong {
    &::selection {
      background: #fff0f0; /* Gecko Browsers */
    }
  }

  .ql-editor {
    p, h1, h2, h3, h4, h5, h6, i, em, strong {
      cursor: pointer;
    }

    &.ql-blank {
      padding: 0;

      &::before {
        color: $color-gray-dark;
        font-style: normal;
        left: 0;
      }
    }
  }
}

.quill-editor .ql-editor {
  padding: 0;
}
</style>
