/*
 * This module contains utility functions to determine if an array is empty, or an object has no
 * keys, etc.
 */
export function isArrayEmpty(array) {
  let empty = false
  if (!Array.isArray(array) || !array.length)
    empty = true

  return empty
}

export function isObjectEmpty(obj) {
  let empty = false
  if (JSON.stringify(obj) === '{}')
    empty = true

  return empty
}

const emptyModule = {
  isArrayEmpty,
  isObjectEmpty,
}

export default emptyModule
