<script>
export default {
  name: 'CameraIcon',
}
</script>

<template>
  <div class="camera-icon">
    <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Web-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="BookBoard-Notes" transform="translate(-1171.000000, -729.000000)" fill="#000000" fill-rule="nonzero">
          <g id="camera" transform="translate(1172.000000, 730.000000)">
            <path id="Shape" d="M8,-1 L7.8758589,-0.992276516 C7.58956969,-0.956488817 7.33023226,-0.798124031 7.16794971,-0.554700196 L5.464,2 L2,2 C0.343145751,2 -1,3.34314575 -1,5 L-1,16 C-1,17.6568542 0.343145751,19 2,19 L20,19 C21.6568542,19 23,17.6568542 23,16 L23,5 L22.9949073,4.82372721 C22.9036609,3.24891996 21.5976809,2 20,2 L16.535,2 L14.8320503,-0.554700196 C14.6465845,-0.832898865 14.3343532,-1 14,-1 L8,-1 Z M8.535,1 L13.464,1 L15.1679497,3.5547002 C15.3534155,3.83289886 15.6656468,4 16,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,16 C21,16.5522847 20.5522847,17 20,17 L2,17 C1.44771525,17 1,16.5522847 1,16 L1,5 C1,4.44771525 1.44771525,4 2,4 L6,4 L6.1241411,3.99227652 C6.41043031,3.95648882 6.66976774,3.79812403 6.83205029,3.5547002 L8.535,1 Z" />
            <path id="Oval" d="M11,5 C8.23857625,5 6,7.23857625 6,10 C6,12.7614237 8.23857625,15 11,15 C13.7614237,15 16,12.7614237 16,10 C16,7.23857625 13.7614237,5 11,5 Z M11,7 C12.6568542,7 14,8.34314575 14,10 C14,11.6568542 12.6568542,13 11,13 C9.34314575,13 8,11.6568542 8,10 C8,8.34314575 9.34314575,7 11,7 Z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang='scss' scoped>
.camera-icon {
  svg {
    path {
      transition: all 0.2s ease-in-out;
      fill: $color-silver;
    }

    &:hover {
      path {
        fill: $color-black;
      }
    }
  }
}
</style>
