<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

import { useAuthStore } from '@/stores/AuthStore'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  setup() {
    return {
      authStore: useAuthStore(),
      queueStore: useQueueStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      newPassword: '',
      errors: {},
      submitted: false,
    }
  },
  validations() {
    return {
      newPassword: {
        required: helpers.withMessage('New password is required.', required),
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length)
          this.errors = {}
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        const formData = {
          ...this.$route.params,
          new_password: this.newPassword,
        }
        this.authStore.resetNewPassword(formData)
          .then(() => {
            this.$router.replace({ name: 'signin' })
            this.queueStore.setAlert({
              type: 'success',
              message: 'You have successfully reset your password.',
              active: true,
            })
          },
          (error) => {
            this.v$.$reset()

            this.newPassword = ''
            this.errors = error.data || {}
            this.submitted = false
          })
      }
    },
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-based-page">
      <div class="user-form">
        <div class="user-form__head">
          <h1>Reset Your Password</h1>
          <p>Reset your password to gain access to your account.</p>
        </div>
        <div class="user-form__alerts">
          <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
            Please correct the following errors:
          </p>
        </div>
        <form @submit.prevent="onSubmit">
          <div class="user-form__input-wrap">
            <BaseInput
              v-model.trim="newPassword"
              type="password"
              placeholder="New Password"
              :class="{ error: v$.newPassword.$error }"
              @blur="v$.newPassword.$touch()"
            />
            <template v-if="v$.newPassword.$error">
              <p
                v-for="error in v$.newPassword.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
            <div v-if="errors.new_password">
              <p v-for="(error, index) in errors.new_password" :key="index" class="form-error-msg">
                {{ error }}
              </p>
            </div>
          </div>
          <BaseButton
            class="user-form__submit-btn"
            button-size="large"
            button-type="primary"
            type="submit"
            :disabled="v$.$errors.length || submitted"
          >
            Submit
          </BaseButton>
        </form>
        <p>
          You remember? Sign in with your existing password. <router-link :to="{ name: 'signin' }">
            Sign In
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
#password-reset {
  position: relative;
  top: 45%;
  transform: translateY(-50%);
}

.password-reset-form {
  width: 45rem;
  margin: 0 auto;
  text-align: center;

  h1, p {
    margin: 0;
  }

  h1 {
    margin-bottom: 0.8rem;
  }

  .password-reset_head {
    margin-bottom: 3.4rem;
  }
}

.input {
  margin: 10px auto;

  input {
    width: 100%;
  }
}

.submit {
  button {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

.error-msg {
  color: red;

  li {
    list-style: none;
    text-align: left;
  }
}
</style>
