import axios from 'axios'

import {
  API_BASE_URL,
  ENDPOINT_ANNOUNCEMENTS,
  ENDPOINT_NOTIFICATIONS,
} from '@/services/endpoints'

// Gets
function getAnnouncements() {
  return axios.get(API_BASE_URL + ENDPOINT_ANNOUNCEMENTS)
}

function getNotifications() {
  return axios.get(API_BASE_URL + ENDPOINT_NOTIFICATIONS)
}

const notificationsServiceModule = {
  getAnnouncements,
  getNotifications,
}

export default notificationsServiceModule
