<script>
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import { useAuthStore } from '@/stores/AuthStore'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  setup() {
    return {
      authStore: useAuthStore(),
      queueStore: useQueueStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      username: '',
      password: '',
      authError: false,
      errorDetail: '',
      submitted: false,
    }
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage('Email or username is required.', required),
      },
      password: {
        required: helpers.withMessage('Password is required.', required),
      },
    }
  },
  methods: {
    onSubmit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        const formData = {
          username: this.username,
          password: this.password,
        }
        this.authStore.login(formData)
          .then(() => {
            this.authStore.accountActivated = null
            this.queueStore.actOnCachedActions()
            return this.authStore.fetchUser()
          })
          .then((userData) => {
            // Return from fetchUser
            if (userData.is_onboarded_web) {
              if ('next' in this.$route.query)
                this.$router.replace(this.$route.query.next)
              else
                this.$router.replace({ name: 'app-root' })
            }
            else {
              this.$router.replace({ name: 'onboarding' })
            }
          })
          .catch((error) => {
            this.v$.$reset()
            this.authStore.accountActivated = null

            this.authError = true
            this.errorDetail = error.data ? error.data.detail : 'Invalid username or password'
            this.password = ''
            this.submitted = false
          })
      }
    },
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-based-page">
      <div class="user-form">
        <div class="user-form__head">
          <h1 v-if="authStore.accountActivated">
            Your Account is Verified!
          </h1>
          <h1 v-else-if="authStore.accountActivated === false">
            Account Already Verified
          </h1>
          <h1 v-else>
            Let's Get Reading!
          </h1>
          <p>Continue your reading experience by signing in with Italic Type.</p>
        </div>
        <div class="user-form__alerts">
          <p v-if="authError" class="user-form__alerts__error-msg">
            {{ errorDetail }}
          </p>
          <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
            Please correct the following errors:
          </p>
        </div>
        <form @submit.prevent="onSubmit">
          <div class="user-form__input-wrap">
            <BaseInput
              v-model.trim="username"
              type="text"
              autocorrect="off"
              autocapitalize="none"
              placeholder="Email or username"
              :class="{ error: v$.username.$error }"
              @blur="v$.username.$touch()"
            />
            <template v-if="v$.username.$error">
              <p
                v-for="error in v$.username.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
          <div class="user-form__input-wrap">
            <BaseInput
              v-model.trim="password"
              type="password"
              placeholder="Password"
              :class="{ error: v$.password.$error }"
              @blur="v$.password.$touch()"
            />
            <template v-if="v$.password.$error">
              <p
                v-for="error in v$.password.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
            <div class="user-form__forgot-password-link">
              <router-link :to="{ name: 'forgot-password' }">
                Forgot Password?
              </router-link>
            </div>
          </div>
          <BaseButton
            class="user-form__submit-btn"
            button-size="large"
            button-type="primary"
            type="submit"
            :disabled="v$.$errors.length || submitted"
          >
            Submit
          </BaseButton>
        </form>
        <p>
          Don't have an account? <router-link :to="{ name: 'signup' }">
            Sign Up
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
