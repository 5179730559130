<script>
import { mapState } from 'pinia'
import ManageSubscriptionWidget from '@/components/payments/ManageSubscriptionWidget.vue'
import OnboardingSuccess from '@/components/svgs/OnboardingSuccess.vue'
import ShippingAddressForm from '@/components/form/ShippingAddressForm.vue'
import { usePaymentsStore } from '@/stores/PaymentsStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ManageSubscriptionWidget,
    OnboardingSuccess,
    ShippingAddressForm,
    ViewLoader,
  },
  setup() {
    return {
      paymentsStore: usePaymentsStore(),
    }
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapState(usePaymentsStore, ['customer']),
  },
  watch: {
    customer(newValue) {
      if (newValue === null)
        return

      // If the customer is not an active subscriber, we should not be here. Redirect to
      // payments form.
      if (!newValue.is_active_subscriber)
        this.$router.replace({ name: 'membership-payments' })
    },
  },
  created() {
    Promise.all([this.paymentsStore.getSubscriptions(), this.paymentsStore.getAddresses()])
      .then(() => {
        this.isLoading = false
      })
      .catch(() => {
        this.paymentsStore.setAlert({
          type: 'error',
          message: 'We could not retrieve your membership information. Please try again.',
          active: true,
        })
      })
  },
}
</script>

<template>
  <div v-if="customer !== null" class="membership">
    <ViewLoader v-if="isLoading" />
    <div v-else class="membership__body">
      <div class="membership__body__left">
        <h1>Thanks for being a member</h1>
        <div class="success-img">
          <OnboardingSuccess />
        </div>
      </div>
      <div class="membership__body__right">
        <ManageSubscriptionWidget
          class="manage-widget"
        />
        <ShippingAddressForm
          class="address-form"
          :inverted="true"
          button-alignment="left"
          button-text="Save Changes"
        >
          <template #title>
            <h3 class="user-form__form-header">
              Update Shipping Address
            </h3>
          </template>
        </ShippingAddressForm>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.membership {
  width: 100%;
  position: relative;

  &__body {
    padding: 5rem 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left {
      padding: 4rem;
      padding-left: 0;
    }

    &__right {
      padding: 4rem;
      padding-right: 0;
    }
  }

  @include respond-to('large') {
    &__body {
      padding: 3rem 2rem;
      flex-direction: column;

      &__left {
        padding: 2rem;
        padding-left: 2rem;

        h1 {
          text-align: center;
        }
      }

      &__right {
        padding: 2rem;
        padding-right: 2rem;
      }
    }
  }
}

.manage-widget {
  margin-top: 1rem;
}

.address-form {
  margin-top: 4rem;

  @include respond-to('large') {
    width: 100%;
    margin: 0;
    margin-top: 3rem;
  }

  @include respond-to('small') {
    padding: 0;
  }
}

.success-img {
  margin: 0 auto;
  margin-top: 6rem;
  width: 75%;

  @include respond-to('large') {
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 40rem;
  }

  @include respond-to('small') {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 80%;
  }
}
</style>
