<script>
import OnboardingSuccess from '@/components/svgs/OnboardingSuccess.vue'

export default {
  components: {
    OnboardingSuccess,
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-status-based-page">
      <div class="user-form-status">
        <div class="user-form-status__head">
          <OnboardingSuccess class="user-form-status__head__image mar-b-xl" />
          <h1><span class="highlight highlight-pink">Thanks</span> for signing up!</h1>
          <p>
            Please check your email to verify your account. You should be automatically redirected to login after clicking the link in your email. If you have already done this, visit <router-link :to="{ name: 'signin' }">
              Login
            </router-link> to login to Italic Type.
          </p>
        </div>
        <p>
          Didn't receive the verification email? <router-link :to="{ name: 'verify-resend' }">
            Resend Email
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
