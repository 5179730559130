// Google Books resources
export const GOOGLE_BOOKS_BASE_URL = 'https://www.googleapis.com/books/v1/'
export const GOOGLE_BOOKS_ENDPOINT_VOLUMES = 'volumes'
export const GOOGLE_BOOKS_SEARCH_QUERY = '?q='
export const GOOGLE_BOOKS_MAX_RESULTS_QUERY_STRING = '&maxResults=40'
export const GOOGLE_BOOKS_API_KEY_QUERY_STRING = '&key=AIzaSyCMew4wc2w_z8c5Gcd9jyHi0WXpAktR2zA'
export const GOOGLE_BOOKS_SOURCE_NAME = 'GoogleBooksV1'
export const GOOGLE_BOOKS_SOURCE_NAME_ID = '1'

// The base URL for Italic Type
export const API_BASE_URL = '/api/'

// Endpoints for authentication
export const ENDPOINT_AUTH_JWT_CREATE = 'auth/jwt/create/'
export const ENDPOINT_AUTH_JWT_REFRESH = 'auth/jwt/refresh/'
export const ENDPOINT_AUTH_USERS = 'auth/users/'
export const ENDPOINT_AUTH_USERS_ACTIVATION = `${ENDPOINT_AUTH_USERS}activation/`
export const ENDPOINT_AUTH_USERS_ME = `${ENDPOINT_AUTH_USERS}me/`
export const ENDPOINT_AUTH_USERS_RESEND_ACTIVATION = `${ENDPOINT_AUTH_USERS}resend_activation/`
export const ENDPOINT_AUTH_USERS_RESET_PASSWORD = `${ENDPOINT_AUTH_USERS}reset_password/`
export const ENDPOINT_AUTH_USERS_RESET_PASSWORD_CONFIRM = `${ENDPOINT_AUTH_USERS}reset_password_confirm/`
export const ENDPOINT_AUTH_USERS_SET_PASSWORD = `${ENDPOINT_AUTH_USERS}set_password/`

// Endpoints and query strings for the books app of Italic Type
export const ENDPOINT_BOOKS = 'books/'
export const ENDPOINT_BOOK_QUEUE_ITEMS = 'bookqueueitems/'
export const ENDPOINT_BOOK_QUEUE_ITEMS_ISBN_LIST = `${ENDPOINT_BOOK_QUEUE_ITEMS}isbn_list/`
export const ACTION_CLEAR_FINISHED_DATE = '/clear_finished_timestamps/'
export const DISPLAY_STATE_QUEUE = 0
export const DISPLAY_STATE_CURRENTLY_READING = 1
export const DISPLAY_STATE_COMPLETED = 2
export const DISPLAY_STATE_PAUSED = 3
export const DISPLAY_STATE_ARCHIVED = 4
export const BY_QUEUE = `?queue=${DISPLAY_STATE_QUEUE}`
export const BY_CURRENTLY_READING = `?queue=${DISPLAY_STATE_CURRENTLY_READING}`
export const BY_COMPLETED = `?queue=${DISPLAY_STATE_COMPLETED}`
export const BY_ISBN = '?isbn_13='
export const BY_PAUSED = `?queue=${DISPLAY_STATE_PAUSED}`
export const BY_ARCHIVED = `?queue=${DISPLAY_STATE_ARCHIVED}`
export const BY_SOURCE_NAME_ID = 'source_name_id='
export const BY_SOURCE_UID = 'source_uid='
export const ENDPOINT_EXPLORE_LISTS = 'explorelists/'
export const ENDPOINT_EXPLORE_LISTS_BOOKS = `${ENDPOINT_EXPLORE_LISTS}books/`
export const ENDPOINT_EXPLORE_LISTS_CURATED_BOOKS = `${ENDPOINT_EXPLORE_LISTS}curated_books/`
export const BY_EXCLUDE_IDS = '?exclude_ids='
export const BY_IS_UNROLLED = '?is_unrolled='
export const ENDPOINT_EXPLORE_BOOKS = 'explorebooks/'
export const BY_CURATED_LIST_ID = '?curated_list_id='
export const ENDPOINT_FILE_EXPORT = 'fileexports/'
export const ENDPOINT_FILE_IMPORT = 'fileimports/'
export const GOODREADS_SOURCE_NAME = 'GoodreadsExport'
export const STORYGRAPH_SOURCE_NAME = 'StoryGraphExport'
export const ENDPOINT_READING_GOALS = 'readinggoals/'
export const ENDPOINT_READING_GOALS_CURRENT = `${ENDPOINT_READING_GOALS}current/`
export const ENDPOINT_RECOMMENDATIONS = 'recommendations/'
export const ENDPOINT_REVIEWS = 'reviews/'
export const ENDPOINT_REVIEW_TAGS = 'reviewtags/'
export const ENDPOINT_SEARCH = 'search/'
export const BY_SEARCH_QUERY = '?q='
export const CODEX_SOURCE_NAME = 'ItalicTypeCodex'
export const CODEX_SOURCE_NAME_ID = '0'
export const MANUAL_WEB_SCRAPE_SOURCE_NAME = 'ManualWebScrape'
export const MANUAL_WEB_SCRAPE_SOURCE_NAME_ID = '3'
export const ENDPOINT_SETTINGS = 'settings/'
export const ENDPOINT_WELCOME_QUOTES = 'welcomequotes/random_quote/'

// Endpoint and query strings for the invites app of Italic Type
export const ENDPOINT_ACTIONS = 'actions/'
export const ENDPOINT_INVITATIONS = 'invitations/'

// Endpoints and query strings for the notifications app of Italic Type
export const ENDPOINT_ANNOUNCEMENTS = 'announcements/'
export const ENDPOINT_NOTIFICATIONS = 'notifications/'

// Endpoints and query strings for the payments app of Italic Type
export const ENDPOINT_PAYMENTS_ADDRESSES = 'payments/addresses/'
export const ADDRESS_TYPE_SHIPPING = 1
export const ENDPOINT_PAYMENTS_BILLING = 'payments/billing/'
export const ENDPOINT_PAYMENTS_CHECKOUT = 'payments/checkout/'
export const ENDPOINT_PAYMENTS_CUSTOMERS = 'payments/customers/'
export const ENDPOINT_PAYMENTS_PRODUCTS = 'payments/products/'
export const ENDPOINT_PAYMENTS_SUBSCRIPTIONS = 'payments/subscriptions/'

// Endpoints and query strings for the spines app of Italic Type
export const ENDPOINT_BOOK_BOARDS = 'bookboards/'
export const ENDPOINT_BOOK_BOARD_ENTRIES = 'bookboardentries/'
export const ENDPOINT_CHAT_GROUP_INVITES = 'chatgroupinvites/'
export const ENDPOINT_CHAT_GROUPS = 'chatgroups/'
export const ACTION_MEMBERS = '/members/'
export const ACTION_INVITE_READERS = '/invite_readers/'
export const ENDPOINT_CHAT_GROUPS_UNREAD_MESSAGES = `${ENDPOINT_CHAT_GROUPS}has_unread_messages/`
export const ENDPOINT_CHAT_MESSAGES_BY_GROUP = 'chatmessages/group/'
export const BY_PARENT_CHAT_MESSAGE = '?parent_chat_message='
export const BY_BOOK_BOARD_ID = '?book_board_id='
export const BY_BOOK_ID = '?book_id='
export const BY_BOOK_SLUG = '?book_slug='

// Endpoints for the users app of Italic Type
export const ENDPOINT_CONTACTS = 'contacts/'
