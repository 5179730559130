<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import analytics from '@/utilities/analytics'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { usePaymentsStore } from '@/stores/PaymentsStore'

export default {
  components: {
    MiniLoader,
  },
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
    buttonAlignment: {
      type: String,
      default: 'center',
      validator(value) {
        // The list type must match one of the three types.
        return ['center', 'left', 'right'].includes(value)
      },
    },
    buttonText: {
      type: String,
      default: 'Confirm',
      required: false,
    },
  },
  emits: ['addressSaved'],
  setup() {
    return {
      paymentsStore: usePaymentsStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      address: {
        id: null,
        address_type: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        is_default: false,
      },
      submitted: false,
    }
  },
  validations() {
    return {
      address: {
        line1: {
          required: helpers.withMessage('Street address is required.', required),
        },
        city: {
          required: helpers.withMessage('City is required.', required),
        },
        country: {
          required: helpers.withMessage('Country is required.', required),
        },
      },
    }
  },
  computed: {
    inputBgColor() {
      let bgColor = ''
      if (this.inverted)
        bgColor = 'inverted'

      return bgColor
    },
    getButtonType() {
      let buttonType = 'secondary--inverted'
      if (this.inverted)
        buttonType = 'primary-dark'

      return buttonType
    },
  },
  created() {
    const customerAddress = this.paymentsStore.customerAddress
    this.address.id = customerAddress.id || null
    this.address_type = customerAddress.address_type || 'billing'
    this.address.line1 = customerAddress.line1 || ''
    this.address.line2 = customerAddress.line2 || ''
    this.address.city = customerAddress.city || ''
    this.address.state = customerAddress.state || ''
    this.address.postal_code = customerAddress.postal_code || ''
    this.address.country = customerAddress.country || ''
    this.address.is_default = customerAddress.is_default || false
  },
  methods: {
    submitShippingAddress() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        this.address.is_default = true // For now, set all updates and creates to default
        if (this.address.id && this.address_type === 'shipping')
          this.updateAddress()
        else
          this.createNewAddress()
      }
    },
    createNewAddress() {
      this.paymentsStore.createShippingAddress(this.address)
        .then(() => {
          this.paymentsStore.getCustomer()
            .catch(() => {
              // Just catch the error, but nothing really to do here
            })
          this.v$.$reset()
          this.submitted = false
          this.paymentsStore.setAlert({
            type: 'success',
            message: 'Your address was successfully confirmed.',
            active: true,
          })
          this.$emit('addressSaved')
          analytics.trackEvent('Selected Membership Perks', analytics.TYPE_NOT_DEFINED, {
            opt_in: true,
          })
        })
        .catch(() => {
          this.paymentsStore.setAlert({
            type: 'error',
            message: 'We could not store your address. Please try again.',
            active: true,
          })
          this.submitted = false
        })
    },
    updateAddress() {
      this.paymentsStore.updateShippingAddress(this.address)
        .then(() => {
          this.paymentsStore.getCustomer()
            .catch(() => {
              // Just catch the error, but nothing really to do here
            })
          this.v$.$reset()
          this.submitted = false
          this.paymentsStore.setAlert({
            type: 'success',
            message: 'Your address was successfully updated.',
            active: true,
          })
          this.$emit('addressSaved')
          analytics.trackEvent('Selected Membership Perks', analytics.TYPE_NOT_DEFINED, {
            opt_in: true,
          })
        })
        .catch(() => {
          this.paymentsStore.setAlert({
            type: 'error',
            message: 'We could not update your address. Please try again.',
            active: true,
          })
          this.submitted = false
        })
    },
  },
}
</script>

<template>
  <form class="user-form" @submit.prevent="submitShippingAddress">
    <slot name="title" />
    <p v-if="!paymentsStore.customerWantsPerks" class="membership-note italic">
      You are currently opted-out from receiving membership perks. If you update your shipping address below, we will make sure to set you up to start receiving membership perks.
    </p>
    <div class="user-form__input-wrap">
      <BaseInput
        v-model.trim="address.line1"
        type="text"
        label="Street address"
        :bg-color="inputBgColor"
        placeholder="Street address"
        :class="{
          'error--inverted': v$.address.line1.$error && !inverted,
          'error': v$.address.line1.$error && inverted,
        }"
        @blur="v$.address.line1.$touch()"
      />
      <template v-if="v$.address.line1.$error">
        <p
          v-for="error in v$.address.line1.$errors"
          :key="error.$uid"
          class="form-error-msg--absolute"
          :class="{ 'error--inverted': !inverted }"
        >
          {{ error.$message }}
        </p>
      </template>
    </div>
    <div class="user-form__input-wrap">
      <BaseInput
        v-model.trim="address.line2"
        type="text"
        label="Unit number or apartment"
        :bg-color="inputBgColor"
        placeholder="Unit number"
      />
    </div>
    <div class="side-by-side">
      <div class="user-form__input-wrap side-by-side__child">
        <BaseInput
          v-model.trim="address.city"
          type="text"
          label="City"
          :bg-color="inputBgColor"
          placeholder="City"
          :class="{
            'error--inverted': v$.address.city.$error && !inverted,
            'error': v$.address.city.$error && inverted,
          }"
          @blur="v$.address.city.$touch()"
        />
        <template v-if="v$.address.city.$error">
          <p
            v-for="error in v$.address.city.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
            :class="{ 'error--inverted': !inverted }"
          >
            {{ error.$message }}
          </p>
        </template>
      </div>
      <div class="user-form__input-wrap side-by-side__child">
        <BaseInput
          v-model.trim="address.state"
          type="text"
          label="State or province"
          :bg-color="inputBgColor"
          placeholder="State or Province"
        />
      </div>
    </div>
    <div class="side-by-side">
      <div class="user-form__input-wrap side-by-side__child">
        <BaseInput
          v-model.trim="address.postal_code"
          type="text"
          label="Postal code"
          :bg-color="inputBgColor"
          placeholder="Postal code"
          class=""
        />
      </div>
      <div class="user-form__input-wrap side-by-side__child">
        <BaseInput
          v-model.trim="address.country"
          type="text"
          label="Country"
          :bg-color="inputBgColor"
          placeholder="Country"
          :class="{
            'error--inverted': v$.address.country.$error && !inverted,
            'error': v$.address.country.$error && inverted,
          }"
          @blur="v$.address.country.$touch()"
        />
        <template v-if="v$.address.country.$error">
          <p
            v-for="error in v$.address.country.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
            :class="{ 'error--inverted': !inverted }"
          >
            {{ error.$message }}
          </p>
        </template>
      </div>
    </div>
    <BaseButton
      class="mar-t-m"
      :class="[buttonAlignment]"
      :button-type="getButtonType"
      button-size="medium"
      type="submit"
    >
      <MiniLoader v-if="submitted" />
      <span v-else>{{ buttonText }}</span>
    </BaseButton>
  </form>
</template>

<style lang="scss" scoped>
.side-by-side {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &__child {
    flex-grow: 1;
  }

  & div:nth-child(2) {
    margin-left: 2rem;

    @include respond-to('small') {
      margin-left: 1rem;
    }
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.membership-note {
  text-align: left;
  margin-bottom: 2rem;
}
</style>
