<script>
export default {
  props: {
    initialIsOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
    uid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.initialIsOpen,
    }
  },
}
</script>

<template>
  <div class="accordion">
    <button
      :aria-expanded="isOpen"
      :aria-controls="`collapse-${uid}`"
      @click="isOpen = !isOpen"
    >
      <slot name="title" />
      <i
        class="material-icons"
        :class="{ 'rotate-180': isOpen, 'rotate-0': !isOpen }"
      >
        keyboard_arrow_down
      </i>
    </button>
    <transition name="slide-down">
      <div v-show="isOpen" :id="`collapse-${uid}`">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.accordion {
  border-bottom: 1px solid $color-section-head-border;
  padding-bottom: 2rem;
}

button {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent;
  }
}

i {
  @include font-size(2);

  color: $color-black;
  line-height: 1rem;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;

  &.rotate-180 {
    transform: rotate(180deg);
  }
}

.slide-down-enter-active, .slide-down-leave-active {
  transition: all 0.3s ease-in;
  transform: translate3d(0, 0, 0);
}

.slide-down-enter-from, .slide-down-leave-to {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}
</style>
