<script>
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { isArrayEmpty } from '@/utilities/empty'
import { usePaymentsStore } from '@/stores/PaymentsStore'

export default {
  components: {
    MiniLoader,
  },
  props: {
    prices: {
      type: Array,
      required: true,
      validator(value) {
        // The array must not be empty
        return !isArrayEmpty(value)
      },
    },
  },
  emits: ['redirectToCheckout'],
  setup() {
    return {
      paymentsStore: usePaymentsStore(),
    }
  },
  data() {
    return {
      currency: 'usd', // In the future, the user may select the currency
      submitted: false,
      subscriptionChoice: '',
      subscriptionOptions: [],
    }
  },
  created() {
    this.subscriptionOptions = this.filterSubscriptionOptions()
    this.subscriptionChoice = this.defaultSubscriptionChoice(this.currency)
  },
  methods: {
    chooseSubscriptionPrice(event) {
      this.submitted = true
      const priceId = event.target.elements.subscriptions.value
      this.paymentsStore.createNewCheckoutSession(priceId)
        .then((data) => {
          this.$emit('redirectToCheckout', data.checkout_session_id)
          setTimeout(() => {
            this.submitted = false
          }, 1000)
        })
        .catch(() => {
          this.paymentsStore.setAlert({
            type: 'error',
            message: 'Unable to checkout at this time. Please try again please.',
            active: true,
          })
          this.submitted = false
        })
    },
    defaultSubscriptionChoice(currency) {
      const defaultOptions = this.prices.filter((price) => {
        return price.currency === currency && price.recurring_interval === 'month'
      })
      return defaultOptions.length > 0 ? defaultOptions[0].stripe_id : ''
    },
    filterSubscriptionOptions() {
      // Filter the prices into label and value
      const pricesByCurrency = this.prices.filter(price => price.currency === this.currency)
      const options = pricesByCurrency.map((price) => {
        return { label: this.formatLabelForPrice(price), value: price.stripe_id }
      })
      // See if we have to swap the order of the choices and default choice to monthly
      if (!options[0].label.startsWith('Monthly'))
        options.reverse()

      return options
    },
    formatLabelForPrice(price) {
      const currencyOptions = {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: 2,
      }
      const currencyString = new Intl.NumberFormat('en-US', currencyOptions)
        .format(price.unit_amount / 100)
      const intervalString = `${price.recurring_interval.charAt(0).toUpperCase()
                             + price.recurring_interval.slice(1)}ly`
      return `${intervalString} (${currencyString})`
    },
  },
}
</script>

<template>
  <div class="subscription-form">
    <div class="content-box">
      <h2 class="content-box__title">
        Become a Founding Member
      </h2>
      <p class="content-box__desc">
        Our number one goal is to create amazing experiences for readers through beautiful design, authentic interaction, and like-minded community building. In order to do that, we need to be ad-free (no spam, no sponsored posts, and no monetizing your attention for said ads).
      </p>
      <p class="content-box__desc">
        That's why we're adopting a membership model. (And it comes with a tote bag!) Still not convinced? Learn more in the FAQs.
      </p>
      <form class="content-box__form" @submit.prevent="chooseSubscriptionPrice">
        <BaseRadioButtonGroup
          v-model="subscriptionChoice"
          class="content-box__form__radio-group"
          name="subscriptions"
          :options="subscriptionOptions"
        />
        <BaseButton
          class="content-box__form__button"
          button-size="large"
          button-type="primary"
          type="submit"
        >
          <MiniLoader v-if="submitted" />
          <span v-else>Subscribe</span>
        </BaseButton>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-box {
  padding: 4rem;
  background-color: $color-white;
  width: 80%;
  height: auto;
  box-shadow: 0 0 20px 1px rgb(43 36 13 / 8%);

  &__title {
    font: 400 2.4rem/2.88rem $sans-font-stack;
  }

  &__desc {
    @include font-size(1.3);
  }

  @include respond-to('medium') {
    width: 100%;
    padding: 2rem;

    &__title {
      text-align: center;
    }

    &__desc {
      text-align: center;

      @include font-size(1.3);
    }

    &__form {
      &__button {
        width: 100%;
      }
    }
  }
}
</style>
