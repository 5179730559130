<script>
export default {
  props: {
    colorClass: {
      type: String,
      required: false,
    },
    additionalStyles: {
      type: Object,
      required: false,
    },
  },
}
</script>

<template>
  <div class="mini-loader_wrap">
    <div class="sp-3balls" :class="colorClass" :style="additionalStyles" />
  </div>
</template>

<style lang='scss'>
.mini-loader_wrap {
  position: relative;
  width: 34px;
  height: 7px;
  display: inline-block;
}

.sp-3balls, .sp-3balls::before, .sp-3balls::after {
  position: absolute;
  top: 0;
  border-radius: 50%;
  background-color: rgb(0 0 0 / 100%);
  width: 5px;
  height: 5px;
  transform-origin: center center;
  display: inline-block;
  opacity: 1;
  animation: sp-scale-alpha 1s infinite linear;
}

.sp-3balls.light, .sp-3balls.light::before, .sp-3balls.light::after {
  position: absolute;
  top: 0;
  border-radius: 50%;
  background-color: rgb(255 255 255 / 70%);
  width: 5px;
  height: 5px;
  transform-origin: center center;
  display: inline-block;
}

.sp-3balls.dark, .sp-3balls.dark::before, .sp-3balls.dark::after {
  background-color: rgb(50 50 50 / 100%);
}

.sp-3balls::before, .sp-3balls::after {
  content: '';
  opacity: 0.25;
}

.sp-3balls::before {
  left: 9px;
  animation: sp-scale-alpha-before 1s infinite linear;
}

.sp-3balls::after {
  left: -9px;
  animation: sp-scale-alpha-after 1s infinite linear;
}

@keyframes sp-scale-alpha {
  0% { opacity: 1; }
  33% { opacity: 0.25; }
  66% { opacity: 0.25; }
  100% { opacity: 1; }
}

@keyframes sp-scale-alpha-before {
  0% { opacity: 0.25; }
  33% { opacity: 1; }
  66% { opacity: 0.25; }
}

@keyframes sp-scale-alpha-after {
  33% { opacity: 0.25; }
  66% { opacity: 1; }
  100% { opacity: 0.25; }
}
</style>
