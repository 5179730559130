<script>
import formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'

import { useNotificationsStore } from '@/stores/NotificationsStore'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      notificationsStore: useNotificationsStore(),
    }
  },
  computed: {
    notificationImageUrl() {
      return this.notification.target.book.cover_image_url
    },
    notificationText() {
      const messageStr = this.notification.batch_count > 1 ? 'messages' : 'message'
      return `${this.notification.batch_count} new ${messageStr} from `
             + `${this.notification.actor.name} on ${this.notification.target.book.title}`
    },
    notificationTimesince() {
      return formatDistance(parseISO(this.notification.timestamp), new Date(), { addSuffix: true })
    },
  },
  methods: {
    goToAction() {
      /*
       * Remove clicked on notification and go to route specified by notification.
       */
      this.notificationsStore.removeNotification(this.notification)
      this.$router.push({
        name: 'chatgroup',
        params: {
          bookSlug: this.notification.target.book.slug,
          groupId: this.notification.target.id,
        },
      })
    },
  },
}
</script>

<template>
  <div class="notification-item list" @click="goToAction">
    <div v-if="notificationImageUrl" class="cover" :style="{ backgroundImage: `url(${notificationImageUrl})` }" />
    <img v-else class="cover" src="@/assets/bookcover-not-found.png">
    <div class="notification-item__meta">
      <p class="notification-item__meta__title">
        {{ notificationText }}
      </p>
      <p class="notification-item__meta__timesince">
        {{ notificationTimesince }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-item {
  display: inline-block;
  vertical-align: top;
  margin: 1rem;
  cursor: pointer;

  &__meta {
    width: 70%;

    &__title {
      font: $body1;
      margin-bottom: 0;
    }

    &__timesince {
      font: $body1;
      color: $color-gray-medium;
    }
  }

  &.list {
    position: relative;
    display: block;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-white-smoke;
    transition: all 0.2s ease-in-out;

    .notification-item__meta {
      display: inline-block;
      vertical-align: middle;
    }

    .cover {
      display: inline-block;
      vertical-align: middle;
      width: 4.5rem;
      height: 6.6rem;
      margin-right: 2rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border: 2px solid $color-book-cover-border;
      border-radius: 0.2rem;
      box-shadow: 0 0 5px 1px rgb(43 36 13 / 8%);
    }

    &:hover {
      border-radius: 0.3rem;

      .notification-item {
        &__meta {
          &__title {
            color: $color-oxblood;
          }
        }
      }
    }
  }
}
</style>
