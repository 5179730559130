<script>
import { mapState } from 'pinia'

import {
  ARCHIVE_BOOK_EVENT,
  CLEAR_FINISHED_DATE_EVENT,
  COMPLETE_BOOK_EVENT,
  MOVE_BOOK_TO_QUEUE_EVENT,
  PAUSE_BOOK_EVENT,
  RECOMMEND_BOOK_EVENT,
  REMOVE_BOOK_EVENT,
  RESTART_BOOK_EVENT,
  REVIEW_BOOK_EVENT,
  START_BOOK_EVENT,
  VIEW_BOOK_DETAIL_EVENT,
} from '@/customEvents'
import GlobalNav from '@/components/GlobalNav.vue'
import NoltWidget from '@/components/NoltWidget.vue'
import RecommendationModal from '@/components/modals/RecommendationModal.vue'
import ReviewCreateModal from '@/components/modals/ReviewCreateModal.vue'
import ReviewViewEditModal from '@/components/modals/ReviewViewEditModal.vue'
import { useQueueEventHandler } from '@/composables/queueEventHandler'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  name: 'Home',
  components: {
    GlobalNav,
    NoltWidget,
    RecommendationModal,
    ReviewCreateModal,
    ReviewViewEditModal,
  },
  provide() {
    return {
      currentlyReadingContextMenu: this.currentlyReadingContextMenu,
      currentlyReadingCustomEvents: this.currentlyReadingCustomEvents,
      queueContextMenu: this.queueContextMenu,
      queueCustomEvents: this.queueCustomEvents,
      completedContextMenu: this.completedContextMenu,
      completedCustomEvents: this.completedCustomEvents,
      pausedContextMenu: this.pausedContextMenu,
      pausedCustomEvents: this.pausedCustomEvents,
      archivedContextMenu: this.archivedContextMenu,
      archivedCustomEvents: this.archivedCustomEvents,
    }
  },
  setup() {
    const {
      bookQueueItemCompleted,
      bookQueueItemRecommended,
      showRecommendationModal,
      showReviewCreateModal,
      showReviewViewEditModal,
      proxyEvent,
      recommendBookHandler,
    } = useQueueEventHandler()
    return {
      queueStore: useQueueStore(),
      bookQueueItemCompleted,
      bookQueueItemRecommended,
      showRecommendationModal,
      showReviewCreateModal,
      showReviewViewEditModal,
      proxyEvent,
      recommendBookHandler,
    }
  },
  data() {
    return {
      currentlyReadingContextMenu: [
        { name: 'Complete Book', emit: COMPLETE_BOOK_EVENT },
        { name: 'Pause Book', emit: PAUSE_BOOK_EVENT },
        { name: 'Archive Book', emit: ARCHIVE_BOOK_EVENT },
        { name: 'Move to Queue', emit: MOVE_BOOK_TO_QUEUE_EVENT },
        { name: 'View Book Details', emit: VIEW_BOOK_DETAIL_EVENT },
        { name: 'Remove', emit: REMOVE_BOOK_EVENT, textColorClass: 'text-color--destructive' },
      ],
      currentlyReadingCustomEvents: [
        COMPLETE_BOOK_EVENT,
        PAUSE_BOOK_EVENT,
        ARCHIVE_BOOK_EVENT,
        MOVE_BOOK_TO_QUEUE_EVENT,
        VIEW_BOOK_DETAIL_EVENT,
        REMOVE_BOOK_EVENT,
      ],
      queueContextMenu: [
        { name: 'Start Reading', emit: START_BOOK_EVENT },
        { name: 'Complete Book', emit: COMPLETE_BOOK_EVENT },
        { name: 'View Book Details', emit: VIEW_BOOK_DETAIL_EVENT },
        { name: 'Remove', emit: REMOVE_BOOK_EVENT, textColorClass: 'text-color--destructive' },
      ],
      queueCustomEvents: [
        START_BOOK_EVENT,
        COMPLETE_BOOK_EVENT,
        VIEW_BOOK_DETAIL_EVENT,
        REMOVE_BOOK_EVENT,
      ],
      completedContextMenu: [
        { name: 'Add/View Review', emit: REVIEW_BOOK_EVENT },
        { name: 'Recommend', emit: RECOMMEND_BOOK_EVENT },
        { name: 'Read It Again', emit: RESTART_BOOK_EVENT },
        { name: 'View Book Details', emit: VIEW_BOOK_DETAIL_EVENT },
        { name: 'Clear Finished Date', emit: CLEAR_FINISHED_DATE_EVENT },
        { name: 'Remove', emit: REMOVE_BOOK_EVENT, textColorClass: 'text-color--destructive' },
      ],
      completedCustomEvents: [
        CLEAR_FINISHED_DATE_EVENT,
        REVIEW_BOOK_EVENT,
        RECOMMEND_BOOK_EVENT,
        RESTART_BOOK_EVENT,
        VIEW_BOOK_DETAIL_EVENT,
        REMOVE_BOOK_EVENT,
      ],
      pausedContextMenu: [
        { name: 'Start Reading Again', emit: RESTART_BOOK_EVENT },
        { name: 'Complete Book', emit: COMPLETE_BOOK_EVENT },
        { name: 'Archive Book', emit: ARCHIVE_BOOK_EVENT },
        { name: 'View Book Details', emit: VIEW_BOOK_DETAIL_EVENT },
        { name: 'Remove', emit: REMOVE_BOOK_EVENT, textColorClass: 'text-color--destructive' },
      ],
      pausedCustomEvents: [
        RESTART_BOOK_EVENT,
        COMPLETE_BOOK_EVENT,
        ARCHIVE_BOOK_EVENT,
        VIEW_BOOK_DETAIL_EVENT,
        REMOVE_BOOK_EVENT,
      ],
      archivedContextMenu: [
        { name: 'Start Reading Again', emit: RESTART_BOOK_EVENT },
        { name: 'Complete Book', emit: COMPLETE_BOOK_EVENT },
        { name: 'View Book Details', emit: VIEW_BOOK_DETAIL_EVENT },
        { name: 'Remove', emit: REMOVE_BOOK_EVENT, textColorClass: 'text-color--destructive' },
      ],
      archivedCustomEvents: [
        RESTART_BOOK_EVENT,
        COMPLETE_BOOK_EVENT,
        VIEW_BOOK_DETAIL_EVENT,
        REMOVE_BOOK_EVENT,
      ],
    }
  },
  computed: {
    ...mapState(useQueueStore, ['queueEventHandledStatus']),
  },
  watch: {
    queueEventHandledStatus: {
      handler(newValue) {
        if (!newValue) {
          const eventName = this.queueStore.queueEvent.name
          const bookQueueItem = this.queueStore.queueEvent.item
          this.queueStore.clearQueueEvent()
          this.proxyEvent(eventName, bookQueueItem)
        }
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <div class="home-layout">
    <div class="home-layout__container">
      <GlobalNav />
      <div class="home-layout__container__content">
        <router-view />
      </div>
    </div>
    <transition name="scale-fade">
      <ReviewCreateModal
        v-if="showReviewCreateModal"
        :book-queue-item="bookQueueItemCompleted"
        @close="showReviewCreateModal = false"
        @trigger-recommend-modal="recommendBookHandler"
      />
    </transition>
    <transition name="scale-fade">
      <ReviewViewEditModal
        v-if="showReviewViewEditModal"
        :book-queue-item="bookQueueItemCompleted"
        @close="showReviewViewEditModal = false"
      />
    </transition>
    <transition name="scale-fade">
      <RecommendationModal
        v-if="showRecommendationModal"
        :book-queue-item="bookQueueItemRecommended"
        @close="showRecommendationModal = false"
      />
    </transition>
    <NoltWidget />
  </div>
</template>

<style lang='scss' scoped>
.home-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &__container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__content {
      background-color: $color-queue-background;
      padding-top: 12rem;
      padding-bottom: 4rem;
      min-height: 100%;

      @include respond-to('small') {
        padding-top: 10rem;
      }
    }
  }
}

.scale-fade-enter-active, .scale-fade-leave-active {
  transition: all 0.22s ease-in-out;
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
}

.scale-fade-enter-from, .scale-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, 30px, 0) scale(1.2);
  opacity: 0;
}
</style>
