<script>
import CloseX from '@/components/svgs/CloseX.vue'
import { DISPLAY_STATE_COMPLETED } from '@/services/endpoints'
import MultiSelectList from '@/components/form/MultiSelectList.vue'
import SelectableStarRating from '@/components/reviews/SelectableStarRating'
import { useQueueStore } from '@/stores/QueueStore'
import ViewableStarRating from '@/components/reviews/ViewableStarRating.vue'

export default {
  components: {
    CloseX,
    MultiSelectList,
    SelectableStarRating,
    ViewableStarRating,
  },
  props: {
    bookQueueItem: {
      type: Object,
      required: true,
    },
    /**
     * If this modal is used in another view other than home layout
     * provide an optional prop to route back home after submitting
     * a recommendation:
     */
    goHomeAfter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      errorDetail: '',
      initialized: false,
      review: {
        book: null,
        review_tags: [],
        thoughts: '',
        rating: null,
      },
      reviewError: false,
      submitted: false,
      updating: false,
    }
  },
  computed: {
    ratingFormatted() {
      const rating = this.bookQueueItem.user_review.rating
      if (rating.endsWith('.0'))
        return rating.charAt(0) // If rating is x.0, just format this as x

      return rating
    },
  },
  created() {
    if (this.queueStore.reviewTags === null) {
      this.queueStore.getReviewTags()
        .then(() => {
          this.setInitialReviewState()
        })
    }
    else {
      this.setInitialReviewState()
    }
  },
  methods: {
    setInitialReviewState() {
      this.review.book = this.bookQueueItem.book
      this.review.thoughts = this.bookQueueItem.user_review.thoughts
      this.review.rating = this.bookQueueItem.user_review.rating
      this.bookQueueItem.user_review.review_tags.forEach((tag) => {
        const reviewTag = this.queueStore.reviewTags.filter(reviewTag => reviewTag.name === tag)
        if (reviewTag.length)
          this.review.review_tags.push(reviewTag[0].id)
      })
      this.initialized = true
    },
    closeAndComplete() {
      if (this.bookQueueItem.display_state !== DISPLAY_STATE_COMPLETED) {
        this.queueStore.completeBook(this.bookQueueItem)
          .then(() => {
            this.queueStore.setAlert({
              type: 'success',
              message: `${this.bookQueueItem.book.title} was successfully added to your completed list.`,
              active: true,
            })
            this.$emit('close')
          })
      }
      else {
        this.$emit('close')
      }
    },
    handleMultiSelectUpdate(selectedOption) {
      // Check for existence of this review tag already in review tags
      const index = this.review.review_tags.indexOf(selectedOption.id)
      if (selectedOption.selected) {
        if (index < 0)
          this.review.review_tags.push(selectedOption.id)
      }
      else {
        if (~index)
          this.review.review_tags.splice(index, 1)
      }
    },
    handleUpdate() {
      this.submitted = true
      this.review.book = this.bookQueueItem.book.id
      this.queueStore.updateReview({ review: this.review, bookQueueItem: this.bookQueueItem })
        .then(() => {
          this.queueStore.setAlert({
            type: 'success',
            message: `Book review for ${this.bookQueueItem.book.title} updated successfully.`,
            active: true,
          })
          this.closeAndComplete()
        })
        .catch((error) => {
          this.reviewError = true
          this.errorDetail = 'Please either click tags, write your thoughts, or click a star '
                             + 'rating (or all three :) )'
          if (error.data) {
            if (error.data.non_field_errors) {
              this.errorDetail = error.data.non_field_errors[0]
            }
            else if (error.data.error) {
              // On this error, this means that we already have a review and should clear state
              this.errorDetail = error.data.error
            }
          }
          this.submitted = false
        })
    },
  },
}
</script>

<template>
  <div class="modal">
    <div v-if="bookQueueItem && queueStore.reviewTags" class="modal__container">
      <div @click="closeAndComplete">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2 v-if="updating">
          Update Your Review of {{ bookQueueItem.book.title }}
        </h2>
        <h2 v-else>
          Your Review
        </h2>
      </div>

      <template v-if="updating && initialized">
        <div class="modal__container__head__alerts">
          <p v-if="reviewError" class="modal__container__head__alerts__error-msg">
            {{ errorDetail }}
          </p>
        </div>
        <form @submit.prevent="handleUpdate">
          <div class="modal__container__body">
            <MultiSelectList :options="queueStore.reviewTags" :initial-values="review.review_tags" @selection-update="handleMultiSelectUpdate" />
            <div class="modal__container__body__text-area">
              <label for="thoughts-text-area">Thoughts</label>
              <div class="modal__container__body__text-area__wrap">
                <textarea
                  id="thoughts-text-area"
                  v-model="review.thoughts"
                  maxlength="440"
                  placeholder="What you liked or didn't like about the book."
                />
              </div>
              <p class="modal__container__body__text-area__character-count">
                character count {{ review.thoughts.split('').length }}/440
              </p>
            </div>
          </div>
          <div class="modal__container__foot">
            <div class="modal__container__foot__star-rating">
              <p>Overall Review</p>
              <SelectableStarRating
                v-model="review.rating"
                class="modal__container__foot__star-rating__star-group"
              />
            </div>
            <div class="modal__container__foot__options">
              <p class="modal__container__foot__options__skip" @click="closeAndComplete">
                Cancel
              </p>
              <BaseButton
                class="modal__container__foot__options__submit-btn"
                button-size="large"
                button-type="primary"
                type="submit"
                :disabled="submitted"
              >
                Update
              </BaseButton>
            </div>
          </div>
        </form>
      </template>

      <template v-else>
        <div class="modal__container__body">
          <div class="modal__container__body__flex-wrap">
            <div class="modal__container__body__flex-wrap__col1">
              <div
                class="cover-image"
                :style="{ backgroundImage: `url(${bookQueueItem.book.cover_image_url})` }"
              />
            </div>
            <div class="modal__container__body__flex-wrap__col2">
              <p class="book-title">
                {{ bookQueueItem.book.title }}
              </p>
              <p class="author">
                by
                <span v-for="(author, i) in bookQueueItem.book.authors" :key="i">{{ author }}{{ (bookQueueItem.book.authors.length - 1) > i ? ', ' : '' }}</span>
              </p>
              <div class="star-group">
                <ViewableStarRating class="stars" :rating="bookQueueItem.user_review.rating || 0" :in-review-card="true" />
                <p v-if="bookQueueItem.user_review.rating" class="rating">
                  {{ ratingFormatted }}/5 stars
                </p>
                <p v-else class="rating">
                  No star rating
                </p>
              </div>
              <div class="review-tags">
                <div
                  v-for="(tag, i) in bookQueueItem.user_review.review_tags"
                  :key="i"
                  class="review-tags__tag"
                >
                  {{ tag }}
                </div>
              </div>
              <p class="review-text">
                {{ bookQueueItem.user_review.thoughts }}
              </p>
            </div>
          </div>
          <div class="modal__container__foot">
            <div class="modal__container__foot__options">
              <BaseButton
                class="modal__container__foot__options__submit-btn"
                button-size="large"
                button-type="secondary"
                @click="updating = true"
              >
                Update Review
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  &__container {
    padding: 5rem 6rem;

    &__body {
      &__text-area {
        position: relative;
        height: 16rem;
        margin-top: 2.5rem;
        width: 100%;

        label {
          font: $body2;

          @include font-size(1.2);
        }

        &__wrap {
          position: absolute;
          height: 13rem;
          width: 100%;
          background-color: $color-seashell;
          padding: 2rem;
          padding-bottom: 3.2rem;
          bottom: 0;
        }

        textarea {
          display: block;
          width: 100%;
          height: 100%;
          resize: none;
          background-color: transparent;

          @include font-size(1.3);
        }

        &__character-count {
          position: absolute;
          bottom: 0.5rem;
          right: 1rem;

          @include font-size(1.1);
        }
      }

      &__flex-wrap {
        display: flex;

        &__col1 {
          padding-right: 4rem;

          .cover-image {
            width: 12rem;
            height: 16rem;
            background-size: cover;
            background-position: center;
            border: 4px solid $color-white;
            box-shadow: 0 1px 15px 0 rgb(0 0 0 / 10%);
          }

          @include respond-to('small') {
            padding-right: 0;
            margin-bottom: 1rem;
          }
        }

        &__col2 {
          flex-grow: 1;

          .book-title {
            @include font-size(1.4);

            font-weight: 600;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .author {
            @include font-size(1.2);

            line-height: 1.7rem;
            margin-bottom: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .stars {
            margin-left: -0.5rem;
          }

          .review-tags {
            display: flex;
            flex-wrap: wrap;
            align-content: stretch;

            &__tag {
              min-width: 9rem;
              margin: 0.5rem;
              padding: 0.5rem 1rem;
              border: 1px solid $color-oxblood;
              border-radius: 3rem;
              text-align: center;
              font-size: 1.1rem;
              color: $color-white;
              background-color: $color-oxblood;
              transition: all 0.1s ease-in-out;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .review-text {
            margin-top: 2rem;
            text-align: left;
          }
        }

        @include respond-to('small') {
          flex-direction: column;
          width: 100%;
          align-items: center;
          text-align: center;
        }
      }
    }

    &__foot {
      &__star-rating {
        display: inline-block;

        &__star-group {
          margin-top: -2rem;
          margin-left: -1rem;
        }
      }

      .secondary {
        margin-top: -2rem;
        margin-left: -1rem;

        @include respond-to('small') {
          margin-top: unset;
          margin-left: unset;
        }
      }
    }
  }
}
</style>
