<script>
import format from 'date-fns/format'
import { mapState } from 'pinia'

import {
  DISPLAY_STATE_ARCHIVED,
  DISPLAY_STATE_COMPLETED,
  DISPLAY_STATE_CURRENTLY_READING,
  DISPLAY_STATE_PAUSED,
  DISPLAY_STATE_QUEUE,
} from '@/services/endpoints'
import { langCodes } from '@/utilities/langIsoCodes'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { RECOMMEND_BOOK_EVENT } from '@/customEvents'
import { transformBook } from '@/services/searchService'
import { useQueueStore } from '@/stores/QueueStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    MiniLoader,
    ViewLoader,
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    const displayStateDictionary = {}
    displayStateDictionary[DISPLAY_STATE_QUEUE] = 'This book is currently in your Queue.'
    displayStateDictionary[DISPLAY_STATE_CURRENTLY_READING] = 'This book is currently in your Currently Reading list.'
    displayStateDictionary[DISPLAY_STATE_COMPLETED] = 'This book is in your Completed list.'
    displayStateDictionary[DISPLAY_STATE_PAUSED] = 'This book is in your Paused list.'
    displayStateDictionary[DISPLAY_STATE_ARCHIVED] = 'This book is in your Archived list.'
    return {
      affiliateIds: {
        bookshop: '12473',
      },
      buyOptionsOpen: false,
      displayStateDictionary,
      foundUserBookQueueItem: null,
      imageLoaded: false,
      listStatusMessage: null,
      readMoreOpen: false,
      sourceNameId: '',
      sourceUid: '',
    }
  },
  computed: {
    ...mapState(useQueueStore, [
      'book',
    ]),
    isItemLoading() {
      return (this.queueStore.loading.saveBook.item
        && this.queueStore.loading.saveBook.item.source_uid === this.queueStore.book.source_uid)
    },
    languageFromIso() {
      return langCodes[this.queueStore.book.language] || this.queueStore.book.language
    },
    bookTitle() {
      return this.queueStore.book.title.toLowerCase()
    },
    publishedDate() {
      if (!this.queueStore.book.published_date)
        return 'Date not set'
      const dateTime = new Date(this.queueStore.book.published_date)
      return format(dateTime, 'MMM. do, yyyy')
    },
    saveText() {
      const text = this.checkAllUserBooks()
      return text
    },
    isAdded() {
      return this.checkAllUserBooks() === 'Added'
    },
    secondaryButtonLabel() {
      return this.getSecondaryButtonLabel()
    },
  },
  watch: {
    $route(value) {
      if (value.name === 'book-detail')
        this.loadInitialState()
    },
    book(value) {
      // This watcher will set the foundUserBookQueueItem if the book is in the list by isbn
      if (value === null)
        return

      if (value.isbn_13 && this.queueStore.isbnListAllBooks
        && this.queueStore.isbnListAllBooks.includes(value.isbn_13) && !this.foundUserBookQueueItem) {
        // Get the book queue item by isbn
        const getBookQueueItemByIsbn = this.queueStore.getBookQueueItemByIsbn
        this.foundUserBookQueueItem = getBookQueueItemByIsbn(value.isbn_13)
        if (!this.foundUserBookQueueItem) {
          this.queueStore.getCurrentBookQueueItemByBookIsbn(value.isbn_13)
            .then((bookQueueItem) => {
              this.foundUserBookQueueItem = bookQueueItem
            })
            .catch(() => {
              // Suppress the error not finding the book queue item, even though it should
              // be found.
            })
        }
      }
    },
  },
  created() {
    if (this.$route.name === 'book-detail')
      this.loadInitialState()
  },
  methods: {
    loadInitialState() {
      this.queueStore.loading.bookDetail = true
      this.listStatusMessage = null
      if (this.queueStore.isbnListAllBooks === null)
        this.queueStore.getIsbnList()

      this.queueStore.book = null

      this.sourceNameId = this.$route.params.sourceNameId
      this.sourceUid = this.$route.params.sourceUid
      const getBookQueueItemBySource = this.queueStore.getBookQueueItemBySource
      this.foundUserBookQueueItem = getBookQueueItemBySource(this.sourceNameId, this.sourceUid)
      if (this.foundUserBookQueueItem) {
        this.intializeFoundBookQueueItem()
      }
      else {
        this.queueStore.getCurrentBookQueueItemByBookSource({
          sourceNameId: this.sourceNameId,
          sourceUid: this.sourceUid,
        })
          .then((bookQueueItem) => {
            this.foundUserBookQueueItem = bookQueueItem
            this.intializeFoundBookQueueItem()
          })
          .catch(() => {
            // Book queue item not found
            this.getThisBook()
          })
      }
    },
    intializeFoundBookQueueItem() {
      this.listStatusMessage = this.displayStateDictionary[this.foundUserBookQueueItem.display_state]
      const book = transformBook(this.foundUserBookQueueItem.book, this.sourceNameId)
      this.queueStore.book = book
      this.queueStore.loading.bookDetail = false
    },
    getThisBook() {
      this.queueStore.searchSingleBook({
        sourceNameId: this.sourceNameId,
        sourceUid: this.sourceUid,
      })
        .then(() => {
          this.queueStore.loading.bookDetail = false
        })
    },
    checkAllUserBooks() {
      if (this.foundUserBookQueueItem)
        return 'Added'
      else
        return 'Add to Queue'
    },
    compileKeywords() {
      const keywords = `${this.queueStore.book.title} ${this.queueStore.book.authors.join(' ')}`
      return keywords
    },
    handleBuyOptions() {
      this.buyOptionsOpen = !this.buyOptionsOpen
    },
    handleBuyOption(option) {
      if (option === 'bookshop') {
        const baseUrl = `https://bookshop.org/books?affiliate=${this.affiliateIds[option]}`
        const keywords = `&keywords=${encodeURIComponent(this.compileKeywords())}`
        const affiliateUrl = `${baseUrl}${keywords.replace(/%20/g, '+')}`
        window.open(affiliateUrl, '_blank', 'noreferrer')
      }
    },
    handleAddCompletedBook() {
      this.handleAddBook(true, DISPLAY_STATE_COMPLETED)
    },
    handleAddBook(useAlert, displayState = DISPLAY_STATE_QUEUE) {
      this.queueStore.loading.saveBook.item = this.queueStore.book
      this.queueStore.addBookToQueue({ book: this.queueStore.book, display_state: displayState })
        .then((bookQueueItem) => {
          this.queueStore.addNewIsbnToList({ isbn_13: this.queueStore.book.isbn_13 })
          this.foundUserBookQueueItem = bookQueueItem
          this.listStatusMessage = this.displayStateDictionary[bookQueueItem.display_state]
          this.queueStore.loading.saveBook.item = null
          if (useAlert)
            this.showSuccessAlert(displayState)

          // Delay a half second to get the queue so when the user goes back to their queues,
          // the book is there
          setTimeout(() => {
            this.reloadUserBookList(displayState)
          }, 500)
        })
        .catch(() => {
          const message = 'An error occurred adding a new book to your lists. Please try again.'
          this.queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
          this.queueStore.loading.saveBook.item = null
        })
    },
    getSecondaryButtonLabel() {
      if (!this.foundUserBookQueueItem)
        return 'Start Reading'

      let label
      switch (this.foundUserBookQueueItem.display_state) {
        case DISPLAY_STATE_QUEUE:
          label = 'Start Reading'
          break
        case DISPLAY_STATE_CURRENTLY_READING:
          label = 'Go to Book Board'
          break
        case DISPLAY_STATE_COMPLETED:
          label = 'Recommend Book'
          break
        case DISPLAY_STATE_PAUSED:
        case DISPLAY_STATE_ARCHIVED:
          label = 'Start Reading Again'
          break
      }
      return label
    },
    handleSecondaryButtonState() {
      if (!this.foundUserBookQueueItem) {
        // Add book and start reading by moving to currently reading list
        return this.handleAddBook(true, DISPLAY_STATE_CURRENTLY_READING)
      }

      const startStates = [DISPLAY_STATE_QUEUE, DISPLAY_STATE_PAUSED, DISPLAY_STATE_ARCHIVED]
      if (startStates.includes(this.foundUserBookQueueItem.display_state)) {
        // Secondary action is to start reading for startStates
        this.startBookHandler()
      }
      else if (this.foundUserBookQueueItem.display_state === DISPLAY_STATE_COMPLETED) {
        // Secondary action is to recommend book
        this.queueStore.setQueueEvent({
          name: RECOMMEND_BOOK_EVENT,
          item: this.foundUserBookQueueItem,
        })
      }
      else if (this.foundUserBookQueueItem.display_state === DISPLAY_STATE_CURRENTLY_READING) {
        // Secondary action is to go to book board
        this.$router.push({
          name: 'bookboard',
          params: {
            bookSlug: this.foundUserBookQueueItem.book.slug,
          },
        })
      }
    },
    showSuccessAlert(displayState) {
      let message
      const bookTitle = this.foundUserBookQueueItem.book.title
      if (displayState === DISPLAY_STATE_QUEUE)
        message = `${bookTitle} was successfully added to your queue.`
      else if (displayState === DISPLAY_STATE_COMPLETED)
        message = `${bookTitle} was successfully added to your completed list.`

      if (message) {
        this.queueStore.setAlert({
          type: 'error',
          message: `${message}`,
          active: true,
        })
      }
    },
    reloadUserBookList(displayState) {
      if (displayState === DISPLAY_STATE_QUEUE)
        this.queueStore.getUserBookList('queue')
      else if (displayState === DISPLAY_STATE_COMPLETED)
        this.queueStore.getUserBookList('completed')
      else if (displayState === DISPLAY_STATE_CURRENTLY_READING)
        this.queueStore.getUserBookList('currentlyReading')
    },
    startBookHandler() {
      this.queueStore.loading.userBookAction.item = this.foundUserBookQueueItem
      this.queueStore.startReadingBook(this.foundUserBookQueueItem)
        .then((response) => {
          this.foundUserBookQueueItem = response.data
          this.listStatusMessage = this.displayStateDictionary[this.foundUserBookQueueItem.display_state]
          this.queueStore.loading.saveBook.item = null
          this.queueStore.loading.userBookAction.item = null
          this.queueStore.setAlert({
            type: 'success',
            message: `${response.data.book.title} was successfully added to your currently reading list.`,
            active: true,
          })
        })
        .catch(() => {
          this.queueStore.loading.saveBook.item = null
          this.queueStore.loading.userBookAction.item = null
          const message = 'An error occurred while trying to start reading this book. Please try again.'
          this.queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div class="book-detail">
    <div v-if="queueStore.book && !queueStore.loading.bookDetail" class="book-detail__wrap">
      <div class="book-detail__wrap__cover pad-t-m">
        <div class="book-detail__wrap__cover__placeholder" :class="{ loading: !imageLoaded }" />
        <img
          v-if="queueStore.book.cover_image_url"
          :src="queueStore.book.cover_image_url"
          :class="{ loading: !imageLoaded }"
          @load="imageLoaded = true"
        >
        <img
          v-else
          src="@/assets/bookcover-not-found.png"
          :class="{ loading: !imageLoaded }"
          @load="imageLoaded = true"
        >
        <h2 class="book-detail__wrap__cover__title">
          {{ bookTitle }}
        </h2>
        <p class="book-detail__wrap__cover__authors">
          by
          <span v-for="(author, i) in queueStore.book.authors" :key="i">
            {{ author }}{{ (queueStore.book.authors.length - 1) > i ? ', ' : '' }}
          </span>
        </p>
        <div class="book-detail__wrap__cover__controls mar-t-l">
          <button
            class="book-detail__wrap__cover__controls__add mar-b-m"
            :class="{ added: isAdded }"
            :disabled="isAdded"
            @click="handleAddBook(false)"
          >
            <MiniLoader v-if="isItemLoading" class="result_save_loader" />
            <span v-else>
              <i v-if="isAdded" class="material-icons">check</i>
              {{ saveText }}
            </span>
          </button>
          <BaseButton
            class="book-detail__wrap__cover__controls__add"
            button-size="large"
            button-type="secondary"
            @click="handleSecondaryButtonState"
          >
            {{ secondaryButtonLabel }}
          </BaseButton>
          <div class="book-detail__wrap__cover__controls__buy-wrap">
            <button
              class="book-detail__wrap__cover__controls__buy-wrap__buy mar-t-m"
              :class="{ 'buy-options-open': buyOptionsOpen }"
              @click="handleBuyOptions"
            >
              Buy<i class="material-icons">keyboard_arrow_down</i>
            </button>
            <transition name="slide-down">
              <ul v-if="buyOptionsOpen" class="book-detail__wrap__cover__controls__buy-wrap__buy__options">
                <li @click="handleBuyOption('bookshop')">
                  <span class="book-seller">
                    Bookshop.org
                    <span class="book-seller-message">Italic Type is an affiliate of Bookshop.org.</span>
                  </span>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
      <div class="book-detail__wrap__book-info">
        <h2 class="book-detail__wrap__book-info__title">
          {{ bookTitle }}
        </h2>
        <p class="book-detail__wrap__book-info__authors">
          by
          <span v-for="(author, i) in queueStore.book.authors" :key="i">
            {{ author }}{{ (queueStore.book.authors.length - 1) > i ? ', ' : '' }}
          </span>
        </p>
        <div
          class="book-detail__wrap__book-info__description"
          :class="{ open: readMoreOpen }"
          v-html="queueStore.book.description"
        />
        <p
          class="book-detail__wrap__book-info__read-more-btn cur-p"
          :class="{ open: readMoreOpen }"
          @click="readMoreOpen = !readMoreOpen"
        >
          Read {{ readMoreOpen ? 'Less' : 'More' }}
          <i class="material-icons">keyboard_arrow_down</i>
        </p>

        <ul class="book-meta-list pad-b-m b-b-light">
          <li>
            <span class="meta-field">Published</span>
            <span class="meta-value">{{ publishedDate }}</span>
          </li>
          <li>
            <span class="meta-field">Length</span>
            <span class="meta-value">{{ queueStore.book.page_count }} Pages</span>
          </li>
          <li>
            <span class="meta-field">Publisher</span>
            <span class="meta-value">{{ queueStore.book.publisher }}</span>
          </li>
          <li>
            <span class="meta-field">Language</span>
            <span class="meta-value">{{ languageFromIso }}</span>
          </li>
          <li>
            <span class="meta-field">ISBN</span>
            <span class="meta-value">{{ queueStore.book.isbn_13 }}</span>
          </li>
        </ul>
        <p v-if="!foundUserBookQueueItem" class="have-you-read mar-t-l">
          Have you read this book?
          <span class="underline" @click="handleAddCompletedBook">Add it to your Completed books</span>
        </p>
        <p v-else class="have-you-read mar-t-l">
          {{ listStatusMessage }}
          <router-link
            v-slot="{ navigate }"
            custom
            :to="{ name: 'bookboard', params: { bookSlug: foundUserBookQueueItem.book.slug } }"
          >
            <span class="underline" @click="navigate">Go to Book Board</span>
          </router-link>
        </p>
      </div>
    </div>
    <ViewLoader v-else />
  </div>
</template>

<style lang="scss">
.book-detail {
  position: relative;
  height: 100%;

  &__wrap {
    position: relative;
    padding: 2rem 10rem 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    max-width: 106rem;

    &__cover {
      position: relative;
      margin-right: 10rem;
      margin-bottom: 2rem;

      &__title {
        font: $title1-small;
        text-align: center;
        text-transform: capitalize;
        display: none;
        margin-top: 2rem;

        @include respond-to('medlarge') {
          display: block;
        }
      }

      &__authors {
        text-align: center;
        display: none;
        margin-bottom: 5rem;

        @include respond-to('medlarge') {
          display: block;
        }
      }

      &__placeholder {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 300px;
        margin: 0 auto;
        background-color: $color-white-smoke;
        opacity: 0;
        visibility: hidden;
        transition: all 0.18s ease-in-out;

        &.loading {
          opacity: 1;
          visibility: visible;
        }
      }

      img {
        position: relative;
        display: block;
        min-height: 300px;
        width: 20rem;
        margin: 0 auto;
        transition: all 0.18s ease-in-out;
        opacity: 1;
        visibility: visible;

        &.loading {
          opacity: 0;
          visibility: hidden;
        }

        @include respond-to('medlarge') {
          min-height: 140px;
          width: 10rem;
        }
      }

      &__controls {
        min-width: 24rem;

        &__add {
          width: 100%;

          &.added {
            background-color: $color-racing-green;
            border-color: $color-racing-green;
            color: $color-white;

            .material-icons {
              font-size: 1.4rem;
              line-height: 1rem;
              display: inline-block;
              vertical-align: middle;
              position: relative;
              top: -0.1rem;
              left: -0.18rem;
              color: $color-white;
            }

            &:disabled {
              opacity: 1;
            }
          }
        }

        &__buy-wrap {
          width: 100%;
          position: relative;
          overflow: hidden;

          &__buy {
            position: relative;
            border-radius: 0.4rem 0.4rem 0 0;
            border: 1px solid $color-burnt-sienna;
            color: $color-burnt-sienna;
            background-color: transparent;
            z-index: 2;
            width: 100%;

            i {
              display: inline-block;
              font-size: 1.8rem;
              line-height: 1rem;
              vertical-align: middle;
              transform: rotate(0deg);
              transition: all 0.15s ease-in-out;
            }

            &:hover {
              background-color: $color-seashell-light;
            }

            &.buy-options-open {
              border: 1px solid $color-burnt-sienna;
              background-color: $color-seashell;

              i {
                transform: rotate(180deg);
              }
            }

            &__options {
              position: relative;
              width: 100%;
              height: auto;
              margin: 0;
              padding: 0;
              border: 1px solid $color-burnt-sienna;
              border-radius: 0 0 0.4rem 0.4rem;
              overflow: hidden;
              top: -2px;
              z-index: 1;

              li {
                list-style-type: none;
                padding: 1.4rem 1.5rem;
                background-color: $color-seashell;
                border-bottom: 2px solid $color-burnt-sienna;
                color: $color-black;

                @include font-size(1.3);

                cursor: pointer;
                transition: all 0.2s ease-in-out;

                .book-seller {
                  display: inline-block;

                  .book-seller-message {
                    display: block;

                    @include font-size(1.1);
                  }
                }

                .book-price {
                  display: block;
                  float: right;
                }

                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  background-color: $color-seashell-dark;
                  color: $color-black;
                }
              }
            }
          }
        }
      }

      @include respond-to('medlarge') {
        margin: 0 auto;
        margin-bottom: 4rem;
      }
    }

    &__book-info {
      &__title {
        text-transform: capitalize;

        @include respond-to('medlarge') {
          display: none;
        }
      }

      p {
        margin-bottom: 1rem;
      }

      &__description {
        position: relative;
        max-height: 20rem;
        overflow: hidden;

        p {
          margin-bottom: 1.5rem;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          height: 8rem;
          width: 100%;
          background: linear-gradient(to top, rgb(255 255 255 / 0%) 0%, rgb(246 245 242 / 100%) 99%, rgb(246 245 242 / 100%) 100%);
          background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(246 245 242 / 100%) 99%, rgb(246 245 242 / 100%) 100%);
          filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0 );
        }

        &.open {
          max-height: none;
          padding-bottom: 4rem;
        }
      }

      &__read-more-btn {
        text-align: center;
        margin: 0;
        opacity: 0.6;

        i {
          @include font-size(2);

          line-height: 1rem;
          vertical-align: middle;
          transition: all 0.18s ease-in-out;
        }

        &.open {
          i { transform: rotate(180deg); }
        }
      }

      .have-you-read {
        font: $body2;
        span { cursor: pointer; }
      }

      &__authors {
        margin-bottom: 2rem;

        @include respond-to('medlarge') {
          display: none;
        }
      }

      .book-meta-list {
        margin: 0;
        padding: 0;
        margin-top: 1rem;

        li {
          list-style-type: none;
          padding: 0.75rem 0;
          .meta-field { font: $body2; }
          .meta-value { float: right; }
        }
      }
    }

    @include respond-to('medlarge') {
      display: block;
      padding: 0 3rem;
    }
  }
}

.fade-in-enter-active, .fade-in-leave-active {
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.fade-in-enter-from, .fade-in-leave-to {
  opacity: 1;
}

.fade-out-enter-active, .fade-out-leave-active {
  transition: all 0.15s ease-in-out;
  opacity: 1;
}

.fade-out-enter-from, .fade-out-leave-to {
  opacity: 0;
}

.slide-down-enter-active, .slide-down-leave-active {
  transition: all 0.15s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.slide-down-enter-from, .slide-down-leave-to {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}
</style>
