<script>
import { GOODREADS_SOURCE_NAME } from '@/services/endpoints'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    MiniLoader,
  },
  emits: ['input'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      fileName: 'goodreads_library_export.csv',
      isSuccess: false,
      isError: false,
      isSubmitted: false,
      errorDetail: '',
    }
  },
  methods: {
    handleUploadFileTrigger() {
      // reset flags on reupload
      this.isSuccess = false
      this.isError = false
      document.getElementById('goodreads-import-el').click()
    },
    handleFileChange() {
      this.$emit('input', this.$refs.goodreadsimport.files[0])
      this.fileName = this.$refs.goodreadsimport.files[0].name
      this.isSubmitted = true

      const formData = new FormData()
      formData.append('source_name', GOODREADS_SOURCE_NAME)
      formData.append('source_file', this.$refs.goodreadsimport.files[0])
      this.queueStore.uploadFileImportSource(formData)
        .then(() => {
          // Create a bit of a delay since the files are a bit small.
          setTimeout(() => {
            this.isSuccess = true
            this.isError = !this.isSuccess
            this.isSubmitted = false
          }, 1000)
        })
        .catch((error) => {
          setTimeout(() => {
            this.errorDetail = (error.data && error.data.non_field_errors)
              ? error.data.non_field_errors[0]
              : 'We were unable to upload your file. Please try again.'
            document.getElementById('goodreads-import-el').value = ''
            this.isSuccess = false
            this.isError = !this.isSuccess
            this.isSubmitted = false
          }, 1000)
        })
    },
  },
}
</script>

<template>
  <div class="goodreads-import">
    <h3>Moving over from Goodreads?</h3>
    <p>You can upload your CSV file from Goodreads to get your reading history, date completed, star reviews, and your to-read list.</p>
    <h4 class="mar-t-l">
      How to
    </h4>
    <p>On a desktop computer (note, this feature is not available on mobile devices), log into Goodreads > Select "My Books" > Under Tools, select "Import and Export".</p>
    <p>Click the button at the top that says "Export Library" to download a CSV file of your Goodreads library.</p>
    <p>Follow this <a href="https://www.goodreads.com/review/import" target="_blank">link to Goodreads</a> to get you most of the way there.</p>
    <p v-if="isSuccess" class="goodreads-import__success">
      {{ fileName }} is currently being imported! We will email you when the process is complete.
    </p>
    <p v-if="isError" class="goodreads-import__error">
      {{ errorDetail }}
    </p>
    <div class="goodreads-import__upload-button">
      <BaseButton
        button-size="large"
        button-type="secondary"
        @click="handleUploadFileTrigger"
      >
        <MiniLoader
          v-if="isSubmitted"
          class="goodreads-importer__loader"
        />
        <span v-else>
          Upload File
        </span>
      </BaseButton>
      <input
        id="goodreads-import-el"
        ref="goodreadsimport"
        type="file"
        accept=".csv,.CSV"
        placeholder="Goodreads CSV File"
        @change="handleFileChange"
      >
    </div>
    <div class="goodreads-import__mobile-msg">
      <p class="italic">
        Please upload your file from a desktop computer.
      </p>
    </div>
    <p>Please note: Our import process is a very new feature. We will match as many of your books as we possibly can, first by ISBN and second by search when the ISBN is not present in your CSV file. There may be some books that we cannot import, but we will alert you to which ones could not be found. We will continue to try to import these as we work to enhance our book data.</p>
  </div>
</template>

<style lang="scss" scoped>
.goodreads-import {
  &__upload-button {
    padding: 2rem 0;

    button {
      width: 14rem;
    }

    @include respond-to('medium') {
      display: none;
    }
  }

  &__mobile-msg {
    display: none;

    @include respond-to('medium') {
      padding: 2rem 0;
      display: block;
    }
  }

  &__success {
    color: $color-racing-green;
    margin-bottom: 0;
  }

  &__error {
    color: $color-error;
    margin-bottom: 0;
  }
}

#goodreads-import-el {
  display: none;
}
</style>
