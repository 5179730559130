<script>
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import analytics from '@/utilities/analytics'
import AvatarFileInput from '@/components/form/AvatarFileInput.vue'
import { email } from '@/utilities/validators'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { removeActionsCache } from '@/utilities/actionsCache'
import { useAuthStore } from '@/stores/AuthStore'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    AvatarFileInput,
    MiniLoader,
  },
  setup() {
    return {
      authStore: useAuthStore(),
      queueStore: useQueueStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      // My info fields
      avatar: null,
      name: '',
      username: '',
      email: '',

      // Backend errors and UI state
      errors: {},
      userSettingsFormError: false,
      userInfoSubmitted: false,
    }
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage('Name is required.', required),
      },
      username: {
        required: helpers.withMessage('Username is required.', required),
      },
      email: {
        email: helpers.withMessage('Please enter a valid email.', email),
        required: helpers.withMessage('Email is required.', required),
      },
    }
  },
  computed: {
    userFormDataHasChanged() {
      if (!this.authStore.user)
        return false

      const nameChanged = this.name !== this.authStore.user.name
      const emailChanged = this.email.toLowerCase() !== this.authStore.user.email.toLowerCase()
      const usernameChanged = this.username !== this.authStore.user.username
      return Boolean(this.avatar || nameChanged || emailChanged || usernameChanged)
    },
    initialAvatarPreview() {
      if (!this.authStore.user)
        return ''

      let avatarPreview
      if (this.authStore.user.avatar) {
        avatarPreview = this.authStore.user.avatar
        if (this.authStore.user.avatar_thumbnail)
          avatarPreview = this.authStore.user.avatar_thumbnail
        else if (this.authStore.user.avatar_thumbnail_square)
          avatarPreview = this.authStore.user.avatar_thumbnail_square
      }
      return avatarPreview
    },
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length)
          this.errors = {}
      },
      deep: true,
    },
  },
  created() {
    this.initUserData()
  },
  methods: {
    blurInputFieldsOnCancel() {
      const inputs = document.getElementsByTagName('input')
      for (let index = 0; index < inputs.length; ++index)
        inputs[index].blur()
    },
    cancelSavingUserData() {
      this.blurInputFieldsOnCancel()
      this.v$.$reset()
      this.initUserData()
      this.stateReset()
    },
    initUserData() {
      if (this.authStore.user) {
        this.avatar = null
        this.name = this.authStore.user.name
        this.username = this.authStore.user.username
        this.email = this.authStore.user.email
      }
    },
    stateReset() {
      this.errors = {}
      this.userSettingsFormError = false
      this.userInfoSubmitted = false
    },
    submitUserInfo() {
      this.userInfoSubmitted = true
      const emailChanged = this.email.toLowerCase() !== this.authStore.user.email.toLowerCase()
      const formData = new FormData()

      if (this.avatar)
        formData.append('avatar', this.avatar)

      if (this.name !== this.authStore.user.name)
        formData.append('name', this.name)

      if (emailChanged)
        formData.append('email', this.email)

      if (this.username !== this.authStore.user.username)
        formData.append('username', this.username)

      this.authStore.updateUser(formData)
        .then(() => {
          if (emailChanged) {
            // If the email has changed, we need to logout the user and redirect to
            // the activation views.
            this.authStore.logout()
              .then(() => {
                removeActionsCache()
                analytics.resetUser()
                this.$router.replace({ name: 'verify-account' })
                this.$router.go(0) // Reload to reset initial state (TODO: pinia way?)
              })
          }
          else {
            this.stateReset()
            this.queueStore.setAlert({
              type: 'success',
              message: 'User information was successfully updated.',
              active: true,
            })
          }
        },
        (error) => {
          this.errors = error.data || {}
          this.userSettingsFormError = true
          this.userInfoSubmitted = false
        })
    },
  },
}
</script>

<template>
  <div class="user-form">
    <div class="user-form__alerts">
      <p v-if="userSettingsFormError || v$.$errors.length" class="user-form__alerts__error-msg">
        Please correct the following errors:
      </p>
    </div>
    <form @submit.prevent="submitUserInfo">
      <div class="user-form__input-wrap">
        <AvatarFileInput
          v-model="avatar"
          bg-color="inverted"
          :initial-preview-image="initialAvatarPreview"
        />
        <div v-if="errors.avatar" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.avatar" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <h3 class="user-form__form-header">
        My Info
      </h3>
      <div class="user-form__input-wrap">
        <BaseInput
          v-model.trim="name"
          type="text"
          bg-color="inverted"
          label="Full name"
          placeholder="Full name"
          :class="{ error: v$.name.$error }"
          @blur="v$.name.$touch()"
        />
        <template v-if="v$.name.$error">
          <p
            v-for="error in v$.name.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
          >
            {{ error.$message }}
          </p>
        </template>
        <div v-if="errors.name" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.name" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="user-form__input-wrap">
        <BaseInput
          v-model.trim="username"
          type="text"
          bg-color="inverted"
          label="Username"
          placeholder="Username"
          :class="{ error: v$.username.$error }"
          @blur="v$.username.$touch()"
        />
        <template v-if="v$.username.$error">
          <p
            v-for="error in v$.username.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
          >
            {{ error.$message }}
          </p>
        </template>
        <div v-if="errors.username" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.username" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="user-form__input-wrap">
        <BaseInput
          v-model.trim="email"
          type="email"
          bg-color="inverted"
          label="Email address"
          placeholder="Email address"
          :class="{ error: v$.email.$error }"
          @blur="v$.email.$touch()"
        />
        <p class="user-settings-form__input-note mar-t-s">
          Note: If you change your email address, you will be logged out and will need to re-confirm your new address before being able to log back in.
        </p>
        <template v-if="v$.email.$error">
          <p
            v-for="error in v$.email.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
          >
            {{ error.$message }}
          </p>
        </template>
        <div v-if="errors.email" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.email" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="user-form__input-wrap__buttons">
        <BaseButton
          button-size="medium"
          button-type="secondary"
          type="button"
          @mousedown.prevent="cancelSavingUserData"
        >
          Cancel
        </BaseButton>
        <BaseButton
          button-size="medium"
          button-type="primary"
          type="submit"
          :disabled="v$.$errors.length || userInfoSubmitted || !userFormDataHasChanged"
        >
          <MiniLoader v-if="userInfoSubmitted" />
          <span v-else>Save</span>
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.user-settings-form {
  &__input-note {
    text-align: left;
  }
}
</style>
