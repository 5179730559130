<script>
import FileExports from '@/components/exporters/FileExports'
import CustomFileImport from '@/components/importers/CustomFileImport'
import GoodreadsFileImport from '@/components/importers/GoodreadsFileImport'
import StorygraphFileImport from '@/components/importers/StorygraphFileImport'

export default {
  components: {
    CustomFileImport,
    FileExports,
    GoodreadsFileImport,
    StorygraphFileImport,
  },
}
</script>

<template>
  <div class="file-import-export">
    <h1 class="mar-b-xl">
      Upload Your Reading History
    </h1>
    <GoodreadsFileImport class="pad-t-xl mar-b-xl" />
    <StorygraphFileImport class="file-import-export__section mar-b-xl" />
    <CustomFileImport class="file-import-export__section mar-b-xl" />
    <FileExports id="exports" class="file-import-export__section" />
  </div>
</template>

<style lang="scss" scoped>
.file-import-export {
  width: 80%;
  position: relative;
  padding: 0 6rem 0 8rem;

  @include respond-to('small') {
    width: 100%;
    padding: 1.25rem 2.5rem 0;
  }

  &__section {
    border-top: 1px solid $color-section-head-border;
    padding-top: 3rem;
  }
}

h1 {
  text-align: left;
}
</style>
