<script>
import FAQAccordion from '@/components/FAQAccordion.vue'

export default {
  components: {
    FAQAccordion,
  },
  data() {
    return {
      boardURL: 'https://italictype.nolt.io/',
    }
  },
  methods: {
    launchNoltWidget() {
      document.querySelector('.it-feedback-button').click()
    },
  },
}
</script>

<template>
  <div class="faqs">
    <h2 class="faqs__title">
      FAQ
    </h2>
    <FAQAccordion :initial-is-open="true" :uid="1" class="mar-b-l">
      <template #title>
        <h3 class="faqs__question">
          Why are you starting a paid membership program?
        </h3>
      </template>
      <template #content>
        <p class="faqs__answer">
          Our goal is to build a profitable, sustainable business by putting readers' needs front and center. There are lots of book trackers out there, but we offer something the others don't. By prioritizing the reader experience above all else, we're committing to beautiful design, authentic interaction, like-minded community building &mdash; translating all of the components of your favorite book club or coziest reading nook into a digital companion. The result is less Internet clutter and noise, maximum relevancy, and a platform you love to use as much as you love to read.
        </p>
        <p class="faqs__answer">
          Oh, and the free stuff? We all know it's never really free. Instead of trying to make money off of your attention, we want your help making a place where we can all give our attention back to the books we're reading and the conversations they start.
        </p>
      </template>
    </FAQAccordion>
    <FAQAccordion :uid="2" class="mar-b-l">
      <template #title>
        <h3 class="faqs__question">
          What do I get for being a founding member?
        </h3>
      </template>
      <template #content>
        <p class="faqs__answer">
          We're building a business to create a positive, nourishing, trustworthy community for book lovers. And we want you to be a part of it. Creating a membership program to keep Italic Type ad-free is core to our mission. In addition to our undying gratitude, you'll get a gift bag with an Italic Type tote and lots of goodies readers will love.
        </p>
        <p class="faqs__answer">
          Over time our membership program will grow to include exclusive content, events, and access to advanced features like book swapping, Bookshop.org credits, and more.
        </p>
      </template>
    </FAQAccordion>
    <FAQAccordion :uid="3" class="mar-b-l">
      <template #title>
        <h3 class="faqs__question">
          Why should I pay now?
        </h3>
      </template>
      <template #content>
        <p class="faqs__answer">
          Have we mentioned the gift bag?! And helping us build a thriving community of independently-minded, intellectually-curious readers?
        </p>
        <p class="faqs__answer">
          But seriously, you should consider starting your membership today if you're someone who gets a lot of value from our platform and/or wants to see Italic Type continue to grow and flourish. We're full of ideas for improvement, many of which were inspired by <span class="feedback-link" @click="launchNoltWidget">your feedback</span>, and we're eager to bring them to life!
        </p>
        <p class="faqs__answer">
          Contributing to Italic Type through starting a membership is a vote of support for us to stay in the game. So much more is on the way!
        </p>
      </template>
    </FAQAccordion>
    <FAQAccordion :uid="4" class="mar-b-l">
      <template #title>
        <h3 class="faqs__question">
          What if I don't pay?
        </h3>
      </template>
      <template #content>
        <p class="faqs__answer">
          If you elect not to contribute today, don't worry. You can continue using Italic Type just as you have always done. You'll own all your book data. And you can export it at any time.
        </p>
        <p class="faqs__answer">
          Italic Type will remain free to sign up and use for some time to come. Becoming a paying member will just put you to the front of the line for new features, and make sure Italic Type can continue to operate sustainably.
        </p>
      </template>
    </FAQAccordion>
    <FAQAccordion :uid="5" class="mar-b-l">
      <template #title>
        <h3 class="faqs__question">
          Who am I supporting?
        </h3>
      </template>
      <template #content>
        <p class="faqs__answer">
          We are Emily, Jason, Christina, Michelle, and Kara; a small, but mighty team. We're readers. We're builders. <a href="https://www.italictype.com/about/" target="_blank">Learn more about us</a>, our company, and our mission to build a platform we love to use as much as we love to read.
        </p>
      </template>
    </FAQAccordion>
    <p class="faqs_answer">
      Have a different question? Reach out to us at <a href="mailto:hello@italictype.com">hello@italictype.com</a>.
    </p>
  </div>
</template>

<style lang="scss" scoped>
.faqs {
  &__question {
    text-align: left;
    width: 90%;
    font: 400 2rem/2.4rem $sans-font-stack;
    margin-bottom: 0;
  }

  &__answer {
    margin-top: 1rem;

    @include font-size(1.3);
  }
}

.feedback-link {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: $color-oxblood;
  cursor: pointer;
}
</style>
