<script>
export default {
  name: 'ItEmblem',
}
</script>

<template>
  <div class="icon it-emblem">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.132 38.283">
      <g transform="translate(-1006.368 -523.5)">
        <rect class="a" width="12" height="35" transform="translate(1028 525)" />
        <path class="a" d="M.013-.429h12L12,31H0Z" transform="translate(1015.686 526.979) rotate(14)" />
      </g>
    </svg>
  </div>
</template>

<style lang='scss'>
.it-emblem {
  .a {
    fill: none;
    stroke: $color-black;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
}
</style>
