<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { email } from '@/utilities/validators'
import { useAuthStore } from '@/stores/AuthStore'

export default {
  setup() {
    return {
      authStore: useAuthStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      name: '',
      username: '',
      email: '',
      password: '',
      registrationError: false,
      errors: {},
      accountActivationFlow: false,
      submitted: false,
    }
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage('Name is required.', required),
      },
      username: {
        required: helpers.withMessage('Username is required.', required),
      },
      email: {
        email: helpers.withMessage('Please enter a valid email.', email),
        required: helpers.withMessage('Email is required.', required),
      },
      password: {
        required: helpers.withMessage('Password is required.', required),
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length)
          this.errors = {}
      },
      deep: true,
    },
  },
  mounted() {
    if (process.env.VUE_APP_ACCOUNT_ACTIVATION_FLOW === '1')
      this.accountActivationFlow = true
  },
  methods: {
    onSubmit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        const formData = {
          name: this.name,
          username: this.username,
          email: this.email,
          password: this.password,
        }
        this.authStore.signup(formData)
          .then(() => {
            if (this.accountActivationFlow) {
              this.$router.replace({ name: 'verify-account' })
            }
            else {
              // Immediately login with the same data and redirect to root
              this.authStore.login(formData)
                .then(() => {
                  this.$router.replace({ name: 'app-root' })
                })
            }
          },
          (error) => {
            this.v$.$reset()

            this.errors = error.data || {}
            this.registrationError = true
            this.password = ''
            this.submitted = false
          })
      }
    },
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-based-page">
      <div class="user-form">
        <div class="user-form__head">
          <h1>Read more books. Make more connections.</h1>
          <p>Our digital tools help readers discover  more of what they love about books: learning, growing, connecting, and sharing.</p>
        </div>
        <div class="user-form__body">
          <div class="user-form__alerts">
            <p v-if="registrationError || v$.$errors.length" class="user-form__alerts__error-msg">
              Please correct the following errors:
            </p>
          </div>
          <form @submit.prevent="onSubmit">
            <div class="user-form__input-wrap">
              <BaseInput
                v-model.trim="name"
                type="text"
                placeholder="Full name"
                :class="{ error: v$.name.$error }"
                @blur="v$.name.$touch()"
              />
              <template v-if="v$.name.$error">
                <p
                  v-for="error in v$.name.$errors"
                  :key="error.$uid"
                  class="form-error-msg--absolute"
                >
                  {{ error.$message }}
                </p>
              </template>
              <div v-if="errors.name" class="form-error-msg">
                <p v-for="(error, index) in errors.name" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <div class="user-form__input-wrap">
              <BaseInput
                v-model.trim="username"
                type="text"
                autocorrect="off"
                autocapitalize="none"
                placeholder="Username"
                :class="{ error: v$.username.$error }"
                @blur="v$.username.$touch()"
              />
              <template v-if="v$.username.$error">
                <p
                  v-for="error in v$.username.$errors"
                  :key="error.$uid"
                  class="form-error-msg--absolute"
                >
                  {{ error.$message }}
                </p>
              </template>
              <div v-if="errors.username" class="form-error-msg">
                <p v-for="(error, index) in errors.username" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <div class="user-form__input-wrap">
              <BaseInput
                v-model.trim="email"
                type="email"
                placeholder="Email address"
                :class="{ error: v$.email.$error }"
                @blur="v$.email.$touch()"
              />
              <template v-if="v$.email.$error">
                <p
                  v-for="error in v$.email.$errors"
                  :key="error.$uid"
                  class="form-error-msg--absolute"
                >
                  {{ error.$message }}
                </p>
              </template>
              <div v-if="errors.email" class="form-error-msg">
                <p v-for="(error, index) in errors.email" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <div class="user-form__input-wrap">
              <BaseInput
                v-model.trim="password"
                type="password"
                placeholder="Password"
                :class="{ error: v$.password.$error }"
                @blur="v$.password.$touch()"
              />
              <template v-if="v$.password.$error">
                <p
                  v-for="error in v$.password.$errors"
                  :key="error.$uid"
                  class="form-error-msg--absolute"
                >
                  {{ error.$message }}
                </p>
              </template>
              <div v-if="errors.password" class="form-error-msg">
                <p v-for="(error, index) in errors.password" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <BaseButton
              class="user-form__submit-btn"
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="v$.$errors.length || submitted"
            >
              Join for Free
            </BaseButton>
          </form>
          <p>
            Already have an account? <router-link :to="{ name: 'signin' }">
              Sign In
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
