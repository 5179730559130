<script>
import StarLeftSide from './StarLeftSide.vue'
import StarRightSide from './StarRightSide.vue'

export default {
  components: {
    StarLeftSide,
    StarRightSide,
  },
  props: {
    viewableState: {
      type: Object,
      required: true,
    },
    inReviewCard: {
      type: Boolean,
      requied: false,
    },
    inBookList: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    leftActive() {
      let active = false
      if (this.viewableState.state) {
        if (this.viewableState.side === 'left' || this.viewableState.side === 'right')
          active = true
      }
      else {
        if (this.viewableState.side === 'right')
          active = true
      }
      return active
    },
    rightActive() {
      let active = false
      if (this.viewableState.state) {
        if (this.viewableState.side === 'right')
          active = true
      }
      return active
    },
  },
}
</script>

<template>
  <div class="icon full-star" :class="{ small: inReviewCard, medium: inBookList }">
    <StarLeftSide
      class="star-left"
      :class="{ active: leftActive, small: inReviewCard, medium: inBookList }"
      :selection-state="leftActive"
    />
    <StarRightSide
      class="star-right"
      :class="{ active: rightActive, small: inReviewCard, medium: inBookList }"
      :selection-state="rightActive"
    />
  </div>
</template>

<style lang='scss' scoped>
.full-star {
  width: 3.3rem;

  &.small {
    width: 1.5rem;
    height: 2rem;
  }

  &.medium {
    width: 1.3rem;
    height: 2rem;
  }
}
</style>
