<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    initialSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectionUpdate'],
  data() {
    return {
      selected: this.initialSelected,
    }
  },
  methods: {
    handleSelection() {
      this.selected = !this.selected
      const option = Object.assign({}, this.option)
      option.selected = this.selected
      this.$emit('selectionUpdate', option)
    },
  },
}
</script>

<template>
  <div
    v-if="option"
    class="option-item"
    :class="{ selected }"
    @click="handleSelection"
  >
    {{ option.name }}
  </div>
</template>

<style lang='scss' scoped>
.option-item {
  min-width: 9rem;
  margin: 0.5rem;
  padding: 0.8rem;
  border: 1px solid $color-oxblood;
  border-radius: 3rem;
  background-color: $color-white;
  text-align: center;
  font-size: 1.1rem;
  color: $color-oxblood;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: $color-option-item-bg-hover;
  }

  &.selected {
    color: $color-white;
    background-color: $color-oxblood;
  }
}
</style>
