<script>
import CloseX from '@/components/svgs/CloseX.vue'

export default {
  components: {
    CloseX,
  },
  emits: ['close', 'reloadAppEvent'],
}
</script>

<template>
  <div class="modal">
    <div class="modal__container">
      <div @click="$emit('close')">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2>An update is available</h2>
      </div>
      <div class="modal__container__body">
        <p>An update is available. Please save all current messages and notes and click update app below. You can also accept these updates by refreshing your browser at any time.</p>
        <p>Not updating may result in errors.</p>
      </div>
      <div class="modal__container__foot">
        <div class="modal__container__foot__options">
          <p class="modal__container__foot__options__skip" @click="$emit('close')">
            Skip for now
          </p>
          <BaseButton
            class="modal__container__foot__options__submit-btn"
            button-size="large"
            button-type="primary"
            @click="$emit('reloadAppEvent')"
          >
            Update App
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.modal {
  &__container {
    padding: 5rem 6rem;

    &__foot {
      &__options {
        text-align: right;
        float: none;
      }
    }

    &__body {
      margin-top: 2.5rem;

      &__input {
        position: relative;
        margin-top: 2.5rem;
        width: 100%;

        label {
          @include font-size(1.2);

          font: $body2;
          margin-bottom: 0.5rem;
          display: block;
        }

        textarea, input {
          display: block;
          position: relative;
          width: 100%;
        }

        textarea {
          background-color: $color-seashell;
          padding: 2rem;
          height: 13rem;
          resize: none;

          @include font-size(1.3);
        }
      }
    }

    &__close {
      position: absolute;
      top: 2.5rem;
      right: 3rem;
      width: 3.5rem;
      cursor: pointer;
    }
  }
}
</style>
