<script>
export default {
  name: 'StarLeftSide',
  props: {
    selectionState: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['hovering', 'ratingSelected'],
  data() {
    return {
      hovering: false,
      selected: false,
    }
  },
  watch: {
    selectionState(value) {
      value ? this.selected = true : this.selected = false
    },
  },
  methods: {
    handleHover(hoveringState) {
      this.$emit('hovering', hoveringState)
    },
    handleRatingSelection() {
      this.selected = !this.selected
      this.$emit('ratingSelected', this.selected)
    },
  },
}
</script>

<template>
  <div
    class="star-left-icon star-left-side"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
    @click="handleRatingSelection"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.016 31.997">
      <g transform="translate(-1.004 -1)">
        <path class="star-path-left" d="M17,1a1.067,1.067,0,0,0-.97.622l-4.08,8.73a1.088,1.088,0,0,1-.81.6l-9.23,1.41a1.081,1.081,0,0,0-.6,1.82L8.05,21.1a1.1,1.1,0,0,1,.29.93l-1.58,9.72a1.066,1.066,0,0,0,1.57,1.11l8.15-4.51a1.036,1.036,0,0,1,.466-.134C17.076,21.38,17,.974,17,1Z" />
      </g>
    </svg>
  </div>
</template>

<style lang='scss'>
.star-left-icon {
  width: 1.9rem;
  padding: 0.5rem;
  padding-right: 0;
  padding-left: 1rem;
  position: absolute;

  .star-path-left {
    fill: $color-star-rating;
    transition: all 0.14s ease-in-out;
  }

  &.active, &.selected {
    .star-path-left {
      fill: $color-black;
    }
  }

  &.selectable {
    .star-path-left {
      &:hover {
        fill: $color-black;
      }
    }
  }

  &.small {
    width: 1rem;
    padding-left: 0.5rem;
  }
}
</style>
