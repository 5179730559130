/* booksPagination.js
 *
 * This composable includes a function to get the next page of books.
 *
 */
import { useQueueStore } from '@/stores/QueueStore'

export function useBooksPagination() {
  // Should this be in the function or in the body of the composable? Not sure and perhaps
  // it doesn't matter.
  const queueStore = useQueueStore()

  function getNextPageOfBooks(listType) {
    queueStore.loading.loadMore[listType] = true
    // Add a bit of delay to see the loader before new books load in
    setTimeout(() => {
      queueStore.getNextPageOfUserBookList(listType)
        .then(() => {
          queueStore.loading.loadMore[listType] = false
        })
        .catch(() => {
          const message = 'An error occurred while loading more books. Please try again.'
          queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    }, 500)
  }
  return { getNextPageOfBooks }
}
