<script>
import BookAvatar from '@/components/svgs/BookAvatar.vue'
import { getExifData } from '@/utilities/exif'

export default {
  components: {
    BookAvatar,
  },
  props: {
    bgColor: {
      type: String,
      required: false,
    },
    initialPreviewImage: {
      type: String,
      required: false,
    },
    modelValue: File,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      previewImage: null,
      previewImageRotation: 0,
    }
  },
  watch: {
    modelValue(value) {
      // If the value has been set back to null, try to reload the previewImage
      if (value === null)
        this.previewImage = this.initialPreviewImage
    },
  },
  created() {
    if (this.initialPreviewImage)
      this.previewImage = this.initialPreviewImage
  },
  methods: {
    handleUploadImageTrigger() {
      document.getElementsByClassName('avatar-upload-el')[0].click()
    },
    handleFileChange() {
      this.$emit('update:modelValue', this.$refs.avatar.files[0])
      this.setPreviewImage(this.$refs.avatar.files[0])
    },
    setPreviewImage(file) {
      if (!file)
        return

      getExifData(file).then((exifData) => {
        this.previewImageRotation = exifData.exifOrientation
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file)
      })
    },
  },
}
</script>

<template>
  <div>
    <div
      class="user-form__input-wrap__upload-trigger"
      :class="bgColor"
      @click="handleUploadImageTrigger"
    >
      <BookAvatar v-if="!previewImage" class="mar-b-xl" />
      <div
        v-else
        class="user-form__input-wrap__upload-trigger__image-preview"
        :class="`user-form__input-wrap__upload-trigger__image-preview--rotate-${previewImageRotation}`"
        :style="{ backgroundImage: `url(${previewImage})` }"
      />
    </div>
    <label class="mar-t-l mar-b-xl" for="avatar">Upload Image</label>
    <input
      ref="avatar"
      type="file"
      class="avatar-upload-el"
      placeholder="Your avatar"
      @change="handleFileChange"
    >
  </div>
</template>

<style lang="scss" scoped>
.user-form__input-wrap {
  &__upload-trigger {
    cursor: pointer;
    width: 10rem;
    height: 10rem;
    background-color: $color-seashell;
    border-radius: 10rem;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    .icon {
      width: 4.5rem;
      height: 6rem;
      position: relative;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
    }

    &__image-preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 6;
      transform-origin: center;

      &--rotate-0 {
        transform: rotate(0deg);
      }

      &--rotate-180 {
        transform: rotate(180deg);
      }

      &--rotate-90 {
        transform: rotate(90deg);
        width: 10rem;
        height: 10rem;
      }

      &--rotate-270 {
        transform: rotate(270deg);
        width: 10rem;
        height: 10rem;
      }
    }
  }

  .avatar-upload-el {
    display: none;
  }

  label {
    display: block;
  }

  .inverted {
    background-color: $color-white;
  }
}
</style>
