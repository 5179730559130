<script>
import BookListCard from '@/components/booklists/BookListCard.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useBooksPagination } from '@/composables/booksPagination'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    BookListCard,
    MiniLoader,
  },
  props: {
    listType: {
      type: String,
      required: true,
      validator(value) {
        // The list type must match one of these two types
        return ['completed', 'paused', 'archived'].includes(value)
      },
    },
  },
  setup() {
    const { getNextPageOfBooks } = useBooksPagination()
    return {
      getNextPageOfBooks,
      queueStore: useQueueStore(),
    }
  },
  computed: {
    bookList() {
      let books = []
      if (this.listType === 'completed')
        books = this.queueStore.completedBooks
      else if (this.listType === 'paused')
        books = this.queueStore.pausedBooks
      else if (this.listType === 'archived')
        books = this.queueStore.archivedBooks

      return books
    },
    nextURL() {
      let nextURL = null
      if (this.listType === 'completed')
        nextURL = this.queueStore.completedBooksNextURL
      else if (this.listType === 'paused')
        nextURL = this.queueStore.pausedBooksNextURL
      else if (this.listType === 'archived')
        nextURL = this.queueStore.archivedBooksNextURL

      return nextURL
    },
    loadingMore() {
      let loadingState = false
      if (this.listType === 'completed')
        loadingState = this.queueStore.loading.loadMore.completed
      else if (this.listType === 'paused')
        loadingState = this.queueStore.loading.loadMore.paused
      else if (this.listType === 'archived')
        loadingState = this.queueStore.loading.loadMore.archived

      return loadingState
    },
  },
  created() {
    if (this.listType === 'completed' && this.queueStore.completedBooks === null)
      this.queueStore.getUserBookList('completed')

    if (this.listType === 'paused' && this.queueStore.pausedBooks === null)
      this.queueStore.getUserBookList('paused')

    if (this.listType === 'archived' && this.queueStore.archivedBooks === null)
      this.queueStore.getUserBookList('archived')

    if (this.queueStore.isbnListAllBooks === null)
      this.queueStore.getIsbnList()
  },
}
</script>

<template>
  <div class="book-list">
    <div class="book-list__section-head">
      <h1>{{ listType }}</h1>
    </div>
    <div class="book-list__container">
      <div class="book-list__container__wrap">
        <BookListCard
          v-for="item in bookList"
          :key="item.id"
          :item="item"
          :list-type="listType"
          class="book-list__container__wrap__item"
        />
      </div>
    </div>
    <div v-if="nextURL !== null" class="load-more">
      <transition name="fade">
        <div
          v-if="loadingMore"
          class="load-more__button__loading-wrap"
        >
          <MiniLoader color-class="light" class="load-more__button__loading-wrap__loader" />
        </div>
      </transition>
      <button class="load-more__button" @click="getNextPageOfBooks(listType)">
        Load More Books
      </button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-list {
  &__section-head {
    padding: 1rem 6.5rem;

    h1 {
      display: inline-block;
      vertical-align: middle;
      text-transform: capitalize;
    }

    @include respond-to('small') {
      padding: 1rem 2.5rem;
    }
  }

  &__container {
    position: relative;
    width: 100%;
    padding: 4rem;

    &__wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;
      justify-content: flex-start;

      &__item {
        position: relative;
        width: 21.2rem;
        text-align: left;
        margin: 1rem;
        cursor: pointer;
        padding: 1.5rem;
        border: 1px dashed rgb(0 0 0 / 0%);

        @include respond-to('small') {
          width: 100%;
          padding: 1rem 0;
          margin: 0;
          border-top: 1px solid $color-section-head-border;
        }
      }
    }

    @include respond-to('small') {
      padding: 2.5rem;
    }
  }
}

.load-more {
  width: 40rem;
  height: 4.6rem;
  position: relative;
  margin: 0 auto;

  @include respond-to('small') {
    width: 80%;
  }

  &__button {
    width: 100%;

    &__loading-wrap {
      @include full-wh;

      background-color: rgb(0 0 0 / 80%);

      &__loader {
        position: relative;
        display: block;
        margin: 0 auto;
        top: 50%;
        left: 10%;
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
