<script>
export default {
  name: 'StarRightSide',
  props: {
    selectionState: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['hovering', 'ratingSelected'],
  data() {
    return {
      hovering: false,
      selected: false,
    }
  },
  watch: {
    selectionState(value) {
      value ? this.selected = true : this.selected = false
    },
  },
  methods: {
    handleHover(hoveringState) {
      this.$emit('hovering', hoveringState)
    },
    handleRatingSelection() {
      this.selected = !this.selected
      this.$emit('ratingSelected', this.selected)
    },
  },
}
</script>

<template>
  <div
    class="star-right-icon star-right-side"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
    @click="handleRatingSelection"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.103 31.997">
      <g transform="translate(-60.899 -1)">
        <path class="star-path-right" d="M32.7,14.182a1.083,1.083,0,0,0-.61-1.82l-9.22-1.41a1.055,1.055,0,0,1-.81-.6l-4.09-8.73A1.067,1.067,0,0,0,17,1s-.184,20.381-.054,27.219a1.269,1.269,0,0,1,.574.134l8.15,4.51a1.074,1.074,0,0,0,1.58-1.11l-1.58-9.72a1.069,1.069,0,0,1,.29-.93Z" transform="translate(44)" />
      </g>
    </svg>
  </div>
</template>

<style lang='scss'>
.star-right-icon {
  width: 1.9rem;
  padding: 0.5rem;
  padding-left: 0;
  padding-right: 1rem;
  position: absolute;
  left: 1.9rem;

  .star-path-right {
    fill: $color-star-rating;
    transition: all 0.14s ease-in-out;
  }

  &.active, &.selected {
    .star-path-right {
      fill: $color-black;
    }
  }

  &.selectable {
    .star-path-right {
      &:hover {
        fill: $color-black;
      }
    }
  }

  &.small {
    width: 1rem;
    left: 1rem;
    padding-right: 0.5rem;
  }
}
</style>
