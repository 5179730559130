<script>
import BookListEmptyState from './BookListEmptyState.vue'
import BookListHeader from './BookListHeader.vue'
import CurrentlyReadingBookListCard from './CurrentlyReadingBookListCard.vue'
import LoadingBookCarousel from './LoadingBookCarousel.vue'
import { useQueueStore } from '@/stores/QueueStore'
import GirlReadingIllustration from '@/components/svgs/GirlReadingIllustration.vue'

export default {
  components: {
    BookListEmptyState,
    BookListHeader,
    CurrentlyReadingBookListCard,
    GirlReadingIllustration,
    LoadingBookCarousel,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    listTotalCount: {
      type: Number,
      default: 0,
    },
    listMeta: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    listLength() {
      if (this.list)
        return this.list.length

      return 0
    },
  },
}
</script>

<template>
  <div class="book-list">
    <BookListHeader
      v-if="listMeta"
      :border-top="listMeta.borderTop"
      :name="listMeta.name"
      :description="listMeta.description"
      :list-url="listMeta.url"
      :list-length="listLength"
      :list-total-count="listTotalCount"
    />
    <LoadingBookCarousel v-if="queueStore.loading.bookLists" />
    <div v-else>
      <div v-if="list && list.length" class="book-list__book-carousel">
        <CurrentlyReadingBookListCard
          v-for="item in list"
          :key="item.id"
          :item="item"
        />
      </div>
      <BookListEmptyState v-else>
        <template #empty-state-image>
          <GirlReadingIllustration />
        </template>
        <template #empty-state-title>
          What are you reading now? Select "Start Reading" from a book in your queue to add it to the top section.
        </template>
      </BookListEmptyState>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-list__book-carousel {
  position: relative;
  display: flex;
  overflow-x: scroll;
  padding: 3rem 8rem 6rem;

  &::after {
    content: "";
    flex: 0 0 1rem;
  }

  @include respond-to('small') {
    padding: 1.25rem 2.5rem 0;
    display: block;
  }
}
</style>
