<script>
import 'quill/dist/quill.core.css'

import CameraIcon from '@/components/svgs/CameraIcon.vue'
import { getExifData } from '@/utilities/exif'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import QuillEditor from '@/components/spine/QuillEditor.vue'
import { useSpineStore } from '@/stores/SpineStore'

export default {
  components: {
    CameraIcon,
    MiniLoader,
    QuillEditor,
  },
  props: {
    chatGroup: {
      type: Object,
      required: true,
    },
    parentMessageId: {
      type: String,
      required: false,
    },
  },
  emits: ['blurred', 'focused', 'newMessageAdded'],
  setup() {
    return {
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      addBtnDisabled: true,
      attachmentFile: null,
      attachmentPreviewImage: null,
      attachmentRotation: 0,
      content: '',
      editing: false,
      editorOptions: {
        theme: 'snow',
        placeholder: 'Add a new message...',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'link'],
          magicUrl: true,
          mention: {
            allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
            defaultMenuOrientation: 'top',
            isolateCharacter: true,
            fixMentionsToQuill: false,
            positioningStrategy: 'absolute',
            onSelect: this.mentionOnSelect,
            source: this.mentionSource,
            renderItem: this.renderItem,
          },
        },
      },
      hasMention: false,
    }
  },
  watch: {
    content(value) {
      if (value || this.attachmentPreviewImage)
        this.addBtnDisabled = false
      else
        this.addBtnDisabled = true
    },
  },
  methods: {
    mentionOnSelect(item, insertItem) {
      this.hasMention = true
      insertItem(item)
    },
    mentionSource(searchTerm, renderList, mentionChar) {
      const atValues = this.chatGroup.members.map((member) => {
        return {
          id: member.username,
          value: member.name,
          avatar: member.avatar_thumbnail_square || member.avatar_thumbnail || member.avatar,
        }
      })

      let values
      if (mentionChar === '@')
        values = atValues

      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      }
      else {
        const matches = values.filter((element) => {
          return ~element.value.toLowerCase().indexOf(searchTerm.toLowerCase())
        })
        renderList(matches, searchTerm)
      }
    },
    renderItem(item) {
      const colors = ['#bb6a3e', '#78403e', '#1d5330']
      const randomColor = colors[item.value.length % colors.length]
      const avatarStyle = 'width: 30px; height: 30px; border-radius: 50%; margin-right: 10px; '
                           + 'background-position: center; background-size: cover; '
                           + `background-repeat: no-repeat; background-color: ${randomColor}; `
      const noAvatarStyle = 'text-align: center;'
      const noAvatarSpan = 'display: block; position: relative; top: 50%; transform: translateY(-50%); '
                           + 'color: white; text-transform: uppercase;'

      if (item.avatar) {
        return `<div style="${avatarStyle} background-image: url(${item.avatar});"></div>`
               + `<span>${item.value}</span>`
      }
      else {
        const firstInitial = item.value.charAt(0)
        return `<div style="${avatarStyle} ${noAvatarStyle}"><span style="${noAvatarSpan}">${firstInitial}</span></div>`
               + `<span>${item.value}</span>`
      }
    },
    onEditorBlur() {
      if (!this.content && !this.attachmentFile) {
        this.editing = false
        this.$emit('blurred')
      }
    },
    onEditorFocus() {
      this.editing = true
      this.$emit('focused')
    },
    onEditorReady() {
    },
    handleAttachImage() {
      document.getElementById(`attachment-upload-el${this.parentMessageId}`).click()
      this.$refs.chatGroupNewMessageEditor.quill.focus()
    },
    handleFileUpload($e) {
      if ($e.target.files && $e.target.files[0]) {
        this.setPreviewImage($e.target.files[0])
        this.attachmentFile = $e.target.files[0]
      }
    },
    setPreviewImage(file) {
      getExifData(file)
        .then((exifData) => {
          this.attachmentRotation = exifData.exifOrientation
          const reader = new FileReader()
          reader.onload = (e) => {
            this.attachmentPreviewImage = e.target.result
            this.addBtnDisabled = false
          }
          reader.readAsDataURL(file)
        })
    },
    handleSaveMessage() {
      this.spineStore.loading.newMessage = true
      const newChatMessage = {
        data: null,
        hasMention: this.hasMention,
        groupId: this.chatGroup.id,
        contentHeader: { 'Content-Type': 'application/json' },
      }
      if (this.attachmentFile) {
        newChatMessage.data = new FormData()
        newChatMessage.data.append('text_content', this.content)
        newChatMessage.data.append('image_original', this.attachmentFile)
        newChatMessage.data.append('has_mention', this.hasMention)
        newChatMessage.contentHeader = { 'Content-Type': 'multipart/form-data' }
        if (this.parentMessageId)
          newChatMessage.data.append('parent_chat_message', this.parentMessageId)
      }
      else {
        newChatMessage.data = {
          text_content: this.content,
          has_mention: this.hasMention,
        }
        if (this.parentMessageId)
          newChatMessage.data.parent_chat_message = this.parentMessageId
      }
      this.spineStore.sendNewChatGroupMessage(newChatMessage)
        .then(() => {
          this.addBtnDisabled = true
          this.attachmentFile = null
          this.attachmentPreviewImage = null
          this.attachmentRotation = 0
          this.content = ''
          this.editing = false
          this.hasMention = false
          this.$emit('blurred')
          this.$emit('newMessageAdded')
          this.$nextTick(() => {
            this.spineStore.loading.newMessage = false
          })
        })
        .catch(() => {
          const message = 'Unable to post a new message to your chat group. Please try again.'
          this.spineStore.loading.newMessage = false
          this.spineStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div class="new-message">
    <QuillEditor
      v-if="chatGroup"
      ref="chatGroupNewMessageEditor"
      v-model="content"
      :options="editorOptions"
      class="new-entry"
      :class="{ focused: editing }"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
    <div
      v-if="attachmentPreviewImage"
      class="new-message__attachment-preview"
      :class="`new-message__attachment-preview--rotate-${attachmentRotation}`"
      :style="{ backgroundImage: `url(${attachmentPreviewImage})` }"
    />
    <span
      class="new-message__attachment"
      @click="handleAttachImage"
    >
      <CameraIcon class="new-message__attachment__icon" />
      <input
        :id="`attachment-upload-el${parentMessageId}`"
        class="attachment-upload-cls"
        type="file"
        @change="handleFileUpload"
      >
    </span>
    <button class="new-message__save" :disabled="addBtnDisabled" @click="handleSaveMessage">
      <span v-if="!spineStore.loading.newMessage">
        <span class="new-message__save__text">Send</span>
        <span class="new-message__save__text-mobile">
          <i class="material-icons">
            add
          </i>
        </span>
      </span>
      <MiniLoader v-else class="new-message__save__loader" color-class="light" />
    </button>
  </div>
</template>

<style lang='scss' scoped>
@import '../../../scss/vendor/quill-snow-override.css';
@import '../../../scss/vendor/quill.mention.css';

.new-entry {
  min-height: 5.35rem;
  background-color: $color-white;
  border: 1px solid $color-new-entry-border;
  padding: 1rem 8.5rem 1rem 4.75rem;
  box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 4%);
  transition: all 0.2s ease-in-out;

  &.focused {
    min-height: 10rem;
  }

  @include respond-to('medium') {
    box-shadow: 0 -2px 8px 0 rgb(0 0 0 / 8%);

    .ql-toolbar {
      bottom: 0;
      left: 0;
    }
  }
}

.new-message {
  position: relative;

  &__attachment {
    position: absolute;
    bottom: 1.7rem;
    right: 9.2rem;
    cursor: pointer;
    z-index: 1;

    &__icon {
      width: 2.4rem;
    }
  }

  &__attachment-preview {
    position: absolute;
    width: 6.8rem;
    height: 3.9rem;
    right: 1.2rem;
    bottom: 5.2rem;
    border: 1px solid $color-attachment-preview-border;
    border-radius: 0.3rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    transform-origin: center;

    &--rotate-0 {
      transform: rotate(0deg);
    }

    &--rotate-180 {
      transform: rotate(180deg);
    }

    &--rotate-90 {
      transform: rotate(90deg);
      width: 3.9rem;
      height: 6.8rem;
      right: 2.8rem;
      bottom: 4rem;
    }

    &--rotate-270 {
      transform: rotate(270deg);
      width: 3.9rem;
      height: 6.8rem;
      right: 2.8rem;
      bottom: 4rem;
    }
  }

  &__save {
    background-color: $color-black;
    position: absolute;
    bottom: 1rem;
    right: 1.1rem;
    width: 7rem;
    height: 3.45rem;
    padding: 0.75rem 1.75rem;
    z-index: 1;

    &__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &__text-mobile {
      display: none;

      i {
        @include font-size(1.8);
      }
    }
  }

  @include respond-to('medium') {
    &__attachment {
      bottom: 1.5rem;
      right: 6rem;

      @include font-size(1.5);
    }

    &__attachment-preview {
      width: 3.8rem;
      right: 0.75rem;
      bottom: 5.2rem;

      &--rotate-90 {
        width: 3.8rem;
        height: 3.8rem;
        right: 0.75rem;
        bottom: 5.2rem;
      }

      &--rotate-270 {
        width: 3.8rem;
        height: 3.8rem;
        right: 0.75rem;
        bottom: 5.2rem;
      }
    }

    &__save {
      bottom: 0.65rem;
      right: 0.75rem;
      padding: 1rem;
      height: 3.85rem;
      width: 3.85rem;

      &__text {
        display: none;
      }

      &__text-mobile {
        display: block;
      }

      &__loader {
        transform: translateX(-50%) translateY(-50%) scale(0.8);
      }
    }
  }
}

.attachment-upload-cls {
  display: none;
}
</style>
