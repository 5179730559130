<script>
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'

import CloseX from '@/components/svgs/CloseX.vue'
import { email } from '@/utilities/validators'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    CloseX,
  },
  props: {
    bookQueueItem: {
      type: Object,
      default: () => {},
    },
    /**
     * If this modal is used in another view other than home layout
     * provide an optional prop to route back home after submitting
     * a recommendation:
     */
    goHomeAfter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup() {
    return {
      queueStore: useQueueStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      recommendation: {
        book: null,
        recipients: [],
        message: '',
      },
      recipientName: '',
      recipientEmail: '',
      recommendationError: false,
      errorDetail: '',
      submitted: false,
    }
  },
  validations() {
    return {
      recipientEmail: {
        email: helpers.withMessage('Please enter a valid email.', email),
        required: helpers.withMessage('Email is required.', required),
      },
      recommendation: {
        message: {
          required: helpers.withMessage('Message is required.', required),
          maxLength: helpers.withMessage(
            ({ $params }) => `Your message must be less than ${$params.max} characters.`,
            maxLength(500),
          ),
        },
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length) {
          this.recommendationError = false
          this.errorDetail = ''
        }
      },
      deep: true,
    },
  },
  methods: {
    handleSkip() {
      if (this.goHomeAfter)
        this.$router.push({ name: 'app-root' })

      this.$emit('close')
    },
    handleSend() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        this.recommendation.book = this.bookQueueItem.book.id
        this.recommendation.recipients.push({
          email: this.recipientEmail,
          name: this.recipientName,
        })
        this.queueStore.sendRecommendation({ recommendation: this.recommendation })
          .then(() => {
            if (this.goHomeAfter)
              this.$router.push({ name: 'app-root' })

            this.queueStore.setAlert({
              type: 'success',
              message: `Your recommendation for ${this.bookQueueItem.book.title} has been sent.`,
              active: true,
            })
            this.$emit('close')
          })
          .catch((error) => {
            this.submitted = false
            this.recommendationError = true
            this.errorDetail = (error.data && error.data.non_field_errors)
              ? error.data.non_field_errors[0]
              : 'You have already sent a recommendation to this person.'
          })
      }
    },
  },
}
</script>

<template>
  <div class="modal">
    <div v-if="bookQueueItem" class="modal__container">
      <div @click="$emit('close')">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2>Recommend {{ bookQueueItem.book.title }} to your friends!</h2>
      </div>
      <div class="modal__container__head__alerts">
        <p v-if="recommendationError" class="user-form__alerts__error-msg">
          {{ errorDetail }}
        </p>
        <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
          Please correct the following errors:
        </p>
      </div>
      <form @submit.prevent="handleSend">
        <div class="modal__container__body">
          <div class="user-form__input-wrap">
            <BaseInput
              v-model="recipientName"
              label="Name"
              type="text"
              placeholder="Reader Name"
            />
          </div>
          <div class="user-form__input-wrap">
            <BaseInput
              v-model="recipientEmail"
              label="Email for friend"
              type="text"
              placeholder="email@forfriend.com"
              :class="{ error: v$.recipientEmail.$error }"
              @blur="v$.recipientEmail.$touch()"
            />
            <template v-if="v$.recipientEmail.$error">
              <p
                v-for="error in v$.recipientEmail.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
          <div class="modal__container__body__input">
            <label for="message-text-area">Message</label>
            <textarea
              id="message-text-area"
              v-model="recommendation.message"
              placeholder="Send your friend a message about what you liked about this book."
              :class="{ error: v$.recommendation.message.$error }"
              @blur="v$.recommendation.message.$touch()"
            />
            <template v-if="v$.recommendation.message.$error">
              <p
                v-for="error in v$.recommendation.message.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
        </div>
        <div class="modal__container__foot">
          <div class="modal__container__foot__options">
            <p class="modal__container__foot__options__skip" @click="handleSkip">
              Skip it
            </p>
            <BaseButton
              class="modal__container__foot__options__submit-btn"
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="v$.$errors.length || submitted"
            >
              Recommend
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.modal {
  &__container {
    padding: 5rem 6rem;

    &__foot {
      &__options {
        text-align: right;
        float: none;
      }
    }

    &__body {
      margin-top: 2.5rem;

      &__input {
        position: relative;
        margin-top: 2.5rem;
        width: 100%;

        label {
          @include font-size(1.2);

          font: $body2;
          margin-bottom: 0.5rem;
          display: block;
        }

        textarea, input {
          display: block;
          position: relative;
          width: 100%;
        }

        textarea {
          background-color: $color-seashell;
          padding: 2rem;
          height: 13rem;
          resize: none;

          @include font-size(1.3);
        }
      }
    }

    &__close {
      position: absolute;
      top: 2.5rem;
      right: 3rem;
      width: 3.5rem;
      cursor: pointer;
    }
  }
}
</style>
