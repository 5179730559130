import axios from 'axios'

import {
  ACTION_INVITE_READERS,
  ACTION_MEMBERS,
  API_BASE_URL,
  BY_BOOK_BOARD_ID,
  BY_BOOK_ID,
  BY_BOOK_SLUG,
  BY_PARENT_CHAT_MESSAGE,
  ENDPOINT_BOOK_BOARDS,
  ENDPOINT_BOOK_BOARD_ENTRIES,
  ENDPOINT_CHAT_GROUPS,
  ENDPOINT_CHAT_GROUPS_UNREAD_MESSAGES,
  ENDPOINT_CHAT_GROUP_INVITES,
  ENDPOINT_CHAT_MESSAGES_BY_GROUP,
} from '@/services/endpoints'

// Deletes
function deleteBookBoardEntry(entryId) {
  return axios.delete(`${API_BASE_URL + ENDPOINT_BOOK_BOARD_ENTRIES + entryId}/`)
}

function deleteChatMessage(groupId, messageId) {
  const baseUrl = `${API_BASE_URL + ENDPOINT_CHAT_MESSAGES_BY_GROUP + groupId}/`
  return axios.delete(`${baseUrl + messageId}/`)
}

// Gets
function getBookBoardEntriesByBoardId(boardId) {
  return axios.get(API_BASE_URL + ENDPOINT_BOOK_BOARD_ENTRIES + BY_BOOK_BOARD_ID + boardId)
}

function getBookBoardsBySlug(bookSlug) {
  return axios.get(API_BASE_URL + ENDPOINT_BOOK_BOARDS + BY_BOOK_SLUG + bookSlug)
}

function getChatGroupById(groupId) {
  return axios.get(`${API_BASE_URL + ENDPOINT_CHAT_GROUPS + groupId}/`)
}

function getChatGroupMembers(groupId) {
  return axios.get(API_BASE_URL + ENDPOINT_CHAT_GROUPS + groupId + ACTION_MEMBERS)
}

function getChatGroupsByBookId(bookId) {
  return axios.get(API_BASE_URL + ENDPOINT_CHAT_GROUPS + BY_BOOK_ID + bookId)
}

function getChatGroupsUnreadMessagesByBookId(bookId) {
  return axios.get(API_BASE_URL + ENDPOINT_CHAT_GROUPS_UNREAD_MESSAGES + BY_BOOK_ID + bookId)
}

function getChatMessagesByGroup(groupId) {
  return axios.get(`${API_BASE_URL + ENDPOINT_CHAT_MESSAGES_BY_GROUP + groupId}/`)
}

function getChatMessagesThreadByGroup(groupId, parentMessageId) {
  return axios.get(`${API_BASE_URL + ENDPOINT_CHAT_MESSAGES_BY_GROUP + groupId}/${
    BY_PARENT_CHAT_MESSAGE}${parentMessageId}`)
}

function getNextPage(nextURL) {
  return axios.get(nextURL)
}

// Patches
function patchBookBoardEntry(entryId, data) {
  return axios.patch(`${API_BASE_URL + ENDPOINT_BOOK_BOARD_ENTRIES + entryId}/`, data)
}

function patchChatGroupInviteReaders(groupId, data) {
  const baseUrl = API_BASE_URL + ENDPOINT_CHAT_GROUPS + groupId
  return axios.patch(baseUrl + ACTION_INVITE_READERS, data)
}

function patchChatMessage(groupId, messageId, data) {
  const baseUrl = `${API_BASE_URL + ENDPOINT_CHAT_MESSAGES_BY_GROUP + groupId}/`
  return axios.patch(`${baseUrl + messageId}/`, data)
}

// Posts
function postBookBoardEntry(data, config = {}) {
  return axios.post(API_BASE_URL + ENDPOINT_BOOK_BOARD_ENTRIES, data, config)
}

function postChatGroupInvites(data) {
  return axios.post(API_BASE_URL + ENDPOINT_CHAT_GROUP_INVITES, data)
}

function postChatMessageToGroup(groupId, data, config = {}) {
  return axios.post(`${API_BASE_URL + ENDPOINT_CHAT_MESSAGES_BY_GROUP + groupId}/`, data, config)
}

const spinesServiceModule = {
  deleteBookBoardEntry,
  deleteChatMessage,
  getBookBoardEntriesByBoardId,
  getBookBoardsBySlug,
  getChatGroupById,
  getChatGroupMembers,
  getChatGroupsByBookId,
  getChatGroupsUnreadMessagesByBookId,
  getChatMessagesByGroup,
  getChatMessagesThreadByGroup,
  getNextPage,
  patchBookBoardEntry,
  patchChatGroupInviteReaders,
  patchChatMessage,
  postBookBoardEntry,
  postChatGroupInvites,
  postChatMessageToGroup,
}

export default spinesServiceModule
