<script></script>

<template>
  <div class="loader">
    Loading...
  </div>
</template>

<style lang='scss' scoped>
.loader {
  position: relative;
  top: 38%;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: gray;
  background: linear-gradient(to left, gray 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to left, gray 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to left, gray 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to left, gray 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to right, gray 10%, rgb(255 255 255 / 0%) 42%);
  animation: load3 1.4s infinite linear;
  transform: translateZ(0) translateY(-50%);
}

.loader::before {
  width: 50%;
  height: 50%;
  background: $color-gray;
  border-radius: 100% 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader::after {
  background: $color-white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  inset: 0;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
