import axios from 'axios'

import {
  ADDRESS_TYPE_SHIPPING,
  API_BASE_URL,
  ENDPOINT_PAYMENTS_ADDRESSES,
  ENDPOINT_PAYMENTS_BILLING,
  ENDPOINT_PAYMENTS_CHECKOUT,
  ENDPOINT_PAYMENTS_CUSTOMERS,
  ENDPOINT_PAYMENTS_PRODUCTS,
  ENDPOINT_PAYMENTS_SUBSCRIPTIONS,
} from '@/services/endpoints'

// Gets
function getAddresses() {
  return axios.get(API_BASE_URL + ENDPOINT_PAYMENTS_ADDRESSES)
}

function getCheckoutSession(sessionId) {
  return axios.get(`${API_BASE_URL + ENDPOINT_PAYMENTS_CHECKOUT + sessionId}/`)
}

function getCustomers() {
  return axios.get(API_BASE_URL + ENDPOINT_PAYMENTS_CUSTOMERS)
}

function getProducts() {
  return axios.get(API_BASE_URL + ENDPOINT_PAYMENTS_PRODUCTS)
}

function getSubscriptions() {
  return axios.get(API_BASE_URL + ENDPOINT_PAYMENTS_SUBSCRIPTIONS)
}

// Patches
function patchAddress(addressId, data) {
  return axios.patch(`${API_BASE_URL + ENDPOINT_PAYMENTS_ADDRESSES + addressId}/`, {
    type_of: ADDRESS_TYPE_SHIPPING,
    ...data,
  })
}

function patchCustomer(customerId, data) {
  return axios.patch(`${API_BASE_URL + ENDPOINT_PAYMENTS_CUSTOMERS + customerId}/`, data)
}

// Posts
function postCheckoutSession(data) {
  return axios.post(API_BASE_URL + ENDPOINT_PAYMENTS_CHECKOUT, data)
}

function postAddress(data) {
  return axios.post(API_BASE_URL + ENDPOINT_PAYMENTS_ADDRESSES, {
    type_of: ADDRESS_TYPE_SHIPPING,
    ...data,
  })
}

function postBilling(data) {
  return axios.post(API_BASE_URL + ENDPOINT_PAYMENTS_BILLING, data)
}

const paymentsServiceModule = {
  getAddresses,
  getCheckoutSession,
  getCustomers,
  getProducts,
  getSubscriptions,
  patchAddress,
  postAddress,
  postBilling,
  patchCustomer,
  postCheckoutSession,
}

export default paymentsServiceModule
