<script>
export default {
  props: {
    okText: {
      type: String,
      default: 'Yes',
    },
    cancelText: {
      type: String,
      default: 'No',
    },
    confirmMessage: {
      type: String,
      default: 'Are you sure?',
    },
    horzAlign: {
      type: String,
      default: 'align-right',
    },
    vertAlign: {
      type: String,
      default: 'align-top',
    },
    uniqueElId: {
      type: [Number, String],
      required: true,
    },
    iconType: {
      type: String,
      default: 'delete',
    },
  },
  emits: ['hideDialog', 'ok', 'showDialog'],
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    alignmentClasses() {
      return `${this.horzAlign} ${this.vertAlign}`
    },
    transitionName() {
      if (this.vertAlign === 'align-bottom')
        return 'up-fade'
      else
        return 'drop-fade'
    },
  },
  mounted() {
    document.addEventListener('click', this.onDismissiveClick)
  },
  methods: {
    handleTriggerBtn() {
      this.isVisible = true
      this.$emit('showDialog')
    },
    handleOk() {
      this.$emit('ok')
      this.isVisible = false
    },
    dismiss() {
      this.$emit('hideDialog')
      this.isVisible = false
    },
    onDismissiveClick(evt) {
      if (!evt.target.closest(`#pop-confirm-${this.uniqueElId}`))
        this.dismiss()
    },
  },
}
</script>

<template>
  <div :id="`pop-confirm-${uniqueElId}`" class="it-pop-confirm">
    <span
      v-if="iconType === 'delete'"
      class="oi delete-icon trigger-btn"
      :class="{ active: isVisible }"
      data-glyph="trash"
      @click="handleTriggerBtn"
    />
    <transition :name="transitionName">
      <div v-if="isVisible" class="it-pop-confirm-dialog" :class="alignmentClasses">
        <div class="confirmation-body">
          <i class="material-icons warning-icon mar-r-s">warning</i>
          <p class="mar-b-m">
            {{ confirmMessage }}
          </p>
        </div>
        <div class="confirmation-options">
          <BaseButton
            class="mar-r-s"
            button-type="primary"
            button-size="small"
            @click="handleOk"
          >
            {{ okText }}
          </BaseButton>
          <BaseButton
            button-type="secondary"
            button-size="small"
            @click="dismiss"
          >
            {{ cancelText }}
          </BaseButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang='scss' scoped>
.it-pop-confirm-dialog {
  position: absolute;
  top: 3.5rem;
  width: 24rem;
  padding: 1.75rem 1.7rem;
  background-color: $color-white;
  box-shadow: -1px -1px 20px 0 rgb(0 0 0 / 11%);
  z-index: 4;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-white;
  }

  .confirmation-body {
    .warning-icon {
      display: inline-block;

      @include font-size(1.5);

      color: $color-warning;
      vertical-align: top;
      width: 2rem;
    }

    p {
      width: calc(100% - 3rem);
      display: inline-block;

      @include font-size(1.25);
      @include line-height(1.85);
    }
  }

  .confirmation-options {
    text-align: right;
  }

  &.align-left {
    left: 0;

    &::before {
      left: 0.6rem;
    }
  }

  &.align-right {
    right: 0;

    &::before {
      right: 0.6rem;
    }
  }

  &.align-bottom {
    top: -100%;
    transform: translateY(-120%);

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -18px;
      top: auto;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid $color-white;
    }
  }
}

.it-pop-confirm {
  .delete-icon {
    @include font-size(1.3);

    width: 2.6rem;
    text-align: center;
    color: $color-action-button;
    opacity: 0.6;
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);

    &:hover, &.active {
      opacity: 1;
      color: $color-black;
    }
  }
}

.drop-fade-enter-active, .drop-fade-leave-active {
  transition: all 0.15s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.drop-fade-enter-from, .drop-fade-leave-to {
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}

.up-fade-enter-active, .up-fade-leave-active {
  transition: all 0.15s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.up-fade-enter-from, .up-fade-leave-to {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}
</style>
