<script>
import InvitationCard from './InvitationCard.vue'
import BookListHeader from '@/components/booklists/BookListHeader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    BookListHeader,
    InvitationCard,
  },
  props: {
    invitationList: {
      type: Array,
      default: () => [],
    },
    invitationsTotalCount: {
      type: Number,
      default: 0,
    },
    invitationListMeta: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    listLength() {
      if (this.invitationList)
        return this.invitationList.length

      return 0
    },
  },
  methods: {
    handleNotInterested(invitation) {
      this.queueStore.loading.invitationAction.item = invitation
      this.queueStore.actOnInvitation({ invitation, acceptedInvite: false })
        .then(() => {
          this.queueStore.loading.invitationAction.item = null
          this.queueStore.setAlert({
            type: 'success',
            message: 'Invitation removed from your list.',
            active: true,
          })
        })
        .catch((error) => {
          this.queueStore.setAlert({
            type: 'error',
            message: `${error.data.error}`,
            active: true,
          })
          this.queueStore.loading.invitationAction.item = null
        })
    },
    handleAcceptInvite(invitation) {
      this.queueStore.loading.invitationAction.item = invitation
      this.queueStore.actOnInvitation({ invitation, acceptedInvite: true })
        .then(() => {
          this.queueStore.loading.invitationAction.item = null
          let message
          let bookObject
          switch (invitation.invite_type) {
            case 'bookrecommendation':
              this.queueStore.getUserBookList('queue')
              bookObject = invitation.invited_object.book
              message = 'Book recommendation accepted into your queue.'
              break
            case 'chatgroupinvite':
              this.queueStore.getUserBookList('currentlyReading')
              bookObject = invitation.invited_object.chat_group.book
              message = 'Chat group invite accepted.'
              break
            default:
              message = 'You have accepted a new invite!'
          }
          this.queueStore.setAlert({
            type: 'success',
            message,
            active: true,
          })
          this.queueStore.addNewIsbnToList(bookObject)
        })
        .catch((error) => {
          this.queueStore.setAlert({
            type: 'error',
            message: `${error.data.error}`,
            active: true,
          })
          this.queueStore.loading.invitationAction.item = null
        })
    },
  },
}
</script>

<template>
  <div class="invitations-list">
    <BookListHeader
      v-if="invitationListMeta"
      :border-top="invitationListMeta.borderTop"
      :name="invitationListMeta.name"
      :description="invitationListMeta.description"
      :list-url="invitationListMeta.url"
      :list-total-count="invitationsTotalCount"
      :list-length="listLength"
      list-type-object="Invitation"
    />
    <div v-if="invitationList" class="invitations-list__invitation-carousel">
      <InvitationCard
        v-for="invitation in invitationList"
        :key="invitation.id"
        :invitation="invitation"
        @dismissed-invite="handleNotInterested"
        @accepted-invite="handleAcceptInvite"
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.invitations-list {
  text-align: right;

  &__invitation-carousel {
    position: relative;
    padding: 5rem 8rem;
    padding-bottom: 0;

    &::after {
      content: "";
      flex: 0 0 1rem;
    }

    @include respond-to('small') {
      padding: 1.25rem 2.5rem 0;
      display: block;
    }
  }
}
</style>
