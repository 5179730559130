import { formatISO, parseISO } from 'date-fns'
import { defineStore } from 'pinia'

import analytics from '@/utilities/analytics'
import { CUSTOM_EVENTS_ARRAY } from '@/customEvents'
import {
  DISPLAY_STATE_ARCHIVED,
  DISPLAY_STATE_COMPLETED,
  DISPLAY_STATE_CURRENTLY_READING,
  DISPLAY_STATE_PAUSED,
  DISPLAY_STATE_QUEUE,
} from '@/services/endpoints'
import { getActionsCache, removeActionFromCache } from '@/utilities/actionsCache'
import { isArrayEmpty } from '@/utilities/empty'
import booksService from '@/services/booksService'
import invitesService from '@/services/invitesService'
import searchService from '@/services/searchService'

// The order of values in array matter. They need to be in this exact order. Do not change!
const queueNames = ['queue', 'currentlyReading', 'completed', 'paused', 'archived']
function queueNameForDisplayState(displayState) {
  return queueNames[displayState]
}

export const useQueueStore = defineStore('queue', {
  state: () => ({
    // Alerts
    queueAlert: {
      type: null,
      message: null,
      active: false,
    },

    // queueEvent
    queueEvent: {
      handled: true,
      name: null,
      item: null,
    },

    // Search
    book: null,
    search: {
      results: null,
    },

    // Reactive watch variables
    queuesNeedUpdating: {
      currentlyReading: false,
      queue: false,
      completed: false,
      invitations: false,
    },
    readingGoalNeedsUpdating: false,

    // The main queues for home view lists and associated metadata
    isbnListAllBooks: null,

    invitations: null,
    invitationsCount: 0,
    invitationsNextURL: null,

    currentlyReadingBooks: null,
    currentlyReadingBooksCount: 0,
    currentlyReadingBooksNextURL: null,

    queueBooks: null,
    queueBooksCount: 0,
    queueBooksNextURL: null,

    completedBooks: null,
    completedBooksCount: 0,
    completedBooksNextURL: null,

    pausedBooks: null,
    pausedBooksCount: 0,
    pausedBooksNextURL: null,

    archivedBooks: null,
    archivedBooksCount: 0,
    archivedBooksNextURL: null,

    bookListsInitialized: {
      currentlyReading: false,
      queue: false,
      completed: false,
      paused: false,
      archived: false,
    },

    // Explore book lists (rolled up and unrolled are treated separately)
    rolledUpCuratedLists: null,
    unrolledCuratedLists: null,

    // Other objects to build home views and to drive recommendations and reviews
    currentBookQueueItem: null,
    readingGoal: {},
    reviews: [],
    reviewTags: null,
    settings: {},
    welcomeQuote: {},

    // Loading states
    loading: {
      search: false,
      bookDetail: false,
      saveBook: {
        item: null,
      },
      readingGoal: false,
      reviewTags: null,
      review: {
        item: null,
      },
      reviews: null,
      recommendation: {
        item: null,
      },
      userBookAction: {
        item: null,
      },
      invitationAction: {
        item: null,
      },
      bookLists: true,
      loadMore: {
        queue: false,
        completed: false,
        paused: false,
        archived: false,
      },
    },
  }),
  getters: {
    queueEventHandledStatus() {
      return this.queueEvent.handled
    },
    currentlyReadingUpdateState() {
      return this.queuesNeedUpdating.currentlyReading
    },
    queueUpdateState() {
      return this.queuesNeedUpdating.queue
    },
    completedUpdateState() {
      return this.queuesNeedUpdating.completed
    },
    invitationsUpdateState() {
      return this.queuesNeedUpdating.invitations
    },
    readingGoalUpdateState() {
      return this.readingGoalNeedsUpdating
    },
    currentlyReadingBooksEmpty() {
      return this.bookListsInitialized.currentlyReading && isArrayEmpty(this.currentlyReadingBooks)
    },
    queueBooksEmpty() {
      return this.bookListsInitialized.queue && isArrayEmpty(this.queueBooks)
    },
    completedBooksEmpty() {
      return this.bookListsInitialized.completed && isArrayEmpty(this.completedBooks)
    },
    pausedBooksEmpty() {
      return this.bookListsInitialized.paused && isArrayEmpty(this.pausedBooks)
    },
    archivedBooksEmpty() {
      return this.bookListsInitialized.archived && isArrayEmpty(this.archivedBooks)
    },
    allBookListsEmpty() {
      // This getter makes sure that the queues have been initialized and are still empty.
      return this.bookListsInitialized.currentlyReading && this.bookListsInitialized.queue
        && this.bookListsInitialized.completed && this.bookListsInitialized.paused
        && this.bookListsInitialized.archived
        && isArrayEmpty(this.currentlyReadingBooks) && isArrayEmpty(this.queueBooks)
        && isArrayEmpty(this.completedBooks) && isArrayEmpty(this.pausedBooks)
        && isArrayEmpty(this.archivedBooks)
    },
    currentlyReadingBooksLength() {
      if (this.currentlyReadingBooks === null)
        return 0

      return this.currentlyReadingBooks.length
    },
    queueBooksLength() {
      if (this.queueBooks === null)
        return 0

      return this.queueBooks.length
    },
    completedBooksLength() {
      if (this.completedBooks === null)
        return 0

      return this.completedBooks.length
    },
    pausedBooksLength() {
      if (this.pausedBooks === null)
        return 0

      return this.pausedBooks.length
    },
    archivedBooksLength() {
      if (this.archivedBooks === null)
        return 0

      return this.archivedBooks.length
    },
    bookQueueItemFinishedDate: state => (bookQueueItem) => {
      // This getter unifies dates and timestamps by converting it into an ISO date string or the
      // blank string if the date does not exist.
      let finishedDateString = ''
      const item = bookQueueItem || state.currentBookQueueItem
      if (item) {
        const dateString = item.date_finished || item.finished_reading_at
        if (dateString)
          finishedDateString = formatISO(parseISO(dateString), { representation: 'date' })

        return finishedDateString
      }
    },
    currentBookQueueItemProgress() {
      let progress = 0
      if (this.currentBookQueueItem) {
        const item = this.currentBookQueueItem
        progress = (item.current_page_number / item.page_count) * 100
      }
      return progress
    },
    getBookQueueItemByIsbn: state => (isbn) => {
      let bookQueueItem = null
      if (state.queueBooks || state.completedBooks || state.currentlyReadingBooks
        || state.pausedBooks || state.archivedBooks) {
        const allUserBooks = [].concat(
          state.queueBooks,
          state.completedBooks,
          state.currentlyReadingBooks,
          state.pausedBooks,
          state.archivedBooks,
        )
        const foundBooks = allUserBooks.filter((bookQueueItem) => {
          return bookQueueItem && bookQueueItem.book.isbn_13 === isbn
        })
        if (foundBooks.length)
          bookQueueItem = foundBooks[0]
      }
      return bookQueueItem
    },
    getBookQueueItemBySource: state => (sourceNameId, sourceUid) => {
      // This getter searches book queues for the book queue item
      let bookQueueItem = null
      const sourceName = searchService.getSourceNameForId(sourceNameId)
      if (state.queueBooks || state.completedBooks || state.currentlyReadingBooks
        || state.pausedBooks || state.archivedBooks) {
        const allUserBooks = [].concat(
          state.queueBooks,
          state.completedBooks,
          state.currentlyReadingBooks,
          state.pausedBooks,
          state.archivedBooks,
        )
        const foundBooks = allUserBooks.filter((bookQueueItem) => {
          return bookQueueItem && bookQueueItem.book.source_name === sourceName
            && bookQueueItem.book.source_uid === sourceUid
        })
        if (foundBooks.length)
          bookQueueItem = foundBooks[0]
      }
      return bookQueueItem
    },
    allBookListsInitialized() {
      return this.bookListsInitialized.currentlyReading && this.bookListsInitialized.queue
        && this.bookListsInitialized.completed && this.bookListsInitialized.paused
        && this.bookListsInitialized.archived
    },
  },
  actions: {
    actOnCachedActions() {
      const actionsCache = getActionsCache()
      actionsCache.forEach((actionObj) => {
        invitesService.postAction({
          type_of: actionObj.actionType,
          share_identifier: actionObj.identifier,
          contact_identifier: actionObj.cid,
        })
          .then((response) => {
            removeActionFromCache(actionObj)
            if (response.data.type_of === 'book')
              this.queuesNeedUpdating.queue = true
            else
              this.queuesNeedUpdating.invitations = true

            analytics.trackEvent('Action Taken', analytics.TYPE_NOT_DEFINED, actionObj)
          })
          .catch((error) => {
            const response = error.response
            if (response.status === 400 && 'non_field_errors' in response.data) {
              // If we get back a 400 error and non field errors, then we know that the share
              // identifier is no longer in the database, and we can safely remove the action.
              removeActionFromCache(actionObj)
            }
          })
      })
    },
    searchSingleBook(payload) {
      this.book = null
      return new Promise((resolve, reject) => {
        searchService.searchSingleBook(this.settings, payload.sourceNameId, payload.sourceUid)
          .then((item) => {
            this.book = item
            resolve(item)
          },
          (error) => {
            reject(error)
          })
      })
    },
    searchBooks(payload) {
      this.loading.search = true
      searchService.searchBooks(this.settings, payload)
        .then((items) => {
          this.setSearchResults(items)
          this.loading.search = false
        })
    },
    getIsbnList() {
      return new Promise((resolve, reject) => {
        booksService.getBookQueueItemsIsbnList()
          .then((response) => {
            this.isbnListAllBooks = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getInvitations() {
      return new Promise((resolve, reject) => {
        invitesService.getInvitations()
          .then((response) => {
            const invitations = response.data
            this.invitations = invitations.results
            this.invitationsCount = invitations.count
            this.invitationsNextURL = invitations.next
            this.queuesNeedUpdating.invitations = false
            resolve(invitations)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    actOnInvitation(payload) {
      return new Promise((resolve, reject) => {
        invitesService.patchInvitation(payload.invitation.id, {
          accepted_invite: payload.acceptedInvite,
        })
          .then((response) => {
            const invitation = response.data
            const index = this.invitations.findIndex(item => item.id === invitation.id)
            if (~index)
              this.invitations.splice(index, 1)

            analytics.trackEvent('Invitation Acted On', analytics.TYPE_INVITATION, invitation)
            resolve(invitation)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getUserBookList(listType) {
      if (!queueNames.includes(listType))
        return Promise.reject(new Error(`List type ${listType} is not a known type.`))

      return new Promise((resolve, reject) => {
        booksService.getBookQueueItemsByListType(listType)
          .then((response) => {
            const books = response.data
            this[`${listType}Books`] = books.results
            this[`${listType}BooksCount`] = books.count
            this[`${listType}BooksNextURL`] = books.next
            this.queuesNeedUpdating[listType] = false
            this.bookListsInitialized[listType] = true
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getNextPageOfUserBookList(listType) {
      if (!queueNames.includes(listType))
        return Promise.reject(new Error(`List type ${listType} is not a known type.`))

      const nextURL = this[`${listType}BooksNextURL`]
      return new Promise((resolve, reject) => {
        booksService.getNextPage(nextURL)
          .then((response) => {
            const books = response.data
            this[`${listType}Books`] = this[`${listType}Books`].concat(books.results)
            this[`${listType}BooksCount`] = books.count
            this[`${listType}BooksNextURL`] = books.next
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    addBookToQueue(payload) {
      const newBookQueueItem = {
        book: {
          source_name: payload.book.source_name,
          source_uid: payload.book.source_uid,
          title: payload.book.title,
        },
        display_state: payload.display_state ? payload.display_state : DISPLAY_STATE_QUEUE,
        display_position: 0,
        label: '',
      }
      return new Promise((resolve, reject) => {
        booksService.postBookQueueItem(newBookQueueItem)
          .then((response) => {
            const bookQueueItem = response.data
            analytics.trackEvent('Book Added', analytics.TYPE_NOT_DEFINED, {
              book_source_name: payload.book.source_name,
              book_source_uid: payload.book.source_uid,
              book_source_url: payload.book.source_url,
              book_title: payload.book.title,
              book_isbn_13: payload.book.isbn_13,
              queue: bookQueueItem.display_state,
            })
            resolve(bookQueueItem)
          })
          .catch((error) => {
            reject(error.response)
          })
      })
    },
    adjustBookPageCount(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(payload.bookQueueItem.id, {
          adjusted_page_count: payload.adjustedPageCount,
        })
          .then((response) => {
            const bookQueueItem = response.data
            if (payload.isCurrentQueueItem)
              this.currentBookQueueItem = bookQueueItem

            this.replaceBookWithUpdate(bookQueueItem)
            analytics.trackEvent('Book Page Count Adjusted', analytics.TYPE_BOOK_QUEUE_ITEM,
              bookQueueItem)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateBookProgress(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(payload.bookQueueItem.id, {
          current_page_number: payload.progress,
        })
          .then((response) => {
            const bookQueueItem = response.data
            if (payload.isCurrentQueueItem)
              this.currentBookQueueItem = bookQueueItem

            this.replaceBookWithUpdate(bookQueueItem)
            analytics.trackEvent('Book Progress Updated', analytics.TYPE_BOOK_QUEUE_ITEM,
              bookQueueItem)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    clearDateFinished(bookQueueItem) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItemClearDateFinished(bookQueueItem.id)
          .then((response) => {
            this.replaceBookWithUpdate(response.data)
            analytics.trackEvent('Date Finished Cleared', analytics.TYPE_BOOK_QUEUE_ITEM,
              response.data)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateDateFinished(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(payload.bookQueueItem.id, {
          date_finished: payload.dateFinished,
        })
          .then((response) => {
            const bookQueueItem = response.data
            if (payload.isCurrentQueueItem)
              this.currentBookQueueItem = bookQueueItem

            this.replaceBookWithUpdate(bookQueueItem)
            analytics.trackEvent('Date Finished Updated', analytics.TYPE_BOOK_QUEUE_ITEM,
              bookQueueItem)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateTrackByPercentageFlag(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(payload.bookQueueItem.id, {
          track_by_percentage: payload.trackByPercentage,
        })
          .then((response) => {
            const bookQueueItem = response.data
            if (payload.isCurrentQueueItem)
              this.currentBookQueueItem = bookQueueItem

            this.replaceBookWithUpdate(bookQueueItem)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateBookQueueItem(bookQueueItem) {
      return new Promise((resolve, reject) => {
        booksService.getBookQueueItem(bookQueueItem.id)
          .then((response) => {
            this.replaceBookWithUpdate(response.data)
            this.currentBookQueueItem = response.data
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    addLabelToBook(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(payload.bookQueueItem.id, { label: payload.label })
          .then((response) => {
            this.replaceBookWithUpdate(response.data)
            analytics.trackEvent('Book Label Added', analytics.TYPE_BOOK_QUEUE_ITEM, response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    clearBookLabel(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(payload.bookQueueItem.id, { label: '' })
          .then((response) => {
            this.replaceBookWithUpdate(response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    startReadingBook(bookQueueItem) {
      const toList = 'currentlyReading'
      const fromList = queueNameForDisplayState(bookQueueItem.display_state)
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(bookQueueItem.id, {
          display_state: DISPLAY_STATE_CURRENTLY_READING,
        })
          .then((response) => {
            this.removeBookFromBookList(fromList, response.data)
            this.addBookToBookList(toList, response.data)
            this.currentBookQueueItem = response.data
            analytics.trackEvent('Book Started', analytics.TYPE_BOOK_QUEUE_ITEM, response.data)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    moveBookBackToQueue(bookQueueItem) {
      const toList = 'queue'
      const fromList = queueNameForDisplayState(bookQueueItem.display_state)
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(bookQueueItem.id, {
          display_state: DISPLAY_STATE_QUEUE,
        })
          .then((response) => {
            this.removeBookFromBookList(fromList, response.data)
            this.addBookToBookList(toList, response.data)
            this.currentBookQueueItem = response.data
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    pauseReadingBook(bookQueueItem) {
      const toList = 'paused'
      const fromList = queueNameForDisplayState(bookQueueItem.display_state)
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(bookQueueItem.id, {
          display_state: DISPLAY_STATE_PAUSED,
        })
          .then((response) => {
            this.removeBookFromBookList(fromList, response.data)
            this.addBookToBookList(toList, response.data)
            this.currentBookQueueItem = response.data
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    archiveBook(bookQueueItem) {
      const toList = 'archived'
      const fromList = queueNameForDisplayState(bookQueueItem.display_state)
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(bookQueueItem.id, {
          display_state: DISPLAY_STATE_ARCHIVED,
        })
          .then((response) => {
            this.removeBookFromBookList(fromList, response.data)
            this.addBookToBookList(toList, response.data)
            this.currentBookQueueItem = response.data
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    completeBook(bookQueueItem) {
      const toList = 'completed'
      const fromList = queueNameForDisplayState(bookQueueItem.display_state)
      return new Promise((resolve, reject) => {
        booksService.patchBookQueueItem(bookQueueItem.id, {
          display_state: DISPLAY_STATE_COMPLETED,
        })
          .then((response) => {
            this.readingGoalNeedsUpdating = true
            this.removeBookFromBookList(fromList, response.data)
            this.addBookToBookList(toList, response.data)
            this.currentBookQueueItem = response.data
            analytics.trackEvent('Book Completed', analytics.TYPE_BOOK_QUEUE_ITEM, response.data)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    completeReview(payload) {
      this.loading.review.item = payload.review
      return new Promise((resolve, reject) => {
        booksService.postReview(payload.review)
          .then((response) => {
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateReview(payload) {
      this.loading.review.item = payload.review
      return new Promise((resolve, reject) => {
        booksService.putReview(payload.bookQueueItem.user_review.id, payload.review)
          .then((response) => {
            this.updateBookQueueItemWithReview(payload.bookQueueItem, response.data)
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    sendRecommendation(payload) {
      this.loading.recommendation.item = payload.recommendation
      return new Promise((resolve, reject) => {
        booksService.postRecommendation(payload.recommendation)
          .then((response) => {
            analytics.trackEvent('Book Recommendation Sent', analytics.TYPE_BOOK_RECOMMENDATION,
              response.data)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    removeBook(bookQueueItem) {
      const fromList = queueNameForDisplayState(bookQueueItem.display_state)
      return new Promise((resolve, reject) => {
        booksService.deleteBookQueueItem(bookQueueItem.id)
          .then((response) => {
            this.removeBookFromBookList(fromList, bookQueueItem)
            resolve(response)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    saveQueueOrder() {
      const itemsToPatch = this.queueBooks.filter(item => item.dirty)
      for (const queueItem of itemsToPatch) {
        booksService.patchBookQueueItem(queueItem.id, {
          display_position: queueItem.display_position,
        })
          .then(() => {
            queueItem.dirty = false
          })
      }
      this.setAlert({
        type: 'success',
        message: 'Your queue has been successfully saved.',
        active: true,
      })
      analytics.trackEvent('Book Queue Reordered', analytics.TYPE_NOT_DEFINED, {
        items_reordered: itemsToPatch.length,
      })
    },
    updateQueuePositions(updatedQueue) {
      let position = 0
      for (const queueItem of updatedQueue) {
        const item = this.queueBooks.find((element) => {
          return element.id === queueItem.id
        })
        if (item.display_position !== position)
          queueItem.dirty = true

        queueItem.display_position = position++
      }
      this.queueBooks = updatedQueue
    },
    getRolledUpCuratedLists() {
      return new Promise((resolve, reject) => {
        booksService.getExploreLists({ isUnrolled: false })
          .then((response) => {
            this.rolledUpCuratedLists = response.data.results
            this.rolledUpCuratedLists.forEach((curatedList) => {
              if (!('curatedbooks' in curatedList)) {
                curatedList.curatedbooks = []
                curatedList.curatedbooksCount = 0
                curatedList.curatedbooksNextURL = null
              }
            })
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getUnrolledCuratedLists() {
      return new Promise((resolve, reject) => {
        booksService.getExploreLists({ isUnrolled: true })
          .then((response) => {
            this.unrolledCuratedLists = response.data.results
            this.unrolledCuratedLists.forEach((curatedList) => {
              if (!('curatedbooks' in curatedList)) {
                curatedList.curatedbooks = []
                curatedList.curatedbooksCount = 0
                curatedList.curatedBooksNextURL = null
              }
            })
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCuratedBooksForList(curatedList) {
      return new Promise((resolve, reject) => {
        booksService.getExploreBooks(curatedList.id)
          .then((response) => {
            const listType = curatedList.is_unrolled ? 'unrolled' : 'rolledUp'
            const index = this[`${listType}CuratedLists`].findIndex(item => item.id === curatedList.id)
            if (~index) {
              const listToUpdate = this[`${listType}CuratedLists`][index]
              listToUpdate.curatedbooks = response.data.results
              listToUpdate.curatedbooksCount = response.data.count
              listToUpdate.curatedbooksNextURL = response.data.next
            }
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getNextPageOfCuratedBooks(curatedList) {
      return new Promise((resolve, reject) => {
        booksService.getNextPage(curatedList.curatedBooksNextURL)
          .then((response) => {
            const listType = curatedList.is_unrolled ? 'unrolled' : 'rolledUp'
            const index = this[`${listType}CuratedLists`].findIndex(item => item.id === curatedList.id)
            if (~index) {
              const listToUpdate = this[`${listType}CuratedLists`][index]
              listToUpdate.curatedbooks = listToUpdate.curatedbooks.concat(response.data.results)
              listToUpdate.curatedbooksCount = response.data.count
              listToUpdate.curatedbooksNextURL = response.data.next
            }
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCurrentBookQueueItemByBookSlug(bookSlug) {
      return new Promise((resolve, reject) => {
        booksService.getBookQueueItemsBySlug(bookSlug)
          .then((response) => {
            if (response.data.count > 0) {
              // Since this is a list view, it is possible that this view returns an empty
              // list because it could not find the book queue item by slug. Only move forward if
              // we found a book queue item and pick off the first result (there should only be one
              // since slug is unique).
              const bookQueueItem = response.data.results[0]
              this.currentBookQueueItem = bookQueueItem
              resolve(bookQueueItem)
            }
            else {
              reject(new Error('Book not found in your queues.'))
            }
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCurrentBookQueueItemByBookSource(payload) {
      return new Promise((resolve, reject) => {
        booksService.getBookQueueItemsBySource(payload.sourceNameId, payload.sourceUid)
          .then((response) => {
            if (response.data.count > 0) {
              // Since this is a list view, it is possible that this view returns an empty
              // list because it could not find the book queue item by slug. Only move forward if
              // we found a book queue item, and pick off the first result.
              const bookQueueItem = response.data.results[0]
              this.currentBookQueueItem = bookQueueItem
              resolve(bookQueueItem)
            }
            else {
              reject(new Error('Book not found in your queues.'))
            }
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCurrentBookQueueItemByBookIsbn(isbn) {
      return new Promise((resolve, reject) => {
        booksService.getBookQueueItemsByIsbn(isbn)
          .then((response) => {
            if (response.data.count > 0) {
              // Since this is a list view, it is possible that this view returns an empty
              // list because it could not find the book queue item by slug. Only move forward if
              // we found a book queue item, and pick off the first result.
              const bookQueueItem = response.data.results[0]
              this.currentBookQueueItem = bookQueueItem
              resolve(bookQueueItem)
            }
            else {
              reject(new Error('Book not found in your queues.'))
            }
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getFileExports() {
      return new Promise((resolve, reject) => {
        booksService.getFileExports()
          .then((response) => {
            resolve(response.data.results)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    requestNewFileExport() {
      return new Promise((resolve, reject) => {
        booksService.postFileExport()
          .then((response) => {
            analytics.trackEvent('Book Export Initiated', analytics.TYPE_NOT_DEFINED, {})
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    uploadFileImportSource(fileImportData) {
      return new Promise((resolve, reject) => {
        booksService.postFileImport(fileImportData)
          .then((response) => {
            analytics.trackEvent('Book Import Initiated', analytics.TYPE_NOT_DEFINED, {
              source_name: fileImportData.get('source_name'),
            })
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getCurrentReadingGoal() {
      this.loading.readingGoal = true
      return new Promise((resolve, reject) => {
        booksService.getCurrentReadingGoal()
          .then((response) => {
            this.readingGoal = response.data
            this.loading.readingGoal = false
            this.readingGoalNeedsUpdating = false
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    setNewReadingGoal(numberOfBooks) {
      return new Promise((resolve, reject) => {
        booksService.postReadingGoal({ number_of_books: numberOfBooks })
          .then((response) => {
            this.readingGoal = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    updateReadingGoal(payload) {
      return new Promise((resolve, reject) => {
        booksService.patchReadingGoal(payload.id, { number_of_books: payload.numberOfBooks })
          .then((response) => {
            this.readingGoal = response.data
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getReviewTags() {
      this.loading.reviewTags = true
      return new Promise((resolve, reject) => {
        booksService.getReviewTags()
          .then((response) => {
            this.reviewTags = response.data.results
            this.loading.reviewTags = false
            resolve(response.data.results)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getReviews() {
      this.loading.reviews = true
      return new Promise((resolve, reject) => {
        booksService.getReviews()
          .then((response) => {
            this.reviews = response.data.results
            this.loading.reviews = false
            resolve(response.data)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        booksService.getSettings()
          .then((response) => {
            this.settings = response.data.settings
            resolve(response.data.settings)
          },
          (error) => {
            reject(error.response)
          })
      })
    },
    getWelcomeQuote() {
      booksService.getRandomWelcomeQuote()
        .then((response) => {
          this.welcomeQuote = response.data
        })
    },
    setSearchResults(items) {
      if (items) {
        const cleanItems = items.filter(item => item.isbn_13)
        cleanItems.length ? this.search.results = cleanItems : this.search.results = null
      }
      else {
        this.search.results = null
      }
    },
    addBookToBookList(listType, bookQueueItem) {
      if (!queueNames.includes(listType))
        return

      const listKey = `${listType}Books`
      if (this[listKey] && this[listKey].length) {
        this[listKey].unshift(bookQueueItem)
      }
      else {
        this[listKey] = []
        this[listKey].push(bookQueueItem)
      }
      this[`${listKey}Count`] += 1
    },
    replaceBookWithUpdate(bookQueueItem) {
      const listType = queueNameForDisplayState(bookQueueItem.display_state)
      if (listType === undefined)
        return

      const queueMap = {
        queue: this.queueBooks,
        currentlyReading: this.currentlyReadingBooks,
        completed: this.completedBooks,
        paused: this.pausedBooks,
        archived: this.archivedBooks,
      }
      const bookQueue = queueMap[listType]
      if (!bookQueue)
        return

      const index = bookQueue.findIndex(item => item.id === bookQueueItem.id)
      if (~index)
        bookQueue.splice(index, 1, bookQueueItem)
    },
    removeBookFromBookList(listType, bookQueueItem) {
      if (!queueNames.includes(listType))
        return

      const listKey = `${listType}Books`
      const index = this[listKey].findIndex(item => item.id === bookQueueItem.id)
      if (~index)
        this[listKey].splice(index, 1)

      this[`${listKey}Count`] -= 1
    },
    addNewIsbnToList(book) {
      if (book && book.isbn_13)
        this.isbnListAllBooks.push(book.isbn_13)
    },
    removeIsbnFromList(book) {
      if (book && book.isbn_13) {
        const index = this.isbnListAllBooks.indexOf(book.isbn_13)
        if (~index)
          this.isbnListAllBooks.splice(index, 1)
      }
    },
    updateBookQueueItemWithReview(bookQueueItem, review) {
      const index = this.completedBooks.findIndex(item => item.id === bookQueueItem.id)
      if (~index) {
        const itemToUpdate = this.completedBooks[index]
        itemToUpdate.user_review.thoughts = review.thoughts
        itemToUpdate.user_review.rating = review.rating
        const reviewTags = this.reviewTags.filter((item) => {
          return review.review_tags.includes(item.id)
        })
        itemToUpdate.user_review.review_tags = reviewTags.flatMap(r => r.name)
      }
    },
    setAlert(payload) {
      this.queueAlert = payload
      setTimeout(() => {
        this.queueAlert.active = false
      }, 4000)
    },
    setQueueEvent(eventPayload) {
      // Only set a new queue event if the name is in the customEvents list.
      if (CUSTOM_EVENTS_ARRAY.includes(eventPayload.name)) {
        this.queueEvent.handled = false
        this.queueEvent.name = eventPayload.name
        this.queueEvent.item = eventPayload.item
      }
    },
    clearQueueEvent() {
      // As soon as the receiver receives the queue event, it is cleared to be handled.
      this.queueEvent.handled = true
      this.queueEvent.name = null
      this.queueEvent.item = null
    },
  },
})
