<script>
import BookBoardEntryNew from '@/components/spine/bookboard/BookBoardEntryNew.vue'
import BookBoardEntryUpdate from '@/components/spine/bookboard/BookBoardEntryUpdate.vue'
import BookProgressField from '@/components/booklists/BookProgressField.vue'
import DateFinishedField from '@/components/spine/bookboard/DateFinishedField.vue'
import { DISPLAY_STATE_COMPLETED } from '@/services/endpoints'
import EmptyStateBook from '@/components/svgs/EmptyStateBook.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'
import { useSpineStore } from '@/stores/SpineStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    BookBoardEntryNew,
    BookBoardEntryUpdate,
    BookProgressField,
    DateFinishedField,
    EmptyStateBook,
    MiniLoader,
    ViewLoader,
  },
  emits: ['openMobileNav'],
  setup() {
    return {
      queueStore: useQueueStore(),
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      focusMode: false,
      boardBodyEl: null,
      dragSortOptions: {
        animation: 350,
        delay: 0,
        disabled: false,
        ghostClass: 'sortable-ghost',
      },
      isInitialPageLoad: true,
    }
  },
  computed: {
    isCurrentBookQueueItemCompleted() {
      let completed = false
      if (this.queueStore.currentBookQueueItem)
        completed = this.queueStore.currentBookQueueItem.display_state === DISPLAY_STATE_COMPLETED

      return completed
    },
  },
  mounted() {
    this.boardBodyEl = document.getElementById('board-thread')
    this.adjustScrollHeight()
  },
  methods: {
    adjustScrollHeight() {
      this.$nextTick(() => {
        this.boardBodyEl.scrollTo({
          top: this.boardBodyEl.scrollHeight,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
    handleFocusModeOn() {
      this.focusMode = true
    },
    handleFocusModeOff() {
      this.focusMode = false
    },
    handleNewEntryAdded() {
      this.adjustScrollHeight()
    },
    handleLoadingMoreEntries() {
      this.spineStore.loading.moreEntries = true
      this.isInitialPageLoad = false
      const currentScrollHeight = this.boardBodyEl.scrollHeight
      this.spineStore.getNextPageOfEntries()
        .then(() => {
          this.boardBodyEl.scrollTop = this.boardBodyEl.scrollHeight - currentScrollHeight
          this.spineStore.loading.moreEntries = false
        })
    },
    handleImageLoaded() {
      if (this.isInitialPageLoad)
        this.adjustScrollHeight()
    },
  },
}
</script>

<template>
  <div id="board-thread" class="board-thread">
    <div class="board-thread__bar">
      <div class="board-thread__head">
        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'app-root' }"
        >
          <i class="material-icons board-thread__head__back" @click="navigate">keyboard_arrow_left</i>
        </router-link>
        <h2 class="board-thread__head__title">
          {{ spineStore.bookBoard.book.title }} <span class="light">Book Board</span>
        </h2>
        <i class="material-icons material-icons board-thread__head__context-menu" @click="$emit('openMobileNav')">more_horiz</i>
      </div>
      <div class="board-thread__inputs">
        <div class="board-thread__inputs__meta mar-t-m">
          <div class="board-thread__inputs__meta__item">
            <BookProgressField
              class="board-thread__inputs__meta__item__field"
              :book-queue-item="queueStore.currentBookQueueItem"
              :show-bar="false"
              :is-current-queue-item="true"
            />
          </div>
          <div
            v-if="isCurrentBookQueueItemCompleted"
            class="board-thread__inputs__meta__item"
          >
            <DateFinishedField
              class="board-thread__inputs__meta__item__field"
              :book-queue-item="queueStore.currentBookQueueItem"
              :is-current-queue-item="true"
            />
          </div>
        </div>
        <div class="board-thread__inputs__progress-line">
          <div
            class="board-thread__inputs__progress-line__progress"
            :style="{ width: `${queueStore.currentBookQueueItemProgress}%` }"
          />
        </div>
      </div>
    </div>
    <ViewLoader v-if="spineStore.loading.bookBoard" />
    <div v-else class="board-thread__body" :class="{ focusMode }">
      <div v-if="spineStore.bookBoardEntries.length" class="board-thread__body__wrap">
        <button
          v-if="spineStore.bookBoardEntriesNextURL"
          class="tertiary small board-thread__body__wrap__load-more-btn"
          @click="handleLoadingMoreEntries"
        >
          <span v-if="spineStore.loading.moreEntries">
            <MiniLoader class="board-thread__body__wrap__load-more-btn__loader" color-class="dark" />
          </span>
          <span v-else>
            Load Earlier Entries
            <i class="material-icons board-thread__body__wrap__load-more-btn__arrow">arrow_upward</i>
          </span>
        </button>
        <p v-else class="board-thread__body__wrap__beginning-msg">
          You've reached the beginning of your book board.
        </p>
      </div>
      <BookBoardEntryUpdate
        v-for="entry in spineStore.bookBoardEntries"
        :key="entry.id"
        :entry="entry"
        @focus-mode-on="handleFocusModeOn"
        @focus-mode-off="handleFocusModeOff"
        @image-loaded="handleImageLoaded"
      />
      <transition name="fade">
        <div v-if="!spineStore.bookBoardEntries.length" class="board-thread__body__empty-cta">
          <EmptyStateBook class="board-thread__body__empty-cta__image mar-b-xl" />
          <h3 class="board-thread__body__empty-cta__title">
            Let's Start Reading!
          </h3>
          <p>As you read about meaningful things that you don't want to forget, add them here as notes, and track your page progress along the way.</p>
        </div>
      </transition>
      <BookBoardEntryNew
        class="board-thread__body__new-entry"
        :book-board="spineStore.bookBoard"
        @new-entry-added="handleNewEntryAdded"
      />
      <div class="board-thread__body__focused-shader" :class="{ on: focusMode }" />
      <div class="board-thread__body__focused-nav-shader" :class="{ on: focusMode }" />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.board-thread {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0 7%;

  @include respond-to('medium') {
    padding: 0 5%;
  }

  &__bar {
    position: fixed;
    left: 28rem;
    padding: 4rem 5% 2rem;
    background-color: $color-white;
    z-index: 2;
    width: calc(100% - 28rem);

    @include respond-to('medium') {
      left: 0;
      padding: 2rem 0 1rem;
      text-align: center;
      width: 100%;
    }
  }

  &__head {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__back {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      cursor: pointer;

      @include respond-to('medium') {
        display: block;
      }
    }

    &__context-menu {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      cursor: pointer;

      @include respond-to('medium') {
        display: block;
      }
    }

    &__title {
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
      margin: 0;

      @include font-size(2.5);
    }

    @include respond-to('medium') {
      display: block;

      &__title {
        width: 60%;
        text-align: center;
        font-weight: 600;

        @include font-size(1.4);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: none;
        }
      }
    }
  }

  &__inputs {
    position: relative;

    @include respond-to('medium') {
      padding: 1rem;
    }

    &__meta {
      display: flex;
      flex-flow: row no-wrap;
      justify-content: space-between;

      @include font-size(1.3);

      align-items: center;

      &__item {
        display: inline-block;

        &__field {
          display: inline-block;
        }
      }
    }

    &__progress-line {
      height: 2px;
      width: 100%;
      background-color: $color-white-smoke;

      &__progress {
        width: 0%;
        height: 100%;
        border-radius: 2rem;
        background-color: $color-oxblood;
        transition: all 0.17s ease-in-out;
      }
    }

    @include respond-to('medium') {
      &__progress-line {
        height: 4px;
        width: 100%;
        bottom: 0;
      }
    }
  }

  &__body {
    padding-bottom: 13rem;

    &__wrap {
      margin-top: 16rem;

      @include respond-to('medium') {
        margin-top: 1rem;
      }

      &__load-more-btn {
        position: relative;

        i {
          position: relative;
          top: -0.1rem;
          vertical-align: middle;
          margin-left: 0.5rem;

          @include font-size(1.5);
        }
      }

      &__beginning-msg {
        position: relative;
        color: gray;
      }
    }

    &__empty-cta {
      position: absolute;
      width: 33rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 7rem;
      top: 42%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);

      &__image {
        position: relative;
        width: 9rem;
        margin: 0 auto;
      }

      &__title {
        @include font-size(1.8);

        font-weight: 600;

        @include line-height(2.2);
      }

      @include respond-to('medium') {
        margin-top: 2rem;
      }
    }

    &__new-entry {
      position: fixed;
      bottom: 3rem;
      width: calc(100% - 46rem);
      left: 37rem;
      z-index: 1;
    }

    &__focused-shader {
      position: fixed;
      top: 0;
      left: 28rem;
      width: calc(100% - 28rem);
      height: 100%;
      background-color: rgb(255 255 255 / 100%);
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 50%, 0);
      transition: all 0.35s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      box-shadow: 0 1px 9px 0 rgb(9 0 0 / 8%);

      &.on {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
        z-index: 5;
      }
    }

    &__focused-nav-shader {
      position: fixed;
      top: 0;
      left: 0;
      width: 28rem;
      height: 100%;
      background-color: rgb(255 255 255 / 100%);
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.35s cubic-bezier(0.17, 0.67, 0.83, 0.67);

      &.on {
        opacity: 0.7;
        visibility: visible;
        z-index: 5;
      }
    }

    @include respond-to('medium') {
      padding: 13rem 1.5rem 1rem;

      &__edit {
        bottom: 1.2rem;
        left: 1.5rem;
      }

      &__new-entry {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 1;
      }
    }
  }

  @include respond-to('medium') {
    padding: 0 0 9rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.15s ease-in-out;
  opacity: 1;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
