<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    welcomeQuote: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    quote() {
      return `"${this.welcomeQuote.quote}" - ${this.welcomeQuote.author}`
    },
  },
}
</script>

<template>
  <div v-if="name && welcomeQuote" class="home-greeting">
    <h1 class="home-greeting__hello">
      Hello {{ name }}
    </h1>
    <p class="home-greeting__quote">
      {{ quote }}
    </p>
  </div>
</template>

<style lang='scss' scoped>
.home-greeting {
  position: relative;
  width: calc(100% - 8rem);
  display: inline-block;
  padding: 3rem 6rem 4rem 0;
  margin-top: 0;
  text-align: left;

  &__hello {
    margin: 0;
    text-transform: capitalize;
  }

  &__quote {
    margin-top: 0;
    color: $color-gray-medium;
  }

  @include respond-to('small') {
    padding: 2.5rem;
    width: 100%;
  }
}
</style>
