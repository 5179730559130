<script>
import StarLeftSide from './StarLeftSide.vue'
import StarRightSide from './StarRightSide.vue'

export default {
  components: {
    StarLeftSide,
    StarRightSide,
  },
  props: {
    starIndex: {
      type: Number,
      required: true,
    },
    hoverState: {
      type: Object,
      required: false,
    },
    selectionState: {
      type: Object,
      required: false,
    },
    inReviewCard: {
      type: Boolean,
      required: false,
    },
    inBookList: {
      type: Boolean,
      required: false,
    },
    selectable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['hovering', 'ratingSelected'],
  data() {
    /*
     * Load initial selection state if selection state is not null.
     */
    const states = this.retrieveSelectionState(this.selectionState)
    return {
      leftActive: false,
      rightActive: false,
      leftSelected: states.leftSelected,
      rightSelected: states.rightSelected,
    }
  },
  watch: {
    hoverState(value) {
      if (value.state) {
        if (value.index > this.starIndex) {
          this.leftActive = true
          this.rightActive = true
        }
        else if (value.index === this.starIndex) {
          if (value.side === 'left') {
            this.leftActive = true
          }
          else if (value.side === 'right') {
            this.leftActive = true
            this.rightActive = true
          }
        }
        else {
          this.leftActive = false
          this.rightActive = false
        }
      }
      else {
        this.leftActive = false
        this.rightActive = false
      }
    },
    selectionState(value) {
      const states = this.retrieveSelectionState(value)
      this.leftSelected = states.leftSelected
      this.rightSelected = states.rightSelected
    },
  },
  methods: {
    handleLeftHover(hoverState) {
      if (this.selectable)
        this.$emit('hovering', { side: 'left', state: hoverState, index: this.starIndex })
    },
    handleRightHover(hoverState) {
      if (this.selectable)
        this.$emit('hovering', { side: 'right', state: hoverState, index: this.starIndex })
    },
    handleLeftRatingSelected(selectionState) {
      if (this.selectable)
        this.$emit('ratingSelected', { side: 'left', state: selectionState, index: this.starIndex })
    },
    handleRightRatingSelected(selectionState) {
      if (this.selectable)
        this.$emit('ratingSelected', { side: 'right', state: selectionState, index: this.starIndex })
    },
    retrieveSelectionState(value) {
      /*
       * This method returns the leftSelected and rightSelected values when selection state is
       * passed in.
       */
      const states = {
        leftSelected: false,
        rightSeleted: false,
      }
      if (value === null)
        return states

      if (value.state) {
        if (value.index > this.starIndex) {
          states.leftSelected = true
          states.rightSelected = true
        }
        else if (value.index === this.starIndex) {
          if (value.side === 'left') {
            states.leftSelected = true
          }
          else if (value.side === 'right') {
            states.leftSelected = true
            states.rightSelected = true
          }
        }
        else if (value.index < this.starIndex) {
          states.leftSelected = false
          states.rightSelected = false
        }
      }
      else {
        if (value.index < this.starIndex) {
          states.leftSelected = false
          states.rightSelected = false
        }
        else if (value.index === this.starIndex) {
          if (value.side === 'left') {
            states.leftSelected = false
            states.rightSelected = false
          }
          else if (value.side === 'right') {
            states.leftSelected = true
            states.rightSelected = false
          }
        }
      }
      return states
    },
  },
}
</script>

<template>
  <div class="icon full-star" :class="{ small: inReviewCard, medium: inBookList }">
    <StarLeftSide
      class="star-left"
      :class="{ active: leftActive, selected: leftSelected, small: inReviewCard, medium: inBookList, selectable }"
      :selection-state="leftSelected"
      @hovering="handleLeftHover"
      @rating-selected="handleLeftRatingSelected"
    />
    <StarRightSide
      class="star-right"
      :class="{ active: rightActive, selected: rightSelected, small: inReviewCard, medium: inBookList, selectable }"
      :selection-state="rightSelected"
      @hovering="handleRightHover"
      @rating-selected="handleRightRatingSelected"
    />
  </div>
</template>

<style lang='scss' scoped>
.full-star {
  width: 3.3rem;

  &.small {
    width: 1.5rem;
    height: 2rem;
  }

  &.medium {
    width: 1.3rem;
    height: 2rem;
  }
}
</style>
