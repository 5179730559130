<script>
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.core.css'

import debounce from 'lodash/debounce'
import formatRelative from 'date-fns/formatRelative'
import parseISO from 'date-fns/parseISO'
import CornerDownRightIcon from '@/components/svgs/CornerDownRightIcon.vue'
import PopConfirm from '@/components/menus/PopConfirm.vue'
import QuillEditor from '@/components/spine/QuillEditor.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useSpineStore } from '@/stores/SpineStore'
import UserAvatar from '@/components/users/UserAvatar.vue'

export default {
  components: {
    CornerDownRightIcon,
    PopConfirm,
    QuillEditor,
    UserAvatar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  emits: ['focusModeOn', 'focusModeOff', 'imageLoaded'],
  setup() {
    return {
      authStore: useAuthStore(),
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      content: '',
      focusMode: false,
      editing: false,
      editorOptions: {
        theme: 'bubble',
        placeholder: 'Add a caption...',
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike', 'blockquote', 'link']],
          magicUrl: true,
        },
      },
      dialogOpen: false,
      showConfirmDelete: false,
    }
  },
  computed: {
    isEditorDisabled() {
      if (this.message.owner.username !== this.authStore.user.username)
        return true

      return !this.editing
    },
    messageImageUrl() {
      let imageUrl = this.message.image_original
      if (this.message.image_thumbnail_small)
        imageUrl = this.message.image_thumbnail_small
      else if (this.message.image_thumbnail_large)
        imageUrl = this.message.image_thumbnail_large

      return imageUrl
    },
    threadMessageCountString() {
      let commentString = 'Replies'
      if (this.message.thread_message_count === 1)
        commentString = 'Reply'

      return `${this.message.thread_message_count} ${commentString}`
    },
    timestamp() {
      return formatRelative(parseISO(this.message.created_at), new Date())
    },
  },
  created() {
    this.debouncedAutoSave = debounce(this.autoSave, 500)
    this.content = this.message.text_content
  },
  unmounted() {
    this.debouncedAutoSave.cancel()
  },
  methods: {
    autoSave() {
      if (this.content !== this.message.text_content && this.content) {
        // Make sure content has changed and that the content is not blank. The content should
        // never be blank on autoupdate.
        this.handleSave()
      }
    },
    handleSave() {
      const updatedMessage = {
        groupId: this.message.chat_group,
        messageId: this.message.id,
        textContent: this.content,
      }
      this.spineStore.updateChatGroupMessage(updatedMessage)
        .catch(() => {
          const message = 'An error occurred while autosaving your message. Please check your connection.'
          this.spineStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
    handleDelete() {
      this.dialogOpen = false
      this.spineStore.removeChatGroupMessage(this.message)
        .then(() => {})
        .catch(() => {
          const message = 'An error occurred while deleting your message. Please try again.'
          this.spineStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
    handleFocusModeOn() {
      this.$emit('focusModeOn')
      setTimeout(() => {
        this.focusMode = true
      }, 400)
    },
    handleFocusModeOff() {
      this.focusMode = false
      setTimeout(() => {
        this.$emit('focusModeOff')
      }, 400)
    },
    handleImageLoad() {
      this.$emit('imageLoaded')
    },
    loadThread(message) {
      this.spineStore.threadParentMessage = message
      this.spineStore.showMessageThread = !this.spineStore.showMessageThread
    },
    onEditorBlur() {
      if (this.message.owner.username !== this.authStore.user.username)
        return

      this.editing = false
    },
    onEditorFocus() {
      if (this.message.owner.username !== this.authStore.user.username)
        return

      this.editing = true
    },
    onEditorReady() {
    },
  },
}
</script>

<template>
  <div
    class="message"
    :class="{ 'focus-mode': focusMode, 'dialog-open': dialogOpen }"
  >
    <div
      class="message__doc"
      :class="{ editing, 'dialog-open': dialogOpen, 'less-padding': message.parent_chat_message }"
    >
      <div class="action-btns">
        <span
          v-if="!message.parent_chat_message && !focusMode"
          class="oi action-btns__expand-icon"
          data-glyph="fullscreen-enter"
          @click="handleFocusModeOn"
        />
        <span
          v-if="!message.parent_chat_message && focusMode"
          class="oi action-btns__de-expand-icon"
          data-glyph="fullscreen-exit"
          @click="handleFocusModeOff"
        />
        <PopConfirm
          v-if="message.owner.username === authStore.user.username"
          class="action-btns__delete-btn"
          :unique-el-id="message.id"
          icon-type="delete"
          ok-text="Yes"
          cancel-text="Cancel"
          horz-align="align-right"
          vert-align="align-top"
          confirm-message="Are you sure you want to delete this message?"
          @show-dialog="dialogOpen = true"
          @hide-dialog="dialogOpen = false"
          @ok="handleDelete"
        />
        <span
          v-if="!message.parent_chat_message"
          class="oi action-btns__thread-btn"
          data-glyph="chat"
          @click="loadThread(message)"
        />
      </div>
      <div class="message__doc__meta">
        <UserAvatar class="message__doc__meta__avatar" :user="message.owner" width="3rem" height="3rem" />
        <div class="message__doc__meta__header">
          <span class="message__doc__meta__header__username">{{ message.owner.friendly_name }}</span>
          <span class="message__doc__meta__header__timestamp">{{ timestamp }}</span>
        </div>
      </div>
      <QuillEditor
        v-model="content"
        :content="content"
        :options="editorOptions"
        :disabled="isEditorDisabled"
        handle=".drag_handle"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
        @change="debouncedAutoSave"
      />
      <div v-if="messageImageUrl" class="message__doc__attachment">
        <img :src="messageImageUrl" @load="handleImageLoad">
      </div>
    </div>
    <div v-if="message.thread_message_count" class="message__thread-count" @click="loadThread(message)">
      <CornerDownRightIcon class="message__thread-count__icon" />
      <span class="message__thread-count__text">{{ threadMessageCountString }}</span>
    </div>
  </div>
</template>

<style lang='scss'>
$action-btn-width: 2.4rem;

.message {
  position: relative;
  border-radius: 0.3rem;
  padding-left: 3.5rem;
  margin-left: -3.5rem;
  margin-top: 1rem;
  transform: translate3d(0, 0, 0);

  .ql-editor {
    padding: 0;
  }

  &.dialog-open {
    z-index: 2;
  }

  &__doc {
    padding: 1.5rem 2.5rem 2.5rem 5.5rem;
    background-color: $color-white;
    border: 1px solid rgb(239 238 237 / 0%);
    border-radius: 0.3rem;
    box-shadow: none;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);

    &.less-padding {
      padding: 1.5rem 2.5rem 1.5rem 5.5rem;
    }

    .action-btns {
      position: absolute;
      right: 0;
      top: 0.8rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      &__expand-icon,
      &__de-expand-icon {
        @include font-size(1);

        width: $action-btn-width;
        text-align: center;
        color: $color-action-button;
        opacity: 0;
        z-index: 2;
        transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);

        @include respond-to('medium') {
          display: none;
        }
      }

      &__delete-btn {
        @include font-size(1.3);

        position: relative;
        text-align: center;
        width: $action-btn-width;
        z-index: 2;
        transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        opacity: 0;

        @include respond-to('medium') {
          right: 0;
        }
      }

      &__thread-btn {
        @include font-size(1.5);

        width: $action-btn-width;
        z-index: 2;
        text-align: center;
        color: $color-action-button;
        transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        opacity: 0;

        @include respond-to('medium') {
          right: 3rem;
        }
      }
    }

    &__meta {
      position: relative;

      &__avatar {
        position: absolute;
        left: -4.5rem;
      }

      &__header {
        &__username {
          font-weight: 600;
          margin-right: 1rem;
        }

        &__timestamp {
          color: $color-dark-gray;

          @include font-size(1.1);

          margin: 0;
          width: 75%;
        }
      }
    }

    p, blockquote, em, strong {
      &::selection {
        background: #fff0f0; /* Gecko Browsers */
      }
    }

    .ql-editor {
      p, h1, h2, h3, h4, h5, h6, i, em, strong {
        cursor: pointer;
      }

      &.ql-blank {
        padding: 0;

        &::before {
          color: $color-gray-dark;
          font-style: normal;
          left: 0;
        }
      }
    }

    &.editing,
    &.dialog-open {
      background-color: $color-white;
      border: 1px solid rgb(239 238 237 / 100%);
      box-shadow: 1px 2px 7px 0 rgb(0 0 0 / 7%);
      z-index: 2;

      p, blockquote, em, strong {
        color: $color-gray-dark;
      }

      .ql-editor {
        p, h1, h2, h3, h4, h5, h6, i, em, strong {
          cursor: text;
        }
      }

      .action-btns__expand-icon,
      .action-btns__de-expand-icon,
      .action-btns__delete-btn,
      .action-btns__thread-btn {
        opacity: 1;
      }

      &:hover {
        background-color: $color-white;

        .action-btns__expand-icon,
        .action-btns__de-expand-icon,
        .action-btns__delete-btn,
        .action-btns__thread-btn {
          opacity: 1;
        }
      }
    }

    &__attachment {
      max-width: 60%;
      border: 1px solid $color-gainsboro;
      border-radius: 0.3rem;
      margin: 1.35rem 0;
      padding: 0.5rem;
    }
  }

  &__thread-count {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: absolute;
    left: 10rem;
    bottom: 0.3rem;
    color: $color-black;
    cursor: pointer;
    border-bottom: none;

    &:hover {
      color: $color-burnt-sienna;
      text-decoration: underline;
    }

    &__icon {
      width: 1.6rem;
    }

    &__text {
      @include font-size(1.2);

      margin-left: 0.5rem;
      margin-bottom: 0.1rem;
    }
  }

  &:hover {
    .message__doc {
      background-color: $color-white-smoke-light;

      &.editing {
        background-color: $color-white;
      }

      .action-btns {
        &__expand-icon,
        &__de-expand-icon,
        &__thread-btn {
          opacity: 0.6;

          &:hover {
            opacity: 1;
            color: $color-black;
          }
        }

        &__delete-btn {
          opacity: 1;
        }
      }
    }
  }

  &.focus-mode {
    position: fixed;
    top: 0;
    left: 31.5rem;
    padding: 0;
    margin-top: 0;
    width: calc(100% - 28rem);
    height: 100%;
    z-index: 20;
    box-shadow: -3px 1px 7px 0 rgb(0 0 0 / 4%);

    .message__doc {
      height: 100%;
      background-color: $color-white;

      .drag_handle {
        opacity: 0;
        visibility: hidden;
      }

      &__meta {
        &__header {
          &__timestamp {
            opacity: 0.5;
          }
        }
      }

      .quill-editor {
        margin-top: 3rem;
        padding: 5rem;

        .ql-container {
          @include font-size(1.59);
        }
      }

      .action-btns {
        &__expand-icon,
        &__de-expand-icon {
          opacity: 0.6;

          &:hover {
            opacity: 1;
            color: $color-black;
          }
        }

        &__delete-btn {
          opacity: 0;
          visibility: hidden;
          transition: none;
        }

        &__thread-btn {
          opacity: 0;
          visibility: hidden;
          transition: none;
        }
      }
    }

    &:hover {
      background-color: $color-white;

      .message__doc {
        background-color: $color-white;
      }
    }
  }
}
</style>
