<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['update:modelValue'],
}
</script>

<template>
  <label :for="value" class="form-control">
    <input
      :id="value"
      type="radio"
      :checked="modelValue === value"
      :value="value"
      v-bind="$attrs"
      @change="$emit('update:modelValue', value)"
    >
    {{ label }}
  </label>
</template>

<style lang="scss">
.form-control {
  font: $body1;

  @include font-size(1.6);

  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;

  @include respond-to('medium') {
    font: $body1;

    @include font-size(1.3);
  }
}
</style>
