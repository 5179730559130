<script>
import { useQueueStore } from '@/stores/QueueStore'

export default {
  props: {
    bookQueueItem: {
      type: Object,
      default: () => {},
    },
    showBar: {
      type: Boolean,
      default: true,
    },
    isCurrentQueueItem: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      editingPageCount: false,
      pageCount: null,
      progressInput: {
        value: null,
        placeholder: '',
      },
      trackByPercentage: null,
    }
  },
  computed: {
    progress() {
      return (this.bookQueueItem.current_page_number / this.bookQueueItem.page_count) * 100
    },
  },
  watch: {
    bookQueueItem() {
      this.pageCount = this.bookQueueItem.page_count
      this.trackByPercentage = this.bookQueueItem.track_by_percentage
      this.percentageOrPages()
    },
  },
  mounted() {
    this.pageCount = this.bookQueueItem.page_count
    this.trackByPercentage = this.bookQueueItem.track_by_percentage
    this.percentageOrPages()
  },
  methods: {
    handleEnter($el) {
      if ($el)
        $el.target.blur()
    },
    saveAdjustedPageCount() {
      if (this.pageCount > 0) {
        this.queueStore.adjustBookPageCount({
          bookQueueItem: this.bookQueueItem,
          adjustedPageCount: this.pageCount,
          isCurrentQueueItem: this.isCurrentQueueItem,
        })
      }
      else {
        // Reset page count to previous sensible number
        this.pageCount = this.bookQueueItem.page_count
      }
      this.editingPageCount = false
    },
    saveProgress() {
      // If we are tracking by percentage, we need to change the input value to pages to
      // interact with the API. We do that here.
      let inputPages = this.progressInput.value
      if (this.trackByPercentage && this.bookQueueItem.page_count) {
        inputPages = Math.floor(
          Math.min(
            this.bookQueueItem.page_count,
            (this.progressInput.value / 100) * this.bookQueueItem.page_count,
          ),
        )
      }
      if (inputPages <= this.bookQueueItem.page_count) {
        if (inputPages) {
          this.queueStore.updateBookProgress({
            bookQueueItem: this.bookQueueItem,
            progress: inputPages,
            isCurrentQueueItem: this.isCurrentQueueItem,
          })
        }
        else {
          this.progressInput.value = 0
          this.queueStore.updateBookProgress({
            bookQueueItem: this.bookQueueItem,
            progress: this.progressInput.value,
            isCurrentQueueItem: this.isCurrentQueueItem,
          })
        }
      }
      else {
        this.progressInput.value = null
      }
    },
    toggleTracking() {
      this.trackByPercentage = !this.trackByPercentage
      this.percentageOrPages()
      this.queueStore.updateTrackByPercentageFlag({
        bookQueueItem: this.bookQueueItem,
        trackByPercentage: this.trackByPercentage,
        isCurrentQueueItem: this.isCurrentQueueItem,
      })
    },
    percentageOrPages() {
      let value
      if (this.trackByPercentage) {
        value = this.bookQueueItem.percent_complete
        this.progressInput.placeholder = 'Pg. %'
      }
      else {
        value = this.bookQueueItem.current_page_number
        this.progressInput.placeholder = 'Pg. #'
      }

      if (this.bookQueueItem.current_page_number === 0)
        value = null

      this.progressInput.value = value
    },
  },
}
</script>

<template>
  <div class="progress-track-group">
    <p class="progress-value">
      <input
        :id="`progress-text-input-${bookQueueItem.id}`"
        v-model.number="progressInput.value"
        class="progress-input"
        type="text"
        :placeholder="progressInput.placeholder"
        @keyup.enter="handleEnter"
        @blur="saveProgress"
      >
      <span v-if="trackByPercentage" class="progress-text">%
        <button class="large link_btn link_btn--secondary" @click="toggleTracking">Percent</button>
      </span>
      <span v-else class="progress-text">of
        <input
          v-if="editingPageCount"
          :id="`total-page-count-${bookQueueItem.id}`"
          v-model.number="pageCount"
          class="progress-input"
          type="text"
          @keyup.enter="handleEnter"
          @blur="saveAdjustedPageCount"
        >
        <button v-else class="large link_btn link_btn--secondary" @click="editingPageCount = !editingPageCount">
          {{ pageCount }}
        </button>
        <button class="large link_btn link_btn--secondary" @click="toggleTracking">Pages</button>
      </span>
    </p>
    <div v-if="showBar" class="progress-bar-wrap">
      <div class="progress-bar" :style="{ width: `${progress}%` }" />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.progress-track-group {
  .progress-bar-wrap {
    position: relative;
    width: calc(100% - 0.5rem);
    height: 0.5rem;
    background-color: $color-gainsboro;
    margin: 0.5rem 0 1.5rem;
    border-radius: 4.1rem;
    overflow: hidden;

    .progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0;
      background-color: $color-oxblood;
      transition: all 0.2s ease-in-out;
    }

    @include respond-to('small') {
      margin: 0.5rem 0;
    }
  }
}

.progress-value {
  position: relative;

  .progress-input {
    position: relative;
    background-color: $color-white-smoke;
    border-radius: 0.3rem;
    border: 0;
    opacity: 1;
    padding: 0 0.3rem 0 0.5rem;
    width: 4rem;
    margin-right: 0.7rem;
    text-align: center;
    display: inline-block;
    vertical-align: middle;

    &:focus {
      border-bottom: 0;
      color: $color-burnt-sienna;
    }
  }

  .progress-text {
    display: inline-block;
    vertical-align: middle;

    .link_btn {
      padding-left: 0;
    }
  }
}
</style>
