<script>
import { formatISO, parseISO } from 'date-fns'

import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    MiniLoader,
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      fileExports: [],
      isError: false,
      isSubmitted: false,
      isSuccess: false,
    }
  },
  created() {
    this.queueStore.getFileExports()
      .then((dataExports) => {
        this.fileExports = dataExports
      })
      .catch(() => {
        this.fileExports = []
        this.queueStore.setAlert({
          type: 'error',
          message: 'We were unable to retrieve your exports. Please reload the page.',
          active: true,
        })
      })
  },
  methods: {
    linkName(fileExport) {
      const exportDateString = formatISO(parseISO(fileExport.created_at), { representation: 'date' })
      return `File exported on ${exportDateString} with ${fileExport.total_count} total books`
    },
    requestNewExport() {
      this.isSubmitted = true
      this.queueStore.requestNewFileExport()
        .then(() => {
          // Create a bit of a delay since this is an empty POST
          setTimeout(() => {
            this.isSuccess = true
            this.isError = !this.isSuccess
            this.isSubmitted = false
          }, 1000)
        })
        .catch((error) => {
          setTimeout(() => {
            this.errorDetail = (error.data && error.data.non_field_errors)
              ? error.data.non_field_errors[0]
              : 'We were unable to process your request. Please try again.'
            this.isSuccess = false
            this.isError = !this.isSuccess
            this.isSubmitted = false
          }, 1000)
        })
    },
  },
}
</script>

<template>
  <div class="file-export">
    <h3>Export your Italic Type bookshelves</h3>
    <p>Click the button below to generate a new CSV file of all of the books from your shelves on Italic Type. Once you generate a new export, we will email you so that you know when to come back to download your new export. Below, always we show your last 5 exports.</p>
    <h4 class="mar-t-l">
      Your Most Recent Exports
    </h4>
    <div v-if="fileExports.length" class="mar-t-m">
      <p
        v-for="fileExport in fileExports"
        :key="fileExport.id"
      >
        <a :href="fileExport.export_file" download>{{ linkName(fileExport) }}</a>
      </p>
    </div>
    <p v-else class="mar-t-m">
      Currently there are no exports to show. Click the button below to create a new export of your book data.
    </p>
    <p v-if="isSuccess" class="file-export__success">
      We are generating your data export and will email just as soon as we are finished processing the file.
    </p>
    <p v-if="isError" class="file-export__error">
      {{ errorDetail }}
    </p>
    <div class="file-export__export-button">
      <BaseButton
        button-size="large"
        button-type="secondary"
        @click="requestNewExport"
      >
        <MiniLoader v-if="isSubmitted" />
        <span v-else>Request New Export</span>
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-export {
  &__export-button {
    padding: 2rem 0;

    button {
      width: 20rem;
    }
  }

  &__success {
    color: $color-racing-green;
    margin-bottom: 0;
  }

  &__error {
    color: $color-error;
    margin-bottom: 0;
  }
}
</style>
