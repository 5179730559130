<script>
import SelectableFullStar from '@/components/svgs/SelectableFullStar.vue'

export default {
  components: {
    SelectableFullStar,
  },
  props: {
    modelValue: [String, Number],
  },
  emits: ['update:modelValue'],
  data() {
    const starSelected = this.initializeStarSelected()
    return {
      starHovered: null,
      starSelected,
    }
  },
  methods: {
    initializeStarSelected() {
      let starSelected = null
      if (this.modelValue !== null && !isNaN(this.modelValue)) {
        const starData = {
          index: null,
          side: null,
          state: false,
        }
        const rating = Number(this.modelValue)
        starData.index = Math.round(this.modelValue)
        if (starData.index < rating) {
          starData.side = 'right'
          starData.state = true
        }
        else {
          if (rating === (starData.index - 0.5)) {
            starData.side = 'left'
            starData.state = true
          }
          else if (rating === starData.index) {
            starData.side = 'right'
            starData.state = true
          }
        }
        starSelected = starData
      }
      return starSelected
    },
    handleStarHover(data) {
      this.starHovered = data
    },
    handleStarSelection(data) {
      this.starSelected = data
      let rating
      if (data.state) {
        if (data.side === 'left')
          rating = data.index - 0.5
        else if (data.side === 'right')
          rating = data.index
      }
      else {
        if (data.side === 'left')
          rating = data.index - 1
        else if (data.side === 'right')
          rating = data.index - 0.5
      }
      this.$emit('update:modelValue', rating)
    },
  },
}
</script>

<template>
  <div class="star-group">
    <SelectableFullStar
      v-for="i in 5"
      :key="i"
      class="star-group__star"
      :star-index="i"
      :hover-state="starHovered"
      :selection-state="starSelected"
      :selectable="true"
      @hovering="handleStarHover"
      @rating-selected="handleStarSelection"
    />
  </div>
</template>

<style lang="scss" scoped>
.star-group {
  &__star {
    display: inline-block;
    position: relative;
  }
}
</style>
