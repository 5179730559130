<script>
import { mapState } from 'pinia'

import AnnouncementBar from '@/components/notifications/AnnouncementBar.vue'
import BookList from '@/components/booklists/BookList.vue'
import CurrentlyReadingBookList from '@/components/booklists/CurrentlyReadingBookList.vue'
import EmptyBookshelfIllustration from '@/components/svgs/EmptyBookshelfIllustration.vue'
import InvitationList from '@/components/invites/InvitationList.vue'
import ReadingGoal from '@/components/ReadingGoal.vue'
import ReadingGoalModal from '@/components/modals/ReadingGoalModal'
import { useAuthStore } from '@/stores/AuthStore'
import { useBooksPagination } from '@/composables/booksPagination'
import { useQueueStore } from '@/stores/QueueStore'
import { useNotificationsStore } from '@/stores/NotificationsStore'
import WelcomeGreeting from '@/components/WelcomeGreeting.vue'

export default {
  components: {
    AnnouncementBar,
    BookList,
    CurrentlyReadingBookList,
    EmptyBookshelfIllustration,
    InvitationList,
    ReadingGoal,
    ReadingGoalModal,
    WelcomeGreeting,
  },
  setup() {
    const { getNextPageOfBooks } = useBooksPagination()
    return {
      authStore: useAuthStore(),
      notificationsStore: useNotificationsStore(),
      queueStore: useQueueStore(),
      getNextPageOfBooks,
    }
  },
  data() {
    return {
      // UI State
      showReadingGoalModal: false,

      // Invitation list configuration
      invitationListMeta: {
        borderTop: false,
        name: 'New Invitations',
        description: 'New invitations just for you.',
      },

      // Currently reading list configuration
      currentlyReadingMeta: {
        borderTop: false,
        name: 'Currently Reading',
        description: 'Books you are reading now.',
      },

      // Queue list configuration
      queueMeta: {
        borderTop: true,
        name: 'Queue',
        description: 'Books you plan to read next.',
        url: '/view-all/queue',
      },

      // Completed list configuration
      completedMeta: {
        borderTop: true,
        name: 'Completed',
        description: 'Books you have finished.',
        url: '/view-all/completed',
      },

      // Paused Configuration
      pausedMeta: {
        borderTop: true,
        name: 'Paused',
        description: 'Books you have put on hold.',
        url: '/view-all/paused',
      },

      // Archived Configuration
      archivedMeta: {
        borderTop: true,
        name: 'Archived',
        description: 'Books you don\'t plan to finish.',
        url: '/view-all/archived',
      },
    }
  },
  computed: {
    ...mapState(useQueueStore, [
      'allBookListsInitialized',
      'completedUpdateState',
      'currentlyReadingUpdateState',
      'invitationsUpdateState',
      'queueUpdateState',
      'readingGoalUpdateState',
    ]),
    userFriendlyName() {
      if (this.authStore.user) {
        return this.authStore.user.name
          ? this.authStore.user.name.split(' ')[0]
          : this.authStore.user.username
      }
      return ''
    },
  },
  watch: {
    currentlyReadingUpdateState: {
      handler(newValue) {
        if (newValue)
          this.queueStore.getUserBookList('currentlyReading')
      },
      immediate: true,
    },
    queueUpdateState: {
      handler(newValue) {
        if (newValue)
          this.queueStore.getUserBookList('queue')
      },
      immediate: true,
    },
    completedUpdateState: {
      handler(newValue) {
        if (newValue)
          this.queueStore.getUserBookList('completed')
      },
      immediate: true,
    },
    invitationsUpdateState: {
      handler(newValue) {
        if (newValue)
          this.queueStore.getInvitations()
      },
      immediate: true,
    },
    allBookListsInitialized: {
      handler(newValue) {
        if (newValue) {
          this.queueStore.loading.bookLists = false
          // Pop in new invitations after a delay so that they pop in after load
          setTimeout(() => {
            this.queueStore.getInvitations()
          }, 500)
        }
      },
      immediate: true,
    },
    readingGoalUpdateState: {
      handler(newValue) {
        if (newValue)
          this.queueStore.getCurrentReadingGoal()
      },
      immediate: true,
    },
  },
  created() {
    // Load in all initial state for the component
    this.notificationsStore.getActiveAnnouncements()
    this.queueStore.getWelcomeQuote()
    this.queueStore.getCurrentReadingGoal()
    if (this.allBookListsInitialized)
      this.queueStore.getInvitations()

    // We only want to load these once since this is expensive. If null, load them up
    if (this.queueStore.completedBooks === null)
      this.queueStore.getUserBookList('completed')

    if (this.queueStore.currentlyReadingBooks === null)
      this.queueStore.getUserBookList('currentlyReading')

    if (this.queueStore.queueBooks === null)
      this.queueStore.getUserBookList('queue')

    if (this.queueStore.pausedBooks === null)
      this.queueStore.getUserBookList('paused')

    if (this.queueStore.archivedBooks === null)
      this.queueStore.getUserBookList('archived')

    if (this.queueStore.isbnListAllBooks === null)
      this.queueStore.getIsbnList()
  },
}
</script>

<template>
  <div class="home-queues">
    <div class="home-queues__announcement">
      <transition name="fade">
        <AnnouncementBar v-if="notificationsStore.activeAnnouncement" />
      </transition>
    </div>
    <div class="home-queues__header">
      <WelcomeGreeting
        :name="userFriendlyName"
        :welcome-quote="queueStore.welcomeQuote"
      />
      <ReadingGoal
        class="home-queues__header__goal"
        :goal="queueStore.readingGoal"
        @set-reading-goal="showReadingGoalModal = true"
      />
    </div>
    <transition name="fade">
      <div v-if="queueStore.allBookListsEmpty" class="empty-cta">
        <EmptyBookshelfIllustration class="empty-cta__image mar-b-xl" />
        <h3 class="empty-cta__title mar-b-l">
          Get started by filling up your bookshelves with what's on your list to read next. Search for books to add to your queue.
        </h3>
        <router-link
          v-slot="{ navigate }"
          custom
          :to="{ name: 'search' }"
        >
          <button @click="navigate">
            Search Books
          </button>
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <InvitationList
        v-if="queueStore.invitations && queueStore.invitations.length"
        :invitation-list="queueStore.invitations"
        :invitations-total-count="queueStore.invitationsCount"
        :invitation-list-meta="invitationListMeta"
      />
    </transition>
    <CurrentlyReadingBookList
      :list="queueStore.currentlyReadingBooks"
      :list-total-count="queueStore.currentlyReadingBooksCount"
      :list-meta="currentlyReadingMeta"
    />
    <BookList
      :list="queueStore.queueBooks"
      :list-total-count="queueStore.queueBooksCount"
      :list-meta="queueMeta"
      :load-more-active="queueStore.queueBooksNextURL !== null"
      list-type="queue"
      @load-more-books="getNextPageOfBooks('queue')"
    />
    <BookList
      :list="queueStore.completedBooks"
      :list-total-count="queueStore.completedBooksCount"
      :list-meta="completedMeta"
      :load-more-active="queueStore.completedBooksNextURL !== null"
      list-type="completed"
      @load-more-books="getNextPageOfBooks('completed')"
    />
    <BookList
      :list="queueStore.pausedBooks"
      :list-total-count="queueStore.pausedBooksCount"
      :list-meta="pausedMeta"
      :load-more-active="queueStore.pausedBooksNextURL !== null"
      list-type="paused"
      @load-more-books="getNextPageOfBooks('paused')"
    />
    <BookList
      :list="queueStore.archivedBooks"
      :list-total-count="queueStore.archivedBooksCount"
      :list-meta="archivedMeta"
      :load-more-active="queueStore.archivedBooksNextURL !== null"
      list-type="archived"
      @load-more-books="getNextPageOfBooks('archived')"
    />
    <transition name="scale-fade">
      <ReadingGoalModal
        v-if="showReadingGoalModal"
        :goal="queueStore.readingGoal"
        @close="showReadingGoalModal = false"
      />
    </transition>
  </div>
</template>

<style lang='scss' scoped>
.home-queues {
  text-align: right;

  &__header {
    position: relative;
    padding: 0 6rem 0 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to('medium') {
      padding: 1.25rem 2.5rem 0;
      flex-direction: column;

      &__goal {
        order: -1;
      }
    }

    @include respond-to('small') {
      padding: 0;
    }
  }

  &__announcement {
    position: relative;
    padding: 0 6rem 0 8rem;

    @include respond-to('medium') {
      padding: 0 6rem 1.25rem;
    }

    @include respond-to('small') {
      padding: 1.25rem 2.5rem;
    }
  }
}

.empty-cta {
  width: 84rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 4rem;
  position: relative;

  &__image {
    width: 24rem;
    margin: 0 auto;
  }

  &__title {
    font: $title1-medium;
  }

  &__btn {
    border-bottom: 1px solid $color-section-head-border;
  }

  @include respond-to('medlarge') {
    padding: 1.25rem 2.5rem 2.5rem;
    margin-top: 2rem;
    width: 100%;

    &__image {
      width: 20rem;
      position: relative;
      margin: 0 auto;
    }

    &__title {
      @include font-size(1.25);

      font: $title1-small;
    }
  }
}

.scale-fade-enter-active, .scale-fade-leave-active {
  transition: all 0.22s ease-in-out;
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
}

.scale-fade-enter-from, .scale-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, 30px, 0) scale(1.2);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.22s ease-in-out;
  opacity: 1;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
