<script>
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { email } from '@/utilities/validators'
import { useAuthStore } from '@/stores/AuthStore'

export default {
  setup() {
    return {
      authStore: useAuthStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      email: '',
      errors: {},
      emailSent: false,
      submitted: false,
    }
  },
  validations() {
    return {
      email: {
        email: helpers.withMessage('Please enter a valid email.', email),
        required: helpers.withMessage('Email is required.', required),
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length)
          this.errors = {}
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        const formData = {
          email: this.email,
        }
        this.authStore.resetPassword(formData)
          .then(() => {
            this.emailSent = true
          },
          (error) => {
            this.errors = error.data || {}
            this.submitted = false
          })
      }
    },
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-based-page">
      <div class="user-form">
        <template v-if="emailSent">
          <div class="user-form__head">
            <h1>Email Sent</h1>
            <p>
              Check your email for instructions on how to reset your password. Once you have reset your password, click to <router-link :to="{ name: 'signin' }">
                Sign In
              </router-link>
            </p>
          </div>
        </template>
        <template v-else>
          <div class="user-form__head">
            <h1>Forgot Password?</h1>
            <p>Enter your email address below to have us send you an email with instructions for resetting your password.</p>
          </div>
          <div class="user-form__alerts">
            <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
              Please correct the following errors:
            </p>
          </div>
          <form @submit.prevent="onSubmit">
            <div class="user-form__input-wrap">
              <BaseInput
                v-model.trim="email"
                type="text"
                placeholder="Email"
                :class="{ error: v$.email.$error }"
                @blur="v$.email.$touch()"
              />
              <template v-if="v$.email.$error">
                <p
                  v-for="error in v$.email.$errors"
                  :key="error.$uid"
                  class="form-error-msg--absolute"
                >
                  {{ error.$message }}
                </p>
              </template>
              <div v-if="errors.email" class="form-error-msg">
                <p v-for="(error, index) in errors.email" :key="index">
                  {{ error }}
                </p>
              </div>
            </div>
            <BaseButton
              class="user-form__submit-btn"
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="v$.$errors.length || submitted"
            >
              Submit
            </BaseButton>
          </form>
          <p>
            You remember? <router-link :to="{ name: 'signin' }">
              Sign In
            </router-link>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
