import axios from 'axios'

import {
  API_BASE_URL,
  ENDPOINT_ACTIONS,
  ENDPOINT_INVITATIONS,
} from './endpoints'

// Gets
function getInvitations() {
  return axios.get(API_BASE_URL + ENDPOINT_INVITATIONS)
}

// Patches
function patchInvitation(invitationId, data) {
  return axios.patch(`${API_BASE_URL + ENDPOINT_INVITATIONS + invitationId}/`, data)
}

// Posts
function postAction(data) {
  return axios.post(API_BASE_URL + ENDPOINT_ACTIONS, data)
}

const invitesServiceModule = {
  getInvitations,
  patchInvitation,
  postAction,
}

export default invitesServiceModule
