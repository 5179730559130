<script>
export default {
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    align: {
      type: String,
      default: 'align-right',
    },
    vertAlign: {
      type: String,
      default: 'align-top',
    },
  },
  emits: ['menuItemClicked'],
  data() {
    return {
      open: false,
    }
  },
  computed: {
    menuLinks() {
      const links = this.menuItems.filter((item) => {
        return item.url
      })
      return links
    },
    menuActions() {
      const actions = this.menuItems.filter((item) => {
        return item.emit
      })
      return actions
    },
    alignmentClasses() {
      return `${this.align} ${this.vertAlign}`
    },
    transitionName() {
      if (this.vertAlign === 'align-bottom')
        return 'up-fade'
      else
        return 'drop-fade'
    },
  },
  mounted() {
    document.addEventListener('click', (evt) => {
      if (!evt.target.closest('#pop-menu-container'))
        this.open = false
    })
  },
  methods: {
    handleEmit(emitterEvent) {
      this.$emit('menuItemClicked', emitterEvent)
    },
  },
}
</script>

<template>
  <div id="pop-menu-container" class="pop-menu-container">
    <div class="trigger-slot-wrap" @click="open = !open">
      <slot class="menu_trigger" />
    </div>
    <transition :name="transitionName">
      <div v-show="open" class="pop_menu" :class="alignmentClasses">
        <ul @click="open = !open">
          <router-link
            v-for="(item, i) in menuLinks"
            :key="i"
            v-slot="{ navigate }"
            custom
            :to="item.url"
          >
            <li :class="item.textColorClass" @click="navigate">
              {{ item.name }}
            </li>
          </router-link>
          <li
            v-for="(item, j) in menuActions"
            :key="`${j}-emitters`"
            :class="item.textColorClass"
            @click="handleEmit(item.emit)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<style lang='scss' scoped>
.menu_trigger {
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.pop_menu {
  position: absolute;
  top: 3.5rem;
  width: 15rem;
  padding: 1rem 0.8rem;
  background-color: $color-white;
  box-shadow: $pop-menu-shadow;
  z-index: 2;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-white;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: 0.5rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: $color-seashell;
        border-radius: 0.25rem;
      }
    }
  }

  &.align-left {
    left: 0;

    &::before {
      left: 10%;
    }
  }

  &.align-right {
    right: 0;

    &::before {
      left: 80%;
    }
  }

  &.align-bottom {
    top: -100%;
    transform: translateY(-120%);

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -18px;
      top: auto;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid $color-white;
    }
  }
}

.pop-menu-container {
  .trigger-slot-wrap {
    cursor: pointer;
  }
}

.text-color--destructive {
  color: $color-pop-menu-destructive;
}

.drop-fade-enter-active, .drop-fade-leave-active {
  transition: all 0.15s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.drop-fade-enter-from, .drop-fade-leave-to {
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}

.up-fade-enter-active, .up-fade-leave-active {
  transition: all 0.15s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.up-fade-enter-from, .up-fade-leave-to {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}
</style>
