<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    bgColor: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue'],
}
</script>

<template>
  <div>
    <label v-if="label" class="label">{{ label }}</label>
    <input
      :class="bgColor"
      :value="modelValue"
      v-bind="{
        ...$attrs,
        onInput: (event) => $emit('update:modelValue', event.target.value),
      }"
    >
  </div>
</template>

<style lang="scss">
.label {
  text-align: left;

  @include font-size(1.2);

  font: $body2;
  margin-bottom: 0.5rem;
  display: block;
}

.inverted {
  background-color: $color-white !important;
}
</style>
