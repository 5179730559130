<script>
import ExploreBookList from '@/components/booklists/ExploreBookList.vue'
import { useQueueStore } from '@/stores/QueueStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ExploreBookList,
    ViewLoader,
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      isLoading: true,
    }
  },
  created() {
    if (this.queueStore.isbnListAllBooks === null)
      this.queueStore.getIsbnList()

    if (this.queueStore.unrolledCuratedLists === null) {
      this.fetchLists()
    }
    else {
      // The lists are already loaded
      this.isLoading = false
    }
  },
  methods: {
    fetchLists() {
      const getRolledUpLists = this.queueStore.getRolledUpCuratedLists()
      const getUnrolledLists = this.queueStore.getUnrolledCuratedLists()
      Promise.all([getRolledUpLists, getUnrolledLists])
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          const message = 'An error occurred retrieving book lists. Please try again.'
          this.queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div class="explore-queues">
    <ViewLoader v-if="isLoading" class="explore-queues-loader" />
    <template v-else>
      <ExploreBookList
        v-for="(curatedList, i) in queueStore.unrolledCuratedLists"
        :key="curatedList.id"
        class="explore-queues__unrolled"
        :top-list="i === 0"
        :curated-list="curatedList"
      />
    </template>
  </div>
</template>

<style lang='scss' scoped>
.explore-queues {
  &__unrolled {
    text-align: right;
  }
}

.explore-queues-loader {
  background-color: $color-queue-background;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
