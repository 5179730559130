<script>
import BookCardOverlay from '@/components/booklists/BookCardOverlay'
import BookLabel from '@/components/booklists/BookLabel.vue'
import BookProgressField from '@/components/booklists/BookProgressField.vue'
import LongArrow from '@/components/svgs/LongArrow.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import PopMenu from '@/components/menus/PopMenu.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    BookCardOverlay,
    BookLabel,
    BookProgressField,
    LongArrow,
    MiniLoader,
    PopMenu,
  },
  inject: [
    'currentlyReadingContextMenu',
    'currentlyReadingCustomEvents',
  ],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      contextMenu: this.currentlyReadingContextMenu,
      customEvents: this.currentlyReadingCustomEvents,
    }
  },
  computed: {
    isItemLoading() {
      return (this.queueStore.loading.userBookAction.item
        && this.queueStore.loading.userBookAction.item.id === this.item.id)
    },
    isUpdatingCurrentQueueItem() {
      let updatingCurrentQueueItem = false
      if (this.queueStore.currentBookQueueItem
        && this.queueStore.currentBookQueueItem.id === this.item.id)
        updatingCurrentQueueItem = true

      return updatingCurrentQueueItem
    },
  },
  methods: {
    proxyEvent(eventName) {
      /* The proxyEvent method makes sure that the eventName is included in customEvents before
       * we dispatch via global state instead of via event emitters.
       */
      if (this.customEvents.includes(eventName))
        this.queueStore.setQueueEvent({ name: eventName, item: this.item })
    },
  },
}
</script>

<template>
  <div class="book">
    <router-link
      v-slot="{ navigate }"
      custom
      :to="{ name: 'bookboard', params: { bookSlug: item.book.slug } }"
    >
      <div
        class="book__cover-link"
        :style="{ backgroundImage: `url(${item.book.cover_image_url})` }"
        @click="navigate"
      >
        <transition name="fade">
          <div
            v-if="isItemLoading"
            class="book__cover-link__loading-wrap"
          >
            <MiniLoader color-class="light" class="book__cover-link__loading-wrap__loader" />
          </div>
        </transition>
        <div class="book__cover-link__rollover">
          <BookCardOverlay />
        </div>
      </div>
    </router-link>
    <div class="book__meta">
      <h4 class="medium book__meta__title">
        {{ item.book.title }}
      </h4>
      <p class="book__meta__author">
        by <span v-for="(author, i) in item.book.authors" :key="i">{{ author }}{{ (item.book.authors.length - 1) > i ? ', ' : '' }}</span>
      </p>
      <PopMenu
        class="book__meta__context-menu"
        :menu-items="contextMenu"
        align="align-right"
        @menu-item-clicked="proxyEvent"
      >
        <i class="material-icons book__meta__context-menu__icon">more_horiz</i>
      </PopMenu>
      <BookLabel
        :book-queue-item="item"
        class="mar-b-l book__meta__label"
      />
      <BookProgressField
        :book-queue-item="item"
        :is-current-queue-item="isUpdatingCurrentQueueItem"
        class="book__meta__progress"
      />
      <router-link
        :to="{ name: 'bookboard', params: { bookSlug: item.book.slug } }"
        class="book__meta__book-board-arrow"
      >
        <p class="book__meta__book-board-arrow--left">
          Go to Book Board
        </p>
        <LongArrow class="book__meta__book-board-arrow--right" />
      </router-link>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book {
  position: relative;
  text-align: left;
  width: 38.5rem;
  height: 26rem;
  display: flex;
  margin-right: 6rem;
  background-color: $color-white;
  border: 0.8rem solid $color-white;
  box-shadow: 0 0 20px 1px rgb(43 36 13 / 8%);

  &__cover-link {
    position: relative;
    width: 18.25rem;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 1;

    &__loading-wrap {
      @include full-wh;

      background-color: rgb(0 0 0 / 70%);

      &__loader {
        position: relative;
        display: block;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%) scale(1.2);
        left: 10%;
      }
    }

    &__rollover {
      z-index: 1;
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }

    &__rollover:hover {
      opacity: 0.9;
    }
  }

  &__meta {
    position: relative;
    width: 21rem;
    padding: 1.5rem;

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0.4rem;
    }

    &__author {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__context-menu {
      position: absolute;
      top: 0.3rem;
      right: 0;
      z-index: 3;

      &__icon {
        position: absolute;
        top: 0;
        right: 0;

        @include font-size(2);
      }
    }

    &__progress {
      position: absolute;
      bottom: 4.5rem;
      left: 1.5rem;
      width: 100%;
      padding-right: 2rem;
    }

    &__book-board-arrow {
      width: calc(100% - 2.2rem);
      position: absolute;
      bottom: 0.3rem;
      text-decoration: none;
      color: $color-black;
      display: flex;

      &--left {
        flex: 0 0 65%;
      }

      &--right {
        padding-top: 0.5rem;
        flex: 1;
      }
    }
  }

  @include respond-to('small') {
    margin-bottom: 2rem;
  }
}

@include respond-to('small') {
  .book {
    width: 100%;
    height: 18rem;
    box-shadow: 0 2px 5px 0 rgb(43 36 13 / 11%);

    &__cover-link {
      width: 11.25rem;
    }

    &__meta {
      width: calc(100% - 11.25rem);
      padding: 0.5rem 1.5rem 1.5rem;

      &__progress {
        bottom: 3.5rem;
      }
    }
  }
}

@include respond-to('xsmall') {
  .book {
    &__meta {
      &__progress {
        padding-right: 0;
        position: relative;
        left: 0;
        bottom: 0;
      }

      &__book-board-arrow {
        display: none;
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
