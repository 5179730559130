import axios from 'axios'

import { isObjectEmpty } from '@/utilities/empty'
import {
  API_BASE_URL,
  BY_SEARCH_QUERY,
  CODEX_SOURCE_NAME,
  CODEX_SOURCE_NAME_ID,
  ENDPOINT_SEARCH,
  GOOGLE_BOOKS_API_KEY_QUERY_STRING,
  GOOGLE_BOOKS_BASE_URL,
  GOOGLE_BOOKS_ENDPOINT_VOLUMES,
  GOOGLE_BOOKS_MAX_RESULTS_QUERY_STRING,
  GOOGLE_BOOKS_SEARCH_QUERY,
  GOOGLE_BOOKS_SOURCE_NAME,
  GOOGLE_BOOKS_SOURCE_NAME_ID,
  MANUAL_WEB_SCRAPE_SOURCE_NAME,
  MANUAL_WEB_SCRAPE_SOURCE_NAME_ID,
} from '@/services/endpoints'
import booksService from '@/services/booksService'

/* This function transforms a google book item returned from the API into the same format as is
 * being returned from the Italic Type search interface so that we can switch between the two
 * API formats easily. It obviously normalizes Google Books format into the Italic Type format
 * since this is the longer term solution.
 *
 * Here is the mapping:
 *
 *  source_name:       'GoogleBooksV1'
 *  source_name_id:    '1'
 *  source_uid:        item.id
 *  source_url:        item.selfLink
 *  title:             item.volumeInfo.title
 *  subtitle:          item.volumeInfo.subtitle
 *  authors:           item.volumeInfo.authors (array of authors)
 *  publisher:         item.volumeInfo.publisher
 *  published_date:    item.volumeInfo.publishedDate
 *  description:       item.volumeInfo.description
 *  isbn_10:           item.volumeInfo.industryIdentifiers.ISBN_10
 *  isbn_13:           item.volumeInfo.industryIdentifiers.ISBN_13
 *  page_count:        item.volumeInfo.pageCount
 *  binding:           'N/A'
 *  cover_image_url:   item.volumeInfo.imageLinks.thumbnail
 *  language:          item.volumeInfo.language
 *
 */
function transformGoogleBooksItem(item) {
  // Function to extract ISBN numbers from industry identifiers.
  const isbn = (isbnType, item) => {
    let result = ''
    if (item && item.volumeInfo.industryIdentifiers) {
      const identifiers = item.volumeInfo.industryIdentifiers.filter((ident) => {
        return ident.type === isbnType
      })
      if (identifiers.length > 0)
        result = identifiers[0].identifier
    }
    return result
  }

  // Function to get the cover image URL from the item
  const coverImageUrl = (item) => {
    let imageUrl
    const imageLinks = item.volumeInfo.imageLinks
    if (typeof imageLinks !== 'undefined') {
      imageUrl = imageLinks.thumbnail || imageLinks.smallThumbnail
      imageUrl = imageUrl.replace('&edge=curl', '')
    }
    else {
      imageUrl = ''
    }
    return imageUrl
  }

  return {
    source_name: GOOGLE_BOOKS_SOURCE_NAME,
    source_name_id: GOOGLE_BOOKS_SOURCE_NAME_ID,
    source_uid: item.id,
    source_url: item.selfLink,
    title: item.volumeInfo.title,
    subtitle: item.volumeInfo.subtitle,
    authors: item.volumeInfo.authors,
    publisher: item.volumeInfo.publisher,
    published_date: item.volumeInfo.publishedDate,
    description: item.volumeInfo.description,
    isbn_10: isbn('ISBN_10', item),
    isbn_13: isbn('ISBN_13', item),
    page_count: item.volumeInfo.pageCount,
    binding: 'N/A',
    cover_image_url: coverImageUrl(item),
    language: item.volumeInfo.language,
  }
}

/* This function transforms a Codex item returned from the elastic search API into the same
 * format as is being returned from the Italic Type book model so that we can switch between the
 * two API formats easily.
 *
 * Here is the mapping:
 *
 *  source_name:       'ItalicTypeCodex'
 *  source_name_id:    '0'
 *  source_uid:        item.source_uid
 *  source_url:        API_BASE_URL + ENDPOINT_SEARCH + item.source_uid
 *  title:             item.title
 *  subtitle:          item.subtitle
 *  authors:           item.author_list (split on ';' to create an array)
 *  publisher:         item.publisher
 *  published_date:    item.pubdate
 *  description:       item.annotation
 *  isbn_10:           item.isbn10
 *  isbn_13:           item.isbn
 *  page_count:        item.pagecount
 *  binding:           item.binding
 *  cover_image_url:   item.cover_art_image
 *  language:          item.currlanguage
 *
 */
function transformCodexItem(item) {
  return {
    source_name: CODEX_SOURCE_NAME,
    source_name_id: CODEX_SOURCE_NAME_ID,
    source_uid: item.source_uid,
    source_url: `${API_BASE_URL}${ENDPOINT_SEARCH}${item.source_uid}`,
    title: item.title,
    subtitle: item.subtitle,
    authors: item.author_list.split(';'),
    publisher: item.publisher,
    published_date: item.pubdate,
    description: item.annotation,
    isbn_10: item.isbn10,
    isbn_13: item.isbn,
    page_count: item.pagecount,
    binding: item.binding,
    cover_image_url: item.cover_art_image,
    language: item.currlanguage,
  }
}

/* This function transforms a book queue item found in a users queue into the format that we
 * expect for searched book objects. This is done so that we can reduce our API calls to Google
 * and our own search service.
 *
 * Here is the mapping:
 *
 *  source_name:       item.book.source_name
 *  source_name_id:    sourceNameId (passed as argument)
 *  source_uid:        item.book.source_uid
 *  source_url:        '' not specified since this is obfuscated to protect data paths
 *  title:             item.book.title
 *  subtitle:          item.book.subtitle
 *  authors:           item.book.authors
 *  publisher:         item.book.publisher
 *  published_date:    item.book.published_date
 *  description:       item.book.description
 *  isbn_10:           item.book.isbn_10
 *  isbn_13:           item.book.isbn_13
 *  page_count:        item.book.page_count
 *  binding:           item.book.binding
 *  cover_image_url:   item.book.cover_image_url
 *  language:          item.book.language
 *
 */
export function transformBook(book, sourceNameId) {
  return {
    source_name: book.source_name,
    source_name_id: sourceNameId,
    source_uid: book.source_uid,
    source_url: '', // We don't have the source_url, since we hide this from book queue items
    title: book.title,
    subtitle: book.subtitle,
    authors: book.authors,
    publisher: book.publisher,
    published_date: book.published_date,
    description: book.description,
    isbn_10: book.isbn_10,
    isbn_13: book.isbn_13,
    page_count: book.page_count,
    binding: book.binding,
    cover_image_url: book.cover_image_url,
    language: book.language,
  }
}

const _axios = axios.create()

/* This function annotates the query for GoogleBooks searches. It currently only handles queries
 * for the ISBN number. In GoogleBooks, if you want to do an ISBN query, you can pass the string
 * isbn+{isbnString} instead of the regular query string. In this case, if the string is all
 * digits, single word, and length >= 10, it will prepend `isbn+` to the query string.
 *
 */
function annotateGoogleBooksQuery(queryString) {
  let newQuery = queryString
  const parts = queryString.split('+')
  if (parts.length === 1) {
    // remove '-' in case the user splits isbn into parts
    const isbn = parts[0].replace(/-/g, '')
    if (/^\d+$/.test(isbn) && isbn.length >= 10) {
      // Only if the query string is only digits and the length is greater than 10 do we
      // annotate.
      newQuery = `isbn:${isbn}`
    }
  }
  return newQuery
}

function getGoogleBooksAPIKey(settings) {
  let apiKey = GOOGLE_BOOKS_API_KEY_QUERY_STRING
  if (!isObjectEmpty(settings) && settings.GOOGLE_BOOKS_CLIENT_API_KEY)
    apiKey = `&key=${settings.GOOGLE_BOOKS_CLIENT_API_KEY}`

  return apiKey
}

function getSourceNameForId(sourceNameId) {
  let sourceName = GOOGLE_BOOKS_SOURCE_NAME
  switch (sourceNameId) {
    case CODEX_SOURCE_NAME_ID:
      sourceName = CODEX_SOURCE_NAME
      break
    case GOOGLE_BOOKS_SOURCE_NAME_ID:
      sourceName = GOOGLE_BOOKS_SOURCE_NAME
      break
    case MANUAL_WEB_SCRAPE_SOURCE_NAME_ID:
      sourceName = MANUAL_WEB_SCRAPE_SOURCE_NAME
      break
  }
  return sourceName
}

function searchGoogleBooks(settings, payload) {
  return new Promise((resolve, reject) => {
    const APIKeyQueryString = getGoogleBooksAPIKey(settings)
    const queryUrlParts = [
      GOOGLE_BOOKS_BASE_URL,
      GOOGLE_BOOKS_ENDPOINT_VOLUMES,
      GOOGLE_BOOKS_SEARCH_QUERY,
      annotateGoogleBooksQuery(payload.query),
      GOOGLE_BOOKS_MAX_RESULTS_QUERY_STRING,
      APIKeyQueryString,
    ]
    _axios.get(queryUrlParts.join(''))
      .then((response) => {
        let results = []
        if (response.data.totalItems > 0)
          results = response.data.items.map(transformGoogleBooksItem)

        resolve(results)
      },
      (error) => {
        reject(error.response)
      })
  })
}

function searchSingleGoogleBook(settings, sourceUid) {
  return new Promise((resolve, reject) => {
    const APIKeyQueryString = getGoogleBooksAPIKey(settings).replace('&', '?')
    _axios.get(
      `${GOOGLE_BOOKS_BASE_URL}${GOOGLE_BOOKS_ENDPOINT_VOLUMES}/${sourceUid}${APIKeyQueryString}`,
    )
      .then((response) => {
        resolve(transformGoogleBooksItem(response.data))
      },
      (error) => {
        reject(error.response)
      })
  })
}

function searchCodexBooks(payload) {
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}${ENDPOINT_SEARCH}${BY_SEARCH_QUERY}${payload.query}`)
      .then((response) => {
        const results = response.data.map(transformCodexItem)
        resolve(results)
      },
      (error) => {
        reject(error.response)
      })
  })
}

function searchSingleCodexBook(sourceUid) {
  return new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}${ENDPOINT_SEARCH}${sourceUid}`)
      .then((response) => {
        resolve(transformCodexItem(response.data))
      },
      (error) => {
        reject(error.response)
      })
  })
}

function searchBooks(settings, payload) {
  const searchEngine = settings.BOOK_SEARCH_SOURCE_NAME || GOOGLE_BOOKS_SOURCE_NAME
  if (searchEngine === CODEX_SOURCE_NAME) {
    return searchCodexBooks(payload)
  }
  else {
    // Defaults to Google Books for now, but this will change hopefully :)
    return searchGoogleBooks(settings, payload)
  }
}

/*
 * For manually scraped books, we aren't really needing to search anything except for just querying the
 * database for the book. These books are just in the local cache and do not need to be searched to
 * be retrieved. For these books, the sourceUid is the same as the book ID in the database.
 *
 */
function retrieveCachedBook(sourceUid) {
  return new Promise((resolve, reject) => {
    booksService.getBook(sourceUid)
      .then((response) => {
        resolve(transformBook(response.data, MANUAL_WEB_SCRAPE_SOURCE_NAME_ID))
      })
      .catch((error) => {
        reject(error.response)
      })
  })
}

function searchSingleBook(settings, sourceNameId, sourceUid) {
  if (sourceNameId === CODEX_SOURCE_NAME_ID) {
    return searchSingleCodexBook(sourceUid)
  }
  else if (sourceNameId === GOOGLE_BOOKS_SOURCE_NAME_ID) {
    return searchSingleGoogleBook(settings, sourceUid)
  }
  else if (sourceNameId === MANUAL_WEB_SCRAPE_SOURCE_NAME_ID) {
    return retrieveCachedBook(sourceUid)
  }
  else {
    // Unknown source name ID
    return Promise.reject(new Error('Unknown source name ID for book search.'))
  }
}

const searchModule = {
  getSourceNameForId,
  searchBooks,
  searchGoogleBooks,
  searchSingleBook,
  searchSingleGoogleBook,
  searchCodexBooks,
  searchSingleCodexBook,
}

export default searchModule
