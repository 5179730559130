<script>
export default {
}
</script>

<template>
  <div class="hero">
    <div class="hero__image" />
    <div class="hero__text-block">
      <h1 class="hero__text-block__title">
        All books. No ads.
      </h1>
      <h2 class="hero__text-block__subtitle">
        That's our Italic Type promise.
      </h2>
      <p class="hero__text-block__body">
        Dear Reader, this platform is for you. Chip in to help us grow. And together we'll build the platform of our collective dreams.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hero {
  position: relative;

  &__image {
    background-image: url('~@/assets/payments-hero-desktop.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 40rem;
  }

  &__text-block {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10rem;
    margin: 0 auto;
    width: 55%;
    height: 40rem;

    &__body {
      @include font-size(1.5);
    }
  }

  @include respond-to('medium') {
    &__image {
      background-image: url('~@/assets/payments-hero-mobile.png');
      height: 25rem;
    }

    &__text-block {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3rem;
      width: 100%;
      height: auto;

      &__body {
        text-align: center;

        @include font-size(1.3);
      }
    }
  }
}
</style>
