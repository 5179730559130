<script>
export default {
  props: {
    borderTop: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'List name...',
    },
    description: {
      type: String,
      reqiured: false,
    },
    listUrl: {
      type: String,
      required: false,
    },
    listLength: {
      type: Number,
      default: 0,
    },
    listTotalCount: {
      type: Number,
      default: 0,
    },
    listTypeObject: {
      type: String,
      default: 'Book',
    },
  },
  computed: {
    sectionHeadClass() {
      let className = ''
      if (this.name)
        className = this.name.toLowerCase().split(' ').join('-')

      return className
    },
    listCountString() {
      let countString
      if (this.listLength >= this.listTotalCount) {
        countString = `${this.listLength} \
          ${this.listTypeObject}${this.pluralCondition(this.listLength)}`
      }
      else {
        countString = `${this.listLength} of ${this.listTotalCount} \
          ${this.listTypeObject}${this.pluralCondition(this.listLength)}`
      }
      return countString
    },
  },
  methods: {
    pluralCondition(listLength) {
      if (listLength !== 0)
        return listLength > 1 ? 's' : ''
      else
        return 's'
    },
  },
}
</script>

<template>
  <div class="section-head" :class="{ 'section-head--bt': borderTop }">
    <div class="section-head__titling">
      <h2 v-if="name" class="section-head__titling__name">
        {{ name }}
      </h2>
      <p v-if="description" class="section-head__titling__description">
        {{ description }}
      </p>
    </div>
    <div class="section-head__meta-control">
      <p v-if="listLength" class="section-head__meta-control__list-count">
        {{ listCountString }}
      </p>
      <router-link
        v-if="listUrl"
        class="section-head__meta-control__view-all"
        :to="listUrl"
      >
        View All
      </router-link>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.section-head {
  position: relative;
  width: calc(100% - 8rem);
  display: inline-block;
  padding: 3rem 6rem 0 0;
  margin-top: 2rem;
  text-align: left;
  border-top: none;

  &--bt {
    border-top: 1px solid $color-section-head-border;
  }

  .section-head__titling {
    display: inline-block;

    &__description {
      color: $color-gray-medium;

      @include respond-to('small') {
        display: none;
      }
    }

    &__name {
      margin-bottom: 0;
    }
  }

  .section-head__meta-control {
    display: inline-block;
    float: right;
    font: $body2;
    text-align: right;

    &__list-count {
      padding-right: 0.6rem;
    }

    &__view-all {
      text-decoration: none;
      font: $body2;

      &__icon {
        @include font-size(1.8);

        vertical-align: middle;
      }

      @include respond-to('small') {
        display: none;
      }
    }
  }

  @include respond-to('small') {
    width: calc(100% - 2.5rem);
    padding: 2.5rem 2.5rem 0 0;
  }
}
</style>
