<script>
import { Capacitor } from '@capacitor/core'

import analytics from '@/utilities/analytics'
import BackHomeNav from '@/components/BackHomeNav.vue'
import ItEmblem from '@/components/svgs/ItEmblem.vue'
import MiniSearch from '@/components/search/MiniSearch.vue'
import NotificationBadge from '@/components/notifications/NotificationBadge.vue'
import NotificationList from '@/components/notifications/NotificationList.vue'
import PopMenu from '@/components/menus/PopMenu.vue'
import { removeActionsCache } from '@/utilities/actionsCache'
import { useAuthStore } from '@/stores/AuthStore'
import { usePaymentsStore } from '@/stores/PaymentsStore'
import { useNotificationsStore } from '@/stores/NotificationsStore'
import UserAvatar from '@/components/users/UserAvatar.vue'

export const NOTIFICATION_RETRIES_BEFORE_DISABLING = 3

export default {
  components: {
    BackHomeNav,
    PopMenu,
    ItEmblem,
    UserAvatar,
    MiniSearch,
    NotificationBadge,
    NotificationList,
  },
  setup() {
    return {
      authStore: useAuthStore(),
      paymentsStore: usePaymentsStore(),
      notificationsStore: useNotificationsStore(),
    }
  },
  data() {
    return {
      boardURL: 'https://italictype.nolt.io/',
      isRunningNativeMobile: Capacitor.isNativePlatform(),
      longPollingInterval: 1000 * 60, // Set this to check every minute
      longPollingIntervalId: null,
      notificationFailureCount: 0,
      utilityMenu: [
        { name: 'Settings', url: '/settings' },
        { name: 'Import and Export', url: '/import' },
        { name: 'Feedback', emit: 'feedback', textColorClass: 'feedback-nav-link' },
        { name: 'Logout', emit: 'logout', textColorClass: 'text-color--destructive' },
      ],
    }
  },
  computed: {
    membershipRoute() {
      // The membership nav route changes depending on if the customer is a subscriber or not.
      let route = { name: 'membership-payments' }
      if (this.paymentsStore.isCustomerActiveSubscriber)
        route = { name: 'membership' }

      return route
    },
    toRoute() {
      let route = '/home'
      if ('back' in this.$route.query)
        route = this.$route.query.back

      return route
    },
  },
  created() {
    // Get customer to see if we should show the membership nav item.
    this.paymentsStore.getCustomer()
      .catch(() => {
        this.paymentsStore.setAlert({
          type: 'error',
          message: 'We could not retrieve your membership information. Please try again.',
          active: true,
        })
      })

    /*
     * Get initial notifications and then set an interval to try to pull in new notifications
     * every longPollingInterval until destroy.
     */
    this.getUnreadNotificationsWrapper()
    this.longPollingIntervalId = setInterval(
      this.getUnreadNotificationsWrapper,
      this.longPollingInterval,
    )
  },
  beforeUnmount() {
    this.clearNotificationInterval()
  },
  mounted() {
    this.$nextTick(() => {
      const { nolt } = window
      nolt('init', { selector: 'li.feedback-nav-link', url: this.boardURL })
      nolt('identify', { jwt: this.authStore.user.nolt_token })
    })
  },
  methods: {
    getUnreadNotificationsWrapper() {
      /* Wrap retrieving notifications so that we can catch any errors thrown in the case of
       * a user logged out
       */
      this.notificationsStore.getUnreadNotifications()
        .then(() => {
          // Reset back to 0 on successfully getting unread notifications
          this.notificationFailureCount = 0
        })
        .catch(() => {
          this.notificationFailureCount += 1
          if (this.notificationFailureCount >= NOTIFICATION_RETRIES_BEFORE_DISABLING) {
            /* We've received 3 consecutive failures on retrieving notifications. Clear the
             * interval and reset the failure count. Likely the user is no longer logged in
             * and we have not torn down state.
             */
            this.clearNotificationInterval()
          }
        })
    },
    clearNotificationInterval() {
      if (this.longPollingIntervalId) {
        this.notificationFailureCount = 0
        clearInterval(this.longPollingIntervalId)
      }
    },
    proxyEvent(eventName) {
      /* PopMenu events now only emit menuItemClicked, and it passes the event name that would
       * have previously been emitted.
       */
      if (eventName === 'logout') {
        this.onLogout()
      }
      else if (eventName === 'feedback') {
        // We do nothing here. Nolt is triggered on the class being clicked.
      }
    },
    onLogout() {
      analytics.trackEvent('Logging Out')
      this.authStore.logout()
        .then(() => {
          removeActionsCache()
          analytics.resetUser()
          this.$router.replace({ name: 'signin' })
          setTimeout(() => {
            window.location.reload() // Reload to reset initial state (TODO: pinia way?)
          }, 0)
        })
    },
  },
}
</script>

<template>
  <div class="global-nav" :class="{ onSearch: $route.name === 'search' }">
    <div class="global-nav__left">
      <router-link v-slot="{ navigate }" custom :to="{ name: 'app-root' }">
        <span class="global-nav__left__logo" @click="navigate"><ItEmblem id="it" /></span>
      </router-link>
      <span v-if="$route.name === 'search'" class="global-nav__left__search-page-title">Search For Books:</span>
      <MiniSearch v-else class="global-nav__left__search" />
    </div>
    <div v-if="!isRunningNativeMobile" class="global-nav__membership">
      <router-link v-slot="{ isExactActive, navigate }" custom :to="membershipRoute">
        <p class="global-nav__nav-item" :class="{ 'router-link-exact-active': isExactActive }" @click="navigate">
          Membership
        </p>
      </router-link>
    </div>
    <div class="global-nav__explore">
      <router-link v-slot="{ isExactActive, navigate }" custom :to="{ name: 'explore' }">
        <p class="global-nav__nav-item" :class="{ 'router-link-exact-active': isExactActive }" @click="navigate">
          Explore
        </p>
      </router-link>
    </div>
    <div class="global-nav__notification-activity">
      <NotificationList :notifications="notificationsStore.unreadNotifications">
        <p class="global-nav__nav-item">
          Activity
        </p>
      </NotificationList>
      <NotificationBadge
        v-if="notificationsStore.unreadNotifications.length"
        class="global-nav__notification-activity__badge"
      />
    </div>
    <PopMenu
      class="global-nav__user-utility-menu"
      :menu-items="utilityMenu"
      align="align-right"
      @menu-item-clicked="proxyEvent"
    >
      <div class="global-nav__user-utility-menu__user-avatar">
        <UserAvatar :user="authStore.user" width="3rem" height="3rem" />
      </div>
      <i class="global-nav__user-utility-menu__arrow-icon material-icons">arrow_drop_down</i>
    </PopMenu>
    <BackHomeNav
      v-if="$route.name !== 'home'"
      :to-route="toRoute"
      class="global-nav__back"
    />
  </div>
</template>

<style lang="scss" scoped>
.global-nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 11rem;
  z-index: 55;
  background: rgb(246 245 242 / 100%);

  @include respond-to('small') {
    background: rgb(246 245 242 / 95%);
    height: 10rem;
  }

  &__back {
    position: fixed;
    top: 8rem;
    display: inline-block;
    left: 7.5rem;
    z-index: 55;
    cursor: pointer;

    @include respond-to('small') {
      display: none;
    }
  }

  &__left {
    position: absolute;
    left: 0;
    top: 0;
    padding: 2rem 0 0 3rem;
    width: 50%;

    @include respond-to('small') {
      padding: 0.5rem 0 0 1.75rem;
      width: calc(100% - 3.2rem);
    }

    &__logo {
      display: block;
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 2.75rem;
      left: 3rem;

      #it {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      @include respond-to('small') {
        top: 2rem;
        left: 2rem;
      }
    }

    &__search-page-title {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      top: 0.75rem;
      left: 5rem;

      @include respond-to('small') {
        top: 1.5rem;
      }
    }

    &__search {
      display: block;
      position: absolute;
      width: calc(100% - 5rem);
      left: 8rem;
      top: 1.5rem;
      z-index: 2;

      @include respond-to('small') {
        width: 100%;
        top: 6.2rem;
        left: unset;
      }
    }
  }

  &__user-utility-menu {
    position: absolute;
    right: 2rem;
    top: 1.7rem;
    z-index: 55;

    @include respond-to('small') {
      right: 1.5rem;
      top: 1.8rem;
    }

    &__user-avatar {
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }

    &__arrow-icon {
      display: inline-block;
      vertical-align: middle;

      @include font-size(2);

      @include respond-to('small') {
        display: none;
      }
    }
  }

  &__notification-activity {
    position: absolute;
    right: 11rem;
    top: 2.4rem;
    z-index: 55;

    @include respond-to('small') {
      right: 7rem;
    }

    &__badge {
      position: absolute;
      top: -0.5rem;
      left: 5rem;
    }
  }

  &__explore {
    position: absolute;
    right: 20rem;
    top: 2.4rem;
    z-index: 55;

    @include respond-to('small') {
      right: 14.3rem;
    }
  }

  &__membership {
    position: absolute;
    right: 29rem;
    top: 2.4rem;
    z-index: 55;

    @include respond-to('small') {
      right: 21.7rem;
    }
  }

  &__nav-item {
    cursor: pointer;

    @include font-size(1.6);

    &.router-link-exact-active {
      border-bottom: 2px solid $color-burnt-sienna;
    }
  }
}
</style>
