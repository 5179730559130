<script>
import { isObjectEmpty } from '@/utilities/empty'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  props: {
    goal: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['setReadingGoal'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    goalUnset() {
      return isObjectEmpty(this.goal)
    },
    progress() {
      let currentProgress = 0
      if (this.goal)
        currentProgress = (this.goal.finished_books_count / this.goal.number_of_books) * 100

      return currentProgress
    },
  },
  methods: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>

<template>
  <div v-if="!queueStore.loading.readingGoal" class="reading-goal">
    <div class="reading-goal__header">
      <h4>Reading Goal {{ currentYear() }}</h4>
      <button v-if="goalUnset" class="large link_btn link_btn--secondary" @click="$emit('setReadingGoal')">
        Set Goal
      </button>
      <button v-else class="large link_btn link_btn--secondary" @click="$emit('setReadingGoal')">
        {{ goal.finished_books_count }} of {{ goal.number_of_books }}
      </button>
    </div>
    <div class="progress-bar-wrap">
      <div class="progress-bar" :style="{ width: `${progress}%` }" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reading-goal {
  width: 40rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include respond-to('medium') {
    width: calc(100% - 8rem);
  }

  @include respond-to('small') {
    width: 100%;
    padding: 2.5rem;
  }
}

.progress-bar-wrap {
  position: relative;
  width: calc(100% - 0.5rem);
  height: 0.5rem;
  background-color: $color-gainsboro;
  margin: 0.5rem 0 1.5rem;
  border-radius: 4.1rem;
  overflow: hidden;

  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: $color-oxblood;
    transition: all 0.2s ease-in-out;
  }

  @include respond-to('small') {
    margin: 0.5rem 0;
  }
}
</style>
