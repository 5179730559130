<script>
import { useQueueStore } from '@/stores/QueueStore'

const INPUT_CLICKABLE_WIDTH = 88
const RESET_BUTTON_CLICKABLE_WIDTH = 16

export default {
  props: {
    bookQueueItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      tag: {
        placeholder: '',
        placeholderCopy: 'For book club...',
        value: '',
        editing: false,
        set: false,
      },
      rulerEl: null,
      inputWidth: null,
    }
  },
  watch: {
    bookQueueItem(value) {
      if (value.label) {
        this.tag.value = value.label
        this.tag.set = true
        this.tag.editing = false
        setTimeout(() => {
          this.setInputWidth()
        }, 0)
      }
      else {
        this.tag.placeholder = ''
        this.tag.value = null
        this.tag.editing = false
        this.tag.set = false
        this.inputWidth = null
      }
    },
    'tag.value': function (value) {
      if (!value && this.tag.editing)
        this.tag.placeholder = this.tag.placeholderCopy
    },
  },
  mounted() {
    this.rulerEl = document.getElementsByClassName(`text_ruler-${this.bookQueueItem.id}`)[0]
    if (this.bookQueueItem.label) {
      this.tag.value = this.bookQueueItem.label
      this.tag.set = true
      this.tag.editing = false
      setTimeout(() => {
        this.setInputWidth()
      }, 0)
    }
    else {
      this.tag.placeholder = ''
      this.tag.value = null
      this.tag.editing = null
      this.tag.set = false
      this.inputWidth = null
    }
  },
  methods: {
    handleClick(e) {
      const rect = e.target.getBoundingClientRect()
      const x = e.clientX - rect.left
      if (this.tag.set) {
        this.editTagContent()
      }
      else {
        if (x < INPUT_CLICKABLE_WIDTH) {
          if (this.tag.editing && x < RESET_BUTTON_CLICKABLE_WIDTH) {
            this.resetTagContent()
            this.$refs[this.bookQueueItem.id].blur()
          }
          else {
            this.editTagContent()
          }
        }
      }
    },
    handleEnter() {
      this.$refs[this.bookQueueItem.id].blur()
    },
    handleBlur() {
      if (this.tag.value)
        this.setTagContent()
      else
        this.resetTagContent()
    },
    setInputWidth(reset) {
      if (this.rulerEl) {
        if (this.tag.value) {
          if (reset) {
            this.inputWidth = null
          }
          else {
            if (this.rulerEl.offsetWidth <= 125)
              this.inputWidth = `width: ${this.rulerEl.offsetWidth + 28}px;`
            else
              this.inputWidth = 'width: 100%;'
          }
        }
      }
    },
    editTagContent() {
      if (!this.tag.value)
        this.tag.placeholder = this.tag.placeholderCopy

      this.tag.set = false
      this.tag.editing = true
      this.setInputWidth(true)
    },
    resetTagContent() {
      this.tag.placeholder = ''
      this.tag.value = null
      this.tag.editing = null
      this.tag.set = false
      this.queueStore.clearBookLabel({ bookQueueItem: this.bookQueueItem })
        .catch(() => {
          this.queueStore.setAlert({
            type: 'error',
            message: 'We could not clear your book label. Please try again.',
            active: true,
          })
        })
    },
    setTagContent() {
      this.tag.set = true
      this.tag.editing = false
      this.setInputWidth()
      this.queueStore.addLabelToBook({ bookQueueItem: this.bookQueueItem, label: this.tag.value })
        .catch(() => {
          this.queueStore.setAlert({
            type: 'error',
            message: 'We could not add your book label. Please try again.',
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div
    v-if="bookQueueItem"
    :id="`book-tag_${bookQueueItem.id}`"
    class="book-tag"
    :class="{ editing: tag.editing, set: tag.set }"
  >
    <div class="label-separator" />
    <button class="open-close-x">
      <span class="plus-trigger">
        <i class="material-icons">add</i>
      </span>
    </button>
    <p class="empty-label">
      Add Label
    </p>
    <input
      :ref="bookQueueItem.id"
      v-model="tag.value"
      type="text"
      :placeholder="tag.placeholder"
      :style="inputWidth"
      @click="handleClick"
      @blur="handleBlur"
      @keyup.enter="handleEnter"
    >
    <div class="ruler" :class="`text_ruler-${bookQueueItem.id}`">
      <p>{{ tag.value }}</p>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-tag {
  position: relative;

  @include font-size(1.2);

  button {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 1.2rem;
    height: 2.8rem;
    padding: 0;
    color: $color-black;
    z-index: 1;
    border: 0;

    span {
      display: block;
      height: 100%;
      transition: all 0.18s ease-in-out;

      i {
        color: $color-gray-medium;
        position: relative;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);

        @include font-size(1.4);
      }
    }

    @include respond-to('small') {
      width: 1.6rem;
      height: 2.2rem;
      top: 1px;

      span {
        i {
          @include font-size(1.2);
        }
      }
    }
  }

  .empty-label {
    @include font-size(1.2);

    display: block;
    color: $color-gray-medium;
    position: absolute;
    top: 6px;
    margin-bottom: 0;
    left: 1.5rem;
    padding: 0;
    z-index: 1;
    border: 0;
    transition: all 0.18s ease-in-out;

    @include respond-to('small') {
      @include font-size(1.1);

      top: 4px;
    }
  }

  .ruler {
    @include font-size(1.3);

    position: absolute;
    padding-right: 0.3rem;
    visibility: hidden;
  }

  input {
    position: relative;
    min-width: 8.8rem;
    width: 8.8rem;
    height: 2.8rem;
    outline: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;

    @include font-size(1.2);

    z-index: 2;
    transition: all 0.18s ease-in-out;

    &:focus {
      background-color: transparent;
    }

    @include respond-to('small') {
      min-width: 8rem;
      width: 8rem;
      height: 2.2rem;
      padding-top: 0.1rem;

      @include font-size(1.1);
    }
  }

  &.editing {
    input {
      padding-left: 2rem;
      width: calc(100% - 2rem);
    }

    button {
      span {
        transform: rotate(45deg);
      }
    }

    .empty-label {
      display: none;
    }

    @include respond-to('small') {
      input {
        width: calc(100% - 2.2rem);
      }
    }
  }

  &.set {
    input {
      position: relative;
      color: $color-san-felix;
      max-width: 95%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    button {
      opacity: 0;
    }

    .empty-label {
      display: none;
    }
  }
}

.label-separator {
  width: 2.2rem;
  border-top: 1px solid $color-section-head-border;
}
</style>
