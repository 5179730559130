<script>
export default {
  inheritAttrs: false,
  props: {
    buttonType: {
      type: String,
      default: 'primary',
      required: false,
      validator(value) {
        // Button type must match these types
        const buttonTypes = [
          'primary',
          'primary-dark',
          'secondary',
          'secondary--inverted',
          'tertiary',
        ]
        return buttonTypes.includes(value)
      },
    },
    buttonSize: {
      type: String,
      default: 'large',
      required: false,
      validator(value) {
        // Button size must match these types.
        const buttonSizes = ['small', 'medium', 'large']
        return buttonSizes.includes(value)
      },
    },
  },
  computed: {
    buttonClass() {
      return `${this.buttonType} ${this.buttonSize}`
    },
  },
}
</script>

<template>
  <button v-bind="$attrs" :class="buttonClass">
    <slot />
  </button>
</template>

<style lang='scss' scoped>
button {
  padding: 1.4rem 3rem;
  color: $color-seashell-light;
  background-color: $color-burnt-sienna;
  font: $button-type;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $color-burnt-sienna-dark;
  }

  &[disabled],
  [disabled]:hover,
  [disabled]:active {
    opacity: 0.3;
  }

  &.large {
    padding: 1.4rem 3rem;
  }

  &.medium {
    padding: 0.8rem 1.35rem;

    @include font-size(1.1);

    font-weight: 500;
  }

  &.small {
    padding: 0.5rem 1rem;

    @include font-size(1.1);

    font-weight: 500;
  }

  &.primary {
    background-color: $color-burnt-sienna;
    border: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $color-burnt-sienna-dark;
    }
  }

  &.primary-dark {
    background-color: $color-gray-dark;
    border: none;
    opacity: 0.9;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid $color-burnt-sienna;
    color: $color-burnt-sienna;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $color-seashell-light;
    }

    &--inverted {
      background-color: transparent;
      border: 1px solid $color-white;
      color: $color-white;
      opacity: 0.9;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.tertiary {
    background-color: $color-tertiary-button-bg;
    color: $color-tertiary-button-text;
  }
}
</style>
