<script>
import { formatISO, parseISO } from 'date-fns'

import { useQueueStore } from '@/stores/QueueStore'

export default {
  props: {
    bookQueueItem: {
      type: Object,
      required: true,
    },
    isCurrentQueueItem: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      dateFinished: this.getFinishedDate(this.bookQueueItem),
      invalidDate: false,
    }
  },
  methods: {
    handleEnter($el) {
      if ($el)
        $el.target.blur()
    },
    handleBlur() {
      this.saveDateFinished()
    },
    getFinishedDate(bookQueueItem) {
      const finishedDateGetter = this.queueStore.bookQueueItemFinishedDate
      return finishedDateGetter(bookQueueItem)
    },
    saveDateFinished() {
      if (this.dateFinished === '') {
        // If the date is invalid, the input will pass a blank string
        this.invalidDate = true
      }
      else {
        const originalFinishedDate = this.getFinishedDate(this.bookQueueItem)
        const cachedDate = parseISO(this.dateFinished)
        if (originalFinishedDate !== formatISO(cachedDate, { representation: 'date' })) {
          this.queueStore.updateDateFinished({
            bookQueueItem: this.bookQueueItem,
            dateFinished: this.dateFinished,
            isCurrentQueueItem: this.isCurrentQueueItem,
          })
            .then(() => {
              this.queueStore.setAlert({
                type: 'success',
                message: `The finished date for ${this.bookQueueItem.book.title} has been updated.`,
                active: true,
              })
            })
        }
      }
    },
  },
}
</script>

<template>
  <div class="date-finished-group">
    <p class="date-finished-value">
      <span class="date-finished-text">Finished</span>
      <input
        v-model="dateFinished"
        class="date-finished-input"
        type="date"
        placeholder="mm/dd/yyyy"
        :class="{ error: invalidDate }"
        @blur="handleBlur"
        @click="invalidDate = false"
        @keyup.enter="handleEnter"
      >
    </p>
  </div>
</template>

<style lang='scss' scoped>
.date-finished-value {
  position: relative;

  .date-finished-input {
    position: relative;
    background-color: $color-white-smoke;
    border-radius: 0.3rem;
    border: 0;
    opacity: 1;
    padding: 0 0.3rem 0 0.5rem;
    width: 14rem;
    margin-left: 0.4rem;
    text-align: center;
    display: inline-block;
    vertical-align: middle;

    &:focus {
      color: $color-burnt-sienna;
    }

    @include respond-to('medium') {
      width: 10.4rem;
      margin-left: 0.2rem;
      padding: 0 0 0 0.2rem;
    }
  }

  .error {
    border: 1px solid red;
  }
}

input[type="date"] {
  font: inherit;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: $color-nobel;
  height: 1.3rem;
  position: relative;
  width: 1.3rem;
  z-index: 0;
  cursor: pointer;

  @include respond-to('medium') {
    display: none;
  }
}
</style>
