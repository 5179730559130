<script>
import useVuelidate from '@vuelidate/core'
import { helpers, minLength, required, sameAs } from '@vuelidate/validators'

import { useAuthStore } from '@/stores/AuthStore'
import { useQueueStore } from '@/stores/QueueStore'
import MiniLoader from '@/components/loaders/MiniLoader.vue'

export default {
  components: {
    MiniLoader,
  },
  setup() {
    return {
      authStore: useAuthStore(),
      queueStore: useQueueStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      reNewPassword: '',
      errors: {},
      resetPasswordFormError: false,
      resetPasswordSubmitted: false,
    }
  },
  validations() {
    return {
      currentPassword: {
        required: helpers.withMessage('Current password is required.', required),
      },
      newPassword: {
        required: helpers.withMessage('New password is required.', required),
        minLength: helpers.withMessage(
          ({ $params }) => `New password must be at least ${$params.min} characters long.`,
          minLength(8),
        ),
      },
      reNewPassword: {
        required: helpers.withMessage('Confirming new password is required.', required),
        sameAsNewPassword: helpers.withMessage(
          () => 'New passwords must match',
          sameAs(this.newPassword),
        ),
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length)
          this.errors = {}
      },
      deep: true,
    },
  },
  methods: {
    blurInputFieldsOnCancel() {
      const inputs = document.getElementsByTagName('input')
      for (let index = 0; index < inputs.length; ++index)
        inputs[index].blur()
    },
    cancelResetPassword() {
      this.blurInputFieldsOnCancel()
      this.v$.$reset()
      this.resetPasswordData()
      this.stateReset()
    },
    resetPasswordData() {
      this.currentPassword = ''
      this.newPassword = ''
      this.reNewPassword = ''
    },
    stateReset() {
      this.errors = {}
      this.resetPasswordFormError = false
      this.resetPasswordSubmitted = false
    },
    submitResetPassword() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.resetPasswordSubmitted = true
        this.authStore.setNewPassword({
          current_password: this.currentPassword,
          new_password: this.newPassword,
        })
          .then(() => {
            this.v$.$reset()
            this.resetPasswordData()
            this.stateReset()
            this.queueStore.setAlert({
              type: 'success',
              message: 'You have successfully changed your password.',
              active: true,
            })
          },
          (error) => {
            this.v$.$reset()
            this.resetPasswordData()
            this.errors = error.data || {}
            this.resetPasswordSubmitted = false
          })
      }
    },
  },
}
</script>

<template>
  <div class="user-form">
    <form @submit.prevent="submitResetPassword">
      <h3 class="user-form__form-header">
        Reset Password
      </h3>
      <div class="user-form__input-wrap">
        <BaseInput
          v-model.trim="currentPassword"
          type="password"
          label="Current Password"
          bg-color="inverted"
          placeholder="Current password"
          :class="{ error: v$.currentPassword.$error }"
          @blur="v$.currentPassword.$touch()"
        />
        <template v-if="v$.currentPassword.$error">
          <p
            v-for="error in v$.currentPassword.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
          >
            {{ error.$message }}
          </p>
        </template>
        <div v-if="errors.current_password" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.current_password" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="user-form__input-wrap">
        <BaseInput
          v-model.trim="newPassword"
          type="password"
          label="New password"
          bg-color="inverted"
          placeholder="New password"
          :class="{ error: v$.newPassword.$error }"
          @blur="v$.newPassword.$touch()"
        />
        <template v-if="v$.newPassword.$error">
          <p
            v-for="error in v$.newPassword.$errors"
            :key="error.$uid"
            class="form-error-msg--absolute"
          >
            {{ error.$message }}
          </p>
        </template>
        <div v-if="errors.new_password" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.new_password" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="user-form__input-wrap">
        <BaseInput
          v-model.trim="reNewPassword"
          type="password"
          label="Confirm new password"
          bg-color="inverted"
          placeholder="Confirm new password"
          :class="{ error: v$.reNewPassword.$error }"
          @blur="v$.reNewPassword.$touch()"
        />
        <template v-if="v$.reNewPassword.$error">
          <p
            v-for="error in v$.reNewPassword.$errors"
            :key="error.$uid"
            class="form-error-msg"
          >
            {{ error.$message }}
          </p>
        </template>
        <div v-if="errors.re_new_password" class="form-error-msg--absolute">
          <p v-for="(error, index) in errors.re_new_password" :key="index">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="user-form__input-wrap__buttons">
        <BaseButton
          button-size="medium"
          button-type="secondary"
          type="button"
          @mousedown.prevent="cancelResetPassword"
        >
          Cancel
        </BaseButton>
        <BaseButton
          button-size="medium"
          button-type="primary"
          type="submit"
          :disabled="v$.$errors.length || resetPasswordSubmitted"
        >
          <MiniLoader v-if="resetPasswordSubmitted" />
          <span v-else>Save</span>
        </BaseButton>
      </div>
    </form>
  </div>
</template>
