<script>
export default {
  name: 'BookBoardEdit',
}
</script>

<template>
  <div class="icon open-book">
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g id="Web-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="BookBoard-Overlay" transform="translate(-438.000000, -215.000000)" stroke="#78403E" stroke-width="2">
          <g id="edit" transform="translate(439.000000, 216.000000)">
            <path id="Shape" d="M18,12.66 L18,18 C18,19.1045695 17.1045695,20 16,20 L2,20 C0.8954305,20 0,19.1045695 0,18 L0,4 C-2.22044605e-16,2.8954305 0.8954305,2 2,2 L7.34,2" />
            <polygon id="Shape" points="16 0 20 4 10 14 6 14 6 10" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
