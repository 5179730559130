<script>
export default {
  props: {
    toRoute: {
      type: String,
      default: '/home',
    },
  },
  computed: {
    friendlyRouteName() {
      let routeName = this.toRoute
      if (!routeName)
        return ''

      routeName = routeName.toString()
      const nameToDisplay = routeName.split('/')[1]
      if (nameToDisplay === 'board')
        return 'To Book Board'

      return `To ${nameToDisplay.charAt(0).toUpperCase()}${nameToDisplay.slice(1)}`
    },

  },
}
</script>

<template>
  <div>
    <router-link v-slot="{ navigate }" custom :to="toRoute">
      <div class="back-link" @click="navigate">
        <p>
          <i class="material-icons">arrow_back</i>
          <span><slot>Back {{ friendlyRouteName }}</slot></span>
        </p>
      </div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.back-link {
  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
    margin-right: 0.6rem;
  }

  span {
    position: relative;
    top: 0.1rem;
  }
}
</style>
