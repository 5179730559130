<script>
import ChatMessageNew from '@/components/spine/chatgroups/ChatMessageNew.vue'
import ChatMessageUpdate from '@/components/spine/chatgroups/ChatMessageUpdate.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useSpineStore } from '@/stores/SpineStore'

export default {
  components: {
    ChatMessageNew,
    ChatMessageUpdate,
    MiniLoader,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      focusMode: false,
      chatBodyEl: null,
      dragSortOptions: {
        delay: 0,
        disabled: false,
        animation: 350,
        ghostClass: 'sortable-ghost',
      },
      isInitialPageLoad: true,
    }
  },
  watch: {
    group: {
      handler(group) {
        this.spineStore.loading.chatGroup = true
        this.fetchMessages(group.id)
      },
      immediate: true,
    },
  },
  mounted() {
    this.chatBodyEl = document.getElementById('chat-thread')
    this.adjustScrollHeight()
  },
  methods: {
    adjustScrollHeight() {
      this.$nextTick(() => {
        this.chatBodyEl.scrollTo({
          top: this.chatBodyEl.scrollHeight,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
    handleFocusModeOn() {
      this.focusMode = true
    },
    handleFocusModeOff() {
      this.focusMode = false
    },
    handleNewMessageAdded() {
      this.adjustScrollHeight()
    },
    fetchMessages(groupId) {
      this.spineStore.getChatGroupMessages(groupId)
        .then(() => {
          this.spineStore.loading.chatGroup = false
          this.adjustScrollHeight()
          this.spineStore.updateChatGroupsUnreadMessages([{ id: groupId, unread_messages: false }])
        })
        .catch(() => {
          this.spineStore.setAlert({
            type: 'error',
            message: 'Unable to retrieve chat messages. Please try again.',
            active: true,
          })
        })
    },
    handleLoadingMoreMessages() {
      this.spineStore.loading.moreMessages = true
      this.isInitialPageLoad = false
      const currentScrollHeight = this.chatBodyEl.scrollHeight
      this.spineStore.getNextPageOfMessages()
        .then(() => {
          this.chatBodyEl.scrollTop = this.chatBodyEl.scrollHeight - currentScrollHeight
          this.spineStore.loading.moreMessages = false
        })
    },
    handleImageLoaded() {
      if (this.isInitialPageLoad)
        this.adjustScrollHeight()
    },
  },
}
</script>

<template>
  <div v-if="!spineStore.loading.chatGroup" class="chat-thread">
    <div class="chat-thread__body">
      <div v-if="spineStore.chatGroupMessages.length" class="chat-thread__body__wrap">
        <button
          v-if="spineStore.chatGroupMessagesNextURL"
          class="tertiary small chat-thread__body__wrap__load-more-btn"
          @click="handleLoadingMoreMessages"
        >
          <span v-if="spineStore.loading.moreMessages">
            <MiniLoader class="chat-thread__body__wrap__load-more-btn__loader" color-class="dark" />
          </span>
          <span v-else>
            Load Earlier Messages
            <i class="material-icons chat-thread__body__wrap__load-more-btn__arrow">arrow_upward</i>
          </span>
        </button>
        <p v-else class="chat-thread__body__wrap__beginning-msg">
          You've reached the beginning of your chat thread.
        </p>
      </div>
      <ChatMessageUpdate
        v-for="message in spineStore.chatGroupMessages"
        :key="message.id"
        :message="message"
        @focus-mode-on="handleFocusModeOn"
        @focus-mode-off="handleFocusModeOff"
        @image-loaded="handleImageLoaded"
      />
      <transition name="fade">
        <div v-if="!spineStore.chatGroupMessages.length" class="chat-thread__body__empty-cta">
          <p>
            This is awesome. You're about to read {{ group.book.title }} together! Even better when you have a dedicated discussion space.
          </p>
          <p>
            Here are some ideas to start the discussion:
          </p>
          <p>
            <ul>
              <li>Share why you want to read this book?</li>
              <li>What are you hoping to learn?</li>
              <li>Give each other some motivation!</li>
            </ul>
          </p>
        </div>
      </transition>
      <ChatMessageNew
        class="chat-thread__body__new-message"
        :chat-group="group"
        @new-message-added="handleNewMessageAdded"
      />
      <div class="chat-thread__body__focused-shader" :class="{ on: focusMode }" />
      <div class="chat-thread__body__focused-nav-shader" :class="{ on: focusMode }" />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.chat-thread {
  position: relative;
  height: auto;

  &__body {
    padding-bottom: 13rem;

    &__wrap {
      margin-top: 15rem;

      @include respond-to('medium') {
        margin-top: 5rem;
      }

      &__load-more-btn {
        position: relative;

        i {
          position: relative;
          top: -0.1rem;
          vertical-align: middle;
          margin-left: 0.5rem;

          @include font-size(1.5);
        }
      }

      &__beginning-msg {
        position: relative;
        color: $color-gray-medium;
      }
    }

    &__empty-cta {
      position: absolute;
      width: 100%;
      text-align: left;
      top: 14rem;

      p {
        @include font-size(1.4);

        color: $color-gray-medium;
      }

      ul {
        list-style-position: inside;
      }
    }

    &__new-message {
      position: fixed;
      bottom: 3rem;
      width: calc(100% - 46rem);
      left: 37rem;
      z-index: 1;
    }

    &__focused-shader {
      position: fixed;
      top: 0;
      left: 28rem;
      width: calc(100% - 28rem);
      height: 100%;
      background-color: rgb(255 255 255 / 100%);
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 50%, 0);
      transition: all 0.35s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      box-shadow: 0 1px 9px 0 rgb(9 0 0 / 8%);

      &.on {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
        z-index: 5;
      }
    }

    &__focused-nav-shader {
      position: fixed;
      top: 0;
      left: 0;
      width: 28rem;
      height: 100%;
      background-color: rgb(255 255 255 / 100%);
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.35s cubic-bezier(0.17, 0.67, 0.83, 0.67);

      &.on {
        opacity: 0.7;
        visibility: visible;
        z-index: 5;
      }
    }

    @include respond-to('medium') {
      padding: 8.5rem 1.5rem 10rem;

      &__edit {
        bottom: 1.2rem;
        left: 1.5rem;
      }

      &__new-message {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 1;
      }
    }
  }

  @include respond-to('medium') {
    height: 100%;
    padding: 0 0 9rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.15s ease-in-out;
  opacity: 1;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
