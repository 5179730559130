<script>
import BookListHeader from './BookListHeader.vue'
import ExploreBookListLoadMoreCard from './ExploreBookListLoadMoreCard.vue'
import ExploreBookListCard from './ExploreBookListCard.vue'
import LoadingBookCarousel from './LoadingBookCarousel.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    BookListHeader,
    ExploreBookListCard,
    ExploreBookListLoadMoreCard,
    LoadingBookCarousel,
  },
  props: {
    topList: {
      type: Boolean,
      default: false,
    },
    curatedList: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
    }
  },
  created() {
    if (this.curatedList.curatedbooks.length === 0) {
      this.fetchBooks()
    }
    else {
      // We already have this lists books in storage, so just show em
      this.isLoading = false
    }
  },
  methods: {
    loadNextPageOfBooks() {
      this.isLoadingMore = true
      this.queueStore.getNextPageOfCuratedBooks(this.curatedList)
        .then(() => {
          this.isLoadingMore = false
        })
        .catch(() => {
          this.queueStore.setAlert({
            type: 'error',
            message: `Unable to retrieve more books for the list ${this.curatedList.title}. Please try again.`,
            active: true,
          })
        })
    },
    fetchBooks() {
      this.isLoading = true
      this.queueStore.getCuratedBooksForList(this.curatedList)
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.queueStore.setAlert({
            type: 'error',
            message: `Unable to retrieve the book list for ${this.curatedList.title}. Please try again.`,
            active: true,
          })
        })
    },
  },
}
</script>

<template>
  <div class="book-list">
    <BookListHeader
      :border-top="!topList"
      :name="curatedList.title"
      :description="curatedList.short_description"
      :list-length="curatedList.curatedbooks.length"
      :list-total-count="curatedList.curatedbooksCount"
    />
    <LoadingBookCarousel v-if="isLoading" />
    <div v-else>
      <div v-if="curatedList.curatedbooks.length" class="book-list__book-carousel">
        <ExploreBookListCard
          v-for="item in curatedList.curatedbooks"
          :key="item.id"
          :item="item"
          :show-curation-details="curatedList.show_curation_details"
        />
        <ExploreBookListLoadMoreCard
          v-if="curatedList.curatedbooksNextURL !== null"
          :is-loading-more="isLoadingMore"
          @load-more-books="loadNextPageOfBooks"
        />
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.book-list {
  &__book-carousel {
    position: relative;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 3rem 8rem 6rem;

    &::after {
      content: "";
      flex: 0 0 1rem;
    }

    @include respond-to('small') {
      padding: 1.6rem 8rem 2rem 2rem;
    }
  }

  &__mobile-view-all {
    display: none;
    font: $body2;
    text-align: left;
    padding-left: 2.5rem;
    text-decoration: none;
    text-transform: capitalize;

    .material-icons {
      @include font-size(1.8);

      vertical-align: middle;
    }

    @include respond-to('small') {
      display: block;
    }
  }
}
</style>
