<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    noImageCharacter: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      default: '3rem',
    },
    height: {
      type: String,
      default: '3rem',
    },
  },
  computed: {
    displayString() {
      let display = '?'
      if (this.noImageCharacter) {
        // Whatever display character was passed in
        display = this.noImageCharacter
      }
      else if (this.user) {
        // User's first initial
        const name = this.user.name ? this.user.name.split(' ')[0] : this.user.username
        display = name.charAt(0)
      }
      return display
    },
    randomBackgroundColor() {
      let backgroundColor = '#78403e'
      if (this.user) {
        const colors = ['#bb6a3e', '#78403e', '#1d5330']
        const name = this.user.name ? this.user.name : this.user.username
        backgroundColor = colors[name.length % colors.length]
      }
      return backgroundColor
    },
  },
}
</script>

<template>
  <div
    v-if="user && user.avatar_thumbnail"
    :style="{
      backgroundImage: `url(${user.avatar_thumbnail})`,
      width,
      height,
      backgroundColor: randomBackgroundColor,
    }"
    class="avatar"
  />
  <div
    v-else
    :style="{ backgroundColor: randomBackgroundColor, width, height }"
    class="avatar no_image"
  >
    <span>{{ displayString }}</span>
  </div>
</template>

<style lang='scss' scoped>
.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-oxblood;

  &.no_image {
    text-align: center;

    span {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: $color-white;
      text-transform: uppercase;
    }
  }
}
</style>
