<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import VueMultiselect from 'vue-multiselect'

import CloseX from '@/components/svgs/CloseX.vue'
import { emailArray } from '@/utilities/validators'
import { useSpineStore } from '@/stores/SpineStore'
import { useUsersStore } from '@/stores/UsersStore'

export default {
  components: {
    CloseX,
    VueMultiselect,
  },
  props: {
    chatGroup: {
      type: Object,
      required: true,
    },
    /**
     * If this modal is used in another view other than home layout
     * provide an optional prop to route back home after submitting
     * a recommendation:
     */
    goHomeAfter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup() {
    return {
      spineStore: useSpineStore(),
      usersStore: useUsersStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      recipients: [],
      chatGroupInviteError: false,
      errorDetail: '',
      submitted: false,
      options: [],
      multiselectCustomCSS: true,
    }
  },
  validations() {
    return {
      recipients: {
        required: helpers.withMessage('At least one friend is required.', required),
        emailArray: helpers.withMessage('Please enter a valid email.', emailArray),
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length) {
          this.chatGroupInviteError = false
          this.errorDetail = ''
        }
      },
      deep: true,
    },
  },
  created() {
    /*
     * Initialize options with the list of contacts from state.
     */
    this.options = [...this.usersStore.contacts]
  },
  methods: {
    addNewContact(contactEmail) {
      const contact = {
        name: '',
        email: contactEmail.trim(),
        user: {
          avatar_thumbnail_square: '',
        },
      }
      this.options.push(contact)
      this.recipients.push(contact)
    },
    handleSkip() {
      if (this.goHomeAfter)
        this.$router.push({ name: 'app-root' })

      this.$emit('close')
    },
    handleAddReaders() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true
        const payload = {
          groupId: this.chatGroup.id,
          recipients: this.recipients,
        }
        this.spineStore.addReadersToChatGroup(payload)
          .then(() => {
            if (this.goHomeAfter)
              this.$router.push({ name: 'app-root' })

            this.spineStore.setAlert({
              type: 'success',
              message: 'You have sucessfully invited new readers to your group.',
              active: true,
            })
            this.$emit('close')
          })
          .catch((error) => {
            this.submitted = false
            this.chatGroupInviteError = true
            this.errorDetail = (error.data && error.data.non_field_errors)
              ? error.data.non_field_errors[0]
              : 'There was an error creating your chat group invites. Please try again.'
          })
      }
    },
  },
}
</script>

<template>
  <div class="modal">
    <div v-if="chatGroup" class="modal__container">
      <div @click="$emit('close')">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2>Add Readers</h2>
        <p>Add additional readers by entering their email. They will be sent an invite. When they accept, you'll be notified. And then keep the reading party going!</p>
      </div>
      <div class="modal__container__head__alerts">
        <p v-if="chatGroupInviteError" class="user-form__alerts__error-msg">
          {{ errorDetail }}
        </p>
        <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
          Please correct the following errors:
        </p>
      </div>
      <form @submit.prevent="handleAddReaders">
        <div class="modal__container__body">
          <div class="modal__container__body__input">
            <label for="multiselect-friends">Friends</label>
            <VueMultiselect
              id="multiselect-friends"
              v-model="recipients"
              placeholder="Add email address or search contacts (pick at least one)."
              :class="{ custom: multiselectCustomCSS }"
              :options="options"
              :searchable="true"
              :allow-empty="true"
              :multiple="true"
              :taggable="true"
              :hide-selected="true"
              tag-placeholder="Add email address to invite friend."
              label="email"
              track-by="email"
              @tag="addNewContact"
              @close="v$.recipients.$touch()"
            />
            <template v-if="v$.recipients.$error">
              <p
                v-for="error in v$.recipients.$errors"
                :key="error.$uid"
                class="form-error-msg"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
        </div>
        <div class="modal__container__foot">
          <div class="modal__container__foot__options">
            <p class="modal__container__foot__options__skip" @click="handleSkip">
              Cancel
            </p>
            <BaseButton
              class="modal__container__foot__options__submit-btn"
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="v$.$errors.length || submitted"
            >
              Add Readers
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang='scss'>
.multiselect.custom .multiselect__tag,
.multiselect.custom .multiselect__tag span,
.multiselect.custom .multiselect__tag input {
  color: $color-white;
  background: $color-oxblood;
}

.multiselect.custom .multiselect__option .multiselect__option--highlight {
  background: $color-oxblood;
}

.multiselect.custom .multiselect__tag-icon::after {
  color: $color-gainsboro;
}

.multiselect.custom .multiselect__tag-icon:focus,
.multiselect.custom .multiselect__tag-icon:hover {
  background-color: $color-oxblood-dark;
}

.multiselect.custom .multiselect__tag-icon:focus::after,
.multiselect.custom .multiselect__tag-icon:hover::after {
  color: $color-white;
}

.multiselect.custom .multiselect__option--highlight {
  background: $color-oxblood;
  color: $color-white;
}

.multiselect.custom .multiselect__option--highlight::after {
  background: $color-oxblood;
  color: $color-white;
}

.modal {
  &__container {
    &__foot {
      &__options {
        text-align: right;
        float: none;
      }
    }

    &__body {
      margin-top: 2.5rem;

      &__input {
        position: relative;
        margin-top: 2.5rem;
        width: 100%;

        label {
          @include font-size(1.2);

          font: $body2;
          margin-bottom: 0.5rem;
          display: block;
        }

        textarea, input {
          display: block;
          position: relative;
          width: 100%;
        }

        textarea {
          background-color: $color-seashell;
          padding: 2rem;
          height: 13rem;
          resize: none;

          @include font-size(1.3);
        }
      }
    }

    &__close {
      position: absolute;
      top: 2.5rem;
      right: 3rem;
      width: 3.5rem;
      cursor: pointer;
    }
  }
}
</style>
