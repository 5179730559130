<script>
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    MiniLoader,
  },
  props: {
    book: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'grid',
    },
  },
  emits: ['closeResults'],
  setup() {
    return {
      queueStore: useQueueStore(),
    }
  },
  computed: {
    isItemLoading() {
      return (this.queueStore.loading.saveBook.item
        && this.queueStore.loading.saveBook.item.source_uid === this.book.source_uid)
    },
    isAdded() {
      return this.saveText === 'Added'
    },
    saveText() {
      if (this.queueStore.isbnListAllBooks) {
        const foundBooks = this.queueStore.isbnListAllBooks.includes(this.book.isbn_13)
        if (foundBooks)
          return 'Added'
        else
          return 'Add'
      }
      else {
        return 'Add'
      }
    },
  },
  methods: {
    goToBook() {
      this.$router.push({
        name: 'book-detail',
        params: {
          sourceNameId: this.book.source_name_id,
          sourceUid: this.book.source_uid,
          bookSlug: this.book.title.toLowerCase().replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').split(' ').join('-'),
        },
      })
      this.$emit('closeResults')
    },
    handleAddBook(book) {
      this.queueStore.loading.saveBook.item = book
      this.queueStore.addBookToQueue({ book })
        .then((bookQueueItem) => {
          setTimeout(() => {
            const getQueue = this.queueStore.getUserBookList('queue')
            const getCompleted = this.queueStore.getUserBookList('completed')
            Promise.all([getQueue, getCompleted])
              .then(() => {
                this.queueStore.addNewIsbnToList({ isbn_13: book.isbn_13 })
                this.queueStore.loading.saveBook.item = null
                setTimeout(() => {
                  this.$emit('closeResults')
                }, 300)
                this.queueStore.setAlert({
                  type: 'success',
                  message: `${bookQueueItem.book.title} was successfully added to your reading list.`,
                  active: true,
                })
              })
              .catch(() => {
                const message = 'An error occurred updating your book lists. Please try again.'
                this.queueStore.setAlert({
                  type: 'error',
                  message: `${message}`,
                  active: true,
                })
                this.queueStore.loading.saveBook.item = null
              })
          }, 500)
        })
        .catch(() => {
          const message = 'An error occurred adding a new book to your queue. Please try again.'
          this.queueStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
          this.queueStore.loading.saveBook.item = null
        })
    },
  },
}
</script>

<template>
  <div v-if="book" class="search-result-book" :class="mode" @click="goToBook">
    <div
      v-if="book.cover_image_url"
      class="cover"
      :style="{ backgroundImage: `url(${book.cover_image_url})` }"
    />
    <img
      v-else
      class="cover"
      src="@/assets/bookcover-not-found.png"
    >

    <div class="search-result-book__meta">
      <p class="search-result-book__meta__title title-hover">
        {{ book.title }}
      </p>
      <p class="search-result-book__meta__author">
        by
        <span v-for="(author, i) in book.authors" :key="i">
          {{ author }}<span v-if="i < (book.authors.length - 1)">, </span>
        </span>
      </p>
      <p class="search-result-book__meta__binding">
        ISBN: {{ book.isbn_13 }}
      </p>
    </div>
    <button class="search-result-book__add-to-queue medium" :class="{ added: isAdded }" :disabled="isAdded" @click.stop="handleAddBook(book)">
      <MiniLoader v-if="isItemLoading" class="search-result-book__add-to-queue__loader" />
      <span v-else>
        <i v-if="isAdded" class="material-icons search-result-book__add-to-queue__added-check">check</i>
        {{ saveText }}
      </span>
    </button>
  </div>
</template>

<style lang='scss'>
.search-result-book {
  display: inline-block;
  vertical-align: top;
  margin: 1rem;
  cursor: pointer;

  .search-result-book__meta {
    width: 60%;

    &__title {
      font: $body1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;

      &:hover {
        color: $color-oxblood;
      }
    }

    &__author {
      font: $caption1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
    }

    &__binding {
      font: $caption1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .search-result-book__add-to-queue {
    position: relative;

    &__loader {
      width: 2.5rem;
      position: relative;
      margin: 0 auto;
      left: -0.2rem;
    }

    &__added-check {
      font-size: 1.4rem;
      line-height: 1rem;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -0.1rem;
      left: -0.18rem;
      color: $color-white;
    }

    &.added {
      background-color: $color-racing-green;
      border-color: $color-racing-green;
      color: $color-white;

      &:disabled {
        opacity: 1;
      }
    }
  }

  &.grid {
    .search-result-book__meta {
      width: 17rem;
      margin-top: 2rem;
    }

    .cover {
      width: 17rem;
      height: 25rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border: 8px solid $color-white;
      box-shadow: 0 0 20px 1px rgb(43 36 13 / 8%);
    }

    .search-result-book__add-to-queue {
      margin-top: 1rem;
    }
  }

  &.list {
    position: relative;
    display: block;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-white-smoke;
    transition: all 0.2s ease-in-out;

    .search-result-book__meta {
      display: inline-block;
      vertical-align: middle;
    }

    .cover {
      display: inline-block;
      vertical-align: middle;
      width: 3.75rem;
      height: 5rem;
      margin-right: 2rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border: 2px solid $color-book-cover-border;
      border-radius: 0.2rem;
      box-shadow: 0 0 5px 1px rgb(43 36 13 / 8%);
    }

    &:hover {
      border-radius: 0.3rem;

      .title-hover {
        color: $color-oxblood;
      }
    }

    .search-result-book__add-to-queue {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
