<script>
import useVuelidate from '@vuelidate/core'
import { helpers, minValue, required } from '@vuelidate/validators'

import CloseX from '@/components/svgs/CloseX.vue'
import { isObjectEmpty } from '@/utilities/empty'
import { useQueueStore } from '@/stores/QueueStore'

export default {
  components: {
    CloseX,
  },
  props: {
    goal: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  setup() {
    return {
      queueStore: useQueueStore(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      buttonLabel: this.goal.id ? 'Update' : 'Save',
      numberOfBooks: this.goal.number_of_books || null,
      readingGoalError: false,
      errorDetail: '',
      submitted: false,
    }
  },
  validations() {
    return {
      numberOfBooks: {
        required: helpers.withMessage('Number of books is required.', required),
        minValue: helpers.withMessage('Please enter a number greater than zero.', minValue(1)),
      },
    }
  },
  watch: {
    'v$.$errors': {
      handler(value) {
        /**
         * If a new front end validation error comes in, clear the previous server side errors to
         * avoid collisions.
         */
        if (value.length) {
          this.readingGoalError = false
          this.errorDetail = ''
        }
      },
      deep: true,
    },
  },
  methods: {
    currentYear() {
      return new Date().getFullYear()
    },
    handleSetReadingGoal() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.submitted = true

        // Determine whether we update an existing goal or create a new one.
        let fn = payload => (this.queueStore.setNewReadingGoal(payload))
        let payload = this.numberOfBooks
        let alertMessage = 'You have successfully created a new reading goal for this year.'
        if (!isObjectEmpty(this.goal) && this.goal.id) {
          fn = payload => (this.queueStore.updateReadingGoal(payload))
          payload = {
            id: this.goal.id,
            numberOfBooks: this.numberOfBooks,
          }
          alertMessage = 'You have sucessfully update your reading goal for this year.'
        }

        fn(payload)
          .then(() => {
            this.queueStore.setAlert({
              type: 'success',
              message: alertMessage,
              active: true,
            })
            this.$emit('close')
          })
          .catch((error) => {
            this.submitted = false
            this.readingGoalError = true
            this.errorDetail = (error.data && error.data.non_field_errors)
              ? error.data.non_field_errors[0]
              : 'You have already created a goal for this year.'
          })
      }
    },
  },
}
</script>

<template>
  <div class="modal">
    <div class="modal__container">
      <div @click="$emit('close')">
        <CloseX class="modal__container__close" />
      </div>
      <div class="modal__container__head">
        <h2>{{ currentYear() }} Reading Goal</h2>
        <p v-if="goal.id">
          Update the number of books you want to read this year.
        </p>
        <p v-else>
          How many books do you want to read this year?
        </p>
      </div>
      <div class="modal__container__head__alerts">
        <p v-if="readingGoalError" class="user-form__alerts__error-msg">
          {{ errorDetail }}
        </p>
        <p v-if="v$.$errors.length" class="user-form__alerts__error-msg">
          Please correct the following errors:
        </p>
      </div>
      <form @submit.prevent="handleSetReadingGoal">
        <div class="modal__container__body">
          <div class="user-form__input-wrap">
            <BaseInput
              v-model.number="numberOfBooks"
              label="Number of Books"
              type="text"
              placeholder="Number of Books"
              :class="{ error: v$.numberOfBooks.$error }"
              @blur="v$.numberOfBooks.$touch()"
            />
            <template v-if="v$.numberOfBooks.$error">
              <p
                v-for="error in v$.numberOfBooks.$errors"
                :key="error.$uid"
                class="form-error-msg--absolute"
              >
                {{ error.$message }}
              </p>
            </template>
          </div>
        </div>
        <div class="modal__container__foot">
          <div class="modal__container__foot__options">
            <BaseButton
              class="modal__container__foot__options__submit-btn"
              button-size="large"
              button-type="primary"
              type="submit"
              :disabled="v$.$errors.length || submitted"
            >
              {{ buttonLabel }}
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.modal {
  &__container {
    padding: 5rem 6rem;

    &__foot {
      &__options {
        text-align: right;
        float: none;
      }
    }

    &__body {
      margin-top: 2.5rem;

      &__input {
        position: relative;
        margin-top: 2.5rem;
        width: 100%;

        label {
          @include font-size(1.2);

          font: $body2;
          margin-bottom: 0.5rem;
          display: block;
        }

        input {
          display: block;
          position: relative;
          width: 100%;
        }
      }
    }

    &__close {
      position: absolute;
      top: 2.5rem;
      right: 3rem;
      width: 3.5rem;
      cursor: pointer;
    }
  }
}
</style>
