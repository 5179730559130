<script>
import AvatarFileInput from '@/components/form/AvatarFileInput.vue'
import { useAuthStore } from '@/stores/AuthStore'

export default {
  components: {
    AvatarFileInput,
  },
  setup() {
    return {
      authStore: useAuthStore(),
    }
  },
  data() {
    return {
      avatar: null,
      onboardingError: false,
      errors: {},
      previewImage: null,
      previewImageRotation: 0,
      submitted: false,
    }
  },
  methods: {
    skipOnboarding() {
      this.authStore.clearOnboardingFlag()
        .then(() => {
          this.$router.replace({ name: 'home' })
        },
        (error) => {
          this.errors = error.data || {}
          this.onboardingError = true
        })
    },
    onSubmit() {
      this.submitted = true
      const formData = new FormData()
      formData.append('avatar', this.avatar)
      formData.append('is_onboarded_web', true)
      this.authStore.updateUser(formData)
        .then(() => {
          this.$router.replace({ name: 'app-root' })
        },
        (error) => {
          this.errors = error.data || {}
          this.onboardingError = true
          this.submitted = false
        })
    },
  },
}
</script>

<template>
  <div class="full-wh-page">
    <div class="form-based-page">
      <div class="user-form">
        <div class="user-form__head">
          <h1>It's Official</h1>
          <p>Now that you're an Italic Type member, upload an avatar for your account to personalize your profile.</p>
        </div>
        <div class="user-form__alerts">
          <p v-if="onboardingError" class="user-form__alerts__error-msg">
            Please correct the following errors:
          </p>
        </div>
        <form @submit.prevent="onSubmit">
          <div class="user-form__input-wrap">
            <AvatarFileInput
              v-model="avatar"
            />
            <div v-if="errors.avatar" class="form-error-msg">
              <p v-for="(error, index) in errors.avatar" :key="index">
                {{ error }}
              </p>
            </div>
          </div>
          <BaseButton
            class="user-form__submit-btn"
            button-size="large"
            button-type="primary"
            type="submit"
            :disabled="submitted"
          >
            Get Started
          </BaseButton>
        </form>
        <p>Want to skip this for now? <a class="skip-link" href="#" @click.prevent="skipOnboarding">Skip</a></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.skip-link {
  text-decoration: none;
}
</style>
