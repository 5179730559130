const EXIF = require('exif-js')

const EXIF_ORIENTATION_ANGLE_MAPPINGS = {
  1: 0, // Upright
  // 2: // flipped horizontally
  3: 180, // upside down
  // 4: // flipped vertically
  // 5: // flipped horizontally and rotated 90 ccw
  6: 90, // 90 degree ccw
  // 7: // flipped horizontally and rotated 270 ccw
  8: 270, // 270 degree ccw
}

function getExifNumber(imageFile) {
  return new Promise((resolve) => {
    EXIF.getData(imageFile, function () {
      const allMetaData = EXIF.getAllTags(this)
      const hasOrientation = (allMetaData && allMetaData.Orientation)

      const exifNumber = (hasOrientation)
        ? allMetaData.Orientation
        : 1

      resolve(exifNumber)
    })
  })
}

function getExifOrientationFromExifNumber(exifNumber) {
  return EXIF_ORIENTATION_ANGLE_MAPPINGS[exifNumber]
}

function getExifData(imageFile) {
  return new Promise((resolve) => {
    getExifNumber(imageFile).then((exifNumber) => {
      resolve({
        exifNumber,
        exifOrientation: getExifOrientationFromExifNumber(exifNumber),
      })
    })
  })
}

export {
  getExifData,
}
