/*
 * This module manages access to announcement state so that we only show announcements one time
 * before they are acted on or removed. Of course if a user goes to a new browser, they may
 * actually be served an announcement they have already seen, but will improve as needs arise.
*/

const STORAGE_KEY = 'announcementIds'

export function storeSeenAnnouncementId(announcementId) {
  const seenAnnouncements = getSeenAnnouncementIds()
  const announcementExists = seenAnnouncements.includes(announcementId)
  if (!announcementExists) {
    seenAnnouncements.push(announcementId)
    localStorage.setItem(STORAGE_KEY, JSON.stringify(seenAnnouncements))
  }
}

export function getSeenAnnouncementIds() {
  let seenAnnouncements = []
  if (localStorage.getItem(STORAGE_KEY))
    seenAnnouncements = JSON.parse(localStorage.getItem(STORAGE_KEY))

  return seenAnnouncements
}

export function removeAllAnnouncementIds() {
  // This function removes the announcement IDs from local storage, typically on a logout.
  localStorage.removeItem(STORAGE_KEY)
}
