<script>
import ChatMessageNew from '@/components/spine/chatgroups/ChatMessageNew.vue'
import ChatMessageUpdate from '@/components/spine/chatgroups/ChatMessageUpdate.vue'
import ChatThreadParentMessage from '@/components/spine/chatgroups/ChatThreadParentMessage.vue'
import CloseX from '@/components/svgs/CloseX.vue'
import MiniLoader from '@/components/loaders/MiniLoader.vue'
import { useSpineStore } from '@/stores/SpineStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    ChatMessageNew,
    ChatMessageUpdate,
    ChatThreadParentMessage,
    CloseX,
    MiniLoader,
    ViewLoader,
  },
  emits: ['close'],
  setup() {
    return {
      spineStore: useSpineStore(),
    }
  },
  data() {
    return {
      chatBodyEl: null,
      isInitialPageLoad: true,
    }
  },
  computed: {
    replyCountString() {
      let replyOrReplies = 'Replies'
      if (this.spineStore.threadMessagesCount === 1)
        replyOrReplies = 'Reply'

      return `${this.spineStore.threadMessagesCount} ${replyOrReplies}`
    },
  },
  mounted() {
    this.chatBodyEl = document.getElementById('chat-thread-modal-id')
    this.spineStore.loading.messageThread = true
    this.fetchThreadMessages()
  },
  methods: {
    adjustScrollHeight() {
      this.$nextTick(() => {
        this.chatBodyEl.scrollTo({
          top: this.chatBodyEl.scrollHeight,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
    fetchThreadMessages() {
      this.spineStore.getThreadMessages()
        .then(() => {
          this.spineStore.loading.messageThread = false
          this.adjustScrollHeight()
        })
        .catch(() => {
          this.spineStore.loading.messageThread = false
          this.spineStore.setAlert({
            type: 'error',
            message: 'Unable to retrieve message thread. Please try again.',
            active: true,
          })
        })
    },
    handleImageLoaded() {
      if (this.isInitialPageLoad)
        this.adjustScrollHeight()
    },
    handleLoadingMoreMessages() {
      this.spineStore.loading.moreMessages = true
      this.isInitialPageLoad = false
      const currentScrollHeight = this.chatBodyEl.scrollHeight
      this.spineStore.getNextPageOfThreadMessages()
        .then(() => {
          this.chatBodyEl.scrollTop = this.chatBodyEl.scrollHeight - currentScrollHeight
          this.spineStore.loading.moreMessages = false
        })
    },
    handleNewMessageAdded() {
      this.adjustScrollHeight()
    },
  },
}
</script>

<template>
  <div class="modal">
    <div
      v-if="spineStore.threadParentMessage && spineStore.currentChatGroup"
      class="modal__container modal__container--lesspadding"
    >
      <div @click="$emit('close')">
        <CloseX class="modal__container__close modal__container__close--right" />
      </div>
      <div id="chat-thread-modal-id" class="chat-thread">
        <ChatThreadParentMessage
          class="chat-thread__parent-message"
          :parent-message="spineStore.threadParentMessage"
        />
        <ViewLoader v-if="spineStore.loading.messageThread" class="chat-thread__loader" />
        <div v-else class="chat-thread__replies">
          <p class="chat-thread__replies__counter">
            {{ replyCountString }}
          </p>
          <div
            v-if="spineStore.threadMessages && spineStore.threadMessages.length"
            class="chat-thread__replies__wrap"
          >
            <button
              v-if="spineStore.threadMessagesNextURL"
              class="tertiary small chat-thread__replies__wrap__load-more-btn"
              @click="handleLoadingMoreMessages"
            >
              <span v-if="spineStore.loading.moreMessages">
                <MiniLoader color-class="dark" />
              </span>
              <span v-else>
                Load Earlier Messages
                <i class="material-icons chat-thread__replies__wrap__load-more-btn__arrow">arrow_upward</i>
              </span>
            </button>
          </div>
          <ChatMessageUpdate
            v-for="message in spineStore.threadMessages"
            :key="message.id"
            :message="message"
            @image-loaded="handleImageLoaded"
          />
        </div>
        <ChatMessageNew
          class="chat-thread__new-reply"
          :chat-group="spineStore.currentChatGroup"
          :parent-message-id="spineStore.threadParentMessage.id"
          @new-message-added="handleNewMessageAdded"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal__container {
  &--lesspadding {
    overflow-y: initial;
    padding: 3rem 0 3rem 3rem;
    width: 60vw;

    @include respond-to('small') {
      padding: 3rem 0 2rem 2rem;
      width: unset;
    }
  }

  &__close--right {
    position: fixed;

    @include respond-to('small') {
      top: 1.5rem;
      right: 1.5rem;
      left: unset;
      width: 3rem;
    }
  }
}

.chat-thread {
  position: relative;
  padding-right: 3rem;
  padding-bottom: 10rem;
  height: 80vh;
  overflow-y: auto;

  &__parent-message {
    padding: 1rem 2.5rem 1.5rem 5.5rem;
    background-color: $color-white;
    border-bottom: 1px solid $color-gainsboro;
  }

  &__loader {
    top: 20%;

    @include respond-to('small') {
      top: 38%;
    }
  }

  &__replies {
    &__counter {
      @include font-size(1.2);

      margin-top: 1rem;
    }

    &__wrap {
      &__load-more-btn {
        position: relative;

        i {
          position: relative;
          top: -0.1rem;
          vertical-align: middle;
          margin-left: 0.5rem;

          @include font-size(1.5);
        }
      }
    }
  }

  &__new-reply {
    position: fixed;
    bottom: 3rem;
    width: 90%;
    z-index: 1;

    @include respond-to('medium') {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 1;
    }
  }

  @include respond-to('medium') {
    height: 90vh;
    padding-right: 2rem;
  }
}
</style>
