<script>
import { loadStripe } from '@stripe/stripe-js/pure'
import { mapState } from 'pinia'

import analytics from '@/utilities/analytics'
import HeroImage from '@/components/payments/HeroImage.vue'
import PaymentsFAQ from '@/components/payments/PaymentsFAQ.vue'
import SubscriptionForm from '@/components/payments/SubscriptionForm.vue'
import { isArrayEmpty, isObjectEmpty } from '@/utilities/empty'
import { usePaymentsStore } from '@/stores/PaymentsStore'
import { useQueueStore } from '@/stores/QueueStore'
import ViewLoader from '@/components/loaders/ViewLoader.vue'

export default {
  components: {
    HeroImage,
    PaymentsFAQ,
    SubscriptionForm,
    ViewLoader,
  },
  setup() {
    return {
      paymentsStore: usePaymentsStore(),
      queueStore: useQueueStore(),
    }
  },
  data() {
    return {
      isLoading: true,
      stripe: null,
    }
  },
  computed: {
    ...mapState(useQueueStore, [
      'customer',
    ]),
  },
  watch: {
    '$route.query': {
      handler(value) {
        if (value?.canceled === '1')
          analytics.trackEvent('Checkout Session Canceled', analytics.TYPE_NOT_DEFINED, {})
      },
      immediate: true,
    },
    customer(newValue) {
      if (newValue === null)
        return

      // If the customer is an active subscriber, we should not be here. Redirect to
      // members page.
      if (newValue.is_active_subscriber)
        this.$router.replace({ name: 'membership' })
    },
  },
  created() {
    this.loadStripeModule()
    if (isArrayEmpty(this.paymentsStore.prices)) {
      this.fetchProductAndPrices()
    }
    else {
      // We already have the product and prices
      this.isLoading = false
    }
  },
  methods: {
    fetchProductAndPrices() {
      this.paymentsStore.getProductAndPrices()
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          const message = 'An error occurred grabbing the current product pricing.'
          this.paymentsStore.setAlert({
            type: 'error',
            message: `${message}`,
            active: true,
          })
        })
    },
    async loadStripeModule() {
      if (isObjectEmpty(this.queueStore.settings))
        await this.queueStore.getSettings()

      this.stripe = await loadStripe(this.queueStore.settings.STRIPE_PUBLISHABLE_KEY)
    },
    redirectToCheckout(checkoutSessionId) {
      if (this.stripe === null) {
        this.paymentsStore.setAlert({
          type: 'error',
          message: 'Unable to connect to our payment processor. Please try again please.',
          active: true,
        })
      }
      analytics.trackEvent('Checkout Session Started', analytics.TYPE_NOT_DEFINED, {
        checkout_session_id: checkoutSessionId,
      })
      this.stripe.redirectToCheckout({ sessionId: checkoutSessionId })
    },
  },
}
</script>

<template>
  <div v-if="customer !== null" class="payments">
    <HeroImage />
    <ViewLoader v-if="isLoading" />
    <div v-else class="payments__body">
      <SubscriptionForm
        class="payments__body__left"
        :prices="paymentsStore.prices"
        @redirect-to-checkout="redirectToCheckout"
      />
      <PaymentsFAQ class="payments__body__right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.payments {
  width: 100%;
  position: relative;

  &__body {
    padding: 5rem 8rem;
    display: flex;
    flex-direction: row;

    &__left {
      width: 60%;
    }

    &__right {
      width: 40%;
    }
  }

  @include respond-to('medium') {
    &__body {
      padding: 3rem 2rem;
      flex-direction: column;

      &__left {
        width: 100%;
      }

      &__right {
        margin-top: 4rem;
        width: 100%;
      }
    }
  }
}
</style>
